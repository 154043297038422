import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import type { Errors, Expense, TypedObject } from '../types';

export interface UpdateExpenseReturn {
  updateExpense: Expense | { reference: string } & TypedObject | Errors;
}

interface UpdateExpenseVars {
  organizationId: string;
  expenseId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateExpenseReturn, UpdateExpenseVars>('UpdateExpense')
    .variableDefinitions({ organizationId: 'ID!', expenseId: 'ID!', input: 'UpdateExpenseInput!' })
    .union('updateExpense')
      .useVariables({ organizationId: 'organizationId',  expenseId: 'expenseId', input: 'input' })
      .onEntity('Expense')
        .scalar('organizationId')
        .scalar('date', Temporal.PlainDate.from)
        .reference('currencyId', 'Currency')
        .reference('expenseTypeId', 'ExpenseType')
        .reference('accountId', 'Account')
        .reference('walletId', 'Wallet')
        .scalar('amount')
        .scalar('description')
        .scalar('reference')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('NegativeAccountBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('ReferenceAlreadyUsed')
        .scalar('reference')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ updateExpense: data }) => {
    if (data.__typename === 'Expense') {
      Document.clearQueries([
        'Expenses',
        'WalletTransactions',
        'AccountTransactions',
        'Wallets',
        'Accounts'
      ]);
    }
  });
