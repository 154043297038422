import { useI18n } from 'solid-compose';
import { Match, Show, Switch, batch, createContext, createSignal, mergeProps, onCleanup, onMount, useContext } from 'solid-js';
import getScrollableAncestor from '../../helpers/getScrollableAncestor';
import { useFormResetter } from '../form/FormResetter';
import FormError from './FormError';
const FormContext = createContext();
const FormProvider = (propsWithoutDefaults) => {
    const props = mergeProps({
        displayGlobalError: true,
        errorWidth: 'firstInput',
        onSubmissionToggle: () => { }
    }, propsWithoutDefaults);
    const [submitting, setSubmitting] = createSignal(false);
    const [unexpectedError, setUnexpectedError] = createSignal(false);
    const [expectedError, setExpectedError] = createSignal('');
    const formResetterContextValue = useFormResetter();
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setUnexpectedError(false);
                        setExpectedError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    let formRef;
    let errorRef;
    let elements = [];
    const addElement = (validatableElement) => {
        elements = [...elements, validatableElement];
        return () => {
            elements = elements.filter((element) => element !== validatableElement);
        };
    };
    const clearError = () => {
        batch(() => {
            setUnexpectedError(false);
            setExpectedError('');
        });
    };
    const submit = (submitAction) => {
        const invalidElements = elements.filter((el) => !el.validate());
        if (invalidElements.length > 0) {
            let minTop = null;
            let firstInvalidElement = null;
            for (let i = 0; i < invalidElements.length; ++i) {
                const top = invalidElements[i].ref.getBoundingClientRect().top;
                if (minTop === null || top < minTop) {
                    minTop = top;
                    firstInvalidElement = invalidElements[i];
                }
            }
            const scrollableAncestor = getScrollableAncestor(firstInvalidElement.ref) ?? document.documentElement;
            scrollableAncestor.scrollTo({
                top: minTop - scrollableAncestor.getBoundingClientRect().top + scrollableAncestor.scrollTop - 5,
                behavior: 'smooth'
            });
            return;
        }
        setSubmitting(true);
        props.onSubmissionToggle(true);
        let width;
        const handleError = (setError) => {
            if (!width) {
                width = (elements.length > 0 && props.errorWidth === 'firstInput')
                    ? elements[0].ref.getBoundingClientRect().width
                    : formRef.firstElementChild?.getBoundingClientRect().width;
            }
            setError();
            setTimeout(() => {
                // expected error message may be empty
                if (!errorRef) {
                    return;
                }
                if (width) {
                    errorRef.style.width = `${width}px`;
                }
                const scrollableAncestor = getScrollableAncestor(errorRef) ?? document.documentElement;
                scrollableAncestor.scrollTo({
                    top: errorRef.getBoundingClientRect().top - scrollableAncestor.getBoundingClientRect().top + scrollableAncestor.scrollTop - 5,
                    behavior: 'smooth'
                });
            }, 0);
            setSubmitting(false);
            props.onSubmissionToggle(false);
        };
        const handleSuccess = () => {
            setSubmitting(false);
            props.onSubmissionToggle(false);
        };
        const handleExpectedError = (message) => {
            handleError(() => setExpectedError(message));
        };
        const handleUnexpectedError = () => {
            handleError(() => setUnexpectedError(true));
        };
        props.onSubmit(handleSuccess, handleExpectedError, handleUnexpectedError, submitAction);
    };
    const contextValue = {
        addElement,
        clearError,
        submit,
        submitting,
        expectedError,
        unexpectedError,
        ref: (el) => { errorRef = el; }
    };
    return (<FormContext.Provider value={contextValue}>
      <form onSubmit={e => e.preventDefault()} ref={formRef}>
        <Show when={props.displayGlobalError}>
          <FormErrorContainer />
        </Show>
        {props.children}
      </form>
    </FormContext.Provider>);
};
export default FormProvider;
export function useForm() {
    return useContext(FormContext);
}
export const FormErrorContainer = () => {
    const translate = useI18n();
    const formContextValue = useForm();
    return (<Switch>
      <Match when={formContextValue.expectedError()}>
        <div ref={formContextValue.ref}>
          <FormError>{formContextValue.expectedError()}</FormError>
        </div>
      </Match>
      <Match when={formContextValue.unexpectedError()}>
        <div ref={formContextValue.ref}>
          <FormError>{translate('unexpected_error')}</FormError>
        </div>
      </Match>
    </Switch>);
};
