import { Onboarding } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import AddNamedEntities from './AddNamedEntities';
const AddRevenueTypes = () => {
    const translate = useI18n();
    return (<AddNamedEntities entitiesType="REVENUE_TYPES" title={translate('add_revenue_types_title')} submitButtonLabel={translate('add_revenue_types')} inputLabel={translate('revenue_types')} addEntityButtonLabel={translate('add_another_revenue_type')} summary={<Onboarding.Description>
          <Onboarding.Note>
            <div>
              {translate('add_revenue_types_description')}
            </div>
          </Onboarding.Note>
        </Onboarding.Description>}/>);
};
export default AddRevenueTypes;
