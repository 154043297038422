import { onCleanup, onMount } from 'solid-js';
import throwIfNoOwner from '../../helpers/throwIfNoOwner';
export default function addBorders(rootElement, accessor) {
    throwIfNoOwner();
    const [styles] = accessor();
    onMount(() => {
        let scrollable = false;
        if (rootElement.scrollHeight > rootElement.clientHeight) {
            scrollable = true;
            rootElement.classList.add(styles.scrollable);
        }
        else {
            rootElement.classList.remove(styles.scrollable);
        }
        const onScroll = () => {
            if (scrollable && rootElement.scrollTop > 5) {
                rootElement.classList.add(styles.scrolling);
            }
            else {
                rootElement.classList.remove(styles.scrolling);
            }
        };
        rootElement.addEventListener('scroll', onScroll);
        onCleanup(() => document.body.removeEventListener('scroll', onScroll));
    });
}
