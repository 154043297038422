import { createClientFetcher } from '@subtile/project-shared';
import { Document } from 'fluent-graphql';
import { Invitation } from '../types';

const client = createClientFetcher({
  httpUrl: import.meta.env.VITE_AUTH_API_URL,
  wsUrl: ''
});

export interface CancelInvitationReturn {
  cancelInvitation: Pick<Invitation, 'id' | '__typename'>;
}

interface CancelInvitationVars {
  organizationId: string;
  invitationId: string;
}

export default Document
  .mutation<CancelInvitationReturn, CancelInvitationVars>('CancelInvitation')
    .variableDefinitions({ organizationId: 'ID!', invitationId: 'ID!' })
    .union('cancelInvitation')
      .useVariables({ organizationId: 'organizationId', invitationId: 'invitationId' })
      .onEntity('Invitation')
        .delete()
        .scalar('organizationId')._._._
  .makeExecutable(client);
