import { onCleanup } from 'solid-js';
export default function pressEscape(_element, accessor) {
    const onKeydown = (e) => {
        if (e.key === 'Escape') {
            accessor()(e);
        }
    };
    document.body.addEventListener('keydown', onKeydown);
    onCleanup(() => document.body.removeEventListener('keydown', onKeydown));
}
