import classNames from 'classnames';
import { Show } from 'solid-js';
import { useForm } from '../form/Form';
import Loader from '../loader/Loader';
import styles from './Button.module.css';
import ButtonType from './ButtonType';
import List, { useList } from './List';
const Button_ = (props) => {
    const formContextValue = useForm();
    const listContextValue = useList();
    function handleClick() {
        if (!props.disabled) {
            if (formContextValue && props.type === ButtonType.Submit) {
                formContextValue.submit(props.submitAction);
                return;
            }
            props.onClick?.();
        }
    }
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!props.disabled) {
                if (formContextValue && props.type === ButtonType.Submit) {
                    formContextValue.submit();
                    return;
                }
                props.onClick?.();
            }
        }
    }
    return (<Show when={(!props.loading && !formContextValue?.submitting()) || props.type !== ButtonType.Submit} fallback={<Loader />}>
      <div class={classNames(styles.button, "no-autofocus")} classList={{
            [styles.primary]: props.type === ButtonType.Primary || props.type === ButtonType.Submit,
            [styles.secondary]: props.type === ButtonType.Secondary,
            [styles.disabled]: props.disabled,
            [styles.inList]: listContextValue?.isList
        }} onClick={handleClick} onKeyDown={handleKeyDown} tabindex="0">
        {props.children}
      </div>
    </Show>);
};
const Button = Object.assign(Button_, { List });
export default Button;
