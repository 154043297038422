import { formatNumber as formatNumber_ } from 'solid-compose';
export function formatMoneyInput(amount) {
    if (amount === '') {
        return '';
    }
    return formatNumber_(amount, { useGrouping: false, trailingZeroDisplay: 'stripIfInteger' });
}
export function formatMoney(organization, amount, currency) {
    const multiCurrency = organization.currencies.filter(({ archived }) => !archived).length > 1;
    return (currency)
        ? formatNumber_(amount, { minimumFractionDigits: currency.precision || 2 }) + ((multiCurrency) ? ' ' + currency.name : '')
        : formatNumber_(amount, { minimumFractionDigits: 2 });
}
export function moneyFormatter(organization) {
    return (amount, currency) => formatMoney(organization, amount, currency);
}
