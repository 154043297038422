import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import type { AccountTransfer, Errors } from '../types';

export interface CreateCrossCurrencyAccountTransferReturn {
  createCrossCurrencyAccountTransfer: AccountTransfer | Errors;
}

interface CreateCrossCurrencyAccountTransferVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateCrossCurrencyAccountTransferReturn, CreateCrossCurrencyAccountTransferVars>('CreateCrossCurrencyAccountTransfer')
    .variableDefinitions({ organizationId: 'ID!', input: 'CreateCrossCurrencyAccountTransferInput!' })
    .union('createCrossCurrencyAccountTransfer')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('AccountTransfer')
        .scalar('organizationId')
        .scalar('date', Temporal.PlainDate.from)
        .reference('sourceCurrencyId', 'Currency')
        .reference('targetCurrencyId', 'Currency')
        .reference('sourceAccountId', 'Account')
        .reference('targetAccountId', 'Account')
        .scalar('sourceAmount')
        .scalar('targetAmount')._
      .onTypedObject('NegativeAccountBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('MismatchedAmountCrossCurrencyAccountWalletTransfers')
        .scalar('currentServerTime')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ createCrossCurrencyAccountTransfer: data }) => {
    if (data.__typename === 'AccountTransfer') {
      Document.clearQueries([
        'AccountTransfers',
        'WalletTransfers',
        'WalletTransactions',
        'AccountTransactions',
        'Accounts',
        'Wallets'
      ]);
    }
  });
