import { Route, Routes, useIsRouting, useLocation } from '@solidjs/router';
import { Route as AuthRoute, AuthRoutes, getRoute as getAuthRoute } from '@subtile/auth';
import { FinanceRoutes } from '@subtile/finance';
import { AuthHelpersProvider } from '@subtile/project-shared';
import { I18nProvider, ThemeStylesheet } from 'solid-compose';
import type { VoidComponent } from 'solid-js';
import { createEffect } from 'solid-js';
import styles from './App.module.css';
import EnsureAuthenticatedRoutes from './EnsureAuthenticatedRoutes';
import Index from './Index';

const App: VoidComponent = () => {
  const isRouting = useIsRouting();
  const location = useLocation();

  createEffect(() => {
    if (!isRouting() && document.getElementsByClassName(styles.content)[0].innerHTML === '') {
      throw new Error(`unknown route ${location.pathname}`);
    }
  });

  return (
    <>
      <ThemeStylesheet />

      <div class={styles.content}>
        <I18nProvider namespaces={['auth']}>
          <AuthRoutes />
        </I18nProvider>

        <AuthHelpersProvider
          guestRoute={getAuthRoute(AuthRoute.ProfileCompletionCheck)}
          logOutRoute={getAuthRoute(AuthRoute.LogOut)}
        >
          <I18nProvider namespaces={['finance']}>
            <FinanceRoutes />
          </I18nProvider>
        </AuthHelpersProvider>

        <I18nProvider namespaces={['portal']}>
          <Routes>
            <Route path="/" component={EnsureAuthenticatedRoutes}>
              <Route path="/" component={Index} />
            </Route>
          </Routes>
        </I18nProvider>
      </div>
    </>
  );
};

export default App;
