import { createClientFetcher } from '@subtile/project-shared';
import { Document } from 'fluent-graphql';
import { Member } from '../types';

const client = createClientFetcher({
  httpUrl: import.meta.env.VITE_AUTH_API_URL,
  wsUrl: ''
});

export interface RemoveMemberReturn {
  removeMember: Pick<Member, 'id' | '__typename'>;
}

interface RemoveMemberVars {
  organizationId: string;
  memberId: string;
}

export default Document
  .mutation<RemoveMemberReturn, RemoveMemberVars>('RemoveMember')
    .variableDefinitions({ organizationId: 'ID!', memberId: 'ID!' })
    .union('removeMember')
      .useVariables({ organizationId: 'organizationId', memberId: 'memberId' })
      .onEntity('Member')
        .delete()
        .scalar('organizationId')._
      .onTypedObject('AtLeastOneMemberRequired')
        .scalar('currentServerTime')._._._
  .makeExecutable(client);
