import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Account, Errors, TypedObject } from '../types';

export interface CreateAccountReturn {
  createAccount: Account | { name: string } & TypedObject | Errors;
}

interface CreateAccountVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateAccountReturn, CreateAccountVars>('CreateAccount')
    .variableDefinitions({ organizationId: 'ID!', input: 'CreateAccountInput!' })
    .union('createAccount')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('Account')
        .scalar('name')
        .scalar('colorTag')
        .scalar('archived')
        .scalar('balanceAmount')
        .scalar('organizationId')
        .reference('currencyId', 'Currency')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
