import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Entity, Errors, TypedObject } from '../types';

export interface RegisterUserReturn {
  registerUser:
    { createdAt: Temporal.Instant } & Entity
    | { email: string } & TypedObject
    | Errors;
}

interface RegisterUserVars {
  input: unknown;
  languageTag: string;
}

export default Document
  .mutation<RegisterUserReturn, RegisterUserVars>('RegisterUser')
    .variableDefinitions({ input: 'RegistrationInput!', languageTag: 'String!' })
    .union('registerUser')
      .useVariables({ input: 'input', languageTag: 'languageTag' })
      .onTypedObject('RegisterUserSuccess')
        .scalar('createdAt', Temporal.Instant.from)._
      .onTypedObject('EmailAlreadyUsed')
        .scalar('email')._
      .onTypedObject('PublicInputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
