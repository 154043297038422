import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Currency } from '../types';

export interface ArchiveCurrencyReturn {
  archiveCurrency: Pick<Currency, 'id' | '__typename' | 'archived'>;
}

interface ArchiveCurrencyVars {
  organizationId: string;
  currencyId: string;
}

export default Document
  .mutation<ArchiveCurrencyReturn, ArchiveCurrencyVars>('ArchiveCurrency')
    .variableDefinitions({ organizationId: 'ID!', currencyId: 'ID!' })
    .union('archiveCurrency')
      .useVariables({ organizationId: 'organizationId', currencyId: 'currencyId' })
      .onEntity('Currency')
        .scalar('organizationId')
        .scalar('archived')._
      .onTypedObject('AtLeastOneActiveCurrencyRequired')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('CannotArchiveDefaultCurrency')
        .scalar('name')._
      .onTypedObject('CannotArchiveCurrencyLinkedToActiveFunds')
        .scalar('name')._._._
  .makeExecutable(clientFetcher);
