import { createContext, useContext } from 'solid-js';
import styles from './List.module.css';
const ListContext = createContext();
const List = (props) => {
    return (<ListContext.Provider value={{ isList: true }}>
      <div class={styles.list}>
        {props.children}
      </div>
    </ListContext.Provider>);
};
export default List;
export function useList() {
    return useContext(ListContext);
}
