import { createEffect } from 'solid-js';
import Group from './Group';
import styles from './ToggleButton.module.css';
import { useToggle } from './context';
function ToggleButton__(props) {
    const [selectedValueSignal, isDropdown] = useToggle();
    const [selectedValue, setSelectedValue] = selectedValueSignal;
    function handleKeyDown(e) {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                setSelectedValue(() => props.value);
                break;
        }
    }
    return (<span class={styles.toggleButton} classList={{
            [styles.selected]: selectedValue() === props.value,
            [styles.isDropdown]: isDropdown(),
        }} onClick={() => setSelectedValue(() => props.value)} onKeyDown={handleKeyDown} tabindex="0">
      {props.children}
    </span>);
}
;
function ToggleButton_(props) {
    const [selectedValueSignal, _isDropdown, selectedToggleButtonSignal] = useToggle();
    const [selectedValue] = selectedValueSignal;
    const [_, setSelectedToggleButtonSignal] = selectedToggleButtonSignal;
    const toggleButtonJsx = () => <ToggleButton__ {...props}/>;
    createEffect(() => {
        if (selectedValue() === props.value) {
            setSelectedToggleButtonSignal(() => toggleButtonJsx);
        }
    });
    return toggleButtonJsx();
}
;
const ToggleButton = Object.assign(ToggleButton_, { Group });
export default ToggleButton;
