import { isArrayOfObjectLiterals, isObjectLiteral } from 'object-array-utils';
export default class MutationResponseHandler {
    data;
    successTypes = {};
    expectedErrorTypes = {};
    onSuccess;
    onUnexpectedError;
    constructor(data, onSuccess, onUnexpectedError) {
        this.data = data;
        this.onSuccess = onSuccess || (() => { });
        this.onUnexpectedError = onUnexpectedError || (() => { });
    }
    onSuccessType(type, callback) {
        this.successTypes[type] = () => callback(this.data);
        return this;
    }
    onExpectedErrorType(type, callback) {
        this.expectedErrorTypes[type] = () => callback(this.data);
        return this;
    }
    handle() {
        if (isArrayOfObjectLiterals(this.data)) {
            this.onUnexpectedError();
            throw new Error();
        }
        else if (isObjectLiteral(this.data)) {
            this.handleObject(this.data);
        }
        else {
            this.onUnexpectedError();
            throw new Error(`data is of type ${typeof this.data}: ${JSON.stringify(this.data)}`);
        }
    }
    async handleObject(data) {
        const successType = this.successTypes[data.__typename];
        if (successType) {
            try {
                await successType();
            }
            catch (e) {
                this.onUnexpectedError();
                throw e;
            }
            this.onSuccess();
            return;
        }
        const expectedErrorType = this.expectedErrorTypes[data.__typename];
        if (expectedErrorType) {
            try {
                await expectedErrorType();
            }
            catch (e) {
                this.onUnexpectedError();
                throw e;
            }
            return;
        }
        if (['InputErrors', 'PublicInputErrors'].includes(data.__typename)) {
            this.onUnexpectedError();
            throw new Error(data.errors);
        }
        this.onUnexpectedError();
        throw new Error(`unknown __typename ${data.__typename}`);
    }
}
