import updateUserProfileMutation from '@finance/graphql/mutations/updateUserProfileMutation';
import findValue from '@mathieuprog/find-value';
import { MutationResponseHandler, Viewport, processResponse, useCurrentUser } from '@subtile/project-shared';
import { Button, ButtonType, Form, Panel, Select, TextField, TimeZonePicker, ToggleButton, getScrollableAncestor, spacers } from '@subtile/universal-ui';
import { getLocalizedDayOfWeek } from 'datetime-helpers';
import { useI18n, useLocale, useViewport } from 'solid-compose';
import { Show, createSignal } from 'solid-js';
import { createStore, unwrap } from 'solid-js/store';
import { ColorScheme, DateEndianness, FirstDayOfWeek, NumberFormat, getNativeLanguageNames } from 'user-locale';
import styles from './Settings.module.css';
const Profile = () => {
    const translate = useI18n();
    const [locale] = useLocale();
    const [currentUser, { refetchCurrentUser }] = useCurrentUser();
    const viewport = useViewport();
    const [selectedMenuItem, setSelectedMenuItem] = createSignal('regional_preferences');
    let regionalSettingsSectionRef;
    let appearanceSettingsSectionRef;
    const languages = () => getNativeLanguageNames(locale.supportedLanguageTags);
    const [regionalSettingsFields, setRegionalSettingsFields] = createStore({
        languageTag: currentUser()?.languageTag,
        numberFormat: currentUser()?.numberFormat,
        dateEndianness: currentUser()?.dateEndianness,
        is24HourClock: currentUser()?.is24HourClock,
        defaultTimeZone: currentUser()?.defaultTimeZone,
        defaultTimeZoneCountryCode: currentUser()?.defaultTimeZoneCountryCode,
        firstDayOfWeek: currentUser()?.firstDayOfWeek
    });
    const [appearanceSettingsFields, setAppearanceSettingsFields] = createStore({
        preferredName: currentUser()?.preferredName || '',
        colorScheme: currentUser()?.colorScheme
    });
    const handleRegionalPreferencesSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => getSubmitHandler(unwrap(regionalSettingsFields))(onSuccess, onExpectedError, onUnexpectedError);
    const handleAppearancePreferencesSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => getSubmitHandler(unwrap(appearanceSettingsFields))(onSuccess, onExpectedError, onUnexpectedError);
    function getSubmitHandler(variables) {
        const handlePreferencesSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
            const onError = (error) => {
                onUnexpectedError();
                throw error;
            };
            const response = await processResponse(updateUserProfileMutation.execute({ input: variables }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            const { updateUserProfile: data } = response;
            new MutationResponseHandler(data, onSuccess, onUnexpectedError)
                .onSuccessType('User', refetchCurrentUser)
                .handle();
        };
        return handlePreferencesSubmit;
    }
    function handleMenuItemClick(value) {
        setSelectedMenuItem(value);
        let ref;
        switch (selectedMenuItem()) {
            case 'regional_preferences':
                ref = regionalSettingsSectionRef;
                break;
            case 'appearance_settings':
                ref = appearanceSettingsSectionRef;
                break;
        }
        const scrollableAncestor = getScrollableAncestor(ref) ?? document.documentElement;
        scrollableAncestor.scrollTo({
            top: ref.getBoundingClientRect().top - scrollableAncestor.getBoundingClientRect().top + scrollableAncestor.scrollTop - 5,
            behavior: 'smooth'
        });
    }
    return (<div class={styles.settingsPage} style={{
            '--button-font-size': '14px',
            '--button-padding': '5px 8px',
            '--panel-width': '400px'
        }}>
      <div class={styles.menuContainer}>
        <Show when={viewport.width === Viewport.SmallWidth} fallback={<div class={styles.menu}>
            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'regional_preferences' }} onClick={() => handleMenuItemClick('regional_preferences')}>
              {translate('regional_preferences')}
            </div>

            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'appearance_settings' }} onClick={() => handleMenuItemClick('appearance_settings')}>
              {translate('appearance_settings')}
            </div>
          </div>}>
          <ToggleButton.Group isDropdown onChange={handleMenuItemClick} selectedValue={selectedMenuItem()}>
            <ToggleButton value="regional_preferences">{translate('regional_preferences')}</ToggleButton>
            <ToggleButton value="appearance_settings">{translate('appearance_settings')}</ToggleButton>
          </ToggleButton.Group>
        </Show>
      </div>

      <div style={{ 'flex-grow': 1 }}>{/* necessary wrapper for flex */}
        <div class={styles.content}>
          <div class={styles.section} ref={regionalSettingsSectionRef}>
            <Panel>
              <div class={styles.title}>{translate('regional_preferences')}</div>

              <Form onSubmit={handleRegionalPreferencesSubmit}>
                <spacers.formFields>
                  <Select label={translate('preferred_language')} placeholder={translate('select')} onChange={value => setRegionalSettingsFields('languageTag', value)} options={languages().map(({ tag }) => tag)} optionText={tag => findValue(languages(), ({ tag: t, name }) => t === tag && name)} required selectedOption={regionalSettingsFields.languageTag}/>

                  <Select label={translate('preferred_number_format')} placeholder={translate('select')} onChange={value => setRegionalSettingsFields('numberFormat', value)} options={[
            NumberFormat.CommaPeriod,
            NumberFormat.PeriodComma,
            NumberFormat.SpaceComma
        ]} optionText={format => {
            switch (format) {
                case NumberFormat.CommaPeriod:
                    return translate('comma_separated_thousands');
                case NumberFormat.PeriodComma:
                    return translate('period_separated_thousands');
                case NumberFormat.SpaceComma:
                    return translate('space_separated_thousands');
            }
        }} selectedOptionText={format => {
            switch (format) {
                case NumberFormat.CommaPeriod:
                    return translate('comma_separated_thousands_short');
                case NumberFormat.PeriodComma:
                    return translate('period_separated_thousands_short');
                case NumberFormat.SpaceComma:
                    return translate('space_separated_thousands_short');
            }
        }} required selectedOption={regionalSettingsFields.numberFormat}/>

                  <Select label={translate('preferred_date_format')} placeholder={translate('select')} onChange={value => setRegionalSettingsFields('dateEndianness', value)} options={[
            DateEndianness.LittleEndian,
            DateEndianness.MiddleEndian,
            DateEndianness.BigEndian
        ]} optionText={endianness => {
            switch (endianness) {
                case DateEndianness.LittleEndian:
                    return translate('date_little_endian');
                case DateEndianness.MiddleEndian:
                    return translate('date_middle_endian');
                case DateEndianness.BigEndian:
                    return translate('date_big_endian');
            }
        }} selectedOptionText={endianness => {
            switch (endianness) {
                case DateEndianness.LittleEndian:
                    return translate('date_little_endian_short');
                case DateEndianness.MiddleEndian:
                    return translate('date_middle_endian_short');
                case DateEndianness.BigEndian:
                    return translate('date_big_endian_short');
            }
        }} required selectedOption={regionalSettingsFields.dateEndianness}/>

                  <Select label={translate('preferred_time_format')} placeholder={translate('select')} onChange={value => setRegionalSettingsFields('is24HourClock', value)} options={[true, false]} optionText={is24HourClock => {
            return (is24HourClock)
                ? translate('24_hour_format')
                : translate('12_hour_format');
        }} required selectedOption={regionalSettingsFields.is24HourClock}/>

                  <TimeZonePicker label={translate('default_time_zone')} placeholder={translate('select')} onChange={(id, countryCode) => {
            setRegionalSettingsFields('defaultTimeZone', id);
            setRegionalSettingsFields('defaultTimeZoneCountryCode', countryCode);
        }} required selectedTimeZoneIdAndCountryCode={{
            timeZoneId: regionalSettingsFields.defaultTimeZone,
            countryCode: regionalSettingsFields.defaultTimeZoneCountryCode
        }}/>

                  <Select label={translate('first_day_of_week')} placeholder={translate('select')} onChange={value => setRegionalSettingsFields('firstDayOfWeek', value)} options={[
            FirstDayOfWeek.Monday,
            FirstDayOfWeek.Sunday,
            FirstDayOfWeek.Friday,
            FirstDayOfWeek.Saturday,
        ]} optionText={dayOfWeek => getLocalizedDayOfWeek(locale.languageTag, dayOfWeek).name.long} required selectedOption={regionalSettingsFields.firstDayOfWeek}/>

                  <Button type={ButtonType.Submit}>
                    {translate('save_settings')}
                  </Button>
                </spacers.formFields>
              </Form>
            </Panel>
          </div>

          <div class={styles.section} ref={appearanceSettingsSectionRef}>
            <Panel>
              <div class={styles.title}>{translate('appearance_settings')}</div>

              <Form onSubmit={handleAppearancePreferencesSubmit}>
                <spacers.formFields>
                  <TextField label={translate('preferred_name')} onChange={value => setAppearanceSettingsFields('preferredName', value)} required value={appearanceSettingsFields.preferredName}/>

                  <Select label={translate('theme')} placeholder={translate('select')} onChange={value => setAppearanceSettingsFields('colorScheme', value)} options={[
            ColorScheme.Dark,
            ColorScheme.Light
        ]} optionText={colorScheme => {
            switch (colorScheme) {
                case ColorScheme.Dark:
                    return translate('dark_mode');
                case ColorScheme.Light:
                    return translate('light_mode');
            }
        }} required selectedOption={appearanceSettingsFields.colorScheme}/>

                  <Button type={ButtonType.Submit}>
                    {translate('save_settings')}
                  </Button>
                </spacers.formFields>
              </Form>
            </Panel>
          </div>
        </div>
      </div>
    </div>);
};
export default Profile;
