import { cloneShape } from 'object-array-utils';
import { createEffect, createResource, onCleanup } from 'solid-js';
import { createDeepSignal, throwIfNoOwner } from '../utils';
const metaMap = new Map();
export function createQueryResource(document, variables_) {
    throwIfNoOwner();
    const variables = variables_ ?? {};
    const [resource, { mutate, refetch }] = createResource(fetcher(document, variables), { storage: createDeepSignal });
    const queryExecutor = document.getQueryExecutor(variables);
    addSubscriber(queryExecutor, mutate);
    cleanUpHandler(queryExecutor);
    return [resource, { refetch }];
}
export function createDynamicQueryResource(document, variables) {
    throwIfNoOwner();
    const [resource, { mutate, refetch }] = createResource(variables, fetcherWithSource(document), { storage: createDeepSignal });
    createEffect(() => {
        const variables_ = variables();
        if (!variables_) {
            return;
        }
        const queryExecutor = document.getQueryExecutor(cloneShape(variables_));
        addSubscriber(queryExecutor, mutate);
        cleanUpHandler(queryExecutor);
    });
    return [resource, { refetch }];
}
function fetcher(document, variables) {
    return (_, refetchInfo) => {
        const queryExecutor = document.getQueryExecutor(variables);
        return (refetchInfo?.refetching)
            ? queryExecutor.refetchQuery()
            : queryExecutor.execute();
    };
}
function fetcherWithSource(document) {
    return (variables, refetchInfo) => {
        const queryExecutor = document.getQueryExecutor(cloneShape(variables));
        return (refetchInfo?.refetching)
            ? queryExecutor.refetchQuery()
            : queryExecutor.execute();
    };
}
function addSubscriber(queryExecutor, mutate) {
    const meta = metaMap.get(queryExecutor);
    if (!meta) {
        const unsubscribeUpdater = queryExecutor.subscribe((updatedResource) => { mutate(() => updatedResource); });
        metaMap.set(queryExecutor, {
            count: 1,
            unsubscribeUpdater
        });
    }
    else {
        if (meta.count === 0) {
            throw new Error();
        }
        metaMap.set(queryExecutor, {
            ...meta,
            count: ++meta.count
        });
    }
}
function cleanUpHandler(queryExecutor) {
    throwIfNoOwner();
    onCleanup(() => {
        const meta = metaMap.get(queryExecutor);
        if (!meta) {
            throw new Error();
        }
        const count = --meta.count;
        metaMap.set(queryExecutor, {
            ...meta,
            count
        });
        if (count === 0) {
            meta.unsubscribeUpdater();
            metaMap.delete(queryExecutor);
        }
    });
}
