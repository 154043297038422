import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, TypedObject, Wallet } from '../types';

export interface CreateWalletReturn {
  createWallet: Wallet | { name: string } & TypedObject | Errors;
}

interface CreateWalletVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateWalletReturn, CreateWalletVars>('CreateWallet')
    .variableDefinitions({ organizationId: 'ID!', input: 'CreateWalletInput!' })
    .union('createWallet')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('Wallet')
        .scalar('name')
        .scalar('colorTag')
        .scalar('archived')
        .scalar('balanceAmount')
        .scalar('organizationId')
        .reference('currencyId', 'Currency')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
