import { getLocalizedDaysOfWeek, getMonthGrid } from 'datetime-helpers';
import { useLocale } from 'solid-compose';
import { For, Show } from 'solid-js';
import styles from './MonthGrid.module.css';
const MonthGrid = (props) => {
    const [locale] = useLocale();
    const daysOfWeek = () => getLocalizedDaysOfWeek(locale.languageTag, locale.firstDayOfWeek);
    const monthGrid = () => getMonthGrid(props.yearMonth, locale.firstDayOfWeek).flat();
    function handleDayClick(date) {
        props.onChange(date);
    }
    return (<div class={styles.monthGrid} classList={{ [styles.stretched]: props.stretched }}>
      <For each={daysOfWeek()}>{(dayOfWeek) => <div class={styles.dayOfWeekCell}>
          <span class={styles.dayOfWeek}>
            {dayOfWeek.name.narrow}
          </span>
        </div>}</For>

      <For each={monthGrid()}>{(monthGridDay) => <div style={`display: contents; ${props.formatDayCell(monthGridDay.date)}`}>
          <div classList={{
                [styles.emptyDayCell]: monthGridDay.chronology !== 'during',
                [styles.dayCell]: monthGridDay.chronology === 'during'
            }} onClick={[handleDayClick, monthGridDay.date]}>
            <Show when={monthGridDay.chronology === 'during'}>
              <div class={styles.day} classList={{ [styles.selectedDate]: props.selectedDate.equals(monthGridDay.date) }}>
                {monthGridDay.date.day}
              </div>
            </Show>
          </div>
        </div>}</For>
    </div>);
};
export default MonthGrid;
