import createAccountTransferMutation from '@finance/graphql/mutations/createAccountTransferMutation';
import createCrossCurrencyAccountTransferMutation from '@finance/graphql/mutations/createCrossCurrencyAccountTransferMutation';
import updateAccountTransferMutation from '@finance/graphql/mutations/updateAccountTransferMutation';
import updateCrossCurrencyAccountTransferMutation from '@finance/graphql/mutations/updateCrossCurrencyAccountTransferMutation';
import { Temporal } from '@js-temporal/polyfill';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, DateField, ErrorRenderer, FormDialog, Money, SelectEntity, TextField, modifiers, spacers } from '@subtile/universal-ui';
import { removeArrayElementByIndex } from 'object-array-utils';
import { formatDate, formatTime, parseNumber, useI18n, useLocale } from 'solid-compose';
import { For, Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { formatMoneyInput, moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function getInitialFormFields(accountTransfer) {
    const sourceAmount = (accountTransfer?.sourceAmount)
        ? formatMoneyInput(accountTransfer.sourceAmount)
        : '';
    const targetAmount = (accountTransfer?.targetAmount)
        ? formatMoneyInput(accountTransfer.targetAmount)
        : '';
    return {
        date: accountTransfer?.date || Temporal.Now.plainDateISO(),
        sourceAccount: accountTransfer?.sourceAccount ? { ...accountTransfer.sourceAccount } : undefined,
        targetAccount: accountTransfer?.targetAccount ? { ...accountTransfer.targetAccount } : undefined,
        sourceAmount,
        targetAmount,
        sourceCurrency: accountTransfer?.sourceCurrency ? { ...accountTransfer.sourceCurrency } : undefined,
        targetCurrency: accountTransfer?.targetCurrency ? { ...accountTransfer.targetCurrency } : undefined,
        walletTransfers: accountTransfer?.walletTransfers.map((transfer) => ({ ...transfer, sourceAmount: formatMoneyInput(transfer.sourceAmount) })) || [
            {
                sourceWallet: undefined,
                targetWallet: undefined,
                sourceAmount: ''
            }
        ]
    };
}
;
const AccountTransferFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [accountsCurrencyMatch, setAccountsCurrencyMatch] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [fields, setFields] = createStore(getInitialFormFields(props.accountTransfer));
    const [locale] = useLocale();
    const translate = useI18n();
    createEffect(() => {
        if (props.isOpen && props.accountTransfer) {
            setFields(getInitialFormFields(props.accountTransfer));
        }
    });
    createEffect(() => {
        setAccountsCurrencyMatch(!fields.sourceAccount
            || !fields.targetAccount
            || fields.sourceAccount.currency.id === fields.targetAccount.currency.id);
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        const variables = (isCrossCurrency) => ({
            input: {
                date: fields.date,
                sourceAccountId: fields.sourceAccount.id,
                targetAccountId: fields.targetAccount.id,
                ...((isCrossCurrency)
                    ? {
                        sourceCurrencyId: fields.sourceCurrency.id,
                        targetCurrencyId: fields.targetCurrency.id,
                        sourceAmount: parseNumber(fields.sourceAmount),
                        targetAmount: parseNumber(fields.targetAmount),
                        walletTransfers: fields.walletTransfers.map((walletTransfer) => ({
                            ...(walletTransfer.id)
                                ? {
                                    id: walletTransfer.id,
                                    deleted: walletTransfer.deleted || false
                                }
                                : {},
                            sourceAmount: parseNumber(walletTransfer.sourceAmount),
                            sourceWalletId: walletTransfer.sourceWallet.id,
                            targetWalletId: walletTransfer.targetWallet.id
                        }))
                    }
                    : {
                        currencyId: fields.sourceCurrency.id,
                        amount: fields.sourceAmount
                    })
            },
            organizationId: organization.id
        });
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (accountsCurrencyMatch()) ?
            ((props.accountTransfer)
                ? await processResponse(updateAccountTransferMutation.execute({ ...variables(false), accountTransferId: props.accountTransfer.id }), { onError })
                : await processResponse(createAccountTransferMutation.execute(variables(false), { onError }))) : ((props.accountTransfer)
            ? await processResponse(updateCrossCurrencyAccountTransferMutation.execute({ ...variables(true), accountTransferId: props.accountTransfer.id }, { onError }))
            : await processResponse(createCrossCurrencyAccountTransferMutation.execute(variables(true), { onError })));
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateAccountTransfer' in response) {
            data = response.updateAccountTransfer;
        }
        else if ('updateCrossCurrencyAccountTransfer' in response) {
            data = response.updateCrossCurrencyAccountTransfer;
        }
        else if ('createAccountTransfer' in response) {
            data = response.createAccountTransfer;
        }
        else if ('createCrossCurrencyAccountTransfer' in response) {
            data = response.createCrossCurrencyAccountTransfer;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('AccountTransfer', () => {
            props.onAccountTransferAddedOrEdited();
            closeDialog();
        })
            .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('MismatchedAmountCrossCurrencyAccountWalletTransfers', () => {
            onExpectedError(translate('mismatched_amount_cross_currency_account_wallet_transfers'));
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        props.onClose();
    }
    function handleWalletTransferSourceWalletChange(wallet, index) {
        setFields('walletTransfers', index, 'sourceWallet', { ...wallet });
    }
    function handleWalletTransferTargetWalletChange(wallet, index) {
        setFields('walletTransfers', index, 'targetWallet', { ...wallet });
    }
    function handleWalletTransferAmountChange(amount, index) {
        setFields('walletTransfers', index, 'sourceAmount', amount);
    }
    function handleAddWalletTransferClick() {
        setFields('walletTransfers', fields.walletTransfers.length, {
            sourceWallet: undefined,
            targetWallet: undefined,
            sourceAmount: ''
        });
    }
    function handleRemoveWalletTransferClick(index) {
        (fields.walletTransfers[index].id)
            ? setFields('walletTransfers', index, 'deleted', true)
            : setFields('walletTransfers', removeArrayElementByIndex(fields.walletTransfers, index));
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <DateField label={translate('date')} onChange={value => setFields('date', value)} value={fields.date} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('sending_account')} entities={organization.accounts} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={account => setFields('sourceAccount', { ...account })} placeholder={translate('select')} selectedEntityId={fields.sourceAccount?.id} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('receiving_account')} entities={organization.accounts} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={account => setFields('targetAccount', { ...account })} placeholder={translate('select')} selectedEntityId={fields.targetAccount?.id} readOnly={props.isViewMode} required/>

          <Show when={accountsCurrencyMatch()} fallback={<>
              <Show when={!props.isViewMode} fallback={<TextField label={translate('sending_amount')} value={formatMoney(props.accountTransfer.sourceAmount, props.accountTransfer.sourceCurrency)} readOnly/>}>
                <Money amount={<TextField label={translate('sending_amount')} type="money" value={fields.sourceAmount} required onChange={value => setFields('sourceAmount', value)}/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('sourceCurrency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.sourceCurrency?.id} required/>}/>
              </Show>

              <Show when={!props.isViewMode} fallback={<TextField label={translate('receiving_amount')} value={formatMoney(props.accountTransfer.targetAmount, props.accountTransfer.targetCurrency)} readOnly/>}>
                <Money amount={<TextField label={translate('receiving_amount')} onChange={value => setFields('targetAmount', value)} type="money" value={fields.targetAmount} required/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('targetCurrency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.targetCurrency?.id} required/>}/>
              </Show>

              <div style={{
                    'font-size': '16px',
                    'margin-top': '6px'
                }}>
                {translate('wallet_transfers')}
              </div>

              <Show when={!fields.walletTransfers[0]?.deleted}>
                <ErrorRenderer>
                  <div style={{
                    display: 'flex',
                    gap: '16px',
                    '--text-field-max-width': '100px'
                }}>
                    <SelectEntity label={translate('sending_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleWalletTransferSourceWalletChange(value, 0)} placeholder={translate('select')} selectedEntityId={fields.walletTransfers[0]?.sourceWallet?.id} readOnly={props.isViewMode} required/>

                    <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleWalletTransferTargetWalletChange(value, 0)} placeholder={translate('select')} selectedEntityId={fields.walletTransfers[0]?.targetWallet?.id} readOnly={props.isViewMode} required/>

                    <TextField label={translate('amount')} onChange={value => handleWalletTransferAmountChange(value, 0)} type="money" value={fields.walletTransfers[0]?.sourceAmount} readOnly={props.isViewMode} required/>
                  </div>
                </ErrorRenderer>
              </Show>

              <For each={removeArrayElementByIndex(fields.walletTransfers, 0)}>{(walletTransfer, i) => <Show when={!walletTransfer.deleted}>
                  <ErrorRenderer>
                    <div style={{
                        display: 'flex',
                        gap: '16px',
                        '--text-field-max-width': '100px'
                    }}>
                      <SelectEntity label={translate('sending_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleWalletTransferSourceWalletChange(value, i() + 1)} placeholder={translate('select')} selectedEntityId={walletTransfer.sourceWallet?.id} readOnly={props.isViewMode} required/>

                      <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleWalletTransferTargetWalletChange(value, i() + 1)} placeholder={translate('select')} selectedEntityId={walletTransfer.targetWallet?.id} readOnly={props.isViewMode} required/>

                      <TextField label={translate('amount')} onChange={value => handleWalletTransferAmountChange(value, i() + 1)} onClose={props.isViewMode ? undefined : (() => handleRemoveWalletTransferClick(i() + 1))} type="money" value={walletTransfer.sourceAmount} readOnly={props.isViewMode} required/>
                    </div>
                  </ErrorRenderer>
                </Show>}</For>

              <Show when={!props.isViewMode}>
                <modifiers.smallButton>
                  <Button type={ButtonType.Secondary} onClick={handleAddWalletTransferClick}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
                    </svg>
                    {translate('add_transfer_wallet')}
                  </Button>
                </modifiers.smallButton>
              </Show>
            </>}>
            <Show when={!props.isViewMode} fallback={<TextField label={translate('sending_amount')} value={formatMoney(props.accountTransfer.sourceAmount, props.accountTransfer.sourceCurrency)} readOnly/>}>
              <Money amount={<TextField label={translate('sending_amount')} type="money" value={fields.sourceAmount} required onChange={value => setFields('sourceAmount', value)}/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('sourceCurrency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.sourceCurrency?.id} required/>}/>
            </Show>
          </Show>

          <Show when={props.isViewMode}>
            <TextField label={translate('last_updated')} value={formatDate(props.accountTransfer.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainDate()) + ' ' +
                formatTime(props.accountTransfer.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainTime(), { precision: 'minute' })} readOnly/>
          </Show>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.accountTransfer ? translate('update') : translate('add')}</Button>}>
            {!props.accountTransfer.deleted &&
                <Button type={ButtonType.Secondary} onClick={() => props.onEditAccountTransferClick(props.accountTransfer)}>{translate('edit')}</Button>}
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={props.isViewMode ? translate('title_account_transfer') : (props.accountTransfer ? translate('title_edit_account_transfer') : translate('title_add_account_transfer'))}/>);
};
export default AccountTransferFormDialog;
