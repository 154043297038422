import styles from './Alert.module.css';
import AlertType from './AlertType';
const Alert = (props) => {
    return (<div class={styles.alert} classList={{
            [styles.info]: props.type === AlertType.Info
        }}>
      {props.children}
    </div>);
};
export default Alert;
