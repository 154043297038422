import { createClientFetcher } from '@subtile/project-shared';
import { Document } from 'fluent-graphql';
import { Errors, Invitation, TypedObject } from '../types';

const client = createClientFetcher({
  httpUrl: import.meta.env.VITE_AUTH_API_URL,
  wsUrl: ''
});

export interface InviteMembersReturn {
  inviteMembers:
    { invitations: Invitation[] } & TypedObject
    | Errors;
}

interface InviteMembersVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<InviteMembersReturn, InviteMembersVars>('InviteMembers')
    .variableDefinitions({ organizationId: 'ID!', input: 'InviteMembersInput!' })
    .union('inviteMembers')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onTypedObject('InviteMembersSuccess')
        .entitySet('invitations')
          .scalar('email')
          .scalar('securityCode')
          .scalar('organizationId')
          ._._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(client);
