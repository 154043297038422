import { mergeProps } from 'solid-js';
import styles from './Container.module.css';
import { TileProvider } from './context';
function Root(propsWithoutDefaults) {
    const props = mergeProps({
        isDirectionColumn: false
    }, propsWithoutDefaults);
    return (<TileProvider isDirectionColumn={props.isDirectionColumn}>
      <Container {...props}/>
    </TileProvider>);
}
;
const Container = (props) => {
    return (<div class={styles.container} classList={{ [styles.isDirectionColumn]: props.isDirectionColumn }}>
      {props.children}
    </div>);
};
export default Root;
