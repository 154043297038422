import { Navigate, Route as R, useLocation } from '@solidjs/router';
import type { ObjectLiteral } from 'object-array-utils';
import { useI18n, useLocale } from 'solid-compose';
import { For } from 'solid-js';

export enum Route {
  Team = 'TEAM',
  Profile = 'PROFILE',
  Transactions = 'TRANSACTIONS',
  Wallets = 'WALLETS',
  Wallet = 'WALLET',
  Accounts = 'ACCOUNTS',
  Account = 'ACCOUNT',
  Settings = 'SETTINGS',
  SelectOrganization = 'SELECT_ORGANIZATION',
  ConfigurationCheck = 'CONFIGURATION_CHECK',
  OrganizationConfigurationCheck = 'ORGANIZATION_CONFIGURATION_CHECK',
  OnboardingWelcome = 'ONBOARDING_WELCOME',
  OnboardingAddOrganization = 'ONBOARDING_ADD_ORGANIZATION',
  OnboardingSelectOrganization = 'ONBOARDING_SELECT_ORGANIZATION',
  OnboardingSetupOrganization = 'ONBOARDING_SETUP_ORGANIZATION',
  OnboardingAddAccounts = 'ONBOARDING_ADD_ACCOUNTS',
  OnboardingAddWallets = 'ONBOARDING_ADD_WALLETS',
  OnboardingAddRevenueTypes = 'ONBOARDING_ADD_REVENUE_TYPES',
  OnboardingAddExpenseTypes = 'ONBOARDING_ADD_EXPENSE_TYPES',
  OnboardingAddInvitees = 'ONBOARDING_ADD_INVITEES',
  OnboardingCompleted = 'ONBOARDING_COMPLETED'
}

export function renderLocaleRedirectRoutes() {
  const [locale] = useLocale();
  const location = useLocation();

  return Object.values(Route).map((routeKey) => {
    return (
      <For each={locale.supportedLanguageTags.filter((l) => l !== locale.languageTag)}>{(languageTag) =>
        <R
          path={getRouteTemplate(routeKey, languageTag)}
          component={() => {
            const params = extractRouteParams(getRouteTemplate(routeKey, languageTag), location.pathname);
            return <Navigate href={`${getRoute(routeKey, params)}${window.location.search}`} />;
          }}
        />
      }</For>
    );
  });
}

function extractRouteParams(template: string, path: string) {
  const templateParts = template.split('/');
  const pathParts = path.split('/');
  const params: ObjectLiteral = {};

  for (let i = 0; i < templateParts.length; i++) {
      if (templateParts[i].startsWith(':')) {
          const paramName = templateParts[i].slice(1);
          params[paramName] = pathParts[i];
      }
  }

  return params;
}

export function getRoute(route: Route, params: ObjectLiteral = {}, languageTag?: string) {
  const translate = useI18n();

  switch (route) {
    case Route.SelectOrganization:
      return translate('uri_finance', {}, languageTag, 'finance')
        + translate('uri_select_organization', {}, languageTag, 'finance');

    case Route.ConfigurationCheck:
      return translate('uri_finance', {}, languageTag, 'finance')
        + translate('uri_configuration_check', {}, languageTag, 'finance');

    case Route.OrganizationConfigurationCheck:
      return translate('uri_finance', {}, languageTag, 'finance')
        + translate('uri_organization_configuration_check', {}, languageTag, 'finance')
        + '/' + (params.organizationId ?? ':organizationId');

    case Route.Transactions:
      return translate('uri_finance', {}, languageTag, 'finance')
        + translate('uri_organizations', {}, languageTag, 'finance')
        + '/' + (params.organizationId ?? ':organizationId')
        + translate('uri_transactions', {}, languageTag, 'finance');

      case Route.Wallets:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_wallets', {}, languageTag, 'finance');

      case Route.Wallet:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_wallets', {}, languageTag, 'finance')
          + '/' + (params.walletId ?? ':walletId');

      case Route.Accounts:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_accounts', {}, languageTag, 'finance');

      case Route.Account:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_accounts', {}, languageTag, 'finance')
          + '/' + (params.accountId ?? ':accountId');

      case Route.Settings:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_settings', {}, languageTag, 'finance');

      case Route.Team:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_team', {}, languageTag, 'finance');

      case Route.Profile:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_profile', {}, languageTag, 'finance');

      case Route.OnboardingWelcome:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_welcome', {}, languageTag, 'finance');

      case Route.OnboardingSelectOrganization:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_select_organization', {}, languageTag, 'finance');

      case Route.OnboardingAddOrganization:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organization', {}, languageTag, 'finance');

      case Route.OnboardingSetupOrganization:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_setup', {}, languageTag, 'finance');

      case Route.OnboardingAddAccounts:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_accounts', {}, languageTag, 'finance');

      case Route.OnboardingAddWallets:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_wallets', {}, languageTag, 'finance');

      case Route.OnboardingAddRevenueTypes:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_revenue_types', {}, languageTag, 'finance');

      case Route.OnboardingAddExpenseTypes:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_expense_types', {}, languageTag, 'finance');

      case Route.OnboardingAddInvitees:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_onboarding', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_invitees', {}, languageTag, 'finance');

      case Route.OnboardingCompleted:
        return translate('uri_finance', {}, languageTag, 'finance')
          + translate('uri_organizations', {}, languageTag, 'finance')
          + '/' + (params.organizationId ?? ':organizationId')
          + translate('uri_completed', {}, languageTag, 'finance');

      default:
        throw Error('route not found');
  }
}

export function getRouteTemplate(route: Route, languageTag?: string) {
  return getRoute(route, {}, languageTag);
}
