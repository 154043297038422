import { createContext, useContext } from 'solid-js';
const RadioContext = createContext([() => undefined, () => { }]);
export const RadioProvider = (props) => {
    return (<RadioContext.Provider value={[() => props.checkedValue, props.setCheckedValue]}>
      {props.children}
    </RadioContext.Provider>);
};
export function useRadio() {
    return useContext(RadioContext);
}
