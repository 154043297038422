import { Temporal } from '@js-temporal/polyfill';
import { Select } from '@subtile/universal-ui';
import { range } from 'object-array-utils';
import { useLocale } from 'solid-compose';
import styles from './YearMonthFilter.module.css';
const YearMonthFilter = (props) => {
    const [locale] = useLocale();
    const currentYearMonth = Temporal.Now.plainDateISO().toPlainYearMonth();
    const yearMonthList = range({ count: 12 }).map((i) => currentYearMonth.subtract({ months: i }));
    function isoString(yearMonth) {
        return yearMonth.toString();
    }
    function localizedString(yearMonth) {
        return yearMonth.toLocaleString(locale.languageTag, { calendar: yearMonth.calendarId, month: 'long', year: 'numeric' });
    }
    return (<div class={styles.root}>
      <Select onChange={props.onChange} optionId={isoString} optionText={localizedString} options={yearMonthList} selectedOption={props.value}/>
    </div>);
};
export default YearMonthFilter;
