import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Account } from '../types';

export interface ArchiveAccountReturn {
  archiveAccount: Pick<Account, 'id' | '__typename' | 'archived'>;
}

interface ArchiveAccountVars {
  organizationId: string;
  accountId: string;
}

export default Document
  .mutation<ArchiveAccountReturn, ArchiveAccountVars>('ArchiveAccount')
    .variableDefinitions({ organizationId: 'ID!', accountId: 'ID!' })
    .union('archiveAccount')
      .useVariables({ organizationId: 'organizationId', accountId: 'accountId' })
      .onEntity('Account')
        .scalar('organizationId')
        .scalar('archived')._
      .onTypedObject('AtLeastOneActiveAccountRequired')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('NonZeroArchivedAccountBalance')
        .scalar('name')._._._
  .makeExecutable(clientFetcher);
