import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Errors, TypedObject } from '../types';

export interface ResendConfirmationEmailReturn {
  resendConfirmationEmail:
    { currentServerTime: Temporal.Instant } & TypedObject
    | Errors;
}

interface ResendConfirmationEmailVars {
  input: { email: string };
  languageTag: string;
}

export default Document
  .mutation<ResendConfirmationEmailReturn, ResendConfirmationEmailVars>('ResendConfirmationEmail')
    .variableDefinitions({ input: 'ResendConfirmationEmailInput!', languageTag: 'String!' })
    .union('resendConfirmationEmail')
      .useVariables({ input: 'input', languageTag: 'languageTag' })
      .onTypedObject('ResendConfirmationEmailSuccess')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('PublicInputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
