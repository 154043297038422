import createExpenseMutation from '@finance/graphql/mutations/createExpenseMutation';
import updateExpenseMutation from '@finance/graphql/mutations/updateExpenseMutation';
import { Temporal } from '@js-temporal/polyfill';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, DateField, FormDialog, Money, SelectEntity, TextArea, TextField, spacers } from '@subtile/universal-ui';
import { formatDate, formatTime, parseNumber, useI18n, useLocale } from 'solid-compose';
import { Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { formatMoneyInput, moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function getInitialFormFields(expense) {
    const amount = (expense?.amount)
        ? formatMoneyInput(expense.amount)
        : '';
    return {
        date: expense?.date || Temporal.Now.plainDateISO(),
        expenseType: expense?.expenseType ? { ...expense.expenseType } : undefined,
        wallet: expense?.wallet ? { ...expense.wallet } : undefined,
        account: expense?.account ? { ...expense.account } : undefined,
        amount,
        currency: expense?.currency ? { ...expense.currency } : undefined,
        description: expense?.description || '',
        reference: expense?.reference || ''
    };
}
;
const ExpenseFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [fields, setFields] = createStore(getInitialFormFields(props.expense));
    const [locale] = useLocale();
    const translate = useI18n();
    const [referenceAlreadyUsedError, setReferenceAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen && props.expense) {
            setFields(getInitialFormFields(props.expense));
        }
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setReferenceAlreadyUsedError('');
        const variables = {
            input: {
                date: fields.date,
                expenseTypeId: fields.expenseType.id,
                walletId: fields.wallet.id,
                accountId: fields.account.id,
                currencyId: fields.currency.id,
                amount: parseNumber(fields.amount),
                description: fields.description,
                reference: fields.reference
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (props.expense)
            ? await processResponse(updateExpenseMutation.execute({ ...variables, expenseId: props.expense.id }), { onError })
            : await processResponse(createExpenseMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateExpense' in response) {
            data = response.updateExpense;
        }
        else if ('createExpense' in response) {
            data = response.createExpense;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Expense', () => {
            props.onExpenseAddedOrEdited();
            closeDialog();
        })
            .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('ReferenceAlreadyUsed', (data) => {
            setReferenceAlreadyUsedError(translate('reference_already_used', { reference: data.reference }));
            onExpectedError('');
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        setReferenceAlreadyUsedError('');
        props.onClose();
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <DateField label={translate('date')} onChange={value => setFields('date', value)} value={fields.date} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('expense_type')} entities={organization.expenseTypes} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={expenseType => setFields('expenseType', { ...expenseType })} placeholder={translate('select')} selectedEntityId={fields.expenseType?.id} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('sending_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={wallet => setFields('wallet', { ...wallet })} placeholder={translate('select')} selectedEntityId={fields.wallet?.id} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('sending_account')} entities={organization.accounts} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={account => setFields('account', { ...account })} placeholder={translate('select')} selectedEntityId={fields.account?.id} readOnly={props.isViewMode} required/>

          <Show when={!props.isViewMode} fallback={<TextField label={translate('amount')} value={formatMoney(props.expense.amount, props.expense.currency)} readOnly/>}>
            <Money amount={<TextField label={translate('amount')} onChange={value => setFields('amount', value)} type="money" value={fields.amount} required/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('currency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.currency?.id} required/>}/>
          </Show>

          <TextArea label={translate('description')} onChange={value => setFields('description', value)} rows={3} value={props.isViewMode ? (fields.description || '-') : fields.description} readOnly={props.isViewMode}/>

          <TextField label={translate('reference')} onChange={value => { setReferenceAlreadyUsedError(''); setFields('reference', value); }} error={referenceAlreadyUsedError()} value={props.isViewMode ? (fields.reference || '-') : fields.reference} readOnly={props.isViewMode}/>

          <Show when={props.isViewMode}>
            <TextField label={translate('last_updated')} value={formatDate(props.expense.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainDate()) + ' ' +
                formatTime(props.expense.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainTime(), { precision: 'minute' })} readOnly/>
          </Show>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.expense ? translate('update') : translate('add')}</Button>}>
            {!props.expense.deleted &&
                <Button type={ButtonType.Secondary} onClick={() => props.onEditExpenseClick(props.expense)}>{translate('edit')}</Button>}
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={props.isViewMode ? translate('title_expense') : (props.expense ? translate('title_edit_expense') : translate('title_add_expense'))}/>);
};
export default ExpenseFormDialog;
