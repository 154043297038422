import styles from './IconButton.module.css';
const IconButton = (props) => {
    return (<div class={styles.iconButton} onClick={props.onClick}>
      <div class={styles.iconButtonInner}>
        <div class={styles.icon}>
          {props.children}
        </div>
      </div>
    </div>);
};
export default IconButton;
