import { useI18n } from 'solid-compose';
import { mergeProps } from 'solid-js';
import styles from './NextPrevious.module.css';
const NextPrevious = (propsWithoutDefaults) => {
    const translate = useI18n();
    const props = mergeProps({
        tooltipTextPrevious: translate('previous'),
        tooltipTextNext: translate('next'),
        nextNavigationDisabled: false,
        previousNavigationDisabled: false
    }, propsWithoutDefaults);
    function handlePreviousClick() {
        !props.previousNavigationDisabled && props.onPreviousClick();
    }
    function handleNextClick() {
        !props.nextNavigationDisabled && props.onNextClick();
    }
    return (<div class={styles.nextPrevious}>
      <div class={styles.previous} classList={{
            [styles.disabled]: props.previousNavigationDisabled
        }} onClick={handlePreviousClick}>
        <span title={props.tooltipTextPrevious}></span>
      </div>

      <div class={styles.next} classList={{
            [styles.disabled]: props.nextNavigationDisabled
        }} onClick={handleNextClick}>
        <span title={props.tooltipTextNext}></span>
      </div>
    </div>);
};
export default NextPrevious;
