import { renderLocaleRedirectRoutes } from '@auth/routes';
import { Route, Routes } from '@solidjs/router';
import { Onboarding } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import AcceptInvitation from './AcceptInvitation';
import AuthenticationMethodSelector from './AuthenticationMethodSelector';
import CreateAccount from './CreateAccount';
import CustomizeSettings from './CustomizeSettings';
import EmailConfirmation from './EmailConfirmation';
import EnsureAuthenticatedRoutes from './EnsureAuthenticatedRoutes';
import EnsureUnauthenticatedRoutes from './EnsureUnauthenticatedRoutes';
import LinkGoogleAccount from './LinkGoogleAccount';
import LogIn from './LogIn';
import LogOut from './LogOut';
import OAuthFailure from './OAuthFailure';
import ProfileCompletionCheck from './ProfileCompletionCheck';
import ResetPassword from './ResetPassword';
import ResetPasswordRequest from './ResetPasswordRequest';
import SelectTheme from './SelectTheme';
const AuthRoutes = () => {
    const translate = useI18n();
    return (<Routes>
      {renderLocaleRedirectRoutes()}

      <Route path={translate('uri_auth')} component={Onboarding.Layout}>
        <Route path={translate('uri_accept_invitation')} component={AcceptInvitation}/>

        <Route path={translate('uri_log_out')} component={LogOut}/>

        <Route path={translate('uri_email_confirmation')} component={EmailConfirmation}/>

        <Route path={translate('uri_link_google_account')} component={LinkGoogleAccount}/>

        <Route path="/" component={EnsureUnauthenticatedRoutes}>
          <Route path={translate('uri_select_signup_auth_method')} element={<AuthenticationMethodSelector mode="signup"/>}/>

          <Route path={translate('uri_log_in_with_email')} component={LogIn}/>

          <Route path={translate('uri_reset_password')} component={ResetPassword}/>

          <Route path={translate('uri_reset_password_request')} component={ResetPasswordRequest}/>

          <Route path={translate('uri_oauth_failed')} component={OAuthFailure}/>

          <Route path={translate('uri_oauth_failed')} component={OAuthFailure}/>

          <Route path={translate('uri_select_login_auth_method')} element={<AuthenticationMethodSelector mode="login"/>}/>

          <Route path={translate('uri_create_account_with_email')} component={CreateAccount}/>
        </Route>

        <Route path="/" component={EnsureAuthenticatedRoutes}>
          <Route path={translate('uri_profile_completion_check')} component={ProfileCompletionCheck}/>

          <Route path={translate('uri_customize_settings')} component={CustomizeSettings}/>

          <Route path={translate('uri_select_theme')} component={SelectTheme}/>
        </Route>
      </Route>
    </Routes>);
};
export default AuthRoutes;
