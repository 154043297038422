import logInUserMutation from '@auth/graphql/mutations/logInUserMutation';
import { useSearchParams } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, csrf, useCurrentUser } from '@subtile/project-shared';
import { OAuth, Panel, TextField, spacers } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { AuthenticationStatus, useI18n } from 'solid-compose';
import { Match, Switch } from 'solid-js';
import { createStore } from 'solid-js/store';
;
const LinkGoogleAccount = () => {
    const [searchParams] = useSearchParams();
    const [fields, setFields] = createStore({
        email: searchParams['email'] || '',
        password: ''
    });
    const [currentUser, { refetchCurrentUser, authenticationStatus }] = useCurrentUser();
    const translate = useI18n();
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        const input = {
            email: fields.email,
            password: fields.password
        };
        const { logInUser: data } = await logInUserMutation.execute({ input }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('LogInUserSuccess', async () => {
            Document.clearAllQueries();
            csrf.clearToken();
            await refetchCurrentUser();
            if (currentUser.error) {
                onUnexpectedError();
                throw currentUser.error;
            }
            if (authenticationStatus() !== AuthenticationStatus.Authenticated) {
                throw new Error('cannot authenticate user');
            }
        })
            .onExpectedErrorType('InvalidLogin', () => {
            onExpectedError(translate('invalid_login'));
        })
            .handle();
    };
    return (<Switch>
      <Match when={authenticationStatus() === AuthenticationStatus.Unauthenticated}>
        <Onboarding.FormPage title={translate('link_google_account_title')} description={translate('link_google_account_description')} buttonText={translate('log_in')} onSubmit={handleSubmit} errorWidth="firstElementChild">
          <Panel>
            <spacers.formFields>
              <TextField label={translate('email')} onChange={value => setFields('email', value)} required type="email" value={fields.email}/>

              <TextField label={translate('password')} onChange={value => setFields('password', value)} required type="password" value={fields.password}/>
            </spacers.formFields>
          </Panel>
        </Onboarding.FormPage>
      </Match>

      <Match when={authenticationStatus() === AuthenticationStatus.Authenticated}>
        <Onboarding.Page title={translate('link_google_account_title')} description={translate('link_google_account_description_step_2')}>
          <OAuth.Button onClick={() => document.location.assign(import.meta.env.VITE_GOOGLE_OAUTH_URL)} provider="google">
            <span innerHTML={translate('continue_with_google')}/>
          </OAuth.Button>
        </Onboarding.Page>
      </Match>
    </Switch>);
};
export default LinkGoogleAccount;
