import inviteMembersMutation from '@finance/graphql/mutations/inviteMembersMutation';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, FormDialog, TextField, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { Show, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function getInitialFormFields() {
    return {
        email: ''
    };
}
;
const InviteMemberFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [securityCode, setSecurityCode] = createSignal();
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore(getInitialFormFields());
    const translate = useI18n();
    const handleSubmit = async (closeDialog, onSuccess, _onExpectedError, onUnexpectedError) => {
        const variables = {
            input: {
                emails: [fields.email]
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(inviteMembersMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { inviteMembers: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('InviteMembersSuccess', ({ invitations }) => {
            setSecurityCode(invitations[0].securityCode);
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        props.onClose();
    }
    return (<Show when={securityCode()} fallback={<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
            <TextField label={translate('email')} onChange={value => setFields('email', value)} value={fields.email} type="email"/>
          </spacers.formFields>} buttons={onClose => <>
            <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
              <Button type={ButtonType.Secondary} onClick={onClose}>{translate('close')}</Button>
            </div>
            <Button type={ButtonType.Submit}>{translate('invite')}</Button>
          </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={translate('title_invite_member')} modifiers={{
                '--dialog-max-width': '400px'
            }}/>}>
      <FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
            <div>
              {translate('secure_invitations_description')}
            </div>

            <div style={{ 'font-size': '24px', 'letter-spacing': '4px', 'font-weight': '600' }}>
              {securityCode()}
            </div>
          </spacers.formFields>} buttons={onClose => <Button type={ButtonType.Secondary} onClick={onClose}>{translate('ok')}</Button>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={translate('title_invite_member')} modifiers={{
            '--dialog-max-width': '400px'
        }}/>
    </Show>);
};
export default InviteMemberFormDialog;
