import updateUserProfileMutation from '@auth/graphql/mutations/updateUserProfileMutation';
import { Route, getRoute } from '@auth/routes';
import findValue from '@mathieuprog/find-value';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { Panel, Select, TextField, focusOnFirstInput, spacers } from '@subtile/universal-ui';
import { useI18n, useLocalStorage, useLocale } from 'solid-compose';
import { createEffect, onCleanup } from 'solid-js';
import { createStore, unwrap } from 'solid-js/store';
import { DateEndianness, FirstDayOfWeek, NumberFormat, getNativeLanguageNames, guessCountryCode } from 'user-locale';
false && focusOnFirstInput;
;
;
const CustomizeSettings = () => {
    const [locale, { setLanguageTag }] = useLocale();
    const translate = useI18n();
    const navigate = useNavigate();
    const [regionalSettings, { set: setRegionalSettings, remove: removeRegionalSettings }] = useLocalStorage('regionalSettings');
    onCleanup(removeRegionalSettings);
    function deserializeNumberFormat(numberFormat) {
        if (numberFormat === undefined) {
            return undefined;
        }
        switch (numberFormat) {
            case String(NumberFormat.CommaPeriod):
                return NumberFormat.CommaPeriod;
            case String(NumberFormat.PeriodComma):
                return NumberFormat.PeriodComma;
            case String(NumberFormat.SpaceComma):
                return NumberFormat.SpaceComma;
        }
    }
    function deserializeDateEndianness(dateEndianness) {
        if (dateEndianness === undefined) {
            return undefined;
        }
        switch (dateEndianness) {
            case String(DateEndianness.LittleEndian):
                return DateEndianness.LittleEndian;
            case String(DateEndianness.MiddleEndian):
                return DateEndianness.MiddleEndian;
            case String(DateEndianness.BigEndian):
                return DateEndianness.BigEndian;
        }
    }
    function deserializeFirstDayOfWeek(firstDayOfWeek) {
        if (firstDayOfWeek === undefined) {
            return undefined;
        }
        switch (firstDayOfWeek) {
            case '1':
                return FirstDayOfWeek.Monday;
            case '7':
                return FirstDayOfWeek.Sunday;
            case '5':
                return FirstDayOfWeek.Friday;
            case '6':
                return FirstDayOfWeek.Saturday;
        }
    }
    function deserializeIs24HourClock(is24HourClock) {
        if (is24HourClock === undefined) {
            return undefined;
        }
        return is24HourClock === 'true';
    }
    const [fields, setFields] = createStore({
        preferredName: regionalSettings()?.preferredName || '',
        languageTag: regionalSettings()?.languageTag || locale.languageTag,
        numberFormat: deserializeNumberFormat(regionalSettings()?.numberFormat) || locale.numberFormat,
        dateEndianness: deserializeDateEndianness(regionalSettings()?.dateEndianness) || locale.dateFormat.endianness,
        is24HourClock: deserializeIs24HourClock(regionalSettings()?.is24HourClock) || locale.timeFormat.is24HourClock,
        defaultTimeZone: regionalSettings()?.defaultTimeZone || locale.timeZone,
        defaultTimeZoneCountryCode: regionalSettings()?.defaultTimeZoneCountryCode || guessCountryCode()[0],
        firstDayOfWeek: deserializeFirstDayOfWeek(regionalSettings()?.firstDayOfWeek) || locale.firstDayOfWeek
    });
    createEffect(() => {
        setRegionalSettings({
            preferredName: fields.preferredName,
            languageTag: fields.languageTag,
            numberFormat: fields.numberFormat,
            dateEndianness: fields.dateEndianness,
            is24HourClock: String(fields.is24HourClock),
            defaultTimeZone: fields.defaultTimeZone,
            defaultTimeZoneCountryCode: fields.defaultTimeZoneCountryCode,
            firstDayOfWeek: String(fields.firstDayOfWeek)
        });
        if (fields.languageTag !== locale.languageTag) {
            setLanguageTag(fields.languageTag);
        }
    });
    const languages = () => getNativeLanguageNames(locale.supportedLanguageTags);
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const variables = unwrap(fields);
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(updateUserProfileMutation.execute({ input: variables }), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { updateUserProfile: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('User', () => {
            navigate(getRoute(Route.ProfileCompletionCheck));
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('customize_settings_title')} description={translate('customize_settings_description')} buttonText={translate('save_settings')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          <TextField label={translate('preferred_name')} onChange={value => setFields('preferredName', value)} required value={fields.preferredName}/>

          <Select label={translate('preferred_language')} onChange={value => setFields('languageTag', value)} options={languages().map(({ tag }) => tag)} optionText={tag => findValue(languages(), ({ tag: t, name }) => t === tag && name)} required selectedOption={fields.languageTag}/>
        </spacers.formFields>
      </Panel>
    </Onboarding.FormPage>);
};
export default CustomizeSettings;
