import { Show } from 'solid-js';
import Container from './Container';
import styles from './Tile.module.css';
import { useTile } from './context';
const Tile_ = (props) => {
    const [isDirectionColumn] = useTile();
    return (<div class={styles.tile} classList={{ [styles.isDirectionColumn]: isDirectionColumn() }}>
      <Show when={props.start || props.end}>
        <div class={styles.start}>{props.start}</div>
        <div class={styles.end}>{props.end}</div>
      </Show>

      {props.children}
    </div>);
};
const Tile = Object.assign(Tile_, { Container });
export default Tile;
