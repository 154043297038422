import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document, FetchStrategy } from 'fluent-graphql';
import { fetchAccount, fetchCurrency, fetchRevenueType, fetchWallet } from '../resources/useOrganizationsResource';
import type { Entity, PaginationInput, Revenues } from '../types';

export interface RevenuesReturn {
  me: { organization: { revenues: Revenues } };
}

export interface RevenuesVars {
  organizationId: string;
  pagination: unknown;
  filters: unknown;
}

export default Document
  .query<RevenuesReturn, RevenuesVars, Revenues>('Revenues')
    .variableDefinitions({ organizationId: 'ID!', pagination: 'PaginationInput!', filters: 'RevenuesFiltersInput' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .wrapper('revenues')
          .useVariables({ revenuesPagination: 'pagination', revenuesFilters: 'filters' })
          .embed('pagination')
            .scalar('cursorDirection')
            .scalar('cursorForEntriesAfter')
            .scalar('cursorForEntriesBefore')
            .scalar('maxPageSize')
            .scalar('hasMoreEntries')._
          .entitySet('paginatedEntries', 'Revenue')
            .scalar('organizationId')
            .scalar('date', Temporal.PlainDate.from)
            .scalar('updatedAt', Temporal.Instant.from)
            .scalar('deleted')
            .virtual('loading', false)
            .scalar('amountBeforeAllocations')
            .scalar('amountAfterAllocations')
            .scalar('description')
            .scalar('reference')
            .entitySet('allocations', 'WalletTransfer')
              .scalar('organizationId')
              .scalar('deleted')
              .scalar('targetAmount')
              .entity('targetWallet', 'Wallet')
                .scalar('name')
                .scalar('colorTag')
                .entity('currency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')._
                .deriveFromReference('targetWalletId', fetchWallet)
                .replaceEntity({
                  Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { targetWalletId: string }) => {
                    // console.log('--- walletTransfer ---', walletTransfer);
                    return walletTransfer.targetWalletId === wallet.id;
                  }
                })._._
            .entity('wallet', 'Wallet')
              .scalar('name')
              .scalar('colorTag')
              .deriveFromReference('walletId', fetchWallet)
              .replaceEntity({
                Wallet: (wallet: Entity, _variables: unknown, revenue: { walletId: string }) => {
                  // console.log('--- revenue ---', revenue);
                  return revenue.walletId === wallet.id;
                }
              })._
            .entity('account', 'Account')
              .scalar('name')
              .scalar('colorTag')
              .deriveFromReference('accountId', fetchAccount)
              .replaceEntity({
                Account: (account: Entity, _variables: unknown, revenue: { accountId: string }) => {
                  // console.log('--- revenue ---', revenue);
                  return revenue.accountId === account.id;
                }
              })._
            .entity('revenueType', 'RevenueType')
              .scalar('name')
              .entitySet('allocationRules', 'RevenueAllocationRule')
                .scalar('allocationPercentage')
                .entity('targetWallet', 'Wallet')
                  .scalar('name')
                  ._._
              .deriveFromReference('revenueTypeId', fetchRevenueType)
              .replaceEntity({
                RevenueType: (revenueType: Entity, _variables: unknown, revenue: { revenueTypeId: string }) => {
                  // console.log('--- revenue ---', revenue);
                  return revenue.revenueTypeId === revenueType.id;
                }
              })._
            .entity('currency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('currencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, revenue: { currencyId: string }) => {
                  // console.log('--- revenue ---', revenue);
                  return revenue.currencyId === currency.id;
                }
              })._._._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { revenues } } }) => revenues)
  .scopeByTenants(({ organizationId }) => ({ organizationId }))
  .setRefetchStrategy(FetchStrategy.FetchFromNetworkAndRecreateCache)
  .clearAfter((variables: { pagination: PaginationInput }) => {
    if (variables.pagination?.cursor) {
      return Temporal.Duration.from({ minutes: 15 });
    }
  });
