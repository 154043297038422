import App from '@portal/components/App';
import { Router } from '@solidjs/router';
import { AwaitAuthenticationResponse } from '@subtile/project-shared';
import { createI18nPrimitive } from 'solid-compose';
import { ErrorBoundary, render } from 'solid-js/web';
import Crash from './components/Crash';
import initialize from './init';

initialize();

createI18nPrimitive({
  fallbackLanguageTag: 'en'
});

function fallback(e: any) {
  setTimeout(() => { throw e }, 0);
  return <Crash />
}

render(
  () => (
    <ErrorBoundary fallback={fallback}>
      <AwaitAuthenticationResponse>
        <Router>
          <App />
        </Router>
      </AwaitAuthenticationResponse>
    </ErrorBoundary>
  ),
  document.getElementById('root') as HTMLElement
);
