import { initialize as initializeAuth } from '@subtile/auth';
import { initialize as initializeFinance } from '@subtile/finance';
import { initialize as initializeProjectShared } from '@subtile/project-shared';
import './translations';

let initialized = false;

export default function initialize() {
  if (initialized) {
    throw new Error('portal already initialized');
  }
  initialized = true;

  initializeProjectShared();
  initializeAuth();
  initializeFinance();
}
