import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { Route, getRoute } from '@finance/routes';
import { useNavigate, useParams } from '@solidjs/router';
import { Setup, useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { isEmptyArray } from 'object-array-utils';
import { orThrow } from 'or-throw';
import { AuthenticationStatus, useLocalStorage } from 'solid-compose';
import { createEffect } from 'solid-js';
const OrganizationConfigurationCheck = (props) => {
    const [currentUser, { authenticationStatus }] = useCurrentUser();
    const [organizations] = useOrganizationsResource();
    // const [selectedOrganizationId, { set: setSelectedOrganizationId, remove: removeSelectedOrganizationId }] = useLocalStorage<string>('organizationId');
    const [invitationStepCompleted] = useLocalStorage('invitationStepCompleted', false);
    const [showWelcomePage] = useLocalStorage('showWelcomePage', true);
    const navigate = useNavigate();
    const params = useParams();
    createEffect(() => {
        if (organizations.loading) {
            return;
        }
        orThrow(authenticationStatus() === AuthenticationStatus.Authenticated);
        orThrow(currentUser());
        orThrow(organizations());
        if (!currentUser().confirmedEmailAt) {
            if (props.navigateToAuthentication) {
                props.navigateToAuthentication();
                return;
            }
            throw new Error('unconfirmed email');
        }
        const organizations_ = organizations();
        const selectedOrganization = params.organizationId && organizations_.find(({ id }) => id === params.organizationId);
        const configuredOrganizationIds = organizations_
            .filter(({ completedSetups }) => completedSetups.includes(Setup.Finance))
            .map(({ id }) => id);
        if (params.organizationId && configuredOrganizationIds.includes(params.organizationId)) {
            navigate(getRoute(Route.Transactions, { organizationId: params.organizationId }));
            return;
        }
        if (isEmptyArray(organizations_)) {
            if (showWelcomePage()) {
                navigate(getRoute(Route.OnboardingWelcome));
                return;
            }
            navigate(getRoute(Route.OnboardingAddOrganization));
            return;
        }
        if (organizations_.length === 1 && configuredOrganizationIds.includes(organizations_[0].id)) {
            navigate(getRoute(Route.Transactions, { organizationId: organizations_[0].id }));
            return;
        }
        if (!selectedOrganization) {
            navigate(getRoute(Route.OnboardingSelectOrganization));
            return;
        }
        if (isEmptyArray(selectedOrganization.currencies)) {
            navigate(getRoute(Route.OnboardingSetupOrganization, { organizationId: selectedOrganization.id }));
            return;
        }
        if (isEmptyArray(selectedOrganization.revenueTypes)) {
            navigate(getRoute(Route.OnboardingAddRevenueTypes, { organizationId: selectedOrganization.id }));
            return;
        }
        if (isEmptyArray(selectedOrganization.expenseTypes)) {
            navigate(getRoute(Route.OnboardingAddExpenseTypes, { organizationId: selectedOrganization.id }));
            return;
        }
        if (isEmptyArray(selectedOrganization.accounts)) {
            navigate(getRoute(Route.OnboardingAddAccounts, { organizationId: selectedOrganization.id }));
            return;
        }
        if (isEmptyArray(selectedOrganization.wallets)) {
            navigate(getRoute(Route.OnboardingAddWallets, { organizationId: selectedOrganization.id }));
            return;
        }
        if (!invitationStepCompleted()) {
            navigate(getRoute(Route.OnboardingAddInvitees, { organizationId: selectedOrganization.id }));
            return;
        }
        navigate(getRoute(Route.OnboardingCompleted, { organizationId: selectedOrganization.id }), { state: { organizationId: selectedOrganization.id } });
    });
    return (<spacers.viewportFill>
      <Loader />
    </spacers.viewportFill>);
};
export default OrganizationConfigurationCheck;
