import { TextDirection, useLocale } from 'solid-compose';
import getScrollableAncestor from '../../helpers/getScrollableAncestor';
export default function getPositionMenuFun({ inputElement, styles }) {
    const scrollableAncestor = getScrollableAncestor(inputElement);
    const menuElement = inputElement.querySelector(`.${styles.menu}`);
    const menuPaddingString = getComputedStyle(menuElement).getPropertyValue('padding-top');
    const menuPadding = parseFloat(menuPaddingString.match(/[\d\.]+/));
    return () => {
        const [locale] = useLocale();
        const selectedOptionElement = inputElement.querySelector(`.${styles.selectedOptionInMenu}`)
            ?? inputElement.querySelector(`.${styles.option}:first-child`);
        if (!selectedOptionElement) {
            return;
        }
        const inputRect = inputElement.getBoundingClientRect();
        let menuRect = menuElement.getBoundingClientRect();
        const selectedOptionRect = selectedOptionElement.getBoundingClientRect();
        // `menuRect.top - selectedOptionRect.top` would place the selected option at the same position as the input
        // menuElement.style.top = `${menuRect.top - selectedOptionRect.top}px`;
        // however, the menu is possibly scrollable
        // we want to:
        // 1. center the menu relative to the input and position the scrollbar so that the selected option is at the
        //    same position as the input;
        // 2. if the selected option is at the top, it happens that we can't position the selected option at the same
        //    position as the input by scrolling; we have to move the menu to the bottom;
        // 3. same if selected option is at the bottom, we may have to move the menu to the top;
        // 4. we have to make sure that the menu doesn't exceed the size of the parent container.
        // center the menu relative to the input
        const menuElementTop = (inputRect.height - menuRect.height) / 2;
        // get the index of the selected option in order to get the 'top' position of the selected option
        // we can't rely on `selectedOptionRect.top` as its value depends on the scroll position
        // use `selectedOptionRect.height * selectedOptionIndex + <top position of select box> instead
        const selectedOptionIndex = Array.from(menuElement.querySelectorAll(`.${styles.option}`)).findIndex((optionElement) => {
            // const selectedOptionIndex = Array.from(menuElement.children).findIndex((optionElement) => {
            return optionElement === selectedOptionElement;
        });
        // set the scroll at the right position, so that the selected option is at the same position as the input
        // `inputRect.top + menuElementTop` => get `menuElement`'s position: `menuElementTop` is relative to `inputElement`'s position
        let scrollTop = selectedOptionRect.height * selectedOptionIndex + (inputRect.top + menuElementTop) - inputRect.top + ((selectedOptionRect.height - inputRect.height) / 2);
        let bottom = 'auto';
        let top = 'auto';
        if (scrollTop <= 0) {
            scrollTop = 0;
            top = `${-(selectedOptionRect.height * selectedOptionIndex) - ((selectedOptionRect.height - inputRect.height) / 2) - menuPadding}px`;
        }
        else if (scrollTop >= menuElement.scrollHeight - menuElement.clientHeight) {
            bottom = `${-(selectedOptionRect.height * (Array.from(menuElement.children).length - selectedOptionIndex - 1)) - ((selectedOptionRect.height - inputRect.height) / 2) - menuPadding}px`;
            scrollTop = menuElement.scrollHeight - menuElement.clientHeight;
        }
        else {
            top = `${menuElementTop - menuPadding}px`;
        }
        menuElement.scrollTop = scrollTop;
        menuElement.style.bottom = bottom;
        menuElement.style.top = top;
        menuRect = menuElement.getBoundingClientRect();
        // Fix positionMenu to avoid an absolute element to be partially hidden
        // because of an ancestor having overflow hidden or scroll
        if (scrollableAncestor) {
            const scrollableAncestorRect = scrollableAncestor.getBoundingClientRect();
            if (scrollableAncestorRect.height < menuRect.height) {
                menuElement.style.maxHeight = `${scrollableAncestorRect.height - 10}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (menuRect.bottom > scrollableAncestorRect.bottom) {
                menuElement.style.top = 'auto';
                menuElement.style.bottom = `${inputRect.bottom - scrollableAncestorRect.bottom + 5}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (menuRect.top < scrollableAncestorRect.top) {
                menuElement.style.bottom = 'auto';
                menuElement.style.top = `${scrollableAncestorRect.top - inputRect.top + 5}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (locale.textDirection === TextDirection.LeftToRight) {
                if (menuRect.right > (scrollableAncestorRect.left + scrollableAncestor.clientWidth)) {
                    menuElement.style.left = 'auto';
                    menuElement.style.right = `${inputRect.right - (scrollableAncestorRect.left + scrollableAncestor.clientWidth) + 5}px`;
                    menuRect = menuElement.getBoundingClientRect();
                }
                if (menuRect.left < scrollableAncestorRect.left) {
                    menuElement.style.right = 'auto';
                    menuElement.style.left = `${scrollableAncestorRect.left - inputRect.left + 5}px`;
                }
            }
            else {
                if (menuRect.left < (scrollableAncestorRect.right - scrollableAncestor.clientWidth)) {
                    menuElement.style.right = 'auto';
                    menuElement.style.left = `${(scrollableAncestorRect.right - scrollableAncestor.clientWidth) - inputRect.left + 5}px`;
                    menuRect = menuElement.getBoundingClientRect();
                }
                if (menuRect.right > scrollableAncestorRect.right) {
                    menuElement.style.left = 'auto';
                    menuElement.style.right = `${inputRect.right - scrollableAncestorRect.right + 5}px`;
                }
            }
        }
        else {
            if (menuRect.bottom > document.documentElement.clientHeight) {
                menuElement.style.top = 'auto';
                menuElement.style.bottom = `${inputRect.bottom - document.documentElement.clientHeight + 5}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (menuRect.top < 0) {
                menuElement.style.bottom = 'auto';
                menuElement.style.top = `${-inputRect.top + 5}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (menuRect.right > document.documentElement.clientWidth) {
                menuElement.style.left = 'auto';
                menuElement.style.right = `${inputRect.right - document.documentElement.clientWidth + 5}px`;
                menuRect = menuElement.getBoundingClientRect();
            }
            if (locale.textDirection === TextDirection.LeftToRight) {
                if (menuRect.left < 0) {
                    menuElement.style.right = 'auto';
                    menuElement.style.left = `${-inputRect.left + 5}px`;
                }
            }
            else {
                if (menuRect.left < 0) {
                    menuElement.style.right = 'auto';
                    menuElement.style.left = `${-(inputRect.right - inputElement.clientWidth) + 5}px`;
                }
            }
        }
    };
}
