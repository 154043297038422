import { Navigate, Route as R, useLocation } from '@solidjs/router';
import { useI18n, useLocale } from 'solid-compose';
import { For } from 'solid-js';
export var Route;
(function (Route) {
    Route["SelectSignupAuthMethod"] = "SELECT_SIGNUP_AUTH_METHOD";
    Route["SelectLoginAuthMethod"] = "SELECT_LOGIN_AUTH_METHOD";
    Route["CreateAccountWithEmail"] = "CREATE_ACCOUNT_WITH_EMAIL";
    Route["LogInWithEmail"] = "LOG_IN_WITH_EMAIL";
    Route["LogOut"] = "LOG_OUT";
    Route["OauthFailed"] = "OAUTH_FAILED";
    Route["ProfileCompletionCheck"] = "PROFILE_COMPLETION_CHECK";
    Route["CustomizeSettings"] = "CUSTOMIZE_SETTINGS";
    Route["LinkGoogleAccount"] = "LINK_GOOGLE_ACCOUNT";
    Route["ResetPasswordRequest"] = "RESET_PASSWORD_REQUEST";
    Route["ResetPassword"] = "RESET_PASSWORD";
    Route["SelectTheme"] = "SELECT_THEME";
    Route["AcceptInvitation"] = "ACCEPT_INVITATION";
    Route["EmailConfirmation"] = "EMAIL_CONFIRMATION";
})(Route || (Route = {}));
export function renderLocaleRedirectRoutes() {
    const [locale] = useLocale();
    const location = useLocation();
    return Object.values(Route).map((routeKey) => {
        return (<For each={locale.supportedLanguageTags.filter((l) => l !== locale.languageTag)}>{(languageTag) => <R path={getRouteTemplate(routeKey, languageTag)} component={() => {
                    const params = extractRouteParams(getRouteTemplate(routeKey, languageTag), location.pathname);
                    return <Navigate href={`${getRoute(routeKey, params)}${window.location.search}`}/>;
                }}/>}</For>);
    });
}
function extractRouteParams(template, path) {
    const templateParts = template.split('/');
    const pathParts = path.split('/');
    const params = {};
    for (let i = 0; i < templateParts.length; i++) {
        if (templateParts[i].startsWith(':')) {
            const paramName = templateParts[i].slice(1);
            params[paramName] = pathParts[i];
        }
    }
    return params;
}
export function getRoute(route, _params = {}, languageTag) {
    const translate = useI18n();
    switch (route) {
        case Route.SelectSignupAuthMethod:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_select_signup_auth_method', {}, languageTag, 'auth');
        case Route.SelectLoginAuthMethod:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_select_login_auth_method', {}, languageTag, 'auth');
        case Route.CreateAccountWithEmail:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_create_account_with_email', {}, languageTag, 'auth');
        case Route.LogInWithEmail:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_log_in_with_email', {}, languageTag, 'auth');
        case Route.LogOut:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_log_out', {}, languageTag, 'auth');
        case Route.OauthFailed:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_oauth_failed', {}, languageTag, 'auth');
        case Route.ProfileCompletionCheck:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_profile_completion_check', {}, languageTag, 'auth');
        case Route.CustomizeSettings:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_customize_settings', {}, languageTag, 'auth');
        case Route.LinkGoogleAccount:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_link_google_account', {}, languageTag, 'auth');
        case Route.ResetPasswordRequest:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_reset_password_request', {}, languageTag, 'auth');
        case Route.ResetPassword:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_reset_password', {}, languageTag, 'auth');
        case Route.SelectTheme:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_select_theme', {}, languageTag, 'auth');
        case Route.AcceptInvitation:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_accept_invitation', {}, languageTag, 'auth');
        case Route.EmailConfirmation:
            return translate('uri_auth', {}, languageTag, 'auth')
                + translate('uri_email_confirmation', {}, languageTag, 'auth');
        default:
            throw Error('route not found');
    }
}
export function getRouteTemplate(route, languageTag) {
    return getRoute(route, {}, languageTag);
}
