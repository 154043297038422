import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { ExpenseType } from '../types';

export interface ArchiveExpenseTypeReturn {
  archiveExpenseType: Pick<ExpenseType, 'id' | '__typename' | 'archived'>;
}

interface ArchiveExpenseTypeVars {
  organizationId: string;
  expenseTypeId: string;
}

export default Document
  .mutation<ArchiveExpenseTypeReturn, ArchiveExpenseTypeVars>('ArchiveExpenseType')
    .variableDefinitions({ organizationId: 'ID!', expenseTypeId: 'ID!' })
    .union('archiveExpenseType')
      .useVariables({ organizationId: 'organizationId', expenseTypeId: 'expenseTypeId' })
      .onEntity('ExpenseType')
        .scalar('organizationId')
        .scalar('archived')._
      .onTypedObject('AtLeastOneActiveExpenseTypeRequired')
        .scalar('currentServerTime', Temporal.Instant.from)._._._
  .makeExecutable(clientFetcher);
