import { createContext, useContext } from 'solid-js';
const FormResetterContext = createContext();
const FormResetterProvider = (props) => {
    let elements = [];
    const addElement = (resettableElement) => {
        elements = [...elements, resettableElement];
        return () => {
            elements = elements.filter((element) => element !== resettableElement);
        };
    };
    const contextValue = {
        addElement
    };
    function resetForm() {
        elements.forEach((element) => element.reset());
    }
    return (<FormResetterContext.Provider value={contextValue}>
      {props.formContainer(resetForm)}
    </FormResetterContext.Provider>);
};
export default FormResetterProvider;
export function useFormResetter() {
    return useContext(FormResetterContext);
}
