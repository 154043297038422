import { Temporal } from '@js-temporal/polyfill';
import findValue from '@mathieuprog/find-value';
import { useI18n, useLocale } from 'solid-compose';
import { For, createEffect, createSignal } from 'solid-js';
import Button from '../button/Button';
import ButtonType from '../button/ButtonType';
import Dialog from '../dialog/Dialog';
import modifiers from '../modifiers';
import TextField from '../text-field/TextField';
import styles from './TimeZonePickerDialog.module.css';
import countries from './countries.json';
import countriesTimeZones_ from './countriesTimeZones.json';
const TimeZonePickerDialog = (props) => {
    const [locale] = useLocale();
    const translate = useI18n();
    const [search, setSearch] = createSignal('');
    const [countriesTimeZones, setCountriesTimeZones] = createSignal();
    const [nowInstant, setNowInstant] = createSignal(Temporal.Now.instant());
    setInterval(() => {
        setNowInstant(Temporal.Now.instant());
    }, 10000);
    createEffect(() => {
        setCountriesTimeZones(countriesTimeZones_.filter((timeZoneData) => {
            const localCountryNames = findValue(countries, country => country.code === timeZoneData.country.code && country.localNames);
            if (!localCountryNames) {
                throw new Error(`cannot find local country name for country ${timeZoneData.country.name}`);
            }
            const [city] = timeZoneData.timeZone.split('/').slice(-1);
            return !(!timeZoneData.country.name.toLowerCase().startsWith(search())
                && !timeZoneData.country.code.toLowerCase().startsWith(search())
                && !timeZoneData.dstZoneAbbr.toLowerCase().startsWith(search())
                && !city.toLowerCase().startsWith(search())
                && !localCountryNames.some((name) => name.toLowerCase().startsWith(search())));
        }));
    });
    function handleSearch(value) {
        setSearch(value.toLowerCase());
    }
    function renderTimeZoneData(timeZoneData) {
        const zoneAbbr = (/[^a-zA-Z]/.test(timeZoneData.dstZoneAbbr))
            ? ''
            : timeZoneData.dstZoneAbbr;
        const currentTime = () => nowInstant()
            .toZonedDateTimeISO(timeZoneData.timeZone)
            .toLocaleString(locale.languageTag, { hour: 'numeric', minute: 'numeric', hour12: !locale.timeFormat.is24HourClock });
        const localCountryNames = findValue(countries, country => country.code === timeZoneData.country.code && country.localNames);
        if (!localCountryNames) {
            throw new Error(`cannot find local country name for country ${timeZoneData.country.name}`);
        }
        if (search()
            && !timeZoneData.country.name.toLowerCase().startsWith(search())
            && !timeZoneData.country.code.toLowerCase().startsWith(search())
            && !timeZoneData.dstZoneAbbr.toLowerCase().startsWith(search())
            && !localCountryNames.some((name) => name.toLowerCase().startsWith(search()))) {
            return;
        }
        return (<div class={styles.timeZoneRow} onClick={() => props.onChange(timeZoneData)}>
        <div class={styles.countryAndTime}>
          <div class={styles.country}>{timeZoneData.country.name}</div>
          <div>{currentTime()}</div>
        </div>
        <div class={styles.localCountryName}>
          <div>{localCountryNames.join(' / ')}</div>
        </div>
        <div class={styles.timeZoneAndZoneAbbr}>
          <div class={styles.timeZone}>{timeZoneData.timeZone}</div>
          <div class={styles.zoneAbbr}>{zoneAbbr}</div>
        </div>
      </div>);
    }
    return (<Dialog isOpen={props.isOpen} onClose={props.onClose} title={translate('select_time_zone')} body={<div class={styles.content}>
          <For each={countriesTimeZones()}>{timeZoneData => renderTimeZoneData(timeZoneData)}</For>
        </div>} buttons={onClose => <Button type={ButtonType.Secondary} onClick={onClose}>{translate('cancel')}</Button>} topBar={<modifiers.fullWidthInput>
          <TextField onChange={handleSearch} icon="search"/>
        </modifiers.fullWidthInput>} modifiers={{
            '--dialog-min-width': 'min(430px, calc(100dvw - 40px))',
            '--dialog-min-height': 'calc(100dvh - 40px)'
        }}/>);
};
export default TimeZonePickerDialog;
