import createExpenseTypeMutation from '@finance/graphql/mutations/createExpenseTypeMutation';
import updateExpenseTypeMutation from '@finance/graphql/mutations/updateExpenseTypeMutation';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, FormDialog, TextField, spacers, } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function getInitialFormFields(expenseType) {
    return {
        name: expenseType?.name || ''
    };
}
;
const ExpenseTypeFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore(getInitialFormFields(props.expenseType));
    const translate = useI18n();
    const [nameAlreadyUsedError, setNameAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen && props.expenseType) {
            setFields(getInitialFormFields(props.expenseType));
        }
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setNameAlreadyUsedError('');
        const variables = {
            input: {
                name: fields.name
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (props.expenseType)
            ? await processResponse(updateExpenseTypeMutation.execute({ ...variables, expenseTypeId: props.expenseType.id }), { onError })
            : await processResponse(createExpenseTypeMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateExpenseType' in response) {
            data = response.updateExpenseType;
        }
        else if ('createExpenseType' in response) {
            data = response.createExpenseType;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('ExpenseType', () => {
            props.onExpenseTypeAddedOrEdited?.();
            closeDialog();
        })
            .onExpectedErrorType('NameAlreadyUsed', (data) => {
            setNameAlreadyUsedError(translate('name_already_used', { name: data.name }));
            onExpectedError('');
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        setNameAlreadyUsedError('');
        props.onClose();
    }
    function renderTitle() {
        if (props.isViewMode) {
            return translate('title_expense_type');
        }
        if (props.expenseType) {
            return translate('title_edit_expense_type');
        }
        return translate('title_add_expense_type');
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <TextField label={translate('name')} onChange={value => { setNameAlreadyUsedError(''); setFields('name', value); }} error={nameAlreadyUsedError()} value={fields.name} readOnly={props.isViewMode} required/>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.expenseType ? translate('update') : translate('add')}</Button>}>
            <Button type={ButtonType.Secondary} onClick={() => props.onEditExpenseTypeClick(props.expenseType)}>{translate('edit')}</Button>
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={renderTitle()} modifiers={{
            '--dialog-max-width': '400px'
        }}/>);
};
export default ExpenseTypeFormDialog;
