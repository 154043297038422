import { Navigate } from '@solidjs/router';
import { Route as AuthRoute, getRoute as getAuthRoute } from '@subtile/auth';
import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus, useLocalStorage } from 'solid-compose';
import type { ParentComponent } from 'solid-js';
import { Match, Show, Switch } from 'solid-js';

const EnsureAuthenticated: ParentComponent = (props) => {
  const [currentUser, { authenticationStatus, authenticationError }] = useCurrentUser();
  const [mode] = useLocalStorage<string>('authenticationMode', 'signup');

  function throwError(): string {
    throw authenticationError();
  }

  return (
    <Show when={!currentUser.loading || currentUser.latest} fallback={
      <spacers.viewportFill>
        <Loader />
      </spacers.viewportFill>
    }>
      <Switch>
        <Match when={authenticationStatus() === AuthenticationStatus.Errored}>
          {throwError()}
        </Match>

        <Match when={authenticationStatus() === AuthenticationStatus.Unauthenticated}>
          <Navigate href={mode() === 'login'
            ? getAuthRoute(AuthRoute.SelectLoginAuthMethod)
            : getAuthRoute(AuthRoute.SelectSignupAuthMethod)}
          />
        </Match>

        <Match when={authenticationStatus() === AuthenticationStatus.Authenticated}>
          {props.children}
        </Match>
      </Switch>
    </Show>
  );
};

export default EnsureAuthenticated;
