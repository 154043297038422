import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { Errors, Organization } from '../types';

export interface UpdateOrganizationReturn {
  updateOrganization: Organization | Errors;
}

interface UpdateOrganizationVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateOrganizationReturn, UpdateOrganizationVars>('UpdateOrganization')
    .variableDefinitions({ organizationId: 'ID!', input: 'UpdateOrganizationInput!' })
    .union('updateOrganization')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('Organization')
        .scalar('name')
        .entitySet('currencies')
          .scalar('name')
          .scalar('code')
          .scalar('precision')
          .scalar('organizationId')._._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
