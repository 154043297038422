import createRevenueMutation from '@finance/graphql/mutations/createRevenueMutation';
import updateRevenueMutation from '@finance/graphql/mutations/updateRevenueMutation';
import { Temporal } from '@js-temporal/polyfill';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Alert, AlertType, Button, ButtonType, DateField, ErrorRenderer, FormDialog, Money, Radio, SelectEntity, TextArea, TextField, modifiers, spacers } from '@subtile/universal-ui';
import Decimal from 'decimal.js-light';
import { removeArrayElementByIndex } from 'object-array-utils';
import { formatDate, formatTime, parseNumber, useI18n, useLocale } from 'solid-compose';
import { For, Index, Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { formatMoneyInput, moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
var RecalculateAllocationsOption;
(function (RecalculateAllocationsOption) {
    RecalculateAllocationsOption["Yes"] = "YES";
    RecalculateAllocationsOption["No"] = "NO";
})(RecalculateAllocationsOption || (RecalculateAllocationsOption = {}));
function getInitialFormFields(revenue) {
    const amount = (revenue?.amountBeforeAllocations)
        ? formatMoneyInput(revenue.amountBeforeAllocations)
        : '';
    const allocations = (revenue)
        ? (revenue.allocations || []).map((allocation) => ({ ...allocation, amount: formatMoneyInput(allocation.targetAmount) }))
        : [{
                targetWallet: undefined,
                amount: ''
            }];
    return {
        date: revenue?.date || Temporal.Now.plainDateISO(),
        revenueType: revenue?.revenueType ? { ...revenue.revenueType } : undefined,
        wallet: revenue?.wallet ? { ...revenue.wallet } : undefined,
        account: revenue?.account ? { ...revenue.account } : undefined,
        amount,
        currency: revenue?.currency ? { ...revenue.currency } : undefined,
        description: revenue?.description || '',
        reference: revenue?.reference || '',
        allocations
    };
}
const RevenueFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [recalculateAllocationsOption, setRecalculateAllocationsOption] = createSignal();
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const hasAllocationRules = () => {
        return (fields.revenueType?.allocationRules || []).length > 0
            || (props.revenue?.allocations || []).length > 0;
    };
    const hasActiveAllocationRules = () => {
        return (fields.revenueType?.allocationRules || []).length > 0
            || (props.revenue?.allocations.filter((allocation) => !allocation.deleted) || []).length > 0;
    };
    const [fields, setFields] = createStore(getInitialFormFields(props.revenue));
    const [locale] = useLocale();
    const translate = useI18n();
    const [referenceAlreadyUsedError, setReferenceAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen) {
            setFields(getInitialFormFields(props.revenue));
        }
    });
    const showRecalculateAllocationsAlert = () => {
        if (!props.revenue || !fields.revenueType?.id || !hasAllocationRules() || !fields.currency) {
            return false;
        }
        const parsedAmount = parseNumber(fields.amount);
        if (!parsedAmount) {
            return false;
        }
        return !new Decimal(props.revenue.amountBeforeAllocations).equals(parsedAmount)
            || props.revenue.revenueType.id !== fields.revenueType?.id;
    };
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setReferenceAlreadyUsedError('');
        const variables = {
            input: {
                date: fields.date,
                revenueTypeId: fields.revenueType.id,
                walletId: fields.wallet.id,
                accountId: fields.account.id,
                currencyId: fields.currency.id,
                amount: parseNumber(fields.amount),
                description: fields.description,
                reference: fields.reference,
                ...(props.revenue && hasAllocationRules())
                    ? {
                        allocations: fields.allocations.map((allocation) => ({
                            ...(allocation.id)
                                ? {
                                    id: allocation.id,
                                    deleted: allocation.deleted || false
                                }
                                : {},
                            amount: parseNumber(allocation.amount),
                            targetWalletId: allocation.targetWallet.id
                        }))
                    }
                    : {}
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (props.revenue)
            ? await processResponse(updateRevenueMutation.execute({ ...variables, revenueId: props.revenue.id }), { onError })
            : await processResponse(createRevenueMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateRevenue' in response) {
            data = response.updateRevenue;
        }
        else if ('createRevenue' in response) {
            data = response.createRevenue;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Revenue', () => {
            props.onRevenueAddedOrEdited();
            closeDialog();
        })
            .onExpectedErrorType('NonZeroArchivedWalletBalance', ({ name }) => {
            alert(translate('cannot_update_archived_wallet', { name }));
            onExpectedError('');
        })
            .onExpectedErrorType('RevenueAllocationsExceedRevenue', ({ revenueAmount, currencyName }) => {
            onExpectedError(translate('revenue_allocations_exceed_revenue', { revenueAmount, currencyName }));
        })
            .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .onExpectedErrorType('ReferenceAlreadyUsed', (data) => {
            setReferenceAlreadyUsedError(translate('reference_already_used', { reference: data.reference }));
            onExpectedError('');
        })
            .handle();
    };
    function handleAllocationTargetWalletChange(wallet, index) {
        setFields('allocations', index, 'targetWallet', { ...wallet });
    }
    function handleAllocationAmountChange(amount, index) {
        setFields('allocations', index, 'amount', amount);
    }
    function handleAddAllocationClick() {
        setFields('allocations', fields.allocations.length, {
            targetWallet: undefined,
            amount: ''
        });
    }
    function handleRemoveAllocationClick(index) {
        (fields.allocations[index].id)
            ? setFields('allocations', index, 'deleted', true)
            : setFields('allocations', removeArrayElementByIndex(fields.allocations, index));
    }
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        setReferenceAlreadyUsedError('');
        setRecalculateAllocationsOption(undefined);
        props.onClose();
    }
    function handleRecalculateAllocationsOptionClick(option) {
        setRecalculateAllocationsOption(option);
        recalculateAllocations();
    }
    function handleAmountChange(value) {
        setFields('amount', value);
        recalculateAllocations();
    }
    function handleRevenueTypeChange(revenueType) {
        setFields('revenueType', { ...revenueType });
        recalculateAllocations();
    }
    function recalculateAllocations() {
        switch (recalculateAllocationsOption()) {
            case RecalculateAllocationsOption.Yes:
                fields.allocations.map((allocation, i) => {
                    (allocation.id)
                        ? setFields('allocations', i, 'deleted', true)
                        : setFields('allocations', removeArrayElementByIndex(fields.allocations, i));
                });
                const revenueAmount = parseNumber(fields.amount);
                fields.revenueType.allocationRules.map((rule) => {
                    const amount = new Decimal(revenueAmount)
                        .mul(rule.allocationPercentage)
                        .toDecimalPlaces(fields.currency.precision, Decimal.ROUND_HALF_EVEN);
                    setFields('allocations', fields.allocations.length, {
                        targetWallet: { ...rule.targetWallet },
                        amount: amount.toString()
                    });
                });
                break;
            case RecalculateAllocationsOption.No:
                const allocations = (props.revenue?.allocations || []).map((allocation) => ({ ...allocation, amount: formatMoneyInput(allocation.targetAmount) }));
                setFields('allocations', allocations);
                break;
        }
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <DateField label={translate('date')} onChange={value => setFields('date', value)} value={fields.date} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('revenue_type')} entities={organization.revenueTypes} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={handleRevenueTypeChange} placeholder={translate('select')} selectedEntityId={fields.revenueType?.id} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={wallet => setFields('wallet', { ...wallet })} placeholder={translate('select')} selectedEntityId={fields.wallet?.id} readOnly={props.isViewMode} required/>

          <SelectEntity label={translate('receiving_account')} entities={organization.accounts} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={account => setFields('account', { ...account })} placeholder={translate('select')} selectedEntityId={fields.account?.id} readOnly={props.isViewMode} required/>

          <Show when={!props.isViewMode} fallback={<>
              <TextField label={translate('amount')} value={formatMoney(props.revenue.amountBeforeAllocations, props.revenue.currency)} readOnly/>

              <Show when={props.revenue && hasActiveAllocationRules()}>
                <Index each={props.revenue.allocations.filter((allocation) => !allocation.deleted)}>{(allocation, i) => <TextField label={i === 0 ? translate('allocations') : undefined} value={`${formatMoney(allocation().targetAmount, props.revenue.currency)} → ${allocation().targetWallet.name}`} readOnly/>}</Index>
              </Show>
            </>}>
            <Money amount={<TextField label={translate('amount')} onChange={handleAmountChange} type="money" value={fields.amount} required/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('currency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.currency?.id} required/>}/>

            <Show when={props.revenue && hasActiveAllocationRules()}>
              <div style={{
                'font-size': '16px',
                'margin-top': '6px'
            }}>
                {translate('allocations')}
              </div>

              <Show when={showRecalculateAllocationsAlert()}>
                <div style={{
                'max-width': '400px'
            }}>
                  <Alert type={AlertType.Info}>
                    <div>{translate('recalculate_allocations')}</div>

                    <Radio.Group checkedValue={recalculateAllocationsOption()} onChange={value => handleRecalculateAllocationsOptionClick(value)} required>
                      <Radio value={RecalculateAllocationsOption.Yes}>{translate('yes')}</Radio>
                      <Radio value={RecalculateAllocationsOption.No}>{translate('no')}</Radio>
                    </Radio.Group>
                  </Alert>
                </div>
              </Show>

              <For each={fields.allocations}>{(allocation, i) => <Show when={!allocation.deleted}>
                  <ErrorRenderer>
                    <div style={{
                    display: 'flex',
                    gap: '16px',
                    '--text-field-max-width': '100px'
                }}>
                      <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleAllocationTargetWalletChange(value, i())} placeholder={translate('select')} selectedEntityId={allocation.targetWallet?.id} readOnly={props.isViewMode} required/>

                      <TextField label={translate('amount')} onChange={value => handleAllocationAmountChange(value, i())} onClose={props.isViewMode ? undefined : (() => handleRemoveAllocationClick(i()))} type="money" value={allocation.amount} readOnly={props.isViewMode} required/>
                    </div>
                  </ErrorRenderer>
                </Show>}</For>

              <Show when={!props.isViewMode}>
                <modifiers.smallButton>
                  <Button type={ButtonType.Secondary} onClick={handleAddAllocationClick}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
                    </svg>
                    {translate('add_transfer_wallet')}
                  </Button>
                </modifiers.smallButton>
              </Show>
            </Show>
          </Show>

          <TextArea label={translate('description')} onChange={value => setFields('description', value)} rows={3} value={props.isViewMode ? (fields.description || '-') : fields.description} readOnly={props.isViewMode}/>

          <TextField label={translate('reference')} onChange={value => { setReferenceAlreadyUsedError(''); setFields('reference', value); }} error={referenceAlreadyUsedError()} value={props.isViewMode ? (fields.reference || '-') : fields.reference} readOnly={props.isViewMode}/>

          <Show when={props.isViewMode}>
            <TextField label={translate('last_updated')} value={formatDate(props.revenue.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainDate()) + ' ' +
                formatTime(props.revenue.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainTime(), { precision: 'minute' })} readOnly/>
          </Show>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.revenue ? translate('update') : translate('add')}</Button>}>
            {!props.revenue.deleted &&
                <Button type={ButtonType.Secondary} onClick={() => props.onEditRevenueClick(props.revenue)}>{translate('edit')}</Button>}
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={props.isViewMode ? translate('title_revenue') : (props.revenue ? translate('title_edit_revenue') : translate('title_add_revenue'))}/>);
};
export default RevenueFormDialog;
