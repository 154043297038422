import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { Setup, useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { orThrow } from 'or-throw';
import { AuthenticationStatus, useLocalStorage } from 'solid-compose';
import { createEffect } from 'solid-js';
const SelectOrganization = () => {
    const [currentUser, { authenticationStatus }] = useCurrentUser();
    const [organizations] = useOrganizationsResource();
    const [selectedOrganizationId, { remove: removeSelectedOrganizationId }] = useLocalStorage('organizationId');
    const navigate = useNavigate();
    createEffect(() => {
        if (organizations.loading) {
            return;
        }
        orThrow(authenticationStatus() === AuthenticationStatus.Authenticated);
        orThrow(currentUser());
        orThrow(organizations());
        const organizations_ = organizations();
        const selectedOrganizationId_ = selectedOrganizationId();
        if (selectedOrganizationId_ && !organizations_.some(({ id }) => id === selectedOrganizationId_)) {
            removeSelectedOrganizationId(); // effect will re-run
            return;
        }
        const configuredOrganizationIds = organizations_
            .filter(({ completedSetups }) => completedSetups.includes(Setup.Finance))
            .map(({ id }) => id);
        if (configuredOrganizationIds.length === 0) {
            navigate(getRoute(Route.ConfigurationCheck));
            return;
        }
        if (selectedOrganizationId_ && configuredOrganizationIds.includes(selectedOrganizationId_)) {
            navigate(getRoute(Route.Transactions, { organizationId: selectedOrganizationId_ }));
            return;
        }
        navigate(getRoute(Route.ConfigurationCheck));
    });
    return (<spacers.viewportFill>
      <Loader />
    </spacers.viewportFill>);
};
export default SelectOrganization;
