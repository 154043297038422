import { Document, OperationType } from 'fluent-graphql';

export function setEntityLoading(
  { __typename, id, organizationId } : { __typename: string, id: string, organizationId: string },
  loading: boolean
) {
  Document
    .getOrCreateByOperationName(OperationType.Mutation, 'SetLoading')
      .entity('entity')
        .scalar('organizationId')
        .scalar('loading')._._
    .makeExecutable()
    .simulateNetworkResponse({
      entity: { id, __typename, organizationId, loading }
    });
}
