import { createContext, useContext } from 'solid-js';
const TileContext = createContext();
export function TileProvider(props) {
    return (<TileContext.Provider value={[() => props.isDirectionColumn]}>
      {props.children}
    </TileContext.Provider>);
}
export function useTile() {
    return useContext(TileContext);
}
