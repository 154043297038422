import useAccountsResource from '@finance/graphql/resources/useAccountsResource';
import useWalletsResource from '@finance/graphql/resources/useWalletsResource';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { Button, ButtonType, Card, ColorTag, IconButton, Loader } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { useI18n } from 'solid-compose';
import { For, Show, createSignal } from 'solid-js';
import { moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import FundFormDialog from './FundFormDialog';
import styles from './FundIndex.module.css';
import Toolbar from './Toolbar';
export var FundType;
(function (FundType) {
    FundType["Account"] = "ACCOUNT";
    FundType["Wallet"] = "WALLET";
})(FundType || (FundType = {}));
const FundIndex = (props) => {
    const navigate = useNavigate();
    const translate = useI18n();
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [showFormDialog, setShowFormDialog] = createSignal(false);
    let funds;
    let refetchFunds;
    switch (props.fundType) {
        case FundType.Account:
            {
                const [funds_, { refetch: refetchFunds_ }] = useAccountsResource({ organizationId: organization.id });
                funds = funds_;
                refetchFunds = refetchFunds_;
            }
            break;
        case FundType.Wallet:
            {
                const [funds_, { refetch: refetchFunds_ }] = useWalletsResource({ organizationId: organization.id });
                funds = funds_;
                refetchFunds = refetchFunds_;
            }
            break;
    }
    const activeFunds = () => funds()?.filter(({ archived }) => !archived);
    function handleCloseFormDialog() {
        setShowFormDialog(false);
    }
    function handleAddClick() {
        setShowFormDialog(true);
    }
    function handleFundClick(fund) {
        switch (props.fundType) {
            case FundType.Account:
                navigate(getRoute(Route.Account, { organizationId: organization.id, accountId: fund.id }));
                break;
            case FundType.Wallet:
                navigate(getRoute(Route.Wallet, { organizationId: organization.id, walletId: fund.id }));
                break;
        }
    }
    function handleRefreshClick() {
        Document.clearQueries([
            'Revenues',
            'Expenses',
            'AccountTransfers',
            'WalletTransfers',
            'WalletTransactions',
            'AccountTransactions',
            'Wallets',
            'Accounts'
        ]);
        refetchFunds();
    }
    return (<div class={styles.fundIndexPage}>
      <Toolbar start={<Button onClick={handleAddClick} type={ButtonType.Secondary}>{translate('add')}</Button>} end={<div style={{ '--icon-size': '20px' }}>
            <IconButton onClick={handleRefreshClick}>
              <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path d="M19.854 8.646c-0.195-0.195-0.512-0.195-0.707 0l-1.149 1.149c-0.051-2.060-0.878-3.99-2.341-5.452-1.511-1.511-3.52-2.343-5.657-2.343s-4.146 0.832-5.657 2.343-2.343 3.52-2.343 5.657 0.832 4.146 2.343 5.657 3.52 2.343 5.657 2.343c2.974 0 5.686-1.635 7.077-4.266 0.129-0.244 0.036-0.547-0.208-0.676s-0.547-0.036-0.676 0.208c-1.217 2.303-3.59 3.734-6.193 3.734-3.86 0-7-3.14-7-7s3.14-7 7-7c3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707z" fill="#000000"></path>
              </svg>
            </IconButton>
          </div>}/>

      <Show when={!funds.loading && activeFunds()} fallback={<div style={{ 'margin-top': '10px' }}><Loader /></div>}>{activeFunds => <>
          <Card.Container>
            <For each={activeFunds()}>{(account) => <Card onClick={() => handleFundClick(account)}>
                  <Card.Header>
                    <ColorTag colorName={account.colorTag} size="18px">{account.name}</ColorTag>
                  </Card.Header>
                  <Card.Body>
                    {formatMoney(account.balanceAmount, account.currency)}
                  </Card.Body>
                </Card>}</For>
          </Card.Container>

          <FundFormDialog fundType={props.fundType} isOpen={showFormDialog()} onClose={handleCloseFormDialog}/>
        </>}</Show>
    </div>);
};
export default FundIndex;
