import resendConfirmationEmailMutation from '@auth/graphql/mutations/resendConfirmationEmailMutation';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, useCurrentUser } from '@subtile/project-shared';
import { Button, ButtonType, Panel, TextField, spacers } from '@subtile/universal-ui';
import { orThrow } from 'or-throw';
import { useI18n, useLocale } from 'solid-compose';
import { Match, Switch, createEffect } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Route, getRoute } from '..';
import EnsureAuthenticated from './EnsureAuthenticated';
const EmailConfirmation = () => {
    const translate = useI18n();
    const [locale] = useLocale();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [currentUser] = useCurrentUser();
    const [fields, setFields] = createStore({
        email: ''
    });
    createEffect(() => {
        if (!currentUser.loading && currentUser()?.confirmedEmailAt) {
            navigate(getRoute(Route.ProfileCompletionCheck));
        }
    });
    function throwError() {
        throw new Error(`unknown ${searchParams['state']} query param for email confirmation`);
    }
    const handleResendEmailSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const input = {
            email: fields.email || orThrow(currentUser()).email
        };
        const { resendConfirmationEmail: data } = await resendConfirmationEmailMutation.execute({ input, languageTag: locale.languageTag }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('ResendConfirmationEmailSuccess', () => {
            navigate(`${getRoute(Route.EmailConfirmation)}?state=resent`);
        })
            .handle();
    };
    return (<Switch fallback={throwError()}>
      <Match when={searchParams['state'] === 'instructions'}>
        <EnsureAuthenticated>
          <Onboarding.FormPage title={translate('email_confirmation_instructions_title')} description={translate('email_confirmation_instructions_description')} buttonText={translate('resend_email')} onSubmit={handleResendEmailSubmit} errorWidth="firstElementChild"/>
        </EnsureAuthenticated>
      </Match>

      <Match when={searchParams['state'] === 'confirmed'}>
        <Onboarding.Page title={translate('email_confirmed_title')} description={translate('email_confirmed_description')}>
          <Button onClick={() => navigate(getRoute(Route.ProfileCompletionCheck))} type={ButtonType.Primary}>
            {translate('set_up_account')}
          </Button>
        </Onboarding.Page>
      </Match>

      <Match when={searchParams['state'] === 'resent'}>
        <Onboarding.Page title={translate('email_confirmation_maybe_resent_title')} description={translate('email_confirmation_maybe_resent_description')}>
          <Button onClick={() => navigate(getRoute(Route.ProfileCompletionCheck))} type={ButtonType.Primary}>
            {translate('back_to_homepage')}
          </Button>
        </Onboarding.Page>
      </Match>

      <Match when={searchParams['state'] === 'failed'}>
        <Onboarding.FormPage title={translate('email_confirmation_failed_title')} description={translate('email_confirmation_failed_description')} buttonText={translate('resend_email')} onSubmit={handleResendEmailSubmit} errorWidth="firstElementChild">
          <Panel>
            <spacers.formFields>
              <TextField label={translate('email')} onChange={value => setFields('email', value)} required type="email" value={fields.email}/>
            </spacers.formFields>
          </Panel>
        </Onboarding.FormPage>
      </Match>
    </Switch>);
};
export default EmailConfirmation;
