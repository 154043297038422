import { useNavigate } from '@solidjs/router';
import { createContext, useContext } from 'solid-js';
const AuthHelpersContext = createContext();
const AuthHelpersProvider = (props) => {
    const navigate = useNavigate();
    const value = {
        logout: () => { navigate(props.logOutRoute); },
        navigateToAuthentication: () => { navigate(props.guestRoute); },
    };
    return (<AuthHelpersContext.Provider value={value}>
      {props.children}
    </AuthHelpersContext.Provider>);
};
export default AuthHelpersProvider;
export function useAuthHelpers() {
    return useContext(AuthHelpersContext);
}
