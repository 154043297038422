import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { WalletTransfer } from '../types';

export interface DeleteWalletTransferReturn {
  deleteWalletTransfer: Pick<WalletTransfer, 'id' | '__typename' | 'deleted'>;
}

interface DeleteWalletTransferVars {
  organizationId: string;
  walletTransferId: string;
}

export default Document
  .mutation<DeleteWalletTransferReturn, DeleteWalletTransferVars>('DeleteWalletTransfer')
    .variableDefinitions({ organizationId: 'ID!', walletTransferId: 'ID!' })
    .union('deleteWalletTransfer')
      .useVariables({ organizationId: 'organizationId', walletTransferId: 'walletTransferId' })
      .onEntity('WalletTransfer')
        .scalar('organizationId')
        .scalar('deleted')._
      .onTypedObject('DirectRevenueAllocationDeletionForbidden')
        .scalar('currentServerTime')._
      .onTypedObject('DirectCrossCurrencyWalletTransferDeletionForbidden')
        .scalar('currentServerTime')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ deleteWalletTransfer: data }) => {
    if (data.__typename === 'WalletTransfer') {
      Document.clearQueries([
        'WalletTransfers',
        'WalletTransactions',
        'Revenues',
        'Wallets'
      ]);
    }
  });
