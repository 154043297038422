import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import type { Errors, Revenue, TypedObject } from '../types';

export interface UpdateRevenueReturn {
  updateRevenue: Revenue | { reference: string } & TypedObject | Errors;
}

interface UpdateRevenueVars {
  organizationId: string;
  revenueId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateRevenueReturn, UpdateRevenueVars>('UpdateRevenue')
    .variableDefinitions({ organizationId: 'ID!', revenueId: 'ID!', input: 'UpdateRevenueInput!' })
    .union('updateRevenue')
      .useVariables({ organizationId: 'organizationId',  revenueId: 'revenueId', input: 'input' })
      .onEntity('Revenue')
        .scalar('organizationId')
        .scalar('date', Temporal.PlainDate.from)
        .reference('currencyId', 'Currency')
        .reference('revenueTypeId', 'RevenueType')
        .reference('accountId', 'Account')
        .reference('walletId', 'Wallet')
        .scalar('amountBeforeAllocations')
        .scalar('amountAfterAllocations')
        .scalar('description')
        .scalar('reference')._
      .onTypedObject('RevenueAllocationsExceedRevenue')
        .scalar('revenueAmount')
        .scalar('currencyName')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('NegativeAccountBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('ReferenceAlreadyUsed')
        .scalar('reference')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ updateRevenue: data }) => {
    if (data.__typename === 'Revenue') {
      Document.clearQueries([
        'Revenues',
        'WalletTransfers',
        'WalletTransactions',
        'AccountTransactions',
        'Wallets',
        'Accounts'
      ]);
    }
  });
