import * as Sentry from '@sentry/browser';
import { createModifier, createSpacer, initialize as initializeUniversalUi } from '@subtile/universal-ui';
import '@subtile/universal-ui/dist/theme/theme.css';
import { Document, findGraphQLErrorByCode } from 'fluent-graphql';
import { AuthenticationStatus, ColorScheme, addLocaleHotkeyListener, createCurrentUserPrimitive, createLocalePrimitive, createTextDirectionEffect, createThemeEffect, createThemePrimitive, createViewportEffect, createViewportPrimitive, getSupportedLanguageTags, useLocale, useViewport } from 'solid-compose';
import { createEffect, createRoot } from 'solid-js';
import Viewport from './Viewport';
import './css/global.css';
import GraphQLErrorCode from './graphql/GraphQLErrorCode';
import useCurrentUserResource from './graphql/queries/useCurrentUserResource';
import './translations';
import useCurrentUser from './useCurrentUser';
globalThis.subtile = {
    enableDebug: () => {
        localStorage.setItem("debug", "true");
    }
};
const debugEnabled = !!localStorage.getItem("debug");
function handleErrorEvent(e) {
    const errorObject = {};
    errorObject.constructor = e.constructor?.name || '';
    errorObject.type = e.type || '';
    errorObject.location = window.location.href;
    if (e instanceof PromiseRejectionEvent) {
        errorObject.message = e.reason.message;
        errorObject.stack = e.reason.stack;
    }
    else if (e instanceof ErrorEvent) {
        errorObject.message = e.message;
    }
    if (import.meta.env.DEV || debugEnabled) {
        console.error(e);
        console.error(errorObject);
    }
}
let initialized = false;
export default function initialize() {
    if (initialized) {
        throw new Error('project-shared already initialized');
    }
    initialized = true;
    Sentry.onLoad(function () {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            allowUrls: ['subtile.app'],
            environment: 'production'
        });
    });
    initializeUniversalUi();
    window.addEventListener('unhandledrejection', handleErrorEvent);
    window.addEventListener('error', handleErrorEvent);
    Document.defineTenantFields((typename) => {
        switch (typename) {
            default:
                return ['organizationId'];
            case 'Organization':
                return [];
            case 'User':
                return [];
        }
    });
    createLocalePrimitive({
        supportedLanguageTags: getSupportedLanguageTags()
    });
    createTextDirectionEffect();
    createThemePrimitive({
        themes: [
            {
                name: 'DARK',
                colorScheme: ColorScheme.Dark,
                default: true
            },
            {
                name: 'LIGHT',
                colorScheme: ColorScheme.Light,
                default: true
            }
        ]
    });
    createThemeEffect();
    if (import.meta.env.DEV || debugEnabled) {
        addLocaleHotkeyListener({
            hotkeys: {
                colorScheme: (e) => e.shiftKey && e.altKey && e.code === 'KeyQ',
                theme: (e) => e.shiftKey && e.altKey && e.code === 'KeyW',
                languageTag: (e) => e.shiftKey && e.altKey && e.code === 'KeyA',
                textDirection: (e) => e.shiftKey && e.altKey && e.code === 'KeyS',
                numberFormat: (e) => e.shiftKey && e.altKey && e.code === 'KeyD',
                timeZone: (e) => e.shiftKey && e.altKey && e.code === 'KeyZ',
                dateFormat: (e) => e.shiftKey && e.altKey && e.code === 'KeyX',
                timeFormat: (e) => e.shiftKey && e.altKey && e.code === 'KeyC',
                firstDayOfWeek: (e) => e.shiftKey && e.altKey && e.code === 'KeyV',
            },
            timeZones: ['Asia/Bangkok', 'Europe/London']
        });
    }
    createViewportPrimitive({
        widthSwitchpoints: {
            [Viewport.SmallWidth]: {
                max: 768
            },
            [Viewport.MediumWidth]: {
                min: 768,
                max: 1280
            },
            [Viewport.LargeWidth]: {
                min: 1280
            }
        }
    });
    createViewportEffect();
    if (import.meta.env.DEV) {
        // Document.setLogLevel(LogLevel.Verbose);
        Document.simulateNetworkDelayGlobally(500, 1500);
    }
    createCurrentUserPrimitive({
        createCurrentUserResource: useCurrentUserResource,
        isUnauthenticatedError: (error) => {
            return !!findGraphQLErrorByCode(error, GraphQLErrorCode.Unauthenticated);
        },
        isAuthenticated: (data) => {
            return data.__typename === 'User';
        }
    });
    createRoot(() => {
        const viewport = useViewport();
        const [locale] = useLocale();
        createEffect(() => {
            Sentry.configureScope(function (scope) {
                scope.setTag('viewport_size', `${viewport.width}x${viewport.height} ${viewport.orientation}`);
                scope.setTag('language_tag', `${locale.languageTag}`);
                scope.setTag('color_scheme', `${locale.colorScheme}`);
                scope.setTag('number_format', `${locale.numberFormat}`);
                scope.setTag('date_format', `${locale.dateFormat.endianness}`);
                scope.setTag('time_format', `${(locale.timeFormat.is24HourClock) ? '24-hour clock' : '12-hour clock'}`);
                scope.setTag('time_zone', `${locale.timeZone}`);
                scope.setTag('first_day_of_week', `${locale.firstDayOfWeek}`);
                scope.setTag('text_direction', `${locale.textDirection}`);
            });
        });
    });
    createRoot(() => {
        const [currentUser, { authenticationStatus }] = useCurrentUser();
        createEffect(() => {
            if (!currentUser.loading
                && authenticationStatus() === AuthenticationStatus.Authenticated
                && currentUser()) {
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: currentUser().email,
                        preferredName: currentUser().preferredName,
                        id: currentUser().id
                    });
                });
            }
        });
    });
    createModifier('fullWidthInput', {
        '--text-field-min-width': '100%'
    });
    createModifier('smallButton', {
        '--button-font-size': '13px',
        '--button-padding': '2px 6px'
    });
    createSpacer('formFields', {
        display: 'flex',
        'flex-direction': 'column',
        gap: '16px'
    });
    createSpacer('center', {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    });
    createSpacer('viewportFill', {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    });
}
