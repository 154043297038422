import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, ExpenseType, TypedObject } from '../types';

export interface CreateExpenseTypeReturn {
  createExpenseType: ExpenseType | { name: string } & TypedObject | Errors;
}

interface CreateExpenseTypeVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateExpenseTypeReturn, CreateExpenseTypeVars>('CreateExpenseType')
    .variableDefinitions({ organizationId: 'ID!', input: 'ExpenseTypeInput!' })
    .union('createExpenseType')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('ExpenseType')
        .scalar('name')
        .scalar('archived')
        .scalar('organizationId')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
