import { createContext, useContext } from 'solid-js';
const ToggleContext = createContext();
export function ToggleProvider(props) {
    return (<ToggleContext.Provider value={[props.selectedValueSignal, () => props.isDropdown, props.selectedToggleButtonSignal]}>
      {props.children}
    </ToggleContext.Provider>);
}
export function useToggle() {
    return useContext(ToggleContext);
}
