import { Temporal } from '@js-temporal/polyfill';
import findValue from '@mathieuprog/find-value';
import { useI18n, useLocale } from 'solid-compose';
import { Show, batch, createSignal, onCleanup, onMount } from 'solid-js';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import styles from './TimeZonePicker.module.css';
import TimeZonePickerDialog from './TimeZonePickerDialog';
import countries from './countries.json';
import countriesTimeZones from './countriesTimeZones.json';
const TimeZonePicker = (props) => {
    let rootRef;
    const translate = useI18n();
    const [shouldShow, setShouldShow] = createSignal(false);
    const [error, setError] = createSignal('');
    const [nowInstant, setNowInstant] = createSignal(Temporal.Now.instant());
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate();
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setShouldShow(false);
                        setNowInstant(Temporal.Now.instant());
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    function validate() {
        setValidatedOnce(true);
        if (props.required && !props.selectedTimeZoneIdAndCountryCode?.timeZoneId) {
            setError(translate('select_time_zone_option'));
            return;
        }
        setError('');
    }
    setInterval(() => {
        setNowInstant(Temporal.Now.instant());
    }, 10000);
    const selectedTimeZoneData = () => {
        if (!props.selectedTimeZoneIdAndCountryCode) {
            return undefined;
        }
        const timeZoneId = props.selectedTimeZoneIdAndCountryCode.timeZoneId;
        const countryCode = props.selectedTimeZoneIdAndCountryCode.countryCode;
        return countriesTimeZones.find((timeZoneData) => {
            return timeZoneData.timeZone === timeZoneId
                && (!countryCode || timeZoneData.country.code === countryCode);
        });
    };
    function renderSelectedTimeZone(timeZoneData) {
        const [locale] = useLocale();
        const localCountryNames = findValue(countries, country => country.code === timeZoneData.country.code && country.localNames);
        const currentTime = () => nowInstant()
            .toZonedDateTimeISO(timeZoneData.timeZone)
            .toLocaleString(locale.languageTag, { hour: 'numeric', minute: 'numeric', hour12: !locale.timeFormat.is24HourClock });
        const zoneAbbr = (/[^a-zA-Z]/.test(timeZoneData.dstZoneAbbr))
            ? ''
            : timeZoneData.dstZoneAbbr;
        return (<>
        <div style={{ 'margin-bottom': '2px' }}>{localCountryNames?.join(' / ')} ({timeZoneData.timeZone})</div>
        <div>{translate('current_time_colon')} {currentTime()} {(zoneAbbr ? ' ' + zoneAbbr : '')}</div>
      </>);
    }
    function handleTimeZoneChange(timeZoneData) {
        props.onChange(timeZoneData.timeZone, timeZoneData.country.code);
        setShouldShow(false);
        if (validatedOnce()) {
            validate();
        }
    }
    return (<div ref={rootRef} class={styles.timeZonePicker} classList={{ [styles.hasError]: !!error() }}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      <div class={styles.input} tabindex="0" onMouseDown={() => setShouldShow(true)}>
        <div class={styles.selectedOption}>
          {selectedTimeZoneData()
            ? renderSelectedTimeZone(selectedTimeZoneData())
            : <span class={styles.placeholder}>{props.placeholder}</span>}
        </div>

        <div class={styles.arrow}/>

        <div class={styles.borderBottom}/>
      </div>

      <FieldError error={error()}/>

      <TimeZonePickerDialog isOpen={shouldShow()} onChange={handleTimeZoneChange} onClose={() => setShouldShow(false)}/>
    </div>);
};
export default TimeZonePicker;
