import classNames from 'classnames';
import { Show, createEffect } from 'solid-js';
import { useErrorRenderer } from './ErrorRenderer';
import styles from './FieldError.module.css';
const FieldError = (props) => {
    const errorContextValue = useErrorRenderer();
    let errorObject_ = {};
    const error = () => {
        errorObject_.message = props.error;
        return errorObject_;
    };
    if (errorContextValue) {
        createEffect(() => {
            const error_ = error();
            (error_.message)
                ? errorContextValue.addError(error_)
                : errorContextValue.removeError(error_);
        });
    }
    return (<Show when={!errorContextValue && props.error}>
      <div class={classNames(styles.fieldError, 'fieldErrorGlobalClass')}>
        {props.error}
      </div>
    </Show>);
};
export default FieldError;
