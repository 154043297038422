import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document, FetchStrategy } from 'fluent-graphql';
import { fetchAccount, fetchCurrency, fetchWallet } from '../resources/useOrganizationsResource';
import type { AccountTransfers, Entity, PaginationInput } from '../types';

export interface AccountTransfersReturn {
  me: { organization: { accountTransfers: AccountTransfers } };
}

export interface AccountTransfersVars {
  organizationId: string;
  pagination: unknown;
  filters: unknown;
}

export default Document
  .query<AccountTransfersReturn, AccountTransfersVars, AccountTransfers>('AccountTransfers')
    .variableDefinitions({ organizationId: 'ID!', pagination: 'PaginationInput!', filters: 'AccountTransfersFiltersInput' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .wrapper('accountTransfers')
          .useVariables({ accountTransfersPagination: 'pagination', accountTransfersFilters: 'filters' })
          .embed('pagination')
            .scalar('cursorDirection')
            .scalar('cursorForEntriesAfter')
            .scalar('cursorForEntriesBefore')
            .scalar('maxPageSize')
            .scalar('hasMoreEntries')._
          .entitySet('paginatedEntries', 'AccountTransfer')
            .scalar('organizationId')
            .scalar('date', Temporal.PlainDate.from)
            .scalar('updatedAt', Temporal.Instant.from)
            .scalar('deleted')
            .virtual('loading', false)
            .scalar('sourceAmount')
            .scalar('targetAmount')
            .entity('sourceAccount', 'Account')
              .scalar('name')
              .scalar('colorTag')
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')._
              .deriveFromReference('sourceAccountId', fetchAccount)
              .replaceEntity({
                Account: (account: Entity, _variables: unknown, accountTransfer: { sourceAccountId: string }) => {
                  // console.log('--- accountTransfer ---', accountTransfer);
                  return accountTransfer.sourceAccountId === account.id;
                }
              })._
            .entity('targetAccount', 'Account')
              .scalar('name')
              .scalar('colorTag')
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')._
              .deriveFromReference('targetAccountId', fetchAccount)
              .replaceEntity({
                Account: (account: Entity, _variables: unknown, accountTransfer: { targetAccountId: string }) => {
                  // console.log('--- accountTransfer ---', accountTransfer);
                  return accountTransfer.targetAccountId === account.id;
                }
              })._
            .entity('sourceCurrency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('sourceCurrencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, accountTransfer: { sourceCurrencyId: string }) => {
                  // console.log('--- accountTransfer ---', accountTransfer);
                  return accountTransfer.sourceCurrencyId === currency.id;
                }
              })._
            .entity('targetCurrency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('targetCurrencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, accountTransfer: { targetCurrencyId: string }) => {
                  // console.log('--- accountTransfer ---', accountTransfer);
                  return accountTransfer.targetCurrencyId === currency.id;
                }
              })._
            .entitySet('walletTransfers', 'WalletTransfer')
              .scalar('organizationId')
              .scalar('deleted')
              .scalar('sourceAmount')
              .scalar('targetAmount')
              .entity('sourceWallet', 'Wallet')
                .scalar('name')
                .scalar('colorTag')
                .deriveFromReference('sourceWalletId', fetchWallet)
                .replaceEntity({
                  Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { sourceWalletId: string }) => {
                    // console.log('--- walletTransfer ---', walletTransfer);
                    return walletTransfer.sourceWalletId === wallet.id;
                  }
                })._
              .entity('targetWallet', 'Wallet')
                .scalar('name')
                .scalar('colorTag')
                .entity('currency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')._
                .deriveFromReference('targetWalletId', fetchWallet)
                .replaceEntity({
                  Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { targetWalletId: string }) => {
                    // console.log('--- walletTransfer ---', walletTransfer);
                    return walletTransfer.targetWalletId === wallet.id;
                  }
                })._
              .entity('sourceCurrency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')
                .deriveFromReference('sourceCurrencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, walletTransfer: { sourceCurrencyId: string }) => {
                    // console.log('--- walletTransfer ---', walletTransfer);
                    return walletTransfer.sourceCurrencyId === currency.id;
                  }
                })._
              .entity('targetCurrency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')
                .deriveFromReference('targetCurrencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, walletTransfer: { targetCurrencyId: string }) => {
                    // console.log('--- walletTransfer ---', walletTransfer);
                    return walletTransfer.targetCurrencyId === currency.id;
                  }
                })._._
            ._._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { accountTransfers } } }) => accountTransfers)
  .scopeByTenants(({ organizationId }) => ({ organizationId }))
  .setRefetchStrategy(FetchStrategy.FetchFromNetworkAndRecreateCache)
  .clearAfter((variables: { pagination: PaginationInput }) => {
    if (variables.pagination?.cursor) {
      return Temporal.Duration.from({ minutes: 15 });
    }
  });
