import { renderLocaleRedirectRoutes } from '@finance/routes';
import { Outlet, Route, Routes } from '@solidjs/router';
import { useAuthHelpers } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import EnsureAuthenticated from './EnsureAuthenticated';
import EnsureOnboardingNotCompletedRoutes from './EnsureOnboardingNotCompletedRoutes';
import Layout from './Layout';
import OrganizationConfigurationCheck from './OrganizationConfigurationCheck';
import ProvideSelectedOrganizationToRoutes from './ProvideSelectedOrganizationToRoutes';
import SelectOrganization from './SelectOrganization';
import FundDetails from './content/FundDetails';
import FundIndex, { FundType } from './content/FundIndex';
import Profile from './content/Profile';
import Settings from './content/Settings';
import Team from './content/Team';
import TransactionInput from './content/TransactionInput';
import Onboarding from './onboarding';
const FinanceRoutes = () => {
    const translate = useI18n();
    const authHelpersContextValue = useAuthHelpers();
    return (<Routes>
      {renderLocaleRedirectRoutes()}

      <Route path={translate('uri_finance')} element={<EnsureAuthenticated navigateToAuthentication={authHelpersContextValue?.navigateToAuthentication}>
          <Outlet />
        </EnsureAuthenticated>}>
        <Route path={'/'} component={SelectOrganization}/>

        <Route path={`${translate('uri_organizations')}/:organizationId`} component={ProvideSelectedOrganizationToRoutes}>
          <Route path="/" element={<Layout onLogOut={authHelpersContextValue?.logout}/>}>
            <Route path={translate('uri_transactions')} component={TransactionInput}/>

            <Route path={translate('uri_wallets')} element={<FundIndex fundType={FundType.Wallet}/>}/>

            <Route path={translate('uri_accounts')} element={<FundIndex fundType={FundType.Account}/>}/>

            <Route path={`${translate('uri_wallets')}/:walletId`} element={<FundDetails fundType={FundType.Wallet}/>}/>

            <Route path={`${translate('uri_accounts')}/:accountId`} element={<FundDetails fundType={FundType.Account}/>}/>

            <Route path={translate('uri_settings')} component={Settings}/>

            <Route path={translate('uri_team')} component={Team}/>

            <Route path={translate('uri_profile')} component={Profile}/>

            <Route path={translate('uri_completed')} component={Onboarding.Completed}/>
          </Route>
        </Route>

        <Route path={`${translate('uri_configuration_check')}`} element={<OrganizationConfigurationCheck navigateToAuthentication={authHelpersContextValue?.navigateToAuthentication}/>}/>

        <Route path={`${translate('uri_organization_configuration_check')}/:organizationId`} component={OrganizationConfigurationCheck}/>

        <Route path={translate('uri_onboarding')} component={Onboarding.Layout}>
          <Route path={translate('uri_welcome')} component={Onboarding.Welcome}/>

          <Route path="/" component={EnsureOnboardingNotCompletedRoutes}>
            <Route path={translate('uri_organization')} component={Onboarding.AddOrganization}/>

            <Route path={translate('uri_select_organization')} component={Onboarding.SelectOrganization}/>

            <Route path={`${translate('uri_organizations')}/:organizationId`} component={ProvideSelectedOrganizationToRoutes}>
              <Route path={translate('uri_setup')} component={Onboarding.SetupOrganization}/>

              <Route path={translate('uri_wallets')} component={Onboarding.AddWallets}/>

              <Route path={translate('uri_revenue_types')} component={Onboarding.AddRevenueTypes}/>

              <Route path={translate('uri_expense_types')} component={Onboarding.AddExpenseTypes}/>

              <Route path={translate('uri_accounts')} component={Onboarding.AddAccounts}/>

              <Route path={translate('uri_invitees')} component={Onboarding.InviteMembers}/>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>);
};
export default FinanceRoutes;
