import registerUserMutation from '@auth/graphql/mutations/registerUserMutation';
import { Route, getRoute } from '@auth/routes';
import { A, useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, csrf, useCurrentUser } from '@subtile/project-shared';
import { Panel, TextField, spacers } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { AuthenticationStatus, useI18n, useLocalStorage, useLocale } from 'solid-compose';
import { createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import styles from './CreateAccount.module.css';
const CreateAccount = () => {
    const [fields, setFields] = createStore({
        email: '',
        password: ''
    });
    const [emailAlreadyUsedError, setEmailAlreadyUsedError] = createSignal('');
    const [currentUser, { refetchCurrentUser, authenticationStatus }] = useCurrentUser();
    const translate = useI18n();
    const [locale] = useLocale();
    const navigate = useNavigate();
    const [_registeredEmail, { set: setRegisteredEmail }] = useLocalStorage('registeredEmail');
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        setEmailAlreadyUsedError('');
        const input = {
            email: fields.email,
            password: fields.password
        };
        const { registerUser: data } = await registerUserMutation.execute({ input, languageTag: locale.languageTag }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('RegisterUserSuccess', async () => {
            Document.clearAllQueries();
            csrf.clearToken();
            await refetchCurrentUser();
            if (currentUser.error) {
                onUnexpectedError();
                throw currentUser.error;
            }
            if (authenticationStatus() === AuthenticationStatus.Authenticated) {
                setRegisteredEmail(currentUser().email);
                navigate(getRoute(Route.ProfileCompletionCheck));
                return;
            }
            throw new Error('cannot authenticate user');
        })
            .onExpectedErrorType('EmailAlreadyUsed', () => {
            setEmailAlreadyUsedError(translate('email_already_used'));
            onExpectedError('');
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('create_account_title')} description={translate('create_account_description')} buttonText={translate('create_my_account')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          <TextField label={translate('email')} onChange={value => { setEmailAlreadyUsedError(''); setFields('email', value); }} error={emailAlreadyUsedError()} required type="email" value={fields.email}/>

          <TextField label={translate('password')} onChange={value => setFields('password', value)} required type="new_password" value={fields.password}/>

          <TextField label={translate('password_confirmation')} match={fields.password} required type="password"/>
        </spacers.formFields>
      </Panel>

      <div class={styles.alreadyAnAccount}>
        {translate("already_an_account")}
        {' '}
        <A href={getRoute(Route.SelectLoginAuthMethod)}>{translate("already_an_account_log_in")}</A>
      </div>
    </Onboarding.FormPage>);
};
export default CreateAccount;
