class Csrf {
    tokenPromise;
    tokenFetchUrl;
    constructor(tokenFetchUrl) {
        this.tokenFetchUrl = tokenFetchUrl;
        this.tokenPromise = null;
    }
    clearToken() {
        this.tokenPromise = null;
    }
    async fetchToken() {
        if (!this.tokenPromise) {
            const promise = async () => {
                const tokenResponse = await fetch(this.tokenFetchUrl, { credentials: 'include' });
                return await tokenResponse.text();
            };
            this.tokenPromise = promise();
        }
        return this.tokenPromise;
    }
}
export default new Csrf(import.meta.env.VITE_CSRF_URL);
