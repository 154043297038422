import { createClientFetcher, type CurrentUser } from '@subtile/project-shared';
import { Document } from 'fluent-graphql';
import { Errors } from '../types';

export interface UpdateUserProfileReturn {
  updateUserProfile: CurrentUser | Errors;
}

interface UpdateUserProfileVars {
  input: unknown;
}

const client = createClientFetcher({
  httpUrl: import.meta.env.VITE_AUTH_API_URL,
  wsUrl: ''
});

export default Document
  .mutation<UpdateUserProfileReturn, UpdateUserProfileVars>('UpdateUserProfile')
    .variableDefinitions({ input: 'UserProfileInput!' })
    .union('updateUserProfile')
      .useVariables({ input: 'input' })
      .onEntity('User')
        .scalar('email')
        .scalar('preferredName')
        .scalar('defaultTimeZone')
        .scalar('defaultTimeZoneCountryCode')
        .scalar('languageTag')
        .scalar('numberFormat')
        .scalar('dateEndianness')
        .scalar('firstDayOfWeek')
        .scalar('is24HourClock')
        .scalar('colorScheme')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(client);
