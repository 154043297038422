import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { Organization } from '../types';

export interface MarkSetupAsCompletedReturn {
  markSetupAsCompleted: Pick<Organization, 'completedSetups'>;
}

interface MarkSetupAsCompletedVars {
  organizationId: string;
  setupName: string;
}

export default Document
  .mutation<MarkSetupAsCompletedReturn, MarkSetupAsCompletedVars>('MarkSetupAsCompleted')
    .variableDefinitions({ organizationId: 'ID!', setupName: 'Setup!' })
    .entity('markSetupAsCompleted')
      .useVariables({ organizationId: 'organizationId', setupName: 'setupName' })
      .scalar('completedSetups')._._
  .makeExecutable(clientFetcher);
