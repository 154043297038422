import { createEffect, createRoot } from 'solid-js';
export default function awaitResource(useResourceFun) {
    return new Promise((resolve) => {
        createRoot((dispose) => {
            const resourceReturn = useResourceFun();
            const [resource] = resourceReturn;
            createEffect(() => {
                if (resource.error) {
                    setTimeout(() => { throw new Error(resource.error); }, 0);
                }
                if (!resource.loading) {
                    try {
                        resolve(resourceReturn);
                        dispose();
                    }
                    catch (e) {
                        setTimeout(() => { throw e; }, 0);
                    }
                }
            });
        });
    });
}
