import { Outlet } from '@solidjs/router';
import EnsureAuthenticated from './EnsureAuthenticated';

const EnsureAuthenticatedRoutes = () => (
  <EnsureAuthenticated>
    <Outlet />
  </EnsureAuthenticated>
);

export default EnsureAuthenticatedRoutes;
