import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus } from 'solid-compose';
import { Match, Show, Switch } from 'solid-js';
const EnsureAuthenticated = (props) => {
    const [currentUser, { authenticationStatus }] = useCurrentUser();
    function handleUnauthenticated() {
        if (props.navigateToAuthentication) {
            props.navigateToAuthentication();
            return null;
        }
        throw new Error('unauthenticated');
    }
    // https://github.com/solidjs/solid/issues/1895
    return (<Show when={!currentUser.loading || currentUser.latest} fallback={<spacers.viewportFill>
        <Loader />
      </spacers.viewportFill>}>
      <Switch>
        <Match when={authenticationStatus() === AuthenticationStatus.Unauthenticated}>
          {handleUnauthenticated()}
        </Match>

        <Match when={authenticationStatus() === AuthenticationStatus.Authenticated}>
          {props.children}
        </Match>
      </Switch>
    </Show>);
};
export default EnsureAuthenticated;
