import classNames from 'classnames';
import { createEffect, createSignal, mergeProps } from 'solid-js';
import { clickOutside } from '../../helpers/directives';
import styles from './Group.module.css';
import { ToggleProvider, useToggle } from './context';
false && clickOutside;
function Root(propsWithoutDefaults) {
    const props = mergeProps({
        isDropdown: false
    }, propsWithoutDefaults);
    const [selectedValue, setSelectedValue] = createSignal(props.selectedValue, { equals: false });
    const selectedToggleButtonSignal = createSignal(() => <>…</>);
    createEffect(() => {
        setSelectedValue(() => props.selectedValue);
    });
    return (<ToggleProvider selectedValueSignal={[selectedValue, setSelectedValue]} selectedToggleButtonSignal={selectedToggleButtonSignal} isDropdown={props.isDropdown}>
      <Group {...props}/>
    </ToggleProvider>);
}
;
function Group(props) {
    const [selectedValueSignal, _isDropdown, selectedToggleButtonSignal] = useToggle();
    const [selectedValue] = selectedValueSignal;
    const [selectedToggleButton] = selectedToggleButtonSignal;
    const [shouldShow, setShouldShow] = createSignal(false);
    function handleDropdownTriggerClick() {
        setShouldShow((prev) => !prev);
    }
    createEffect(() => {
        setShouldShow(false);
        const selectedValue_ = selectedValue();
        if (props.selectedValue !== selectedValue_) {
            props.onChange(selectedValue_);
        }
    });
    function renderSelectedToggleButton() {
        const accessor = selectedToggleButton();
        return accessor();
    }
    return (<div class={styles.toggleButtonGroup} classList={{
            [styles.isDropdown]: props.isDropdown
        }} use:clickOutside={() => {
            setShouldShow(false);
        }}>
      <div class={classNames(styles.dropdownTrigger, 'toggleButtonsDropdownTriggerGlobalClass')} onClick={handleDropdownTriggerClick}>
        {renderSelectedToggleButton()}
      </div>
      <div class={styles.buttonsContainer} classList={{ [styles.shouldShow]: shouldShow() }}>
        {props.children}
      </div>
    </div>);
}
;
export default Root;
