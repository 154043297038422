import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { Errors, Organization } from '../types';
// import { createClientFetcher } from '@subtile/project-shared';

// const client = createClientFetcher({
//   httpUrl: import.meta.env.VITE_AUTH_API_URL,
//   wsUrl: ''
// });

export interface CreateOrganizationReturn {
  createOrganization: Organization | Errors;
}

interface CreateOrganizationVars {
  input: unknown;
}

export default Document
  .mutation<CreateOrganizationReturn, CreateOrganizationVars>('CreateOrganization')
    .variableDefinitions({ input: 'CreateOrganizationInput!' })
    .union('createOrganization')
      .useVariables({ input: 'input' })
      .onEntity('Organization')
        .scalar('name')
        .scalar('completedSetups')
        .scalar('archived')
        .entitySet('members')
          .scalar('organizationId')
          .entity('user')
            .scalar('preferredName')._._
        .entitySet('currencies')
          .scalar('name')
          .scalar('code')
          .scalar('precision')
          .scalar('isDefault')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('revenueTypes')
          .scalar('name')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('expenseTypes')
          .scalar('name')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('accounts')
          .scalar('name')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('wallets')
          .scalar('name')
          .scalar('archived')
          .scalar('balanceAmount')
          .scalar('organizationId')._._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
  // .makeExecutable(client);
