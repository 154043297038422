import { Onboarding } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import AddNamedEntities from './AddNamedEntities';
const AddAccounts = () => {
    const translate = useI18n();
    return (<AddNamedEntities entitiesType="ACCOUNTS" title={translate('add_accounts_title')} submitButtonLabel={translate('add_accounts')} inputLabel={translate('accounts')} addEntityButtonLabel={translate('add_another_account')} summary={<Onboarding.Description>
          <Onboarding.Note>
            <div>
              {translate('add_accounts_description')}
            </div>
          </Onboarding.Note>
        </Onboarding.Description>}/>);
};
export default AddAccounts;
