import { Temporal } from '@js-temporal/polyfill';
export default function validateDate(yearString, monthString, dayString) {
    const year = Number(yearString);
    const month = Number(monthString);
    const day = Number(dayString);
    const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let totalDays;
    const invalidDateComponents = [];
    if (!year || String(year).length !== 4) {
        invalidDateComponents.push('year');
    }
    else if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLengths[1] = 29;
    }
    if (!month || month < 1 || month > 12) {
        invalidDateComponents.push('month');
        totalDays = 31;
    }
    else {
        totalDays = monthLengths[month - 1];
    }
    if (!day || day < 1 || day > totalDays) {
        invalidDateComponents.push('day');
    }
    const date = (invalidDateComponents.length === 0) ? new Temporal.PlainDate(year, month, day) : null;
    return { date, invalidDateComponents };
}
