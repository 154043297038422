const focusableSelector = `
  textarea:not([disabled]),
  input:not([type="hidden"]):not([type="submit"]):not([type="reset"]):not([type="button"]):not([type="image"]):not([disabled]),
  select:not([disabled]),
  [tabindex]:not([tabindex="-1"]):not(.no-autofocus)
`
    .split('\n').join(' ').trim();
import { onMount } from 'solid-js';
export default function focusOnFirstInput(container) {
    onMount(() => {
        let focusableElements = container.querySelectorAll(focusableSelector);
        focusableElements = Array.from(focusableElements).filter((el) => !el.hidden);
        setTimeout(() => focusableElements[0]?.focus(), 0);
    });
}
