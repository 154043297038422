import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { TypedObject } from '../types';

export interface LogInUserReturn {
  logInUser:
    { loggedInAt: Temporal.Instant } & TypedObject
    | { email: string } & TypedObject;
}

interface LogInUserVars {
  input: unknown;
}

export default Document
  .mutation<LogInUserReturn, LogInUserVars>('LogInUser')
    .variableDefinitions({ input: 'LogInInput!' })
    .union('logInUser')
      .useVariables({ input: 'input' })
      .onTypedObject('LogInUserSuccess')
        .scalar('loggedInAt', Temporal.Instant.from)._
      .onTypedObject('InvalidLogin')
        .scalar('email')._._._
  .makeExecutable(clientFetcher);
