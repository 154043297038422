import createAccountMutation from '@finance/graphql/mutations/createAccountMutation';
import createWalletMutation from '@finance/graphql/mutations/createWalletMutation';
import updateAccountMutation from '@finance/graphql/mutations/updateAccountMutation';
import updateWalletMutation from '@finance/graphql/mutations/updateWalletMutation';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, FormDialog, Select, SelectEntity, TextField, getColorByColorName, getColors, spacers } from '@subtile/universal-ui';
import { ColorScheme, useI18n, useLocale } from 'solid-compose';
import { Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
export var FundType;
(function (FundType) {
    FundType["Account"] = "ACCOUNT";
    FundType["Wallet"] = "WALLET";
})(FundType || (FundType = {}));
function getInitialFormFields(fund) {
    return {
        name: fund?.name || '',
        colorTag: fund?.colorTag || undefined,
        currency: fund?.currency ? { ...fund.currency } : undefined,
    };
}
;
const FundFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore(getInitialFormFields(props.fund));
    const translate = useI18n();
    const [locale] = useLocale();
    const [nameAlreadyUsedError, setNameAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen && props.fund) {
            setFields(getInitialFormFields(props.fund));
        }
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setNameAlreadyUsedError('');
        const variables = (isUpdate) => ({
            input: {
                name: fields.name,
                colorTag: fields.colorTag || '',
                ...((isUpdate)
                    ? {}
                    : { currencyId: fields.currency.id })
            },
            organizationId: organization.id
        });
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        let result;
        switch (props.fundType) {
            case FundType.Account:
                result = (props.fund)
                    ? await processResponse(updateAccountMutation.execute({ ...variables(true), accountId: props.fund.id }, { onError }))
                    : await processResponse(createAccountMutation.execute(variables(false), { onError }));
                break;
            case FundType.Wallet:
                result = (props.fund)
                    ? await processResponse(updateWalletMutation.execute({ ...variables(true), walletId: props.fund.id }, { onError }))
                    : await processResponse(createWalletMutation.execute(variables(false), { onError }));
                break;
        }
        const response = result;
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateAccount' in response) {
            data = response.updateAccount;
        }
        else if ('updateWallet' in response) {
            data = response.updateWallet;
        }
        else if ('createAccount' in response) {
            data = response.createAccount;
        }
        else if ('createWallet' in response) {
            data = response.createWallet;
        }
        else {
            throw new Error();
        }
        const successType = (props.fundType === FundType.Account) ? 'Account' : 'Wallet';
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType(successType, () => {
            props.onFundAddedOrEdited?.();
            closeDialog();
        })
            .onExpectedErrorType('NameAlreadyUsed', (data) => {
            setNameAlreadyUsedError(translate('name_already_used', { name: data.name }));
            onExpectedError('');
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        setNameAlreadyUsedError('');
        props.onClose();
    }
    function renderColorOption(color) {
        const colorValue = (locale.colorScheme === ColorScheme.Dark)
            ? color.darkModeColor
            : color.lightModeColor;
        const style = {
            'background-color': colorValue,
            'border-radius': '50%',
            display: 'inline-block',
            'flex-shrink': 0,
            height: '18px',
            'margin-right': '6px',
            width: '18px'
        };
        return (<div style={{ display: 'flex', 'align-items': 'center', margin: '1px 0' }}>
        <span style={style}/> {translate(color.i18nKey)}
      </div>);
    }
    function renderTitle() {
        if (props.isViewMode) {
            return (props.fundType === FundType.Account) ? translate('title_account') : translate('title_wallet');
        }
        if (props.fund) {
            return (props.fundType === FundType.Account) ? translate('title_edit_account') : translate('title_edit_wallet');
        }
        return (props.fundType === FundType.Account) ? translate('title_add_account') : translate('title_add_wallet');
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <TextField label={translate('name')} onChange={value => { setNameAlreadyUsedError(''); setFields('name', value); }} error={nameAlreadyUsedError()} value={fields.name} readOnly={props.isViewMode} required/>

          <Show when={!!props.fund}>
            <TextField label={translate('amount')} value={props.fund.balanceAmount} readOnly/>
          </Show>

          <SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('currency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.currency?.id} readOnly={!!props.fund} required/>

          <Select label={translate('color_tag')} onChange={color => setFields('colorTag', color?.name)} optionId={({ name }) => name} optionText={renderColorOption} options={getColors()} placeholder={translate('no_color')} selectedOption={fields.colorTag ? getColorByColorName(fields.colorTag) : undefined} readOnly={props.isViewMode} allowEmptyOption/>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.fund ? translate('update') : translate('add')}</Button>}>
            <Button type={ButtonType.Secondary} onClick={() => props.onEditFundClick(props.fund)}>{translate('edit')}</Button>
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={renderTitle()}/>);
};
export default FundFormDialog;
