import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Errors, TypedObject } from '../types';

export interface AcceptInvitationReturn {
  acceptInvitation:
    { currentServerTime: Temporal.Instant } & TypedObject
    | Errors;
}

interface AcceptInvitationVars {
  token: string;
  input: unknown;
}

export default Document
  .mutation<AcceptInvitationReturn, AcceptInvitationVars>('AcceptInvitation')
    .variableDefinitions({ input: 'AcceptInvitationInput!', token: 'String!' })
    .union('acceptInvitation')
      .useVariables({ input: 'input', token: 'token' })
      .onTypedObject('AcceptInvitationSuccess')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('TokenInvalidOrExpired')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('EmailDoesNotMatchInvitation')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('InvalidSecurityCode')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('ReachedMaxSecurityCodeAttempts')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
