import { clickOutside, pressEscape } from '../../helpers/directives';
import Select from './Select';
// TS workaround for Solid custom directives
false && clickOutside;
false && pressEscape;
function SelectEntity(props) {
    const optionText = (props.entityLabelField)
        ? (entity) => entity[props.entityLabelField]
        : props.entityLabel;
    function options(entities) {
        return (props.filter)
            ? entities.filter(props.filter)
            : entities;
    }
    return (<Select label={props.label} onChange={props.onEntityChange} optionId={({ id }) => id} options={options(props.entities)} optionText={optionText} placeholder={props.placeholder} selectedOption={props.entities.find(({ id }) => props.selectedEntityId === id)} readOnly={props.readOnly} required/>);
}
export default SelectEntity;
