import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Currency, Errors, TypedObject } from '../types';

export interface CreateCurrencyReturn {
  createCurrency: Currency | { name: string } & TypedObject | Errors;
}

interface CreateCurrencyVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateCurrencyReturn, CreateCurrencyVars>('CreateCurrency')
    .variableDefinitions({ organizationId: 'ID!', input: 'CurrencyInput!' })
    .union('createCurrency')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('Currency')
        .scalar('name')
        .scalar('code')
        .scalar('precision')
        .scalar('archived')
        .scalar('isDefault')
        .scalar('organizationId')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
