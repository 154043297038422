import { Temporal } from '@js-temporal/polyfill';
import { Document, maybe } from 'fluent-graphql';
import { getClosestSupportedLanguageTag, useLocale, useTheme } from 'solid-compose';
import { batch } from 'solid-js';
import createClientFetcher from '../createClientFetcher';
import { useQueryResource } from '../useQueryResource';
const client = createClientFetcher({
    httpUrl: import.meta.env.VITE_AUTH_API_URL,
    wsUrl: ''
});
const document = Document
    .query('CurrentUser')
    .entity('me', 'User')
    .scalar('email')
    .scalar('preferredName')
    .scalar('confirmedEmailAt', maybe(Temporal.Instant.from))
    .scalar('defaultTimeZone')
    .scalar('defaultTimeZoneCountryCode')
    .scalar('languageTag')
    .scalar('numberFormat')
    .scalar('dateEndianness')
    .scalar('firstDayOfWeek')
    .scalar('is24HourClock')
    .scalar('colorScheme')._._
    .makeExecutable(client)
    .transformResponse(({ me }) => me)
    .afterExecution((user) => {
    const [locale, { setColorScheme, setDateFormat, setFirstDayOfWeek, setLanguageTag, setNumberFormat, setTimeFormat, setTimeZone }] = useLocale();
    const [_theme, setTheme] = useTheme();
    batch(() => {
        if (user.colorScheme && user.colorScheme !== locale.colorScheme) {
            setColorScheme(user.colorScheme);
            setTheme(user.colorScheme);
        }
        if (user.dateEndianness && user.dateEndianness !== locale.dateFormat.endianness) {
            setDateFormat({ endianness: user.dateEndianness });
        }
        if (user.firstDayOfWeek && user.firstDayOfWeek !== locale.firstDayOfWeek) {
            setFirstDayOfWeek(user.firstDayOfWeek);
        }
        if (user.languageTag) {
            const closestSupportedTag = getClosestSupportedLanguageTag(user.languageTag);
            if (closestSupportedTag !== locale.languageTag) {
                setLanguageTag(closestSupportedTag);
            }
        }
        if (user.numberFormat && user.numberFormat !== locale.numberFormat) {
            setNumberFormat(user.numberFormat);
        }
        if (user.is24HourClock && user.is24HourClock !== locale.timeFormat.is24HourClock) {
            setTimeFormat({ is24HourClock: user.is24HourClock });
        }
        if (user.defaultTimeZone && user.defaultTimeZone !== locale.timeZone) {
            setTimeZone(user.defaultTimeZone);
        }
    });
});
export default () => useQueryResource(document, {});
