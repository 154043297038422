import { Button, ButtonType, focusOnFirstInput, Form } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { createSignal, Show } from 'solid-js';
import styles from './FormPage.module.css';
false && focusOnFirstInput;
const FormPage = (props) => {
    const translate = useI18n();
    const [submitting, setSubmitting] = createSignal(false);
    return (<div class={styles.page} use:focusOnFirstInput={undefined}>
      <div class={styles.titleDescriptionContainer}>
        <div class={styles.title}>
          {props.title}
        </div>

        <Show when={props.description}>
          <div class={styles.description}>
            {props.description}
          </div>
        </Show>
      </div>

      <Form onSubmit={props.onSubmit} onSubmissionToggle={setSubmitting} errorWidth={props.errorWidth}>
        <div class={styles.form}>
          <div class={styles.innerForm}>
            {props.children}
          </div>

          <Show when={props.buttonText}>
            <div class={styles.buttonsContainer}>
              <Show when={props.skipButton}>
                <div style={{ visibility: submitting() ? 'hidden' : 'visible' }}>
                  <Button type={ButtonType.Secondary} onClick={props.onSkip}>
                    {translate('skip_for_now')}
                  </Button>
                </div>
              </Show>

              <Button type={ButtonType.Submit}>
                {props.buttonText}
              </Button>
            </div>
          </Show>
        </div>
      </Form>
    </div>);
};
export default FormPage;
