import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { Route, getRoute } from '@finance/routes';
import { useNavigate, useParams } from '@solidjs/router';
import { Loader, spacers } from '@subtile/universal-ui';
import { orThrow } from 'or-throw';
import { useLocalStorage } from 'solid-compose';
import { Show, createContext, createEffect, useContext } from 'solid-js';
const OrganizationContext = createContext();
const ProvideSelectedOrganization = (props) => {
    const [_selectedOrganizationId, { set: setSelectedOrganizationId }] = useLocalStorage('organizationId');
    const [organizations] = useOrganizationsResource();
    const params = useParams();
    const navigate = useNavigate();
    createEffect(() => {
        if (organizations.loading) {
            return;
        }
        if (organizations.error) {
            throw new Error(organizations.error);
        }
        const organizations_ = organizations();
        if (!organizations_.some(({ id }) => id === params.organizationId)) {
            navigate(getRoute(Route.ConfigurationCheck));
            return;
        }
    });
    function selectedOrganization(organizations) {
        return organizations.find(({ id }) => id === params.organizationId);
    }
    function setSelectedOrganizationId_(id) {
        setSelectedOrganizationId(id);
        // navigate(translate('uri_organization_configuration_check'));
    }
    return (<Show when={(!organizations.loading || organizations.latest) && selectedOrganization(organizations())} fallback={<spacers.viewportFill>
        <Loader />
      </spacers.viewportFill>}>
      <OrganizationContext.Provider value={[
            selectedOrganization(organizations()),
            { setSelectedOrganizationId: setSelectedOrganizationId_ }
        ]}>
        {props.children}
      </OrganizationContext.Provider>
    </Show>);
};
export default ProvideSelectedOrganization;
export function useSelectedOrganization() {
    return useContext(OrganizationContext);
}
export function useSelectedOrganizationOrThrow() {
    const [organization, setters] = useContext(OrganizationContext);
    return [orThrow(organization), setters];
}
