import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document, FetchStrategy } from 'fluent-graphql';
import { fetchCurrency, fetchWallet } from '../resources/useOrganizationsResource';
import type { Entity, PaginationInput, WalletTransfers } from '../types';

interface WalletTransfersReturn {
  me: { organization: { walletTransfers: WalletTransfers } };
}

export interface WalletTransfersVars {
  organizationId: string;
  pagination: unknown;
  filters: unknown;
}

export default Document
  .query<WalletTransfersReturn, WalletTransfersVars, WalletTransfers>('WalletTransfers')
    .variableDefinitions({ organizationId: 'ID!', pagination: 'PaginationInput!', filters: 'WalletTransfersFiltersInput' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .wrapper('walletTransfers')
          .useVariables({ walletTransfersPagination: 'pagination', walletTransfersFilters: 'filters' })
          .embed('pagination')
            .scalar('cursorDirection')
            .scalar('cursorForEntriesAfter')
            .scalar('cursorForEntriesBefore')
            .scalar('maxPageSize')
            .scalar('hasMoreEntries')._
          .entitySet('paginatedEntries', 'WalletTransfer')
            .scalar('organizationId')
            .scalar('date', Temporal.PlainDate.from)
            .scalar('updatedAt', Temporal.Instant.from)
            .scalar('deleted')
            .virtual('loading', false)
            .scalar('sourceAmount')
            .scalar('targetAmount')
            .scalar('description')
            .scalar('revenueIdForAllocation')
            .entity('sourceWallet', 'Wallet')
              .scalar('name')
              .scalar('colorTag')
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')._
              .deriveFromReference('sourceWalletId', fetchWallet)
              .replaceEntity({
                Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { sourceWalletId: string }) => {
                  // console.log('--- walletTransfer ---', walletTransfer);
                  return walletTransfer.sourceWalletId === wallet.id;
                }
              })._
            .entity('targetWallet', 'Wallet')
              .scalar('name')
              .scalar('colorTag')
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')._
              .deriveFromReference('targetWalletId', fetchWallet)
              .replaceEntity({
                Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { targetWalletId: string }) => {
                  // console.log('--- walletTransfer ---', walletTransfer);
                  return walletTransfer.targetWalletId === wallet.id;
                }
              })._
            .entity('sourceCurrency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('sourceCurrencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, walletTransfer: { sourceCurrencyId: string }) => {
                  // console.log('--- walletTransfer ---', walletTransfer);
                  return walletTransfer.sourceCurrencyId === currency.id;
                }
              })._
            .entity('targetCurrency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('targetCurrencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, walletTransfer: { targetCurrencyId: string }) => {
                  // console.log('--- walletTransfer ---', walletTransfer);
                  return walletTransfer.targetCurrencyId === currency.id;
                }
              })._._._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { walletTransfers } } }) => walletTransfers)
  .scopeByTenants(({ organizationId }) => ({ organizationId }))
  .setRefetchStrategy(FetchStrategy.FetchFromNetworkAndRecreateCache)
  .clearAfter((variables: { pagination: PaginationInput }) => {
    if (variables.pagination?.cursor) {
      return Temporal.Duration.from({ minutes: 15 });
    }
  });
