import inviteMembersMutation from '@finance/graphql/mutations/inviteMembersMutation';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, Panel, TextField, focusOnFirstInput, modifiers, spacers } from '@subtile/universal-ui';
import { removeArrayElementByIndex } from 'object-array-utils';
import { useI18n, useLocalStorage } from 'solid-compose';
import { Index, Show, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import styles from './InviteMembers.module.css';
false && focusOnFirstInput;
const InviteMembers = () => {
    const [fields, setFields] = createStore({
        emails: ['']
    });
    const translate = useI18n();
    const navigate = useNavigate();
    const [organization] = useSelectedOrganizationOrThrow();
    const [_, { set: setInvitationStepCompleted }] = useLocalStorage('invitationStepCompleted');
    const [securityCode, setSecurityCode] = createSignal();
    function handleEmailChange(text, index) {
        setFields('emails', index, text);
    }
    function handleRemoveEmailClick(index) {
        const emails = removeArrayElementByIndex(fields.emails, index);
        setFields('emails', emails);
    }
    function handleAddEmailClick() {
        setFields('emails', fields.emails.length, '');
        setTimeout(() => {
            const lastInviteeInputWrapper = Array.from(document.getElementsByClassName(styles.invitee)).at(-1);
            const lastInviteeInput = lastInviteeInputWrapper.querySelector('input');
            lastInviteeInput.focus();
        }, 0);
    }
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const variables = {
            input: {
                emails: fields.emails
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(inviteMembersMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { inviteMembers: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('InviteMembersSuccess', ({ invitations }) => {
            setSecurityCode(invitations[0].securityCode);
            setInvitationStepCompleted(true);
        })
            .handle();
    };
    function handleSkip() {
        setInvitationStepCompleted(true);
        navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
    }
    function handleNextClick() {
        navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
    }
    return (<Show when={securityCode()} fallback={<Onboarding.FormPage title={translate('invite_users_title', { organizationName: organization.name })} description={translate('invite_users_description')} buttonText={translate('send_invites')} onSubmit={handleSubmit} errorWidth="firstElementChild" onSkip={handleSkip} skipButton>
        <Panel>
          <spacers.formFields>
            <div class={styles.invitee}>
              <TextField label={translate('emails')} onChange={value => handleEmailChange(value, 0)} required type="email" value={fields.emails[0]}/>
            </div>

            <Index each={removeArrayElementByIndex(fields.emails, 0)}>{(email, i) => <div class={styles.invitee}>
                <TextField onChange={value => handleEmailChange(value, i + 1)} onClose={() => handleRemoveEmailClick(i + 1)} required type="email" value={email()}/>
              </div>}</Index>

            <modifiers.smallButton>
              <Button type={ButtonType.Secondary} onClick={handleAddEmailClick}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
                </svg>
                {translate('add_another_email')}
              </Button>
            </modifiers.smallButton>
          </spacers.formFields>
        </Panel>
      </Onboarding.FormPage>}>
      <Onboarding.Page title={translate('secure_invitations_title')}>
        <Panel>
          <Onboarding.Description>
            <div>
              {translate('secure_invitations_description')}
            </div>

            <div class={styles.securityCode}>
              {securityCode()}
            </div>
          </Onboarding.Description>
        </Panel>

        <Button onClick={handleNextClick} type={ButtonType.Primary}>
          {translate('next')}
        </Button>
      </Onboarding.Page>
    </Show>);
};
export default InviteMembers;
