import { Show } from 'solid-js';
import styles from './Group.module.css';
const Group = (props) => {
    let rootRef;
    return (<div ref={rootRef} class={styles.checkboxGroup}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      {props.children}
    </div>);
};
export default Group;
