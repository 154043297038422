import { isEmptyArray } from 'object-array-utils';
import { createEffect, onCleanup, onMount } from 'solid-js';
import throwIfNoOwner from '../../helpers/throwIfNoOwner';
export default function setDataGridHeight(rootElement, accessor) {
    throwIfNoOwner();
    const [styles, maxHeight, loading, data] = accessor();
    function setHeight() {
        const parentElement = rootElement.parentElement ?? document.documentElement;
        const bodyElement = rootElement.querySelector(`.${styles.body}`);
        const footerElement = rootElement.querySelector(`.${styles.footer}`);
        const actionMenuElement = rootElement.querySelector(`.${styles.actionMenu}`);
        const rootRect = rootElement.getBoundingClientRect();
        const parentRect = parentElement.getBoundingClientRect();
        const bodyRect = bodyElement.getBoundingClientRect();
        const footerRect = footerElement.getBoundingClientRect();
        if (maxHeight !== 'none') {
            if (data() && isEmptyArray(data())) {
                if (actionMenuElement) {
                    bodyElement.style.minHeight = '0';
                }
                return;
            }
            if (actionMenuElement && bodyRect.height < actionMenuElement.offsetHeight) {
                const diff = Math.ceil(actionMenuElement.offsetHeight - bodyRect.height) + 5;
                bodyElement.style.minHeight = `${bodyRect.height + diff}px`;
                bodyElement.style.height = 'auto';
            }
            return;
        }
        const paddingBottomString = window.getComputedStyle(parentElement).getPropertyValue('padding-bottom');
        const paddingBottom = parseFloat(paddingBottomString.match(/[\d\.]+/));
        let rootElementHeight = parentRect.bottom - rootRect.top - paddingBottom;
        let bodyElementHeight = parentRect.bottom - bodyRect.top - paddingBottom - footerRect.height;
        if (actionMenuElement && bodyElementHeight < actionMenuElement.offsetHeight) {
            const diff = Math.ceil(actionMenuElement.offsetHeight - bodyElementHeight) + 5;
            bodyElement.style.minHeight = `${bodyElementHeight + diff}px`;
            rootElement.style.height = `${rootElementHeight + diff}px`;
        }
        else {
            rootElement.style.height = `${rootElementHeight}px`;
            bodyElement.style.maxHeight = `${bodyElementHeight}px`;
        }
    }
    onMount(() => {
        setHeight();
        window.addEventListener('resize', setHeight);
        onCleanup(() => document.body.removeEventListener('resize', setHeight));
    });
    if (maxHeight !== 'none') {
        createEffect(() => {
            loading();
            setHeight();
        });
    }
}
