import { focusOnFirstInput } from '@subtile/universal-ui';
import { Show } from 'solid-js';
import styles from './Page.module.css';
false && focusOnFirstInput;
const Page = (props) => {
    return (<div class={styles.page} use:focusOnFirstInput={undefined}>
      <div class={styles.titleDescriptionContainer}>
        <div class={styles.title}>
          {props.title}
        </div>

        <Show when={props.description}>
          <div class={styles.description}>
            {props.description}
          </div>
        </Show>
      </div>

      <div class={styles.content}>
        {props.children}
      </div>
    </div>);
};
export default Page;
