import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, RevenueType, TypedObject } from '../types';

export interface CreateRevenueTypeReturn {
  createRevenueType: RevenueType | { name: string } & TypedObject | Errors;
}

interface CreateRevenueTypeVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateRevenueTypeReturn, CreateRevenueTypeVars>('CreateRevenueType')
    .variableDefinitions({ organizationId: 'ID!', input: 'RevenueTypeInput!' })
    .union('createRevenueType')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('RevenueType')
        .scalar('name')
        .scalar('archived')
        .entitySet('allocationRules')
          .overrideElements()
          .scalar('allocationPercentage')
          .reference('targetWalletId', 'Wallet')._
        .scalar('organizationId')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
