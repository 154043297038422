import { ColorTag } from '@subtile/universal-ui';
import styles from './FundTitle.module.css';
const FundTitle = (props) => {
    return (<div class={styles.titleContainer}>
      <ColorTag colorName={props.colorTag} size="24px"/>

      <div class={styles.title}>
        {props.children}
      </div>
    </div>);
};
export default FundTitle;
