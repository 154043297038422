import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Errors, TypedObject } from '../types';

export interface ResetPasswordRequestReturn {
  resetPasswordRequest:
    { currentServerTime: Temporal.Instant } & TypedObject
    | Errors;
}

interface ResetPasswordRequestVars {
  input: unknown;
}

export default Document
  .mutation<ResetPasswordRequestReturn, ResetPasswordRequestVars>('ResetPasswordRequest')
    .variableDefinitions({ input: 'ResetPasswordRequestInput!' })
    .union('resetPasswordRequest')
      .useVariables({ input: 'input' })
      .onTypedObject('ResetPasswordRequestSuccess')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('PublicInputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
