import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, RevenueType, TypedObject } from '../types';

export interface UpdateRevenueTypeReturn {
  updateRevenueType: RevenueType | { name: string } & TypedObject | Errors;
}

interface UpdateRevenueTypeVars {
  organizationId: string;
  revenueTypeId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateRevenueTypeReturn, UpdateRevenueTypeVars>('UpdateRevenueType')
    .variableDefinitions({ organizationId: 'ID!', revenueTypeId: 'ID!', input: 'RevenueTypeInput!' })
    .union('updateRevenueType')
      .useVariables({ organizationId: 'organizationId',  revenueTypeId: 'revenueTypeId', input: 'input' })
      .onEntity('RevenueType')
        .scalar('name')
        .scalar('archived')
        .entitySet('allocationRules')
          .overrideElements()
          .scalar('organizationId')
          .scalar('allocationPercentage')
          .reference('targetWalletId', 'Wallet')._
        .scalar('organizationId')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
