import updateOrganizationMutation from '@finance/graphql/mutations/updateOrganizationMutation';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { Panel, TextField, focusOnFirstInput, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
false && focusOnFirstInput;
const SetupOrganization = () => {
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore({
        name: organization.name,
        initialDefaultCurrencyName: ''
    });
    const translate = useI18n();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const variables = {
            organizationId: organization.id,
            input: {
                initialDefaultCurrencyName: fields.initialDefaultCurrencyName
            }
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(updateOrganizationMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { updateOrganization: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Organization', () => {
            navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('setup_organization_title')} buttonText={translate('save_settings')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          <TextField label={translate('organization_name')} onChange={value => setFields('name', value)} required value={fields.name}/>

          <TextField label={translate('default_currency')} onChange={value => setFields('initialDefaultCurrencyName', value)} required value={fields.initialDefaultCurrencyName}/>
        </spacers.formFields>
      </Panel>
    </Onboarding.FormPage>);
};
export default SetupOrganization;
