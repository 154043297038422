import { Temporal } from '@js-temporal/polyfill';
import { Panel } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import type { VoidComponent } from 'solid-js';

const Crash: VoidComponent = () => {
  const translate = useI18n();

  function errorMessage() {
    return (navigator.onLine)
      ? translate('ui_crashed', { dateTime: dateTimeString() })
      : translate('offline');
  }

  function dateTimeString() {
    let str = '';
    try {
      Temporal.Now.plainDateTimeISO().toLocaleString('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: Temporal.Now.timeZoneId(),
        timeZoneName: 'short'
      });
    } catch (e) {}
    return str;
  }

  return (
    <div
      style={{
        display: 'flex',
        'align-items': 'center',
        'flex-direction': 'column',
        'height': '100dvh',
        'margin-top': '20dvh'
      }}
    >
      <Panel>
        <div innerHTML={errorMessage()} />
      </Panel>
    </div>
  );
};

export default Crash;
