import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, TypedObject, Wallet } from '../types';

export interface UpdateWalletReturn {
  updateWallet: Wallet | { name: string } & TypedObject | Errors;
}

interface UpdateWalletVars {
  organizationId: string;
  walletId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateWalletReturn, UpdateWalletVars>('UpdateWallet')
    .variableDefinitions({ organizationId: 'ID!', walletId: 'ID!', input: 'UpdateWalletInput!' })
    .union('updateWallet')
      .useVariables({ organizationId: 'organizationId',  walletId: 'walletId', input: 'input' })
      .onEntity('Wallet')
        .scalar('name')
        .scalar('colorTag')
        .scalar('archived')
        .scalar('balanceAmount')
        .scalar('organizationId')
        .reference('currencyId', 'Currency')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
