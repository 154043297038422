import { addTranslations } from 'solid-compose';
import en from './en.json';
import fr from './fr.json';

const translations_ = {
  fr,
  en
};

type TranslationTuple = [string, string, Record<string, any>];
type TranslationList = TranslationTuple[];

const translations: TranslationList = ([
  Object.entries(translations_).map(([locale, obj]) => [locale, 'portal', obj]) as TranslationTuple[]
]).flat();

translations.forEach(([locale, namespace, translations]) => {
  (namespace === 'general')
    ? addTranslations(locale, translations)
    : addTranslations(locale, namespace, translations);
});
