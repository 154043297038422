import clientFetcher from '@auth/clientFetcher';
import { Document } from 'fluent-graphql';
import { Errors, User } from '../types';

export interface UpdateUserProfileReturn {
  updateUserProfile: User | Errors;
}

interface UpdateUserProfileVars {
  input: unknown;
}

export default Document
  .mutation<UpdateUserProfileReturn, UpdateUserProfileVars>('UpdateUserProfile')
    .variableDefinitions({ input: 'UserProfileInput!' })
    .union('updateUserProfile')
      .useVariables({ input: 'input' })
      .onEntity('User')
        .scalar('email')
        .scalar('preferredName')
        .scalar('defaultTimeZone')
        .scalar('defaultTimeZoneCountryCode')
        .scalar('languageTag')
        .scalar('numberFormat')
        .scalar('dateEndianness')
        .scalar('firstDayOfWeek')
        .scalar('is24HourClock')
        .scalar('colorScheme')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
