import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { fetchCurrency } from '../resources/useOrganizationsResource';
import type { Entity, Organization, Wallet } from '../types';

function addEntity(entitiesType: string) {
  return (entity: { __typename: string; organizationId: string }, _: unknown, organization: Organization) => {
    if (entitiesType !== entity.__typename) {
      return;
    }
    if (!entity.organizationId) {
      throw new Error(`missing organization ID for type ${entitiesType}`);
    }
    return entity.organizationId === organization.id;
  }
}

export interface WalletsReturn {
  me: { organization: { wallets: Wallet[] } };
}

export interface WalletsVars {
  organizationId: string;
}

export default Document
  .query<WalletsReturn, WalletsVars, Wallet[]>('Wallets')
    .variableDefinitions({ organizationId: 'ID!' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .entitySet('wallets', 'Wallet')
          .addEntity({
            Wallet: () => addEntity('Wallet')
          })
          .scalar('organizationId')
          .entity('currency', 'Currency')
            .scalar('name')
            .scalar('precision')
            .scalar('code')
            .deriveFromReference('currencyId', fetchCurrency)
            .replaceEntity({
              Currency: (currency: Entity, _variables: unknown, wallet: { currencyId: string }) => {
                // console.log('--- wallet ---', wallet);
                return wallet.currencyId === currency.id;
              }
            })._
          .scalar('name')
          .scalar('colorTag')
          .scalar('balanceAmount')
          .scalar('archived')._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { wallets } } }) => {
    return wallets.toSorted((w1: Wallet, w2: Wallet) => w1.name.localeCompare(w2.name));
  })
  .scopeByTenants(({ organizationId }) => ({ organizationId }));
