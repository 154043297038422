import createNamedEntitiesMutation from '@finance/graphql/mutations/createNamedEntitiesMutation';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, Panel, TextField, focusOnFirstInput, modifiers, spacers } from '@subtile/universal-ui';
import { removeArrayElementByIndex } from 'object-array-utils';
import { useI18n } from 'solid-compose';
import { Index, createSignal, } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import styles from './AddNamedEntities.module.css';
false && focusOnFirstInput;
const AddNamedEntities = (props) => {
    const [fields, setFields] = createStore({
        names: []
    });
    const translate = useI18n();
    const navigate = useNavigate();
    const [organization] = useSelectedOrganizationOrThrow();
    const [nameAlreadyUsedError, setNameAlreadyUsedError] = createSignal(null);
    function handleEntityNameChange(text, index) {
        setNameAlreadyUsedError(null);
        setFields('names', index, text);
    }
    function handleRemoveNamedEntity(index) {
        setNameAlreadyUsedError(null);
        const names = removeArrayElementByIndex(fields.names, index);
        setFields('names', names);
    }
    function handleAddNamedEntityClick() {
        setFields('names', fields.names.length, '');
        setTimeout(() => {
            const lastNamedEntityInputWrapper = Array.from(document.getElementsByClassName(styles.namedEntity)).at(-1);
            const lastNamedEntityInput = lastNamedEntityInputWrapper.querySelector('input');
            lastNamedEntityInput.focus();
        }, 0);
    }
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        setNameAlreadyUsedError(null);
        const variables = {
            names: fields.names,
            type: props.entitiesType,
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(createNamedEntitiesMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { createNamedEntities: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('CreateNamedEntitiesSuccess', () => {
            navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
        })
            .onExpectedErrorType('NameAlreadyUsed', (data) => {
            setNameAlreadyUsedError({
                message: translate('name_already_used', { name: data.name }),
                name: data.name
            });
            onExpectedError('');
        })
            .handle();
    };
    function errorMessage(entityName) {
        const nameAlreadyUsedError_ = nameAlreadyUsedError();
        return (nameAlreadyUsedError_ && entityName === nameAlreadyUsedError_.name)
            ? nameAlreadyUsedError_.message
            : undefined;
    }
    return (<Onboarding.FormPage title={props.title} description={props.description} buttonText={props.submitButtonLabel} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          {props.summary}

          <div class={styles.namedEntity}>
            <TextField error={errorMessage(fields.names[0])} label={props.inputLabel} onChange={value => handleEntityNameChange(value, 0)} required value={fields.names[0]}/>
          </div>

          <Index each={removeArrayElementByIndex(fields.names, 0)}>{(name, i) => <div class={styles.namedEntity}>
              <TextField error={errorMessage(name())} onChange={value => handleEntityNameChange(value, i + 1)} onClose={() => handleRemoveNamedEntity(i + 1)} required value={name()}/>
            </div>}</Index>

          <modifiers.smallButton>
            <Button type={ButtonType.Secondary} onClick={handleAddNamedEntityClick}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
              </svg>
              {props.addEntityButtonLabel}
            </Button>
          </modifiers.smallButton>
        </spacers.formFields>
      </Panel>
    </Onboarding.FormPage>);
};
export default AddNamedEntities;
