import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { getRoute, Route } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { Layout as Layout_ } from '@subtile/project-shared';
import MainMenu from './MainMenu';
import { useSelectedOrganizationOrThrow } from './ProvideSelectedOrganization';
const Layout = (props) => {
    const [organizations] = useOrganizationsResource();
    const [selectedOrganization] = useSelectedOrganizationOrThrow();
    const navigate = useNavigate();
    function handleSwitchOrganization(organization) {
        navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
    }
    function handleLogoutClick() {
        props.onLogOut?.();
    }
    return (<Layout_ menu={<MainMenu />} organizationsResource={organizations} selectedOrganizationId={selectedOrganization.id} onSwitchOrganization={handleSwitchOrganization} onLogoutClick={handleLogoutClick}/>);
};
export default Layout;
