import { setEntityLoading } from '@finance/graphql/localState';
import deleteAccountTransferMutation from '@finance/graphql/mutations/deleteAccountTransferMutation';
import deleteExpenseMutation from '@finance/graphql/mutations/deleteExpenseMutation';
import deleteRevenueMutation from '@finance/graphql/mutations/deleteRevenueMutation';
import deleteWalletTransferMutation from '@finance/graphql/mutations/deleteWalletTransferMutation';
import createAccountTransfersResource from '@finance/graphql/resources/createAccountTransfersResource';
import createExpensesResource from '@finance/graphql/resources/createExpensesResource';
import createRevenuesResource from '@finance/graphql/resources/createRevenuesResource';
import createWalletTransfersResource from '@finance/graphql/resources/createWalletTransfersResource';
import { Temporal } from '@js-temporal/polyfill';
import { useSearchParams } from '@solidjs/router';
import { MutationResponseHandler, Viewport, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, ColorTag, DataGrid, IconButton, ToggleButton } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { cloneShape } from 'object-array-utils';
import { formatDate, useI18n, useViewport } from 'solid-compose';
import { Match, Show, Switch, batch, createEffect, createSignal } from 'solid-js';
import { moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import AccountTransferFormDialog from './AccountTransferFormDialog';
import ExpenseFormDialog from './ExpenseFormDialog';
import RevenueFormDialog from './RevenueFormDialog';
import Toolbar from './Toolbar';
import styles from './TransactionInput.module.css';
import WalletTransferFormDialog from './WalletTransferFormDialog';
import YearMonthFilter from './YearMonthFilter';
;
var TransactionsType;
(function (TransactionsType) {
    TransactionsType["Revenues"] = "REVENUES";
    TransactionsType["Expenses"] = "EXPENSES";
    TransactionsType["WalletTransfers"] = "WALLET_TRANSFERS";
    TransactionsType["AccountTransfers"] = "ACCOUNT_TRANSFERS";
})(TransactionsType || (TransactionsType = {}));
const TransactionInput = () => {
    const translate = useI18n();
    const viewport = useViewport();
    const [searchParams, setSearchParams] = useSearchParams();
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [yearMonthFilter, setYearMonthFilter] = createSignal(Temporal.Now.plainDateISO().toPlainYearMonth());
    const initialQueryVariables = {
        organizationId: organization.id,
        pagination: { pageSize: 20 },
        filters: {
            fromDate: yearMonthFilter().toPlainDate({ day: 1 }).toString(),
            toDate: yearMonthFilter().toPlainDate({ day: yearMonthFilter().daysInMonth }).toString()
        }
    };
    const [transactionsType, setTransactionsType] = createSignal(getTransactionTypeByQueryString());
    const [showFormDialog, setShowFormDialog] = createSignal(null);
    const [commonVariables, setCommonVariables] = createSignal({ ...initialQueryVariables });
    const [revenuesQueryVariables, setRevenuesQueryVariables] = createSignal(null);
    const [expensesQueryVariables, setExpensesQueryVariables] = createSignal(null);
    const [walletTransfersQueryVariables, setWalletTransfersQueryVariables] = createSignal(null);
    const [accountTransfersQueryVariables, setAccountTransfersQueryVariables] = createSignal(null);
    const [revenues, { refetch: refetchRevenues }] = createRevenuesResource(revenuesQueryVariables);
    const [expenses, { refetch: refetchExpenses }] = createExpensesResource(expensesQueryVariables);
    const [walletTransfers, { refetch: refetchWalletTransfers }] = createWalletTransfersResource(walletTransfersQueryVariables);
    const [accountTransfers, { refetch: refetchAccountTransfers }] = createAccountTransfersResource(accountTransfersQueryVariables);
    const [revenueBeingEdited, setRevenueBeingEdited] = createSignal(null);
    const [expenseBeingEdited, setExpenseBeingEdited] = createSignal(null);
    const [walletTransferBeingEdited, setWalletTransferBeingEdited] = createSignal(null);
    const [accountTransferBeingEdited, setAccountTransferBeingEdited] = createSignal(null);
    const [revenueBeingViewed, setRevenueBeingViewed] = createSignal(null);
    const [expenseBeingViewed, setExpenseBeingViewed] = createSignal(null);
    const [walletTransferBeingViewed, setWalletTransferBeingViewed] = createSignal(null);
    const [accountTransferBeingViewed, setAccountTransferBeingViewed] = createSignal(null);
    createEffect(() => {
        if (revenues.error) {
            throw new Error(revenues.error);
        }
        if (expenses.error) {
            throw new Error(expenses.error);
        }
        if (walletTransfers.error) {
            throw new Error(expenses.error);
        }
        if (accountTransfers.error) {
            throw new Error(expenses.error);
        }
    });
    createEffect(() => {
        if (transactionsType() === TransactionsType.Revenues && !revenuesQueryVariables()) {
            setRevenuesQueryVariables(commonVariables());
        }
    });
    createEffect(() => {
        if (transactionsType() === TransactionsType.Expenses && !expensesQueryVariables()) {
            setExpensesQueryVariables(commonVariables());
        }
    });
    createEffect(() => {
        if (transactionsType() === TransactionsType.WalletTransfers && !walletTransfersQueryVariables()) {
            setWalletTransfersQueryVariables(commonVariables());
        }
    });
    createEffect(() => {
        if (transactionsType() === TransactionsType.AccountTransfers && !accountTransfersQueryVariables()) {
            setAccountTransfersQueryVariables(commonVariables());
        }
    });
    createEffect(() => {
        batch(() => {
            setRevenuesQueryVariables((variables) => maybeMergeCommonVariables(variables, commonVariables()));
            setExpensesQueryVariables((variables) => maybeMergeCommonVariables(variables, commonVariables()));
            setWalletTransfersQueryVariables((variables) => maybeMergeCommonVariables(variables, commonVariables()));
            setAccountTransfersQueryVariables((variables) => maybeMergeCommonVariables(variables, commonVariables()));
        });
    });
    createEffect(() => {
        (getTransactionTypeByQueryString())
            ? setTransactionsType(getTransactionTypeByQueryString())
            : setSearchParams({ type: TransactionsType.Revenues.toLowerCase() });
    });
    function getTransactionTypeByQueryString() {
        const type = searchParams.type && searchParams.type.toUpperCase();
        return Object.values(TransactionsType).includes(type) ? type : null;
    }
    function handleTransactionTypeChange(transactionType) {
        setSearchParams({ type: transactionType.toLowerCase() });
    }
    function maybeMergeCommonVariables(queryVariables, commonVariables) {
        if (!queryVariables) {
            return null;
        }
        return {
            ...queryVariables,
            filters: {
                ...queryVariables.filters,
                ...commonVariables.filters
            },
            pagination: {
                ...commonVariables.pagination,
                cursor: undefined,
                direction: undefined
            }
        };
    }
    function clearFormDialogState() {
        batch(() => {
            setShowFormDialog(null);
            setRevenueBeingViewed(null);
            setRevenueBeingEdited(null);
            setExpenseBeingViewed(null);
            setExpenseBeingEdited(null);
            setWalletTransferBeingViewed(null);
            setWalletTransferBeingEdited(null);
            setAccountTransferBeingViewed(null);
            setAccountTransferBeingEdited(null);
        });
    }
    function handleCloseFormDialog() {
        clearFormDialogState();
    }
    function handleAddClick() {
        batch(() => {
            clearFormDialogState();
            setShowFormDialog(transactionsType());
        });
    }
    function handleRevenueClick(revenue) {
        batch(() => {
            clearFormDialogState();
            setRevenueBeingViewed(revenue);
            setShowFormDialog(TransactionsType.Revenues);
        });
    }
    function handleEditRevenueClick(revenue) {
        batch(() => {
            clearFormDialogState();
            setRevenueBeingEdited(cloneShape(revenue));
            setShowFormDialog(TransactionsType.Revenues);
        });
    }
    async function handleDeleteRevenueClick(revenue) {
        setEntityLoading(revenue, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(revenue, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(deleteRevenueMutation.execute({
                organizationId: organization.id,
                revenueId: revenue.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.deleteRevenue)
                .onSuccessType('Revenue', () => {
                refetchRevenues();
                refetchWalletTransfers();
            })
                .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .handle();
            setEntityLoading(revenue, false);
        });
    }
    function handleExpenseClick(expense) {
        batch(() => {
            clearFormDialogState();
            setExpenseBeingViewed(expense);
            setShowFormDialog(TransactionsType.Expenses);
        });
    }
    function handleEditExpenseClick(expense) {
        batch(() => {
            clearFormDialogState();
            setExpenseBeingEdited(cloneShape(expense));
            setShowFormDialog(TransactionsType.Expenses);
        });
    }
    async function handleDeleteExpenseClick(expense) {
        setEntityLoading(expense, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(expense, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(deleteExpenseMutation.execute({
                organizationId: organization.id,
                expenseId: expense.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.deleteExpense)
                .onSuccessType('Expense', () => {
                refetchExpenses();
            })
                .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .handle();
            setEntityLoading(expense, false);
        });
    }
    function handleWalletTransferClick(walletTransfer) {
        batch(() => {
            clearFormDialogState();
            setWalletTransferBeingViewed(walletTransfer);
            setShowFormDialog(TransactionsType.WalletTransfers);
        });
    }
    function handleEditWalletTransferClick(walletTransfer) {
        batch(() => {
            clearFormDialogState();
            setWalletTransferBeingEdited(cloneShape(walletTransfer));
            setShowFormDialog(TransactionsType.WalletTransfers);
        });
    }
    async function handleDeleteWalletTransferClick(walletTransfer) {
        setEntityLoading(walletTransfer, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(walletTransfer, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(deleteWalletTransferMutation.execute({
                organizationId: organization.id,
                walletTransferId: walletTransfer.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.deleteWalletTransfer)
                .onSuccessType('WalletTransfer', () => {
                refetchWalletTransfers();
                refetchRevenues();
            })
                .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('DirectRevenueAllocationDeletionForbidden', () => {
                setWalletTransferBeingEdited(walletTransfer);
                setShowFormDialog(TransactionsType.WalletTransfers);
            })
                .onExpectedErrorType('DirectCrossCurrencyWalletTransferDeletionForbidden', () => {
                alert(translate('wallet_transfer_deletion_causes_mismatched_amount_cross_currency_account_wallet_transfers'));
            })
                .handle();
            setEntityLoading(walletTransfer, false);
        });
    }
    function handleAccountTransferClick(accountTransfer) {
        batch(() => {
            clearFormDialogState();
            setAccountTransferBeingViewed(accountTransfer);
            setShowFormDialog(TransactionsType.AccountTransfers);
        });
    }
    function handleEditAccountTransferClick(accountTransfer) {
        batch(() => {
            clearFormDialogState();
            setAccountTransferBeingEdited(cloneShape(accountTransfer));
            setShowFormDialog(TransactionsType.AccountTransfers);
        });
    }
    async function handleDeleteAccountTransferClick(accountTransfer) {
        setEntityLoading(accountTransfer, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(accountTransfer, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(deleteAccountTransferMutation.execute({
                organizationId: organization.id,
                accountTransferId: accountTransfer.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.deleteAccountTransfer)
                .onSuccessType('AccountTransfer', () => {
                refetchAccountTransfers();
                refetchWalletTransfers();
            })
                .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .handle();
            setEntityLoading(accountTransfer, false);
        });
    }
    function handleYearMonthFilterChange(yearMonth) {
        setYearMonthFilter(yearMonth);
        setCommonVariables((variables) => ({
            ...variables,
            filters: {
                ...variables.filters,
                fromDate: yearMonth.toPlainDate({ day: 1 }).toString(),
                toDate: yearMonth.toPlainDate({ day: yearMonth.daysInMonth }).toString()
            }
        }));
    }
    function renderFundName(fund, deleted) {
        const style = (deleted) ? { 'text-decoration': 'line-through' } : {};
        return (fund.colorTag)
            ? (<ColorTag size="14px" colorName={fund.colorTag} style={style}>
          {fund.name}
        </ColorTag>)
            : fund.name;
    }
    function handleRefreshClick() {
        batch(() => {
            Document.clearQueries([
                'Revenues',
                'Expenses',
                'AccountTransfers',
                'WalletTransfers',
                'WalletTransactions',
                'AccountTransactions',
                'Wallets',
                'Accounts'
            ]);
            refetchRevenues();
            refetchExpenses();
            refetchAccountTransfers();
            refetchWalletTransfers();
        });
    }
    return (<Show when={transactionsType()}>
      <div class={styles.transactionInputPage}>
        <Toolbar start={<>
              <ToggleButton.Group isDropdown={viewport.width === Viewport.SmallWidth} onChange={handleTransactionTypeChange} selectedValue={transactionsType()}>
                <ToggleButton value={TransactionsType.Revenues}>{translate('revenues')}</ToggleButton>
                <ToggleButton value={TransactionsType.Expenses}>{translate('expenses')}</ToggleButton>
                <ToggleButton value={TransactionsType.WalletTransfers}>{translate('wallet_transfers')}</ToggleButton>
                <ToggleButton value={TransactionsType.AccountTransfers}>{translate('account_transfers')}</ToggleButton>
              </ToggleButton.Group>

              <Button onClick={handleAddClick} type={ButtonType.Secondary}>{translate('add')}</Button>
            </>} end={<div style={{ '--icon-size': '20px' }}>
              <IconButton onClick={handleRefreshClick}>
                <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path d="M19.854 8.646c-0.195-0.195-0.512-0.195-0.707 0l-1.149 1.149c-0.051-2.060-0.878-3.99-2.341-5.452-1.511-1.511-3.52-2.343-5.657-2.343s-4.146 0.832-5.657 2.343-2.343 3.52-2.343 5.657 0.832 4.146 2.343 5.657 3.52 2.343 5.657 2.343c2.974 0 5.686-1.635 7.077-4.266 0.129-0.244 0.036-0.547-0.208-0.676s-0.547-0.036-0.676 0.208c-1.217 2.303-3.59 3.734-6.193 3.734-3.86 0-7-3.14-7-7s3.14-7 7-7c3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707z" fill="#000000"></path>
                </svg>
              </IconButton>
            </div>}/>

        <div class={styles.filters}>
          <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z" fill="#000000"></path>
          </svg>

          <YearMonthFilter value={yearMonthFilter()} onChange={handleYearMonthFilterChange}/>
        </div>

        <Switch>
          <Match when={transactionsType() === TransactionsType.Revenues}>
            <DataGrid paginationResponseMeta={revenues()?.pagination} paginationRequestMeta={revenuesQueryVariables()?.pagination} setPaginationRequestMeta={pagination => {
            setRevenuesQueryVariables((variables) => ({
                ...variables ?? initialQueryVariables,
                pagination: {
                    ...variables?.pagination ?? initialQueryVariables.pagination,
                    ...pagination
                }
            }));
        }} actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditRevenueClick
            },
            {
                id: 'delete',
                content: translate('delete'),
                onClick: handleDeleteRevenueClick
            }
        ]} loading={revenues.loading} data={revenues()?.paginatedEntries} onRowClick={handleRevenueClick} transformers={{
            date: formatDate,
            revenueType: ({ name }) => name,
            account: (account, revenue) => renderFundName(account, revenue.deleted),
            wallet: (wallet, revenue) => renderFundName(wallet, revenue.deleted),
            amount: ([amountBeforeAllocations, amountAfterAllocations], revenue) => {
                return (amountAfterAllocations === amountBeforeAllocations)
                    ? formatMoney(amountAfterAllocations, revenue.currency)
                    : `${formatMoney(amountAfterAllocations)} / ${formatMoney(amountBeforeAllocations, revenue.currency)}`;
            },
            // updatedAt: (instant: Temporal.Instant) => {
            //   const zonedDateTime = instant.toZonedDateTimeISO(locale.timeZone);
            //   return <span style={{ 'display': 'inline-block' }}>{
            //     formatDate(zonedDateTime.toPlainDate())
            //     + ' ' + formatTime(zonedDateTime.toPlainTime(), { precision: 'minute' })
            //   }</span>;
            // }
        }} isRowDataDeleted={({ deleted }) => deleted} isRowDataLoading={({ loading }) => loading} responsiveLayouts={{
            [Viewport.SmallWidth]: {
                strategy: 'groupedColumns',
                columnDefinitions: [
                    {
                        headers: [translate('date')],
                        fields: ['date'],
                        width: '105px'
                    },
                    {
                        headers: [translate('wallet'), translate('account')],
                        fields: ['wallet', 'account'],
                        width: '1fr'
                    },
                    {
                        headers: [translate('amount'), translate('revenue_type')],
                        fields: [['amountBeforeAllocations', 'amountAfterAllocations'], 'revenueType'],
                        keys: ['amount', 'revenueType'],
                        width: '1fr'
                    }
                ]
            },
            [Viewport.MediumWidth]: {
                strategy: 'hideColumns',
                columnDefinitions: [
                    {
                        field: 'date',
                        header: translate('date'),
                        width: '110px'
                    },
                    {
                        field: 'revenueType',
                        header: translate('revenue_type'),
                        width: '140px'
                    },
                    {
                        field: 'wallet',
                        header: translate('wallet'),
                        width: '115px'
                    },
                    {
                        field: 'account',
                        header: translate('account'),
                        width: '115px'
                    },
                    {
                        fields: ['amountBeforeAllocations', 'amountAfterAllocations'],
                        key: 'amount',
                        header: translate('amount'),
                        width: '190px',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        field: 'reference',
                        header: translate('reference'),
                        width: '1fr'
                    }
                ]
            }
        }} columnDefinitions={[
            {
                field: 'date',
                header: translate('date'),
                width: '125px'
            },
            {
                field: 'revenueType',
                header: translate('revenue_type'),
                width: '180px'
            },
            {
                field: 'wallet',
                header: translate('receiving_wallet'),
                width: '170px'
            },
            {
                field: 'account',
                header: translate('receiving_account'),
                width: '170px'
            },
            {
                fields: ['amountBeforeAllocations', 'amountAfterAllocations'],
                key: 'amount',
                header: translate('amount'),
                width: '190px',
                allowOverflow: true,
                align: 'end'
            },
            {
                field: 'reference',
                header: translate('reference'),
                width: '130px'
            },
            // {
            //   field: 'updatedAt',
            //   header: translate('last_updated'),
            //   width: '160px'
            // },
            {
                field: 'description',
                header: translate('description'),
                width: '1fr'
            }
        ]}/>

            <RevenueFormDialog isOpen={showFormDialog() === TransactionsType.Revenues} onClose={handleCloseFormDialog} onRevenueAddedOrEdited={() => {
            batch(() => {
                refetchRevenues();
                refetchWalletTransfers();
            });
        }} onEditRevenueClick={handleEditRevenueClick} revenue={revenueBeingViewed() || revenueBeingEdited()} isViewMode={!!revenueBeingViewed()}/>
          </Match>

          <Match when={transactionsType() === TransactionsType.Expenses}>
            <DataGrid paginationResponseMeta={expenses()?.pagination} paginationRequestMeta={expensesQueryVariables()?.pagination} setPaginationRequestMeta={pagination => {
            setExpensesQueryVariables((variables) => ({
                ...variables ?? initialQueryVariables,
                pagination: {
                    ...variables?.pagination ?? initialQueryVariables.pagination,
                    ...pagination
                }
            }));
        }} actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditExpenseClick
            },
            {
                id: 'delete',
                content: translate('delete'),
                onClick: handleDeleteExpenseClick
            }
        ]} loading={expenses.loading} data={expenses()?.paginatedEntries} onRowClick={handleExpenseClick} transformers={{
            date: formatDate,
            expenseType: ({ name }) => name,
            account: (account, expense) => renderFundName(account, expense.deleted),
            wallet: (wallet, expense) => renderFundName(wallet, expense.deleted),
            amount: (amount, expense) => formatMoney(amount, expense.currency),
            // updatedAt: (instant: Temporal.Instant) => {
            //   const zonedDateTime = instant.toZonedDateTimeISO(locale.timeZone);
            //   return formatDate(zonedDateTime.toPlainDate())
            //     + ' ' + formatTime(zonedDateTime.toPlainTime(), { precision: 'minute' });
            // }
        }} isRowDataDeleted={({ deleted }) => deleted} isRowDataLoading={({ loading }) => loading} responsiveLayouts={{
            [Viewport.SmallWidth]: {
                strategy: 'groupedColumns',
                columnDefinitions: [
                    {
                        headers: [translate('date')],
                        fields: ['date'],
                        width: '105px'
                    },
                    {
                        headers: [translate('wallet'), translate('account')],
                        fields: ['wallet', 'account'],
                        width: '1fr'
                    },
                    {
                        headers: [translate('amount'), translate('expense_type')],
                        fields: ['amount', 'expenseType'],
                        width: '1fr'
                    }
                ]
            },
            [Viewport.MediumWidth]: {
                strategy: 'hideColumns',
                columnDefinitions: [
                    {
                        field: 'date',
                        header: translate('date'),
                        width: '110px'
                    },
                    {
                        field: 'expenseType',
                        header: translate('expense_type'),
                        width: '140px'
                    },
                    {
                        field: 'wallet',
                        header: translate('wallet'),
                        width: '115px'
                    },
                    {
                        field: 'account',
                        header: translate('account'),
                        width: '115px'
                    },
                    {
                        field: 'amount',
                        header: translate('amount'),
                        width: '170px',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        field: 'reference',
                        header: translate('reference'),
                        width: '1fr'
                    }
                ]
            }
        }} columnDefinitions={[
            {
                field: 'date',
                header: translate('date'),
                width: '125px'
            },
            {
                field: 'expenseType',
                header: translate('expense_type'),
                width: '180px'
            },
            {
                field: 'wallet',
                header: translate('sending_wallet'),
                width: '170px'
            },
            {
                field: 'account',
                header: translate('receiving_account'),
                width: '170px'
            },
            {
                field: 'amount',
                header: translate('amount'),
                width: '170px',
                allowOverflow: true,
                align: 'end'
            },
            {
                field: 'reference',
                header: translate('reference'),
                width: '130px'
            },
            // {
            //   field: 'updatedAt',
            //   header: translate('last_updated'),
            //   width: '160px'
            // },
            {
                field: 'description',
                header: translate('description'),
                width: '1fr'
            }
        ]}/>

            <ExpenseFormDialog isOpen={showFormDialog() === TransactionsType.Expenses} onClose={handleCloseFormDialog} onExpenseAddedOrEdited={refetchExpenses} onEditExpenseClick={handleEditExpenseClick} expense={expenseBeingViewed() || expenseBeingEdited()} isViewMode={!!expenseBeingViewed()}/>
          </Match>

          <Match when={transactionsType() === TransactionsType.WalletTransfers}>
            <DataGrid paginationResponseMeta={walletTransfers()?.pagination} paginationRequestMeta={walletTransfersQueryVariables()?.pagination} setPaginationRequestMeta={pagination => {
            setWalletTransfersQueryVariables((variables) => ({
                ...variables ?? initialQueryVariables,
                pagination: {
                    ...variables?.pagination ?? initialQueryVariables.pagination,
                    ...pagination
                }
            }));
        }} actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditWalletTransferClick
            },
            {
                id: 'delete',
                content: translate('delete'),
                onClick: handleDeleteWalletTransferClick
            }
        ]} loading={walletTransfers.loading} data={walletTransfers()?.paginatedEntries} onRowClick={handleWalletTransferClick} transformers={{
            description: (text, walletTransfer) => {
                const content = [];
                if (walletTransfer.revenueIdForAllocation) {
                    content.push(<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path d="M8.5 20c-2.27 0-4.405-0.884-6.010-2.49s-2.49-3.74-2.49-6.010c0-2.27 0.884-4.405 2.49-6.010s3.74-2.49 6.010-2.49c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5c-4.136 0-7.5 3.364-7.5 7.5s3.364 7.5 7.5 7.5c4.136 0 7.5-3.364 7.5-7.5 0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5c0 2.27-0.884 4.405-2.49 6.010s-3.74 2.49-6.010 2.49z" fill="#000000"></path>
                      <path d="M18.5 10h-8c-0.276 0-0.5-0.224-0.5-0.5v-8c0-0.276 0.224-0.5 0.5-0.5 2.27 0 4.405 0.884 6.010 2.49s2.49 3.74 2.49 6.010c0 0.276-0.224 0.5-0.5 0.5zM11 9h6.984c-0.247-3.738-3.246-6.736-6.984-6.984v6.984z" fill="#000000"></path>
                    </svg>);
                }
                if (text) {
                    content.push(<span>{text}</span>);
                }
                return <div style={{ display: 'flex', 'align-items': 'center', gap: '6px' }}>{content}</div>;
            },
            date: formatDate,
            sourceWallet: (sourceWallet, walletTransfer) => renderFundName(sourceWallet, walletTransfer.deleted),
            targetWallet: (targetWallet, walletTransfer) => renderFundName(targetWallet, walletTransfer.deleted),
            // updatedAt: (instant: Temporal.Instant) => {
            //   const zonedDateTime = instant.toZonedDateTimeISO(locale.timeZone);
            //   return formatDate(zonedDateTime.toPlainDate())
            //     + ' ' + formatTime(zonedDateTime.toPlainTime(), { precision: 'minute' });
            // },
            amount: ([sourceAmount, targetAmount], walletTransfer) => {
                return (walletTransfer.sourceCurrency.id === walletTransfer.targetCurrency.id)
                    ? formatMoney(sourceAmount, walletTransfer.sourceCurrency)
                    : formatMoney(sourceAmount, walletTransfer.sourceCurrency) + ' → ' + formatMoney(targetAmount, walletTransfer.targetCurrency);
            },
            wallets: ([sourceWallet, targetWallet], walletTransfer) => <span style={{ 'align-items': 'center', display: 'flex', gap: '6px' }}>
                  {renderFundName(sourceWallet, walletTransfer.deleted)} → {renderFundName(targetWallet, walletTransfer.deleted)}
                </span>,
            spacer: () => ''
        }} isRowDataDeleted={({ deleted }) => deleted} isRowDataLoading={({ loading }) => loading} responsiveLayouts={{
            [Viewport.SmallWidth]: {
                strategy: 'groupedColumns',
                columnDefinitions: [
                    {
                        headers: [translate('date'), translate('wallets')],
                        fields: ['date', ['sourceWallet', 'targetWallet']],
                        keys: ['date', 'wallets'],
                        width: 'minmax(max-content, 220px)'
                    },
                    {
                        headers: [translate('amount')],
                        fields: [['sourceAmount', 'targetAmount']],
                        keys: ['amount'],
                        width: 'minmax(max-content, 220px)',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        headers: [''],
                        keys: ['spacer'],
                        width: '1fr'
                    }
                ]
            },
            [Viewport.MediumWidth]: {
                strategy: 'hideColumns',
                columnDefinitions: [
                    {
                        field: 'date',
                        header: translate('date'),
                        width: '120px'
                    },
                    {
                        field: 'sourceWallet',
                        header: translate('sending_wallet'),
                        width: '180px'
                    },
                    {
                        field: 'targetWallet',
                        header: translate('receiving_wallet'),
                        width: '180px'
                    },
                    {
                        key: 'amount',
                        fields: ['sourceAmount', 'targetAmount'],
                        header: translate('amount'),
                        width: '190px',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        key: 'spacer',
                        header: '',
                        width: '1fr'
                    }
                ]
            }
        }} columnDefinitions={[
            {
                field: 'date',
                header: translate('date'),
                width: '125px'
            },
            {
                field: 'sourceWallet',
                header: translate('sending_wallet'),
                width: '190px'
            },
            {
                field: 'targetWallet',
                header: translate('receiving_wallet'),
                width: '190px'
            },
            {
                key: 'amount',
                fields: ['sourceAmount', 'targetAmount'],
                header: translate('amount'),
                width: '190px',
                allowOverflow: true,
                align: 'end'
            },
            // {
            //   field: 'updatedAt',
            //   header: translate('last_updated'),
            //   width: '160px'
            // },
            {
                field: 'description',
                header: translate('description'),
                width: '1fr'
            }
        ]}/>

            <WalletTransferFormDialog isOpen={showFormDialog() === TransactionsType.WalletTransfers} onClose={handleCloseFormDialog} onWalletTransferAddedOrEdited={() => {
            batch(() => {
                refetchWalletTransfers();
                refetchRevenues();
            });
        }} onRevenueEdited={() => {
            batch(() => {
                refetchWalletTransfers();
                refetchRevenues();
            });
        }} onEditWalletTransferClick={handleEditWalletTransferClick} walletTransfer={walletTransferBeingViewed() || walletTransferBeingEdited()} isViewMode={!!walletTransferBeingViewed()}/>
          </Match>

          <Match when={transactionsType() === TransactionsType.AccountTransfers}>
            <DataGrid paginationResponseMeta={accountTransfers()?.pagination} paginationRequestMeta={accountTransfersQueryVariables()?.pagination} setPaginationRequestMeta={pagination => {
            setAccountTransfersQueryVariables((variables) => ({
                ...variables ?? initialQueryVariables,
                pagination: {
                    ...variables?.pagination ?? initialQueryVariables.pagination,
                    ...pagination
                }
            }));
        }} actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditAccountTransferClick
            },
            {
                id: 'delete',
                content: translate('delete'),
                onClick: handleDeleteAccountTransferClick
            }
        ]} loading={accountTransfers.loading} data={accountTransfers()?.paginatedEntries} onRowClick={handleAccountTransferClick} transformers={{
            date: formatDate,
            sourceAccount: (sourceAccount, accountTransfer) => renderFundName(sourceAccount, accountTransfer.deleted),
            targetAccount: (targetAccount, accountTransfer) => renderFundName(targetAccount, accountTransfer.deleted),
            // updatedAt: (instant: Temporal.Instant) => {
            //   const zonedDateTime = instant.toZonedDateTimeISO(locale.timeZone);
            //   return formatDate(zonedDateTime.toPlainDate())
            //     + ' ' + formatTime(zonedDateTime.toPlainTime(), { precision: 'minute' });
            // },
            amount: ([sourceAmount, targetAmount], accountTransfer) => {
                return (accountTransfer.sourceCurrency.id === accountTransfer.targetCurrency.id)
                    ? formatMoney(sourceAmount, accountTransfer.sourceCurrency)
                    : formatMoney(sourceAmount, accountTransfer.sourceCurrency) + ' → ' + formatMoney(targetAmount, accountTransfer.targetCurrency);
            },
            accounts: ([sourceAccount, targetAccount], accountTransfer) => <span style={{ 'align-items': 'center', display: 'flex', gap: '6px' }}>
                  {renderFundName(sourceAccount, accountTransfer.deleted)} → {renderFundName(targetAccount, accountTransfer.deleted)}
                </span>,
            spacer: () => ''
        }} isRowDataDeleted={({ deleted }) => deleted} isRowDataLoading={({ loading }) => loading} responsiveLayouts={{
            [Viewport.SmallWidth]: {
                strategy: 'groupedColumns',
                columnDefinitions: [
                    {
                        headers: [translate('date'), translate('accounts')],
                        fields: ['date', ['sourceAccount', 'targetAccount']],
                        keys: ['date', 'accounts'],
                        width: 'minmax(max-content, 220px)'
                    },
                    {
                        headers: [translate('amount')],
                        fields: [['sourceAmount', 'targetAmount']],
                        keys: ['amount'],
                        width: 'minmax(max-content, 220px)',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        headers: [''],
                        keys: ['spacer'],
                        width: '1fr'
                    }
                ]
            },
            [Viewport.MediumWidth]: {
                strategy: 'hideColumns',
                columnDefinitions: [
                    {
                        field: 'date',
                        header: translate('date'),
                        width: '120px'
                    },
                    {
                        field: 'sourceAccount',
                        header: translate('sending_account'),
                        width: '180px'
                    },
                    {
                        field: 'targetAccount',
                        header: translate('receiving_account'),
                        width: '180px'
                    },
                    {
                        key: 'amount',
                        fields: ['sourceAmount', 'targetAmount'],
                        header: translate('amount'),
                        width: '190px',
                        allowOverflow: true,
                        align: 'end'
                    },
                    {
                        key: 'spacer',
                        header: '',
                        width: '1fr'
                    }
                ]
            }
        }} columnDefinitions={[
            {
                field: 'date',
                header: translate('date'),
                width: '125px'
            },
            {
                field: 'sourceAccount',
                header: translate('sending_account'),
                width: '190px'
            },
            {
                field: 'targetAccount',
                header: translate('receiving_account'),
                width: '190px'
            },
            {
                key: 'amount',
                fields: ['sourceAmount', 'targetAmount'],
                header: translate('amount'),
                width: '190px',
                allowOverflow: true,
                align: 'end'
            },
            // {
            //   field: 'updatedAt',
            //   header: translate('last_updated'),
            //   width: '1fr'
            // },
            {
                key: 'spacer',
                header: '',
                width: '1fr'
            }
        ]}/>

            <AccountTransferFormDialog isOpen={showFormDialog() === TransactionsType.AccountTransfers} onClose={handleCloseFormDialog} onAccountTransferAddedOrEdited={() => {
            batch(() => {
                refetchAccountTransfers();
                refetchWalletTransfers();
            });
        }} onEditAccountTransferClick={handleEditAccountTransferClick} accountTransfer={accountTransferBeingViewed() || accountTransferBeingEdited()} isViewMode={!!accountTransferBeingViewed()}/>
          </Match>
        </Switch>
      </div>
    </Show>);
};
export default TransactionInput;
