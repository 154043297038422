import { cloneShape } from 'object-array-utils';
import { getOwner, untrack } from 'solid-js';
import { createStore, reconcile, unwrap } from 'solid-js/store';
export function createDeepSignal(value) {
    const [store, setStore] = createStore({ value });
    return [
        () => store.value,
        (v) => untrack(() => {
            const unwrapped = unwrap(store.value);
            typeof v === 'function' && (v = v(unwrapped));
            setStore('value', reconcile(cloneShape(v)));
            return store.value;
        })
    ];
}
export function throwIfNoOwner() {
    if (!getOwner()) {
        throw new Error('(╯°□°)╯︵ ┻━┻');
    }
}
