import { useLocale } from 'solid-compose';
import selectStyles from '../select/Select.module.css';
const Input = (props) => {
    const [locale] = useLocale();
    const selectedDateString = () => props.selectedDate.toLocaleString(locale.languageTag, { month: 'short', year: 'numeric', day: 'numeric' });
    return (<div onClick={props.onClick} class={selectStyles.input} tabindex="0" onBlur={props.onBlur}>
      <span class={selectStyles.selectedOption}>{selectedDateString()}</span>
    </div>);
};
export default Input;
