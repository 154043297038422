import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { AccountTransfer } from '../types';

export interface DeleteAccountTransferReturn {
  deleteAccountTransfer: Pick<AccountTransfer, 'id' | '__typename' | 'deleted'>;
}

interface DeleteAccountTransferVars {
  organizationId: string;
  accountTransferId: string;
}

export default Document
  .mutation<DeleteAccountTransferReturn, DeleteAccountTransferVars>('DeleteAccountTransfer')
    .variableDefinitions({ organizationId: 'ID!', accountTransferId: 'ID!' })
    .union('deleteAccountTransfer')
      .useVariables({ organizationId: 'organizationId', accountTransferId: 'accountTransferId' })
      .onEntity('AccountTransfer')
        .scalar('organizationId')
        .scalar('deleted')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ deleteAccountTransfer: data }) => {
    if (data.__typename === 'AccountTransfer') {
      Document.clearQueries([
        'AccountTransfers',
        'WalletTransfers',
        'WalletTransactions',
        'AccountTransactions',
        'Accounts',
        'Wallets'
      ]);
    }
  });
