import { createContext, createSignal, Index, Show, useContext } from 'solid-js';
import styles from './FieldError.module.css';
const ErrorContext = createContext();
const ErrorRenderer = (props) => {
    const [errors, setErrors] = createSignal([]);
    const addError = (error) => {
        setErrors((errors) => [...errors, error]);
    };
    const removeError = (error) => {
        setErrors((errors) => errors.filter((e) => e !== error));
    };
    const clearErrors = () => {
        setErrors([]);
    };
    const contextValue = {
        addError,
        removeError,
        clearErrors
    };
    return (<ErrorContext.Provider value={contextValue}>
      {props.children}

      <Show when={errors().length > 0}>
        <div style={{ display: 'flex', 'flex-direction': 'column', gap: '4px', 'margin-top': '-8px' }}>
          <Index each={errors()}>{error => <div class={styles.fieldError}>{error().message}</div>}</Index>
        </div>
      </Show>
    </ErrorContext.Provider>);
};
export default ErrorRenderer;
export function useErrorRenderer() {
    return useContext(ErrorContext);
}
