import createRevenueTypeMutation from '@finance/graphql/mutations/createRevenueTypeMutation';
import updateRevenueTypeMutation from '@finance/graphql/mutations/updateRevenueTypeMutation';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, Checkbox, ErrorRenderer, FormDialog, SelectEntity, TextField, modifiers, spacers } from '@subtile/universal-ui';
import Decimal from 'decimal.js-light';
import { removeArrayElementByIndex } from 'object-array-utils';
import { useI18n } from 'solid-compose';
import { Index, Show, batch, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function renderPercentage(percentage) {
    return new Decimal(percentage).times(100).toString();
}
function getInitialFormFields(revenueType) {
    const allocationRules = (revenueType)
        ? (revenueType.allocationRules || []).map((rule) => ({ ...rule, allocationPercentage: renderPercentage(rule.allocationPercentage) }))
        : [{
                targetWallet: undefined,
                allocationPercentage: ''
            }];
    return {
        name: revenueType?.name || '',
        allocationRules
    };
}
const RevenueTypeFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [hasAllocationRules, setHasAllocationRules] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore(getInitialFormFields(props.revenueType));
    const translate = useI18n();
    const [nameAlreadyUsedError, setNameAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen && props.revenueType) {
            setFields(getInitialFormFields(props.revenueType));
        }
    });
    createEffect(() => {
        if (props.isOpen && props.revenueType) {
            setHasAllocationRules(fields.allocationRules.length > 0);
        }
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setNameAlreadyUsedError('');
        const variables = {
            input: {
                name: fields.name,
                allocationRules: (hasAllocationRules())
                    ? fields.allocationRules.map((rule) => ({
                        allocationPercentage: new Decimal(rule.allocationPercentage).div(100).toString(),
                        targetWalletId: rule.targetWallet.id
                    }))
                    : []
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (props.revenueType)
            ? await processResponse(updateRevenueTypeMutation.execute({ ...variables, revenueTypeId: props.revenueType.id }), { onError })
            : await processResponse(createRevenueTypeMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateRevenueType' in response) {
            data = response.updateRevenueType;
        }
        else if ('createRevenueType' in response) {
            data = response.createRevenueType;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('RevenueType', () => {
            props.onRevenueTypeAddedOrEdited?.();
            closeDialog();
        })
            .onExpectedErrorType('NameAlreadyUsed', (data) => {
            setNameAlreadyUsedError(translate('name_already_used', { name: data.name }));
            onExpectedError('');
        })
            .handle();
    };
    function handleDialogClosed() {
        batch(() => {
            setFields(getInitialFormFields());
            setHasAllocationRules(false);
            setNameAlreadyUsedError('');
        });
        props.onClose();
    }
    function renderTitle() {
        if (props.isViewMode) {
            return translate('title_revenue_type');
        }
        if (props.revenueType) {
            return translate('title_edit_revenue_type');
        }
        return translate('title_add_revenue_type');
    }
    function handleHasAllocationsClick(hasAllocationRules) {
        batch(() => {
            setHasAllocationRules(hasAllocationRules);
            (hasAllocationRules)
                ? setFields('allocationRules', [{
                        targetWallet: undefined,
                        allocationPercentage: ''
                    }])
                : setFields('allocationRules', []);
        });
    }
    function handleTargetWalletForAllocationRuleChange(wallet, index) {
        setFields('allocationRules', index, 'targetWallet', { ...wallet });
    }
    function handleAllocationRulePercentageChange(percentage, index) {
        setFields('allocationRules', index, 'allocationPercentage', percentage);
    }
    function handleAddAllocationRuleClick() {
        setFields('allocationRules', fields.allocationRules.length, {
            targetWallet: undefined,
            allocationPercentage: ''
        });
    }
    function handleRemoveAllocationRuleClick(index) {
        const allocationRules = removeArrayElementByIndex(fields.allocationRules, index);
        setFields('allocationRules', allocationRules);
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <TextField label={translate('name')} onChange={value => { setNameAlreadyUsedError(''); setFields('name', value); }} error={nameAlreadyUsedError()} value={fields.name} readOnly={props.isViewMode} required/>

          <Checkbox onChange={handleHasAllocationsClick} checked={hasAllocationRules()}>
            <div style={{ 'margin-bottom': '10px' }}>{translate('has_revenue_type_allocations')}</div>
            <Show when={!hasAllocationRules()}>
              <div style={{ 'font-size': '12px' }}>{translate('revenue_allocations_explanation')}</div>
            </Show>
          </Checkbox>

          <Show when={hasAllocationRules()}>
            <ErrorRenderer>
              <div style={{
                display: 'flex',
                gap: '16px',
                '--text-field-max-width': '50px'
            }}>
                <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleTargetWalletForAllocationRuleChange(value, 0)} placeholder={translate('select')} selectedEntityId={fields.allocationRules[0]?.targetWallet?.id} required/>

                <TextField label={translate('percentage')} onChange={value => handleAllocationRulePercentageChange(value, 0)} type="percentage" value={fields.allocationRules[0]?.allocationPercentage} required/>
              </div>
            </ErrorRenderer>

            <Index each={removeArrayElementByIndex(fields.allocationRules, 0)}>{(rule, i) => <ErrorRenderer>
                <div style={{
                    display: 'flex',
                    gap: '16px',
                    '--text-field-max-width': '50px'
                }}>
                  <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={value => handleTargetWalletForAllocationRuleChange(value, i + 1)} placeholder={translate('select')} selectedEntityId={rule().targetWallet?.id} required/>

                  <TextField label={translate('percentage')} onChange={value => handleAllocationRulePercentageChange(value, i + 1)} onClose={props.isViewMode ? undefined : (() => handleRemoveAllocationRuleClick(i + 1))} type="percentage" value={rule().allocationPercentage} required/>
                </div>
              </ErrorRenderer>}</Index>

            <modifiers.smallButton>
              <Button type={ButtonType.Secondary} onClick={handleAddAllocationRuleClick}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
                </svg>
                {translate('add_revenue_allocation_rule')}
              </Button>
            </modifiers.smallButton>
          </Show>

        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.revenueType ? translate('update') : translate('add')}</Button>}>
            <Button type={ButtonType.Secondary} onClick={() => props.onEditRevenueTypeClick(props.revenueType)}>{translate('edit')}</Button>
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={renderTitle()} modifiers={{
            '--dialog-max-width': '400px'
        }}/>);
};
export default RevenueTypeFormDialog;
