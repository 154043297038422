import { Loader, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus, useCurrentUser } from 'solid-compose';
import { Match, Switch } from 'solid-js';
const AwaitAuthenticationResponse = (props) => {
    const [_currentUser, { authenticationStatus, authenticationError }] = useCurrentUser();
    function throwError() {
        throw authenticationError();
    }
    return (<Switch fallback={props.children}>
      <Match when={authenticationStatus() === AuthenticationStatus.Errored}>
        {throwError()}
      </Match>

      <Match when={authenticationStatus() === AuthenticationStatus.Pending}>
        <spacers.viewportFill>
          <Loader />
        </spacers.viewportFill>
      </Match>
    </Switch>);
};
export default AwaitAuthenticationResponse;
