import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { Onboarding } from '@subtile/project-shared';
import { Button, ButtonType, Loader } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { For, Show } from 'solid-js';
const SelectOrganization = () => {
    const [organizations] = useOrganizationsResource();
    const translate = useI18n();
    const navigate = useNavigate();
    function handleOrganizationClick(organization) {
        navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
    }
    return (<Onboarding.Page title={translate('select_organization_title')} description={translate('select_organization_description')}>
      <Button.List>
        <Show when={!organizations.loading} fallback={<div style={{ margin: '10px 0' }}><Loader /></div>}>
          <For each={organizations()}>{(organization) => <Button onClick={() => handleOrganizationClick(organization)} type={ButtonType.Secondary}>
              {organization.name}
            </Button>}</For>
        </Show>

        <Button onClick={() => navigate(getRoute(Route.OnboardingAddOrganization))} type={ButtonType.Primary}>
          {translate('add_new_organization')}
        </Button>
      </Button.List>
    </Onboarding.Page>);
};
export default SelectOrganization;
