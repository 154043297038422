import styles from './Money.module.css';
const Money = (props) => {
    return (<div class={styles.money}>
      <div class={styles.amountContainer}>
        {props.amount}
      </div>

      <div class={styles.currencyContainer}>
        {props.currency}
      </div>
    </div>);
};
export default Money;
