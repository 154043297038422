import logInUserMutation from '@auth/graphql/mutations/logInUserMutation';
import { Route, getRoute } from '@auth/routes';
import { A, useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, csrf, useCurrentUser } from '@subtile/project-shared';
import { Panel, TextField, spacers } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { AuthenticationStatus, useI18n, useLocalStorage } from 'solid-compose';
import { createStore } from 'solid-js/store';
import styles from './LogIn.module.css';
;
const LogIn = () => {
    const [registeredEmail, { set: setRegisteredEmail }] = useLocalStorage('registeredEmail');
    const [fields, setFields] = createStore({
        email: registeredEmail() || '',
        password: ''
    });
    const [currentUser, { refetchCurrentUser, authenticationStatus }] = useCurrentUser();
    const translate = useI18n();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        const input = {
            email: fields.email,
            password: fields.password
        };
        const { logInUser: data } = await logInUserMutation.execute({ input }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('LogInUserSuccess', async () => {
            Document.clearAllQueries();
            csrf.clearToken();
            await refetchCurrentUser();
            if (currentUser.error) {
                onUnexpectedError();
                throw currentUser.error;
            }
            if (authenticationStatus() === AuthenticationStatus.Authenticated) {
                setRegisteredEmail(currentUser().email);
                navigate(getRoute(Route.ProfileCompletionCheck));
                return;
            }
            throw new Error('cannot authenticate user');
        })
            .onExpectedErrorType('InvalidLogin', () => {
            onExpectedError(translate('invalid_login'));
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('log_in_title')} buttonText={translate('log_in')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          <TextField label={translate('email')} onChange={value => setFields('email', value)} required type="email" value={fields.email}/>

          <TextField label={translate('password')} onChange={value => setFields('password', value)} required type="password" value={fields.password}/>
        </spacers.formFields>
      </Panel>

      <div class={styles.noAccountYet}>
        {translate("no_account_yet")}
        {' '}
        <A href={getRoute(Route.SelectSignupAuthMethod)}>{translate("create_account")}</A>
      </div>

      <div class={styles.forgotPassword}>
        {translate("forgot_password")}
        {' '}
        <A href={getRoute(Route.ResetPasswordRequest)}>{translate("forgot_password_reset")}</A>
      </div>
    </Onboarding.FormPage>);
};
export default LogIn;
