import { Onboarding } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import AddNamedEntities from './AddNamedEntities';
const AddExpenseTypes = () => {
    const translate = useI18n();
    return (<AddNamedEntities entitiesType="EXPENSE_TYPES" title={translate('add_expense_types_title')} submitButtonLabel={translate('add_expense_types')} inputLabel={translate('expense_types')} addEntityButtonLabel={translate('add_another_expense_type')} summary={<Onboarding.Description>
          <Onboarding.Note>
            <div>
              {translate('add_expense_types_description')}
            </div>
          </Onboarding.Note>
        </Onboarding.Description>}/>);
};
export default AddExpenseTypes;
