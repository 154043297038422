import useOrganizationsResource from '@finance/graphql/resources/useOrganizationsResource';
import { Route, getRoute } from '@finance/routes';
import { Navigate } from '@solidjs/router';
import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { Match, Switch } from 'solid-js';
const EnsureOnboardingNotCompleted = (props) => {
    const [currentUser] = useCurrentUser();
    const [organizations] = useOrganizationsResource();
    return (<Switch fallback={props.children}>
      <Match when={currentUser.loading || organizations.loading}>
        <spacers.viewportFill>
          <Loader />
        </spacers.viewportFill>
      </Match>
      <Match when={organizations()?.some(o => o.completedSetups.includes('finance'))}>
        <Navigate href={getRoute(Route.SelectOrganization)}/>
      </Match>
    </Switch>);
};
export default EnsureOnboardingNotCompleted;
