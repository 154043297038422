// @ts-nocheck
import classNames from 'classnames';
import styles from './Logo.module.css';
const Logo = () => {
    return (<svg class={classNames(styles.logo, "icon")} xmlns="http://www.w3.org/2000/svg" viewBox="204.37 184.58 176.39 176.39">
      <polygon class={styles.polygon} points="380.76 280.79 300.59 360.97 220.41 360.97 340.67 240.7 380.76 280.79"/>
      <polygon class={styles.polygon} points="204.37 264.76 284.55 184.58 364.73 184.58 244.46 304.85 204.37 264.76"/>
    </svg>);
};
export default Logo;
