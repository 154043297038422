import { setEntityLoading } from '@finance/graphql/localState';
import archiveAccountMutation from '@finance/graphql/mutations/archiveAccountMutation';
import archiveWalletMutation from '@finance/graphql/mutations/archiveWalletMutation';
import deleteAccountTransferMutation from '@finance/graphql/mutations/deleteAccountTransferMutation';
import deleteExpenseMutation from '@finance/graphql/mutations/deleteExpenseMutation';
import deleteRevenueMutation from '@finance/graphql/mutations/deleteRevenueMutation';
import deleteWalletTransferMutation from '@finance/graphql/mutations/deleteWalletTransferMutation';
import createAccountTransactionsResource from '@finance/graphql/resources/createAccountTransactionsResource';
import createWalletTransactionsResource from '@finance/graphql/resources/createWalletTransactionsResource';
import useAccountsResource from '@finance/graphql/resources/useAccountsResource';
import useWalletsResource from '@finance/graphql/resources/useWalletsResource';
import { Route, getRoute } from '@finance/routes';
import { Temporal } from '@js-temporal/polyfill';
import { useNavigate, useParams } from '@solidjs/router';
import { MutationResponseHandler, Viewport, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, ColorTag, DataGrid, IconButton, Loader, Select, Tile } from '@subtile/universal-ui';
import Decimal from 'decimal.js-light';
import { Document } from 'fluent-graphql';
import { cloneShape, range } from 'object-array-utils';
import { orThrow } from 'or-throw';
import { formatDate, useI18n, useViewport } from 'solid-compose';
import { Show, batch, createEffect, createSignal } from 'solid-js';
import { moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import AccountTransferFormDialog from './AccountTransferFormDialog';
import ExpenseFormDialog from './ExpenseFormDialog';
import styles from './FundDetails.module.css';
import FundFormDialog from './FundFormDialog';
import FundTitle from './FundTitle';
import RevenueFormDialog from './RevenueFormDialog';
import WalletTransferFormDialog from './WalletTransferFormDialog';
export var FundType;
(function (FundType) {
    FundType["Account"] = "ACCOUNT";
    FundType["Wallet"] = "WALLET";
})(FundType || (FundType = {}));
var TransactionTypeFilter;
(function (TransactionTypeFilter) {
    TransactionTypeFilter["All"] = "ALL";
    TransactionTypeFilter["Revenue"] = "REVENUE";
    TransactionTypeFilter["Expense"] = "EXPENSE";
    TransactionTypeFilter["InternalTransfer"] = "INTERNAL_TRANSFER";
})(TransactionTypeFilter || (TransactionTypeFilter = {}));
var TransactionsType;
(function (TransactionsType) {
    TransactionsType["Revenues"] = "REVENUES";
    TransactionsType["Expenses"] = "EXPENSES";
    TransactionsType["WalletTransfers"] = "WALLET_TRANSFERS";
    TransactionsType["AccountTransfers"] = "ACCOUNT_TRANSFERS";
})(TransactionsType || (TransactionsType = {}));
const FundDetails = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const translate = useI18n();
    const viewport = useViewport();
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [showFundFormDialog, setShowFundFormDialog] = createSignal(false);
    const [showTransactionFormDialog, setShowTransactionFormDialog] = createSignal(null);
    const [fundBeingEdited, setFundBeingEdited] = createSignal(null);
    const [revenueBeingEdited, setRevenueBeingEdited] = createSignal(null);
    const [expenseBeingEdited, setExpenseBeingEdited] = createSignal(null);
    const [accountTransferBeingEdited, setAccountTransferBeingEdited] = createSignal(null);
    const [walletTransferBeingEdited, setWalletTransferBeingEdited] = createSignal(null);
    const [revenueBeingViewed, setRevenueBeingViewed] = createSignal(null);
    const [expenseBeingViewed, setExpenseBeingViewed] = createSignal(null);
    const [accountTransferBeingViewed, setAccountTransferBeingViewed] = createSignal(null);
    const [walletTransferBeingViewed, setWalletTransferBeingViewed] = createSignal(null);
    const fund = () => {
        return orThrow((props.fundType === FundType.Account)
            ? organization.accounts.find(({ id }) => id === params.accountId)
            : organization.wallets.find(({ id }) => id === params.walletId));
    };
    const currentYear = Temporal.Now.plainDateISO().year;
    const [yearFilter, setYearFilter] = createSignal(currentYear);
    const [typeFilter, setTypeFilter] = createSignal(TransactionTypeFilter.All);
    const initialQueryVariables = {
        organizationId: organization.id,
        ...((props.fundType === FundType.Account)
            ? { accountId: fund().id }
            : { walletId: fund().id }),
        filters: {
            fromDate: Temporal.PlainDate.from({ year: currentYear, month: 1, day: 1 }).toString(),
            toDate: Temporal.PlainDate.from({ year: currentYear, month: 12, day: 31 }).toString()
        }
    };
    const [queryVariables, setQueryVariables] = createSignal({ ...initialQueryVariables });
    // const [walletQueryVariables, setWalletQueryVariables] = createSignal<QueryVariables>({ ...initialQueryVariables });
    const [transactions, { refetch: refetchTransactions }] = (props.fundType === FundType.Account)
        ? createAccountTransactionsResource(queryVariables)
        : createWalletTransactionsResource(queryVariables);
    const [_funds, { refetch: refetchFunds }] = (props.fundType === FundType.Account)
        ? useAccountsResource({ organizationId: organization.id })
        : useWalletsResource({ organizationId: organization.id });
    const [totalInflow, setTotalInflow] = createSignal('');
    const [totalOutflow, setTotalOutflow] = createSignal('');
    createEffect(() => {
        if (transactions.error) {
            throw new Error(transactions.error);
        }
        if (!transactions.loading) {
            const totalInflow = calculateTotalInflow(fund(), transactions().paginatedEntries);
            setTotalInflow(totalInflow.toString());
            const totalOutflow = calculateTotalOutflow(fund(), transactions().paginatedEntries);
            setTotalOutflow(totalOutflow.toString());
        }
    });
    function handleYearFilterChange(year) {
        setYearFilter(year);
        setQueryVariables((variables) => ({
            ...variables,
            filters: {
                ...variables.filters,
                fromDate: Temporal.PlainDate.from({ year, month: 1, day: 1 }).toString(),
                toDate: Temporal.PlainDate.from({ year, month: 12, day: 31 }).toString()
            }
        }));
    }
    function handleTypeFilterChange(type) {
        setTypeFilter(type);
        setQueryVariables((variables) => ({
            ...variables,
            filters: {
                ...variables.filters,
                type: (type === TransactionTypeFilter.All) ? undefined : type
            }
        }));
    }
    function handleEditFundClick() {
        const fund_ = orThrow(fund());
        batch(() => {
            setFundBeingEdited(cloneShape(fund_));
            setShowFundFormDialog(true);
        });
    }
    async function handleArchiveFundClick() {
        const fund_ = orThrow(fund());
        setEntityLoading(fund_, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(fund_, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = (props.fundType === FundType.Account)
                ? await processResponse(archiveAccountMutation.execute({
                    organizationId: organization.id,
                    accountId: fund_.id
                }), { onError })
                : await processResponse(archiveWalletMutation.execute({
                    organizationId: organization.id,
                    walletId: fund_.id
                }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            let data;
            if ('archiveAccount' in response) {
                data = response.archiveAccount;
            }
            else if ('archiveWallet' in response) {
                data = response.archiveWallet;
            }
            else {
                throw new Error();
            }
            new MutationResponseHandler(data)
                .onSuccessType((props.fundType === FundType.Account) ? 'Account' : 'Wallet', () => {
                switch (props.fundType) {
                    case FundType.Account:
                        navigate(getRoute(Route.Accounts, { organizationId: organization.id }));
                        break;
                    case FundType.Wallet:
                        navigate(getRoute(Route.Wallets, { organizationId: organization.id }));
                        break;
                }
            })
                .onExpectedErrorType('CannotArchiveTargetWalletForAllocation', ({ name }) => {
                alert(translate('cannot_archive_target_wallet_for_allocation', { name }));
            })
                .onExpectedErrorType('NonZeroArchivedAccountBalance', () => {
                alert(translate('cannot_archive_account_with_positive_balance'));
            })
                .onExpectedErrorType('NonZeroArchivedWalletBalance', () => {
                alert(translate('cannot_archive_wallet_with_positive_balance'));
            })
                .onExpectedErrorType('AtLeastOneActiveAccountRequired', () => {
                alert(translate('at_least_one_active_account_required'));
            })
                .onExpectedErrorType('AtLeastOneActiveWalletRequired', () => {
                alert(translate('at_least_one_active_wallet_required'));
            })
                .handle();
            setEntityLoading(fund_, false);
        });
    }
    function handleTransactionClick(transaction) {
        batch(() => {
            clearFormDialogState();
            if (transaction.revenue) {
                setRevenueBeingViewed(transaction.revenue);
                setShowTransactionFormDialog(TransactionsType.Revenues);
            }
            else if (transaction.expense) {
                setExpenseBeingViewed(transaction.expense);
                setShowTransactionFormDialog(TransactionsType.Expenses);
            }
            else if ('accountTransfer' in transaction && transaction.accountTransfer) {
                setAccountTransferBeingViewed(transaction.accountTransfer);
                setShowTransactionFormDialog(TransactionsType.AccountTransfers);
            }
            else if ('walletTransfer' in transaction && transaction.walletTransfer) {
                setWalletTransferBeingViewed(transaction.walletTransfer);
                setShowTransactionFormDialog(TransactionsType.WalletTransfers);
            }
        });
    }
    function handleEditTransactionClick(transaction) {
        if (transaction.revenue) {
            handleEditRevenueClick(transaction.revenue);
        }
        else if (transaction.expense) {
            handleEditExpenseClick(transaction.expense);
        }
        else if ('accountTransfer' in transaction && transaction.accountTransfer) {
            handleEditAccountTransferClick(transaction.accountTransfer);
        }
        else if ('walletTransfer' in transaction && transaction.walletTransfer) {
            handleEditWalletTransferClick(transaction.walletTransfer);
        }
    }
    function handleEditRevenueClick(revenue) {
        batch(() => {
            clearFormDialogState();
            setRevenueBeingEdited(cloneShape(revenue));
            setShowTransactionFormDialog(TransactionsType.Revenues);
        });
    }
    function handleEditExpenseClick(expense) {
        batch(() => {
            clearFormDialogState();
            setExpenseBeingEdited(cloneShape(expense));
            setShowTransactionFormDialog(TransactionsType.Expenses);
        });
    }
    function handleEditAccountTransferClick(accountTransfer) {
        batch(() => {
            clearFormDialogState();
            setAccountTransferBeingEdited(cloneShape(accountTransfer));
            setShowTransactionFormDialog(TransactionsType.AccountTransfers);
        });
    }
    function handleEditWalletTransferClick(walletTransfer) {
        batch(() => {
            clearFormDialogState();
            setWalletTransferBeingEdited(cloneShape(walletTransfer));
            setShowTransactionFormDialog(TransactionsType.WalletTransfers);
        });
    }
    async function handleDeleteTransactionClick(transaction) {
        setEntityLoading(transaction, true);
        batch(async () => {
            let result;
            const onError = (error) => {
                setEntityLoading(transaction, false);
                alert(translate('operation_failed'));
                throw error;
            };
            if (transaction.revenue) {
                result =
                    await processResponse(deleteRevenueMutation.execute({
                        organizationId: organization.id,
                        revenueId: transaction.revenue.id
                    }), { onError });
            }
            else if (transaction.expense) {
                result =
                    await processResponse(deleteExpenseMutation.execute({
                        organizationId: organization.id,
                        expenseId: transaction.expense.id
                    }), { onError });
            }
            else if ('accountTransfer' in transaction && transaction.accountTransfer) {
                result =
                    await processResponse(deleteAccountTransferMutation.execute({
                        organizationId: organization.id,
                        accountTransferId: transaction.accountTransfer.id
                    }), { onError });
            }
            else if ('walletTransfer' in transaction && transaction.walletTransfer) {
                result =
                    await processResponse(deleteWalletTransferMutation.execute({
                        organizationId: organization.id,
                        walletTransferId: transaction.walletTransfer.id
                    }), { onError });
            }
            else {
                throw new Error();
            }
            const response = result;
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            let data;
            let successType;
            if ('deleteRevenue' in response) {
                successType = 'Revenue';
                data = response.deleteRevenue;
            }
            else if ('deleteExpense' in response) {
                successType = 'Expense';
                data = response.deleteExpense;
            }
            else if ('deleteAccountTransfer' in response) {
                successType = 'AccountTransfer';
                data = response.deleteAccountTransfer;
            }
            else if ('deleteWalletTransfer' in response) {
                successType = 'WalletTransfer';
                data = response.deleteWalletTransfer;
            }
            else {
                throw new Error();
            }
            new MutationResponseHandler(data)
                .onSuccessType(successType, () => {
                refetchFunds();
                refetchTransactions();
            })
                .onExpectedErrorType('DirectRevenueAllocationDeletionForbidden', () => {
                setWalletTransferBeingEdited(transaction.walletTransfer);
                setShowTransactionFormDialog(TransactionsType.WalletTransfers);
            })
                .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .onExpectedErrorType('NegativeAccountBalance', ({ name, balanceAmount, currencyName }) => {
                alert(translate('insufficient_revert_funds', { name, balanceAmount, currencyName }));
            })
                .handle();
            setEntityLoading(transaction, false);
        });
    }
    function clearFormDialogState() {
        batch(() => {
            setShowFundFormDialog(false);
            setShowTransactionFormDialog(null);
            setRevenueBeingEdited(null);
            setRevenueBeingViewed(null);
            setExpenseBeingViewed(null);
            setExpenseBeingEdited(null);
            setAccountTransferBeingViewed(null);
            setAccountTransferBeingEdited(null);
            setWalletTransferBeingViewed(null);
            setWalletTransferBeingEdited(null);
        });
    }
    function handleCloseFormDialog() {
        batch(() => {
            clearFormDialogState();
        });
    }
    function renderFundName(fund, deleted) {
        const style = (deleted) ? { 'text-decoration': 'line-through' } : {};
        return (fund.colorTag)
            ? (<ColorTag size="14px" colorName={fund.colorTag} style={style}>
          {fund.name}
        </ColorTag>)
            : fund.name;
    }
    function handleRefreshClick() {
        batch(() => {
            Document.clearQueries([
                'Revenues',
                'Expenses',
                'AccountTransfers',
                'WalletTransfers',
                'WalletTransactions',
                'AccountTransactions',
                'Wallets',
                'Accounts'
            ]);
            refetchFunds();
            refetchTransactions();
        });
    }
    return (<Show when={fund()}>
      {fund => <div class={styles.fundDetailsPage}>
          <div class={styles.titleAndTopLevelButtons}>
            <FundTitle colorTag={fund().colorTag}>
              {fund().name}
            </FundTitle>

            <div class={styles.topLevelButtons}>
              <Show when={!fund().loading} fallback={<Loader />}>
                <Button type={ButtonType.Secondary} onClick={handleEditFundClick}>
                  {translate('edit')}
                </Button>

                <Button type={ButtonType.Secondary} onClick={handleArchiveFundClick}>
                  {translate('archive')}
                </Button>

                <div style={{ '--icon-size': '20px' }}>
                  <IconButton onClick={handleRefreshClick}>
                    <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path d="M19.854 8.646c-0.195-0.195-0.512-0.195-0.707 0l-1.149 1.149c-0.051-2.060-0.878-3.99-2.341-5.452-1.511-1.511-3.52-2.343-5.657-2.343s-4.146 0.832-5.657 2.343-2.343 3.52-2.343 5.657 0.832 4.146 2.343 5.657 3.52 2.343 5.657 2.343c2.974 0 5.686-1.635 7.077-4.266 0.129-0.244 0.036-0.547-0.208-0.676s-0.547-0.036-0.676 0.208c-1.217 2.303-3.59 3.734-6.193 3.734-3.86 0-7-3.14-7-7s3.14-7 7-7c3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707z" fill="#000000"></path>
                    </svg>
                  </IconButton>
                </div>
              </Show>
            </div>
          </div>

          <Tile.Container isDirectionColumn={viewport.width === Viewport.SmallWidth}>
            <Tile start={translate('balance')} end={formatMoney(fund().balanceAmount, fund().currency)}/>

            <Tile start={translate('inflow')} end={<>
                  {totalInflow() === '' || transactions.loading ? '…' : formatMoney(totalInflow(), fund().currency)}
                  <span style={{ display: 'flex', 'font-weight': 200 }}>
                    (<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                      <path d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z" fill="#000000"></path>
                    </svg>)
                  </span>
                </>}/>

            <Tile start={translate('outflow')} end={<>
                  {totalOutflow() === '' || transactions.loading ? '…' : formatMoney(totalOutflow(), fund().currency)}
                  <span style={{ display: 'flex', 'font-weight': 200 }}>
                    (<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                      <path d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z" fill="#000000"></path>
                    </svg>)
                  </span>
                </>}/>
          </Tile.Container>

          <div class={styles.filters}>
            <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z" fill="#000000"></path>
            </svg>

            <Select onChange={handleYearFilterChange} options={range({ count: 5 }).map((i) => currentYear - i)} selectedOption={yearFilter()}/>

            <Select onChange={handleTypeFilterChange} optionText={type => {
                switch (type) {
                    case TransactionTypeFilter.All: return translate('all_types');
                    case TransactionTypeFilter.Revenue: return translate('revenues');
                    case TransactionTypeFilter.Expense: return translate('expenses');
                    case TransactionTypeFilter.InternalTransfer: return translate('internal_transfers');
                }
            }} options={Object.values(TransactionTypeFilter)} selectedOption={typeFilter()}/>
          </div>

          <DataGrid actions={[
                {
                    id: 'edit',
                    content: translate('edit'),
                    onClick: handleEditTransactionClick
                },
                {
                    id: 'delete',
                    content: translate('delete'),
                    onClick: handleDeleteTransactionClick
                }
            ]} loading={transactions.loading} data={transactions()?.paginatedEntries} onRowClick={handleTransactionClick} transformers={{
                spacer: () => '',
                date: formatDate,
                description: ([revenue, expense, accountTransfer, walletTransfer]) => {
                    if (revenue)
                        return revenue.description;
                    if (expense)
                        return expense.description;
                    if (accountTransfer)
                        return '';
                    if (walletTransfer) {
                        const content = [];
                        if (walletTransfer.revenueIdForAllocation) {
                            content.push(<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <path d="M8.5 20c-2.27 0-4.405-0.884-6.010-2.49s-2.49-3.74-2.49-6.010c0-2.27 0.884-4.405 2.49-6.010s3.74-2.49 6.010-2.49c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5c-4.136 0-7.5 3.364-7.5 7.5s3.364 7.5 7.5 7.5c4.136 0 7.5-3.364 7.5-7.5 0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5c0 2.27-0.884 4.405-2.49 6.010s-3.74 2.49-6.010 2.49z" fill="#000000"></path>
                      <path d="M18.5 10h-8c-0.276 0-0.5-0.224-0.5-0.5v-8c0-0.276 0.224-0.5 0.5-0.5 2.27 0 4.405 0.884 6.010 2.49s2.49 3.74 2.49 6.010c0 0.276-0.224 0.5-0.5 0.5zM11 9h6.984c-0.247-3.738-3.246-6.736-6.984-6.984v6.984z" fill="#000000"></path>
                    </svg>);
                        }
                        if (walletTransfer.description) {
                            content.push(<span>{walletTransfer.description}</span>);
                        }
                        return <div style={{ display: 'flex', 'align-items': 'center', gap: '6px' }}>{content}</div>;
                    }
                    throw new Error();
                },
                type: ([revenue, expense, accountTransfer, walletTransfer]) => {
                    if (revenue)
                        return translate('revenue');
                    if (expense)
                        return translate('expense');
                    if (accountTransfer || walletTransfer)
                        return translate('internal_transfer');
                    throw new Error();
                },
                detail: ([revenue, expense, accountTransfer, walletTransfer], transaction) => {
                    if (revenue)
                        return revenue.revenueType.name;
                    if (expense)
                        return expense.expenseType.name;
                    if (accountTransfer) {
                        const isSourceAccount = fund().id === accountTransfer.sourceAccount.id;
                        const isTargetAccount = fund().id === accountTransfer.targetAccount.id;
                        if (isSourceAccount) {
                            return <div class={styles.toFromAccountOrWallet}>
                      <div>{translate('internal_transfer_to')}</div>
                      <div>{renderFundName(accountTransfer.targetAccount, transaction.deleted)}</div>
                    </div>;
                        }
                        if (isTargetAccount) {
                            return <div class={styles.toFromAccountOrWallet}>
                      <div>{translate('internal_transfer_from')}</div>
                      <div>{renderFundName(accountTransfer.sourceAccount, transaction.deleted)}</div>
                    </div>;
                        }
                        return '';
                    }
                    if (walletTransfer) {
                        const isSourceWallet = fund().id === walletTransfer.sourceWallet.id;
                        const isTargetWallet = fund().id === walletTransfer.targetWallet.id;
                        if (isSourceWallet) {
                            return <div class={styles.toFromAccountOrWallet}>
                      <div>{translate('internal_transfer_to')}</div>
                      <div>{renderFundName(walletTransfer.targetWallet, transaction.deleted)}</div>
                    </div>;
                        }
                        if (isTargetWallet) {
                            return <div class={styles.toFromAccountOrWallet}>
                      <div>{translate('internal_transfer_from')}</div>
                      <div>{renderFundName(walletTransfer.sourceWallet, transaction.deleted)}</div>
                    </div>;
                        }
                        return '';
                    }
                    throw new Error();
                },
                amount: ([revenue, expense, accountTransfer, walletTransfer]) => {
                    if (revenue)
                        return '+' + formatMoney(revenue.amountAfterAllocations, revenue.currency);
                    if (expense)
                        return '-' + formatMoney(expense.amount, expense.currency);
                    if (accountTransfer) {
                        const isSourceAccount = fund().id === accountTransfer.sourceAccount.id;
                        const isTargetAccount = fund().id === accountTransfer.targetAccount.id;
                        if (isSourceAccount && isTargetAccount) {
                            return '-' + formatMoney(accountTransfer.sourceAmount, accountTransfer.sourceCurrency)
                                + ' | ' + '+' + formatMoney(accountTransfer.targetAmount, accountTransfer.targetCurrency);
                        }
                        if (isSourceAccount) {
                            return '-' + formatMoney(accountTransfer.sourceAmount, accountTransfer.sourceCurrency);
                        }
                        if (isTargetAccount) {
                            return '-' + formatMoney(accountTransfer.targetAmount, accountTransfer.targetCurrency);
                        }
                    }
                    if (walletTransfer) {
                        const isSourceWallet = fund().id === walletTransfer.sourceWallet.id;
                        const isTargetWallet = fund().id === walletTransfer.targetWallet.id;
                        if (isSourceWallet && isTargetWallet) {
                            return '-' + formatMoney(walletTransfer.sourceAmount, walletTransfer.sourceCurrency)
                                + ' | ' + '+' + formatMoney(walletTransfer.targetAmount, walletTransfer.targetCurrency);
                        }
                        if (isSourceWallet) {
                            return '-' + formatMoney(walletTransfer.sourceAmount, walletTransfer.sourceCurrency);
                        }
                        if (isTargetWallet) {
                            return '-' + formatMoney(walletTransfer.targetAmount, walletTransfer.targetCurrency);
                        }
                    }
                    throw new Error();
                }
            }} isRowDataDeleted={({ deleted }) => deleted} isRowDataLoading={({ loading }) => loading} responsiveLayouts={{
                [Viewport.SmallWidth]: {
                    strategy: 'groupedColumns',
                    columnDefinitions: [
                        {
                            fields: ['date', ['revenue', 'expense', 'accountTransfer', 'walletTransfer']],
                            headers: [translate('date'), translate('transaction_type')],
                            keys: ['date', 'type'],
                            width: 'minmax(max-content, 220px)'
                        },
                        {
                            fields: [['revenue', 'expense', 'accountTransfer', 'walletTransfer']],
                            headers: [translate('amount')],
                            keys: ['amount'],
                            width: 'minmax(max-content, 220px)',
                            allowOverflow: true,
                            align: 'end'
                        },
                        {
                            headers: [''],
                            keys: ['spacer'],
                            width: '1fr'
                        }
                    ]
                },
                [Viewport.MediumWidth]: {
                    strategy: 'hideColumns',
                    columnDefinitions: [
                        {
                            field: 'date',
                            header: translate('date'),
                            width: '125px'
                        },
                        {
                            fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                            key: 'type',
                            header: translate('transaction_type'),
                            width: '180px'
                        },
                        {
                            fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                            key: 'detail',
                            header: translate('transaction_detail'),
                            width: '170px'
                        },
                        {
                            fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                            key: 'amount',
                            header: translate('amount'),
                            width: '170px',
                            allowOverflow: true,
                            align: 'end'
                        },
                        {
                            fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                            key: 'description',
                            header: translate('description'),
                            width: '1fr'
                        }
                    ]
                }
            }} columnDefinitions={[
                {
                    field: 'date',
                    header: translate('date'),
                    width: '125px'
                },
                {
                    fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                    key: 'type',
                    header: translate('transaction_type'),
                    width: '190px'
                },
                {
                    fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                    key: 'detail',
                    header: translate('transaction_detail'),
                    width: '190px'
                },
                {
                    fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                    key: 'amount',
                    header: translate('amount'),
                    width: '170px',
                    allowOverflow: true,
                    align: 'end'
                },
                {
                    fields: ['revenue', 'expense', 'accountTransfer', 'walletTransfer'],
                    key: 'description',
                    header: translate('description'),
                    width: '1fr'
                }
            ]}/>

          <FundFormDialog fundType={props.fundType} isOpen={showFundFormDialog()} onClose={handleCloseFormDialog} onEditFundClick={handleEditFundClick} fund={fundBeingEdited()} isViewMode={false}/>

          <RevenueFormDialog isOpen={showTransactionFormDialog() === TransactionsType.Revenues} onClose={handleCloseFormDialog} onRevenueAddedOrEdited={() => {
                batch(() => {
                    refetchFunds();
                    refetchTransactions();
                });
            }} onEditRevenueClick={handleEditRevenueClick} revenue={revenueBeingViewed() || revenueBeingEdited()} isViewMode={!!revenueBeingViewed()}/>

          <ExpenseFormDialog isOpen={showTransactionFormDialog() === TransactionsType.Expenses} onClose={handleCloseFormDialog} onExpenseAddedOrEdited={() => {
                batch(() => {
                    refetchFunds();
                    refetchTransactions();
                });
            }} onEditExpenseClick={handleEditExpenseClick} expense={expenseBeingViewed() || expenseBeingEdited()} isViewMode={!!expenseBeingViewed()}/>

          <AccountTransferFormDialog isOpen={showTransactionFormDialog() === TransactionsType.AccountTransfers} onClose={handleCloseFormDialog} onAccountTransferAddedOrEdited={() => {
                batch(() => {
                    refetchFunds();
                    refetchTransactions();
                });
            }} onEditAccountTransferClick={handleEditAccountTransferClick} accountTransfer={accountTransferBeingViewed() || accountTransferBeingEdited()} isViewMode={!!accountTransferBeingViewed()}/>

          <WalletTransferFormDialog isOpen={showTransactionFormDialog() === TransactionsType.WalletTransfers} onClose={handleCloseFormDialog} onWalletTransferAddedOrEdited={() => {
                batch(() => {
                    refetchFunds();
                    refetchTransactions();
                });
            }} onRevenueEdited={() => {
                batch(() => {
                    refetchFunds();
                    refetchTransactions();
                });
            }} onEditWalletTransferClick={handleEditWalletTransferClick} walletTransfer={walletTransferBeingViewed() || walletTransferBeingEdited()} isViewMode={!!walletTransferBeingViewed()}/>
        </div>}
    </Show>);
};
function calculateTotalInflow(fund, transactions) {
    return transactions.reduce((total, transaction) => {
        if (transaction.deleted) {
            return total;
        }
        if (transaction.revenue) {
            return total.plus(transaction.revenue.amountBeforeAllocations);
        }
        else if ('accountTransfer' in transaction && transaction.accountTransfer) {
            if (transaction.accountTransfer.targetAccount.id === fund.id) {
                return total.plus(transaction.accountTransfer.targetAmount);
            }
            else if (transaction.accountTransfer.sourceAccount.id !== fund.id) {
                throw new Error();
            }
        }
        else if ('walletTransfer' in transaction && transaction.walletTransfer) {
            if (transaction.walletTransfer.targetWallet.id === fund.id) {
                return total.plus(transaction.walletTransfer.targetAmount);
            }
            else if (transaction.walletTransfer.sourceWallet.id !== fund.id) {
                throw new Error();
            }
        }
        else if (!transaction.expense) {
            throw new Error();
        }
        return total;
    }, new Decimal(0));
}
function calculateTotalOutflow(fund, transactions) {
    return transactions.reduce((total, transaction) => {
        if (transaction.deleted) {
            return total;
        }
        if (transaction.expense) {
            return total.plus(transaction.expense.amount);
        }
        else if ('accountTransfer' in transaction && transaction.accountTransfer) {
            if (transaction.accountTransfer.sourceAccount.id === fund.id) {
                return total.plus(transaction.accountTransfer.sourceAmount);
            }
            else if (transaction.accountTransfer.targetAccount.id !== fund.id) {
                throw new Error();
            }
        }
        else if ('walletTransfer' in transaction && transaction.walletTransfer) {
            if (transaction.walletTransfer.sourceWallet.id === fund.id) {
                return total.plus(transaction.walletTransfer.sourceAmount);
            }
            else if (transaction.walletTransfer.targetWallet.id !== fund.id) {
                throw new Error();
            }
        }
        else if (!transaction.revenue) {
            throw new Error();
        }
        return total;
    }, new Decimal(0));
}
export default FundDetails;
