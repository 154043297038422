import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import type { Errors, TypedObject, WalletTransfer } from '../types';

export interface UpdateWalletTransferReturn {
  updateWalletTransfer: WalletTransfer | { reference: string } & TypedObject | Errors;
}

interface UpdateWalletTransferVars {
  organizationId: string;
  walletTransferId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateWalletTransferReturn, UpdateWalletTransferVars>('UpdateWalletTransfer')
    .variableDefinitions({ organizationId: 'ID!', walletTransferId: 'ID!', input: 'UpdateWalletTransferInput!' })
    .union('updateWalletTransfer')
      .useVariables({ organizationId: 'organizationId',  walletTransferId: 'walletTransferId', input: 'input' })
      .onEntity('WalletTransfer')
        .scalar('organizationId')
        .scalar('date', Temporal.PlainDate.from)
        .reference('sourceCurrencyId', 'Currency')
        .reference('targetCurrencyId', 'Currency')
        .reference('sourceWalletId', 'Wallet')
        .reference('targetWalletId', 'Wallet')
        .scalar('sourceAmount')
        .scalar('targetAmount')
        .scalar('description')._
      .onTypedObject('DirectCrossCurrencyWalletTransferEditForbidden')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('RevenueAllocationsExceedRevenue')
        .scalar('revenueAmount')
        .scalar('currencyName')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ updateWalletTransfer: data }) => {
    if (data.__typename === 'WalletTransfer') {
      Document.clearQueries([
        'WalletTransfers',
        'WalletTransactions',
        'Revenues',
        'Wallets'
      ]);
    }
  });
