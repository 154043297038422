import { Route, getRoute } from '@auth/routes';
import { useNavigate } from '@solidjs/router';
import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { orThrow } from 'or-throw';
import { AuthenticationStatus, useLocalStorage } from 'solid-compose';
import { createEffect } from 'solid-js';
const ProfileCompletionCheck = () => {
    const [currentUser, { authenticationStatus }] = useCurrentUser();
    const navigate = useNavigate();
    const [_mode, { set: storeMode }] = useLocalStorage('authenticationMode', 'signup');
    const [invitationToken] = useLocalStorage('invitationToken');
    createEffect(() => {
        orThrow(authenticationStatus() === AuthenticationStatus.Authenticated);
        orThrow(currentUser());
        storeMode('login');
        const currentUser_ = currentUser();
        if (!currentUser_.confirmedEmailAt) {
            navigate(`${getRoute(Route.EmailConfirmation)}?state=instructions`);
            return;
        }
        if (!currentUser_.colorScheme) {
            navigate(getRoute(Route.SelectTheme));
            return;
        }
        if (!currentUser_.preferredName) {
            navigate(getRoute(Route.CustomizeSettings));
            return;
        }
        if (invitationToken()) {
            navigate(getRoute(Route.AcceptInvitation));
            return;
        }
        navigate('/');
    });
    return (<spacers.viewportFill>
      <Loader />
    </spacers.viewportFill>);
};
export default ProfileCompletionCheck;
