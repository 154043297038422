import styles from './Checkbox.module.css';
import Group from './Group';
const Checkbox = (props) => {
    return (<div class={styles.checkbox} classList={{ [styles.checked]: props.checked }} onClick={() => props.onChange(!props.checked)}>
      <div class={styles.icon}/>

      <span class={styles.text}>{props.children}</span>
    </div>);
};
const Checkbox_ = Object.assign(Checkbox, { Group });
export default Checkbox_;
