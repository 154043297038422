import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { Button, ButtonType } from '@subtile/universal-ui';
import classNames from 'classnames';
import { useI18n, useLocalStorage } from 'solid-compose';
import styles from './Welcome.module.css';
const Welcome = () => {
    const translate = useI18n();
    const navigate = useNavigate();
    const [_, { set: setShowWelcomePage }] = useLocalStorage('showWelcomePage');
    function handleClick() {
        setShowWelcomePage(false);
        navigate(getRoute(Route.ConfigurationCheck));
    }
    return (<div class={styles.welcome}>
      <svg class={classNames(styles.symbol, "icon")} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M17.5 9h-0.5v-2.5c0-0.827-0.673-1.5-1.5-1.5h-2.5v-4.5c0-0.276-0.224-0.5-0.5-0.5h-10c-0.276 0-0.5 0.224-0.5 0.5v4.55c-1.14 0.232-2 1.242-2 2.45v11c0 0.827 0.673 1.5 1.5 1.5h16c0.827 0 1.5-0.673 1.5-1.5v-8c0-0.827-0.673-1.5-1.5-1.5zM3 1h9v6h-9v-6zM2 6.086v1.414c0 0.276 0.224 0.5 0.5 0.5h12c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h2.5c0.276 0 0.5 0.224 0.5 0.5v2.5h-13.5c-0.827 0-1.5-0.673-1.5-1.5 0-0.652 0.418-1.208 1-1.414zM18 18.5c0 0.276-0.224 0.5-0.5 0.5h-16c-0.276 0-0.5-0.224-0.5-0.5v-9.001c0.418 0.315 0.938 0.501 1.5 0.501h15c0.276 0 0.5 0.224 0.5 0.5v8z" fill="#000000"></path>
        <path d="M10.5 3h-0.5v-0.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276 0-0.5 0.224-0.5 0.5v1.5h-1v-1.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v0.5c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-1.5h1v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.5h0.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill="#000000"></path>
        <path d="M16.5 18h-4c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h4c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill="#000000"></path>
      </svg>

      <div class={styles.titleContainer}>
        <div class={styles.title}>{translate('welcome_title')}</div>
        <div class={styles.subtitle}>{translate('welcome_subtitle')}</div>
      </div>

      <Button type={ButtonType.Primary} onClick={handleClick}>
        {translate('get_started')}
      </Button>
    </div>);
};
export default Welcome;
