import { setEntityLoading } from '@finance/graphql/localState';
import cancelInvitationMutation from '@finance/graphql/mutations/cancelInvitationMutation';
import removeMemberMutation from '@finance/graphql/mutations/removeMemberMutation';
import updateOrganizationMutation from '@finance/graphql/mutations/updateOrganizationMutation';
import invitationsQuery from '@finance/graphql/queries/invitationsQuery';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Viewport, processResponse, useQueryResource } from '@subtile/project-shared';
import { Button, ButtonType, DataGrid, Form, Panel, TextField, ToggleButton, getScrollableAncestor, spacers } from '@subtile/universal-ui';
import { useI18n, useViewport } from 'solid-compose';
import { Show, batch, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import InviteMemberFormDialog from './InviteMemberFormDialog';
import styles from './Settings.module.css';
const Team = () => {
    const translate = useI18n();
    const [organization] = useSelectedOrganizationOrThrow();
    const viewport = useViewport();
    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = createSignal('members');
    const [showInviteMemberFormDialog, setShowInviteMemberFormDialog] = createSignal(false);
    const [organizationFields, setOrganizationFields] = createStore({
        name: organization.name
    });
    let membersSectionRef;
    let organizationSectionRef;
    function clearFormDialogState() {
        setShowInviteMemberFormDialog(false);
    }
    function handleCloseFormDialog() {
        clearFormDialogState();
    }
    function handleInviteMemberClick() {
        batch(() => {
            clearFormDialogState();
            setShowInviteMemberFormDialog(true);
        });
    }
    function handleRemoveMemberClick(member) {
        setEntityLoading(member, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(member, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(removeMemberMutation.execute({
                organizationId: organization.id,
                memberId: member.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.removeMember, () => { }, () => setEntityLoading(member, false))
                .onSuccessType('Member', () => { })
                .onExpectedErrorType('AtLeastOneMemberRequired', () => {
                setEntityLoading(member, false);
                alert(translate('at_least_one_member_required'));
            })
                .handle();
        });
    }
    function handleCancelInvitationClick(invitation) {
        setEntityLoading(invitation, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(invitation, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(cancelInvitationMutation.execute({
                organizationId: organization.id,
                invitationId: invitation.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.cancelInvitation, () => { }, () => setEntityLoading(invitation, false))
                .onSuccessType('Invitation', () => { })
                .handle();
        });
    }
    const handleUpdateOrganizationSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        const variables = {
            input: {
                name: organizationFields.name
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const result = await processResponse(updateOrganizationMutation.execute({ ...variables }), { onError });
        const response = result;
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { updateOrganization: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Organization', () => { })
            .handle();
    };
    function handleAddOrganizationClick() {
        navigate(getRoute(Route.OnboardingAddOrganization, { organizationId: organization.id }));
    }
    function handleMenuItemClick(value) {
        setSelectedMenuItem(value);
        let ref;
        switch (selectedMenuItem()) {
            case 'members':
                ref = membersSectionRef;
                break;
            case 'organization':
                ref = organizationSectionRef;
                break;
        }
        const scrollableAncestor = getScrollableAncestor(ref) ?? document.documentElement;
        scrollableAncestor.scrollTo({
            top: ref.getBoundingClientRect().top - scrollableAncestor.getBoundingClientRect().top + scrollableAncestor.scrollTop - 5,
            behavior: 'smooth'
        });
    }
    const [invitations] = useQueryResource(invitationsQuery, { organizationId: organization.id });
    return (<div class={styles.settingsPage} style={{
            '--button-font-size': '14px',
            '--button-padding': '5px 8px',
            '--panel-width': '400px'
        }}>
      <div class={styles.menuContainer}>
        <Show when={viewport.width === Viewport.SmallWidth} fallback={<div class={styles.menu}>
            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'members' }} onClick={() => handleMenuItemClick('members')}>
              {translate('members')}
            </div>

            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'organization' }} onClick={() => handleMenuItemClick('organization')}>
              {translate('organization')}
            </div>
          </div>}>
          <ToggleButton.Group isDropdown onChange={handleMenuItemClick} selectedValue={selectedMenuItem()}>
            <ToggleButton value="members">{translate('members')}</ToggleButton>
            <ToggleButton value="organization">{translate('organization')}</ToggleButton>
          </ToggleButton.Group>
        </Show>
      </div>

      <div style={{ 'flex-grow': 1 }}>{/* necessary wrapper for flex */}
        <div class={styles.content}>
          <div class={styles.section} ref={membersSectionRef}>
            <Panel>
              <div class={styles.title}>
                {translate('members')}
                <Button onClick={handleInviteMemberClick} type={ButtonType.Secondary}>
                  {translate('invite')}
                </Button>
              </div>

              <div style={{ display: 'flex', 'flex-direction': 'column', gap: '20px' }}>
                <DataGrid actions={[
            {
                id: 'delete',
                content: translate('remove'),
                onClick: handleRemoveMemberClick
            }
        ]} transformers={{
            email: ({ email }) => email
        }} isRowDataLoading={({ loading }) => loading} data={organization.members} maxHeight="300px" columnDefinitions={[
            {
                key: 'email',
                field: 'user',
                header: translate('active_members'),
                width: '1fr'
            }
        ]}/>

                <DataGrid actions={[
            {
                id: 'delete',
                content: translate('cancel'),
                onClick: handleCancelInvitationClick
            }
        ]} loading={invitations.loading} isRowDataLoading={({ loading }) => loading} data={invitations()} maxHeight="300px" columnDefinitions={[
            {
                field: 'email',
                header: translate('invited_members'),
                width: '1fr'
            }
        ]}/>
              </div>
            </Panel>
          </div>

          <div class={styles.section} ref={organizationSectionRef}>
            <Panel>
              <div class={styles.title}>
                {translate('organization')}
                <Button onClick={handleAddOrganizationClick} type={ButtonType.Secondary}>
                  {translate('add')}
                </Button>
              </div>

              <Form onSubmit={handleUpdateOrganizationSubmit}>
                <spacers.formFields>
                  <TextField label={translate('name')} onChange={value => setOrganizationFields('name', value)} required value={organizationFields.name}/>

                  <Button type={ButtonType.Submit}>
                    {translate('update')}
                  </Button>
                </spacers.formFields>
              </Form>
            </Panel>
          </div>
        </div>
      </div>

      <InviteMemberFormDialog isOpen={showInviteMemberFormDialog()} onClose={handleCloseFormDialog}/>
    </div>);
};
export default Team;
