import resetPasswordMutation from '@auth/graphql/mutations/resetPasswordMutation';
import { Route, getRoute } from '@auth/routes';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { MutationResponseHandler, Onboarding } from '@subtile/project-shared';
import { Button, ButtonType, FormError, Panel, TextField, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { Match, Switch, batch, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
;
const ResetPassword = () => {
    const [fields, setFields] = createStore({
        password: ''
    });
    const [resetSuccess, setResetSuccess] = createSignal(false);
    const [tokenInvalidOrExpired, setTokenInvalidOrExpired] = createSignal(false);
    const [searchParams] = useSearchParams();
    const token = searchParams['token'];
    if (!token) {
        throw new Error('request password url is missing token');
    }
    const translate = useI18n();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        const input = {
            password: fields.password
        };
        const { resetPassword: data } = await resetPasswordMutation.execute({ token, input }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('ResetPasswordSuccess', () => {
            setResetSuccess(true);
        })
            .onExpectedErrorType('TokenInvalidOrExpired', () => {
            batch(() => {
                setTokenInvalidOrExpired(true);
                onExpectedError(translate('reset_password_token_invalid_or_expired'));
            });
        })
            .handle();
    };
    return (<Switch fallback={<Onboarding.FormPage title={translate('reset_password_title')} description={translate('reset_password_description')} buttonText={translate('reset_password')} onSubmit={handleSubmit} errorWidth="firstElementChild">
        <Panel>
          <spacers.formFields>
            <TextField label={translate('password')} onChange={value => setFields('password', value)} required type="new_password" value={fields.password}/>

            <TextField label={translate('password_confirmation')} match={fields.password} required type="password"/>
          </spacers.formFields>
        </Panel>
      </Onboarding.FormPage>}>
      <Match when={tokenInvalidOrExpired()}>
        <Onboarding.Page title={translate('reset_password_title')}>
          <FormError>{translate('reset_password_token_invalid_or_expired')}</FormError>

          <Button onClick={() => navigate(getRoute(Route.ResetPasswordRequest))} type={ButtonType.Primary}>
            {translate('retry_reset_password')}
          </Button>
        </Onboarding.Page>
      </Match>

      <Match when={resetSuccess()}>
        <Onboarding.Page title={translate('reset_password_success_title')} description={translate('reset_password_success_description')}>
          <Button onClick={() => navigate(getRoute(Route.LogInWithEmail))} type={ButtonType.Primary}>
            {translate('back_to_login_page')}
          </Button>
        </Onboarding.Page>
      </Match>
    </Switch>);
};
export default ResetPassword;
