import clientFetcher from '@auth/clientFetcher';
import { Document } from 'fluent-graphql';

export interface LogOutUserReturn {
  logOutUser: true;
}

export default Document
  .mutation<LogOutUserReturn, {}>('LogOutUser')
  .scalar('logOutUser')._
  .makeExecutable(clientFetcher);
