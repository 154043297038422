import { createEffect, createSignal, mergeProps, Show } from 'solid-js';
import { Portal } from 'solid-js/web';
import { pressEscape } from '../../helpers/directives';
import focusOnFirstInput from '../../helpers/focusOnFirstInput';
import Form, { FormErrorContainer } from '../form/Form';
import FormResetter from '../form/FormResetter';
import addBorders from './addBorders';
import styles from './Dialog.module.css';
false && addBorders;
false && pressEscape;
function focusDialogOnShow(element, accessor) {
    createEffect(() => {
        const isOpen = accessor();
        if (isOpen) {
            element.tabIndex = -1;
            element.focus();
            focusOnFirstInput(element);
        }
    });
}
false && focusDialogOnShow;
const defaultRenderStrategy = 'conditionalRenderThenToggleVisibility';
export const FormDialog = (props) => {
    const handleClose = (resetForm) => {
        props.onClose();
        const renderStrategy = props.renderStrategy || defaultRenderStrategy;
        if (renderStrategy !== 'conditionalRender') {
            resetForm();
        }
    };
    const handleSubmit = (resetForm, onSuccess, onExpectedError, onUnexpectedError, submitAction) => {
        const maybePromise = props.onSubmit(() => handleClose(resetForm), onSuccess, onExpectedError, onUnexpectedError, submitAction);
        Promise.resolve(maybePromise).catch((e) => {
            onUnexpectedError();
            throw e;
        });
    };
    return (<FormResetter formContainer={resetForm => <Dialog_ {...props} onClose={() => handleClose(resetForm)} inner={<Form onSubmit={(...args) => handleSubmit(resetForm, ...args)} onSubmissionToggle={props.onSubmissionToggle} displayGlobalError={false}>
            <div class={styles.headerAndBody}>
              <Header {...props} onClose={() => handleClose(resetForm)}/>

              <div class={styles.body} use:addBorders={[styles]}>
                <FormErrorContainer />
                {props.body}
              </div>
            </div>

            <Buttons {...props} onClose={() => handleClose(resetForm)}/>
          </Form>}/>}/>);
};
export const Dialog = (props) => {
    return (<Dialog_ {...props} inner={<DialogInner {...props}/>}/>);
};
const Dialog_ = (propsWithoutDefaults) => {
    const props = mergeProps({
        renderStrategy: defaultRenderStrategy,
        modifiers: {}
    }, propsWithoutDefaults);
    const [openedOnce, setOpenedOnce] = createSignal(false);
    createEffect(() => {
        if (props.isOpen) {
            setOpenedOnce(true);
        }
    });
    function render(isOpen, renderStrategy) {
        if (isOpen) {
            return true;
        }
        if (renderStrategy === 'toggleVisibility') {
            return true;
        }
        if (renderStrategy === 'conditionalRender') {
            return false;
        }
        if (renderStrategy === 'conditionalRenderThenToggleVisibility' && !openedOnce()) {
            return false;
        }
        return true;
    }
    return (<Show when={render(props.isOpen, props.renderStrategy)}>
      <Portal mount={document.body}>
        <div classList={{ [styles.hidden]: !props.isOpen }} use:pressEscape={props.onClose}>
          <div class={styles.backdrop}/>

          <div style={{
            display: 'contents',
            ...props.modifiers
        }}>
            <div class={styles.dialog} use:focusDialogOnShow={props.isOpen}>
              {props.inner}
            </div>
          </div>
        </div>
      </Portal>
    </Show>);
};
const DialogInner = (props) => {
    return (<>
      <HeaderAndBody {...props}/>
      <Buttons {...props}/>
    </>);
};
const HeaderAndBody = (props) => {
    return (<div class={styles.headerAndBody}>
      <Header {...props}/>
      <Body {...props}/>
    </div>);
};
const Header = (props) => {
    return (<>
      <div class={styles.header}>
        <span class={styles.title}>{props.title}</span>
      </div>

      <Show when={props.topBar}>
        <div class={styles.topBar}>
          {props.topBar}
        </div>
      </Show>
    </>);
};
const Body = (props) => {
    return (<div class={styles.body} use:addBorders={[styles]}>
      {props.body}
    </div>);
};
const Buttons = (props) => {
    return (<div class={styles.buttonContainer}>
      {props.buttons(props.onClose)}
    </div>);
};
export default Dialog;
