import Body from './Body';
import styles from './Card.module.css';
import Container from './Container';
import Header from './Header';
const Card_ = (props) => {
    return (<div class={styles.card} classList={{ [styles.clickable]: !!props.onClick }} onClick={props.onClick}>
      {props.children}
    </div>);
};
const Card = Object.assign(Card_, { Container, Body, Header });
export default Card;
