import { addTranslations } from 'solid-compose';
import en from './en.json';
import fr from './fr.json';
const translations_ = {
    fr,
    en
};
const translations = ([
    Object.entries(translations_).map(([locale, obj]) => [locale, 'general', obj])
]).flat();
translations.forEach(([locale, namespace, translations]) => {
    (namespace === 'general')
        ? addTranslations(locale, translations)
        : addTranslations(locale, namespace, translations);
});
