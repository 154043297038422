import createOrganizationMutation from '@finance/graphql/mutations/createOrganizationMutation';
import { Route, getRoute } from '@finance/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { Panel, TextField, focusOnFirstInput, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { createStore } from 'solid-js/store';
false && focusOnFirstInput;
const AddOrganization = () => {
    const [fields, setFields] = createStore({
        name: '',
        defaultCurrencyName: ''
    });
    const translate = useI18n();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const input = {
            name: fields.name,
            currencies: [
                { name: fields.defaultCurrencyName }
            ]
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(createOrganizationMutation.execute({ input }), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        const { createOrganization: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Organization', (organization) => {
            navigate(getRoute(Route.OrganizationConfigurationCheck, { organizationId: organization.id }));
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('add_organization_title')} description={translate('add_organization_description')} buttonText={translate('add_organization')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <Panel>
        <spacers.formFields>
          <TextField label={translate('organization_name')} onChange={value => setFields('name', value)} required value={fields.name}/>

          <TextField label={translate('default_currency')} onChange={value => setFields('defaultCurrencyName', value)} required value={fields.defaultCurrencyName}/>
        </spacers.formFields>
      </Panel>
    </Onboarding.FormPage>);
};
export default AddOrganization;
