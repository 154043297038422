import { isNullOrUndefined } from 'object-array-utils';
import { useI18n } from 'solid-compose';
import { batch, createEffect, createSignal, For, mergeProps, onCleanup, onMount, Show } from 'solid-js';
import { clickOutside, pressEscape } from '../../helpers/directives';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import getPositionMenuFun from './getPositionMenuFun';
import styles from './Select.module.css';
// TS workaround for Solid custom directives
false && clickOutside;
false && pressEscape;
function Select(propsWithoutDefaults) {
    let rootRef;
    const translate = useI18n();
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    const props = mergeProps({
        optionId: (option) => option,
        optionText: (option) => option
    }, propsWithoutDefaults);
    createEffect(() => {
        if (isNullOrUndefined(props.selectedOption)) {
            if (props.options.length === 1) {
                setTimeout(() => props.onChange(props.options[0]), 0);
                return;
            }
            if (!props.placeholder) {
                const optionText = props.optionText || ((option) => option);
                const options = props.options.map(optionText).join();
                throw new Error(`set a placeholder or select one of those options: ${options}`);
            }
        }
    });
    const emptyOption = {};
    let inputElement;
    const [shouldShow, setShouldShow] = createSignal(false);
    const [hoveredOption, setHoveredOption] = createSignal();
    const [error, setError] = createSignal('');
    const [isInputMouseDown, setInputMouseDown] = createSignal(false);
    const [isOptionMouseDown, setOptionMouseDown] = createSignal(false);
    let positionMenu;
    onMount(() => {
        positionMenu = !props.readOnly ? getPositionMenuFun({ inputElement, styles }) : undefined;
    });
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate();
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setShouldShow(false);
                        setHoveredOption(undefined);
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    function validate() {
        setValidatedOnce(true);
        if (props.required && isNullOrUndefined(props.selectedOption)) {
            setError(translate('select_option'));
            return;
        }
        setError('');
    }
    createEffect(() => {
        shouldShow();
        positionMenu?.();
    });
    createEffect(() => {
        if (!shouldShow()) {
            setHoveredOption(() => undefined);
        }
    });
    function handleInputMouseDown() {
        if (isOptionMouseDown()) {
            return;
        }
        setInputMouseDown(true);
        setShouldShow((prev) => !prev);
    }
    function handleInputKeyDown(e) {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                handleSelectionDown();
                break;
            case 'ArrowUp':
                e.preventDefault();
                handleSelectionUp();
                break;
            case 'Enter':
                e.preventDefault();
                const hoveredOption_ = hoveredOption();
                if (shouldShow()) {
                    if (!isNullOrUndefined(hoveredOption_)) {
                        props.onChange(hoveredOption_);
                        if (validatedOnce()) {
                            validate();
                        }
                    }
                    setShouldShow(false);
                }
                else {
                    handleFocusByKey();
                }
                break;
        }
    }
    function handleFocusByKey() {
        if (!isNullOrUndefined(props.selectedOption)) {
            setHoveredOption(() => props.selectedOption);
        }
        setShouldShow((prev) => !prev);
    }
    function handleOptionClick(option) {
        if (option === emptyOption) {
            if (props.allowEmptyOption) {
                props.onChange(undefined);
            }
            else {
                throw new Error();
            }
        }
        else {
            props.onChange(option);
        }
        setShouldShow((prev) => !prev);
        if (validatedOnce()) {
            validate();
        }
    }
    function handleOptionMouseDown() {
        setOptionMouseDown(true);
    }
    function handleOptionMouseUp() {
        setOptionMouseDown(false);
    }
    function handleOptionHover(option) {
        setHoveredOption(() => option);
    }
    function handleSelectionDown() {
        const hoveredOption_ = hoveredOption();
        if (isNullOrUndefined(hoveredOption_)) {
            if (!isNullOrUndefined(props.selectedOption)) {
                setHoveredOption(() => props.selectedOption);
            }
            else {
                setHoveredOption(() => props.options[0]);
                props.onChange(props.options[0]);
                if (validatedOnce()) {
                    validate();
                }
            }
            return;
        }
        const currentIndex = props.options.findIndex((o) => props.optionId(o) === props.optionId(hoveredOption_));
        if (currentIndex === props.options.length - 1) {
            return;
        }
        setHoveredOption(() => props.options[currentIndex + 1]);
        props.onChange(props.options[currentIndex + 1]);
        if (validatedOnce()) {
            validate();
        }
    }
    function handleSelectionUp() {
        const hoveredOption_ = hoveredOption();
        if (isNullOrUndefined(hoveredOption_)) {
            if (!isNullOrUndefined(props.selectedOption)) {
                setHoveredOption(() => props.selectedOption);
            }
            else {
                setHoveredOption(() => props.options[props.options.length - 1]);
                props.onChange(props.options[props.options.length - 1]);
                if (validatedOnce()) {
                    validate();
                }
            }
            return;
        }
        const currentIndex = props.options.findIndex((o) => props.optionId(o) === props.optionId(hoveredOption_));
        if (currentIndex === 0) {
            return;
        }
        setHoveredOption(() => props.options[currentIndex - 1]);
        props.onChange(props.options[currentIndex - 1]);
        if (validatedOnce()) {
            validate();
        }
    }
    function isSelectedOption(option) {
        if (option === emptyOption) {
            return props.selectedOption === undefined;
        }
        return !isNullOrUndefined(props.selectedOption) && props.optionId(option) === props.optionId(props.selectedOption);
    }
    function isHoveredOption(option) {
        if (option === emptyOption) {
            return hoveredOption() === emptyOption;
        }
        const hoveredOption_ = hoveredOption();
        return !isNullOrUndefined(hoveredOption_) && props.optionId(option) === props.optionId(hoveredOption_);
    }
    function handleBlur() {
        setShouldShow(false);
    }
    return (<div ref={rootRef} class={styles.select} classList={{ [styles.hasError]: !!error() }}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      <Show when={!props.readOnly} fallback={!isNullOrUndefined(props.selectedOption)
            ? (props.selectedOptionText
                ? props.selectedOptionText(props.selectedOption)
                : props.optionText(props.selectedOption))
            : ''}>
        <div class={styles.input} tabindex="0" onBlur={handleBlur} onKeyDown={handleInputKeyDown} onMouseDown={handleInputMouseDown} ref={inputElement}>
          <div class={styles.selectedOption}>
            {!isNullOrUndefined(props.selectedOption)
            ? (props.selectedOptionText
                ? props.selectedOptionText(props.selectedOption)
                : props.optionText(props.selectedOption))
            : <span class={styles.placeholder}>{props.placeholder}</span>}
          </div>

          <div class={styles.arrow}/>

          <div class={styles.menu} classList={{
            [styles.shouldShow]: shouldShow()
        }} use:clickOutside={() => {
            if (!isInputMouseDown()) {
                setShouldShow(false);
            }
            setInputMouseDown(false);
        }} use:pressEscape={() => setShouldShow(false)}>
            <Show when={props.allowEmptyOption}>
              <div class={styles.option} classList={{
            [styles.hoveredOptionInMenu]: isHoveredOption(emptyOption),
            [styles.selectedOptionInMenu]: isSelectedOption(emptyOption)
        }} onClick={[handleOptionClick, emptyOption]} onMouseDown={handleOptionMouseDown} onMouseUp={handleOptionMouseUp} onMouseOver={[handleOptionHover, emptyOption]}>
                <span class={styles.optionText}>{props.placeholder || translate('select')}</span>
              </div>
            </Show>
            <For each={props.options}>{option => <div class={styles.option} classList={{
                [styles.hoveredOptionInMenu]: isHoveredOption(option),
                [styles.selectedOptionInMenu]: isSelectedOption(option)
            }} onClick={[handleOptionClick, option]} onMouseDown={handleOptionMouseDown} onMouseUp={handleOptionMouseUp} onMouseOver={[handleOptionHover, option]}>
                <span class={styles.optionText}>{props.optionText(option)}</span>
              </div>}</For>
          </div>
          <div class={styles.borderBottom}/>
        </div>

        <FieldError error={error()}/>
      </Show>
    </div>);
}
export default Select;
