import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, NamedEntity, TypedObject } from '../types';

export interface CreateNamedEntitiesReturn {
  createNamedEntities:
    { namedEntities: NamedEntity[] } & TypedObject
    | { name: string } & TypedObject
    | Errors;
}

interface CreateNamedEntitiesVars {
  organizationId: string;
  type: string;
  names: string[];
}

export default Document
  .mutation<CreateNamedEntitiesReturn, CreateNamedEntitiesVars>('CreateNamedEntities')
    .variableDefinitions({ organizationId: 'ID!', type: 'NamedEntitiesType!', names: '[String!]!' })
    .union('createNamedEntities')
      .useVariables({ organizationId: 'organizationId', type: 'type', names: 'names' })
      .onTypedObject('CreateNamedEntitiesSuccess')
        .interfaceSet('namedEntities')
          .onEntity('RevenueType')
            .scalar('name')
            .entitySet('allocationRules')
              .deriveFrom(() => [])._._
          .onEntity('Wallet')
            .scalar('colorTag')
            .reference('currencyId', 'Currency')
            .scalar('balanceAmount')._
          .onEntity('Account')
            .scalar('colorTag')
            .reference('currencyId', 'Currency')
            .scalar('balanceAmount')._
          .scalar('archived')
          .scalar('organizationId')
          .scalar('name')._._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
