import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { Expense } from '../types';

export interface DeleteExpenseReturn {
  deleteExpense: Pick<Expense, 'id' | '__typename' | 'deleted'>;
}

interface DeleteExpenseVars {
  organizationId: string;
  expenseId: string;
}

export default Document
  .mutation<DeleteExpenseReturn, DeleteExpenseVars>('DeleteExpense')
    .variableDefinitions({ organizationId: 'ID!', expenseId: 'ID!' })
    .union('deleteExpense')
      .useVariables({ organizationId: 'organizationId', expenseId: 'expenseId' })
      .onEntity('Expense')
        .scalar('organizationId')
        .scalar('deleted')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ deleteExpense: data }) => {
    if (data.__typename === 'Expense') {
      Document.clearQueries([
        'Expenses',
        'WalletTransactions',
        'AccountTransactions',
        'Wallets',
        'Accounts'
      ]);
    }
  });
