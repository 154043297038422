import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { RevenueType } from '../types';

export interface ArchiveRevenueTypeReturn {
  archiveRevenueType: Pick<RevenueType, 'id' | '__typename' | 'archived'>;
}

interface ArchiveRevenueTypeVars {
  organizationId: string;
  revenueTypeId: string;
}

export default Document
  .mutation<ArchiveRevenueTypeReturn, ArchiveRevenueTypeVars>('ArchiveRevenueType')
    .variableDefinitions({ organizationId: 'ID!', revenueTypeId: 'ID!' })
    .union('archiveRevenueType')
      .useVariables({ organizationId: 'organizationId', revenueTypeId: 'revenueTypeId' })
      .onEntity('RevenueType')
        .scalar('organizationId')
        .scalar('archived')._
      .onTypedObject('AtLeastOneActiveRevenueTypeRequired')
        .scalar('currentServerTime', Temporal.Instant.from)._._._
  .makeExecutable(clientFetcher);
