import { Route, getRoute } from '@auth/routes';
import { Navigate } from '@solidjs/router';
import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus, useLocalStorage } from 'solid-compose';
import { Match, Switch } from 'solid-js';
const EnsureAuthenticated = (props) => {
    const [currentUser, { authenticationStatus }] = useCurrentUser();
    const [mode] = useLocalStorage('authenticationMode', 'signup');
    return (<Switch>
      <Match when={currentUser.loading}>
        <spacers.viewportFill>
          <Loader />
        </spacers.viewportFill>
      </Match>
      <Match when={authenticationStatus() === AuthenticationStatus.Unauthenticated}>
        <Navigate href={mode() === 'login' ? getRoute(Route.SelectLoginAuthMethod) : getRoute(Route.SelectSignupAuthMethod)}/>
      </Match>
      <Match when={authenticationStatus() === AuthenticationStatus.Authenticated}>
        {props.children}
      </Match>
    </Switch>);
};
export default EnsureAuthenticated;
