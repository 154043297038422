import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Account, Errors, TypedObject } from '../types';

export interface UpdateAccountReturn {
  updateAccount: Account | { name: string } & TypedObject | Errors;
}

interface UpdateAccountVars {
  organizationId: string;
  accountId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateAccountReturn, UpdateAccountVars>('UpdateAccount')
    .variableDefinitions({ organizationId: 'ID!', accountId: 'ID!', input: 'UpdateAccountInput!' })
    .union('updateAccount')
      .useVariables({ organizationId: 'organizationId',  accountId: 'accountId', input: 'input' })
      .onEntity('Account')
        .scalar('name')
        .scalar('colorTag')
        .scalar('archived')
        .scalar('balanceAmount')
        .scalar('organizationId')
        .reference('currencyId', 'Currency')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
