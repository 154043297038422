import createCurrencyMutation from '@finance/graphql/mutations/createCurrencyMutation';
import updateCurrencyMutation from '@finance/graphql/mutations/updateCurrencyMutation';
import useOrganizations from '@finance/graphql/resources/useOrganizationsResource';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, Checkbox, FormDialog, TextField, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { Show, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
function getInitialFormFields(currency) {
    return {
        name: currency?.name || '',
        code: currency?.code || '',
        isDefault: currency?.isDefault || false
    };
}
;
const CurrencyFormDialog = (props) => {
    const [_organizations, { refetch: refetchOrganizations }] = useOrganizations();
    const [submitting, setSubmitting] = createSignal(false);
    const [organization] = useSelectedOrganizationOrThrow();
    const [fields, setFields] = createStore(getInitialFormFields(props.currency));
    const translate = useI18n();
    const [nameAlreadyUsedError, setNameAlreadyUsedError] = createSignal('');
    createEffect(() => {
        if (props.isOpen && props.currency) {
            setFields(getInitialFormFields(props.currency));
        }
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        setNameAlreadyUsedError('');
        const variables = {
            input: {
                name: fields.name,
                code: fields.code,
                isDefault: fields.isDefault
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        let result = (props.currency)
            ? await processResponse(updateCurrencyMutation.execute({ ...variables, currencyId: props.currency.id }), { onError })
            : await processResponse(createCurrencyMutation.execute(variables), { onError });
        const response = result;
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('updateCurrency' in response) {
            data = response.updateCurrency;
        }
        else if ('createCurrency' in response) {
            data = response.createCurrency;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('Currency', async () => {
            await refetchOrganizations();
            props.onCurrencyAddedOrEdited?.();
            closeDialog();
        })
            .onExpectedErrorType('NameAlreadyUsed', (data) => {
            setNameAlreadyUsedError(translate('name_already_used', { name: data.name }));
            onExpectedError('');
        })
            .handle();
    };
    function handleDialogClosed() {
        setFields(getInitialFormFields());
        setNameAlreadyUsedError('');
        props.onClose();
    }
    function renderTitle() {
        if (props.isViewMode) {
            return translate('title_currency');
        }
        if (props.currency) {
            return translate('title_edit_currency');
        }
        return translate('title_add_currency');
    }
    return (<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
          <TextField label={translate('name')} onChange={value => { setNameAlreadyUsedError(''); setFields('name', value); }} error={nameAlreadyUsedError()} value={fields.name} readOnly={props.isViewMode} required/>

          <TextField label={translate('currency_code')} onChange={value => setFields('code', value)} value={fields.code} readOnly={props.isViewMode}/>

          <Checkbox onChange={value => setFields('isDefault', value)} checked={fields.isDefault}>
            {translate('set_as_default_currency')}
          </Checkbox>
        </spacers.formFields>} buttons={onClose => <>
          <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
            <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
          </div>
          <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.currency ? translate('update') : translate('add')}</Button>}>
            <Button type={ButtonType.Secondary} onClick={() => props.onEditCurrencyClick(props.currency)}>{translate('edit')}</Button>
          </Show>
        </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={renderTitle()} modifiers={{
            '--dialog-max-width': '400px'
        }}/>);
};
export default CurrencyFormDialog;
