import { setEntityLoading } from '@finance/graphql/localState';
import archiveCurrencyMutation from '@finance/graphql/mutations/archiveCurrencyMutation';
import archiveExpenseTypeMutation from '@finance/graphql/mutations/archiveExpenseTypeMutation';
import archiveRevenueTypeMutation from '@finance/graphql/mutations/archiveRevenueTypeMutation';
import { MutationResponseHandler, Viewport, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, DataGrid, Panel, ToggleButton, getScrollableAncestor } from '@subtile/universal-ui';
import { cloneShape } from 'object-array-utils';
import { useI18n, useViewport } from 'solid-compose';
import { Show, batch, createSignal } from 'solid-js';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import CurrencyFormDialog from './CurrencyFormDialog';
import ExpenseTypeFormDialog from './ExpenseTypeFormDialog';
import RevenueTypeFormDialog from './RevenueTypeFormDialog';
import styles from './Settings.module.css';
const Settings = () => {
    const translate = useI18n();
    const [organization] = useSelectedOrganizationOrThrow();
    const viewport = useViewport();
    const [selectedMenuItem, setSelectedMenuItem] = createSignal('revenue_types');
    const [showRevenueTypeFormDialog, setShowRevenueTypeFormDialog] = createSignal(false);
    const [showExpenseTypeFormDialog, setShowExpenseTypeFormDialog] = createSignal(false);
    const [revenueTypeBeingViewed, setRevenueTypeBeingViewed] = createSignal(null);
    const [revenueTypeBeingEdited, setRevenueTypeBeingEdited] = createSignal(null);
    const [expenseTypeBeingViewed, setExpenseTypeBeingViewed] = createSignal(null);
    const [expenseTypeBeingEdited, setExpenseTypeBeingEdited] = createSignal(null);
    const [showCurrencyFormDialog, setShowCurrencyFormDialog] = createSignal(false);
    const [currencyBeingEdited, setCurrencyBeingEdited] = createSignal(null);
    let revenueTypesSectionRef;
    let expenseTypesSectionRef;
    let currenciesSectionRef;
    function clearFormDialogState() {
        batch(() => {
            setRevenueTypeBeingViewed(null);
            setRevenueTypeBeingEdited(null);
            setExpenseTypeBeingViewed(null);
            setExpenseTypeBeingEdited(null);
            setShowRevenueTypeFormDialog(false);
            setShowExpenseTypeFormDialog(false);
            setShowCurrencyFormDialog(false);
            setCurrencyBeingEdited(null);
        });
    }
    function handleCloseFormDialog() {
        clearFormDialogState();
    }
    function handleAddRevenueTypeClick() {
        batch(() => {
            clearFormDialogState();
            setShowRevenueTypeFormDialog(true);
        });
    }
    function handleEditRevenueTypeClick(revenueType) {
        batch(() => {
            clearFormDialogState();
            setRevenueTypeBeingEdited(cloneShape(revenueType));
            setShowRevenueTypeFormDialog(true);
        });
    }
    function handleArchiveRevenueTypeClick(revenueType) {
        setEntityLoading(revenueType, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(revenueType, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(archiveRevenueTypeMutation.execute({
                organizationId: organization.id,
                revenueTypeId: revenueType.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.archiveRevenueType)
                .onSuccessType('RevenueType', () => { })
                .onExpectedErrorType('AtLeastOneActiveRevenueTypeRequired', () => {
                setEntityLoading(revenueType, false);
                alert(translate('at_least_one_active_revenue_type_required'));
            })
                .handle();
            setEntityLoading(revenueType, false);
        });
    }
    function handleAddExpenseTypeClick() {
        batch(() => {
            clearFormDialogState();
            setShowExpenseTypeFormDialog(true);
        });
    }
    function handleEditExpenseTypeClick(expenseType) {
        batch(() => {
            clearFormDialogState();
            setExpenseTypeBeingEdited(cloneShape(expenseType));
            setShowExpenseTypeFormDialog(true);
        });
    }
    function handleArchiveExpenseTypeClick(expenseType) {
        setEntityLoading(expenseType, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(expenseType, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(archiveExpenseTypeMutation.execute({
                organizationId: organization.id,
                expenseTypeId: expenseType.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.archiveExpenseType)
                .onSuccessType('ExpenseType', () => { })
                .onExpectedErrorType('AtLeastOneActiveExpenseTypeRequired', () => {
                setEntityLoading(expenseType, false);
                alert(translate('at_least_one_active_expense_type_required'));
            })
                .handle();
            setEntityLoading(expenseType, false);
        });
    }
    function handleAddCurrencyClick() {
        batch(() => {
            clearFormDialogState();
            setShowCurrencyFormDialog(true);
        });
    }
    function handleEditCurrencyClick(currency) {
        batch(() => {
            clearFormDialogState();
            setCurrencyBeingEdited(cloneShape(currency));
            setShowCurrencyFormDialog(true);
        });
    }
    function handleArchiveCurrencyClick(currency) {
        setEntityLoading(currency, true);
        batch(async () => {
            const onError = (error) => {
                setEntityLoading(currency, false);
                alert(translate('operation_failed'));
                throw error;
            };
            const response = await processResponse(archiveCurrencyMutation.execute({
                organizationId: organization.id,
                currencyId: currency.id
            }), { onError });
            if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
                return;
            }
            new MutationResponseHandler(response.archiveCurrency)
                .onSuccessType('Currency', () => { })
                .onExpectedErrorType('CannotArchiveDefaultCurrency', () => {
                alert(translate('cannot_archive_default_currency'));
            })
                .onExpectedErrorType('CannotArchiveCurrencyLinkedToActiveFunds', () => {
                alert(translate('cannot_archive_currency_with_active_funds'));
            })
                .onExpectedErrorType('AtLeastOneActiveCurrencyRequired', () => {
                setEntityLoading(currency, false);
                alert(translate('at_least_one_active_currency_required'));
            })
                .handle();
            setEntityLoading(currency, false);
        });
    }
    function handleMenuItemClick(value) {
        setSelectedMenuItem(value);
        let ref;
        switch (selectedMenuItem()) {
            case 'revenue_types':
                ref = revenueTypesSectionRef;
                break;
            case 'expense_types':
                ref = expenseTypesSectionRef;
                break;
            case 'currencies':
                ref = currenciesSectionRef;
                break;
        }
        const scrollableAncestor = getScrollableAncestor(ref) ?? document.documentElement;
        scrollableAncestor.scrollTo({
            top: ref.getBoundingClientRect().top - scrollableAncestor.getBoundingClientRect().top + scrollableAncestor.scrollTop - 5,
            behavior: 'smooth'
        });
    }
    return (<div class={styles.settingsPage} style={{
            '--button-font-size': '14px',
            '--button-padding': '5px 8px',
            '--panel-width': '400px'
        }}>
      <div class={styles.menuContainer}>
        <Show when={viewport.width === Viewport.SmallWidth} fallback={<div class={styles.menu}>
            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'revenue_types' }} onClick={() => handleMenuItemClick('revenue_types')}>
              {translate('revenue_types')}
            </div>

            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'expense_types' }} onClick={() => handleMenuItemClick('expense_types')}>
              {translate('expense_types')}
            </div>

            <div class={styles.menuItem} classList={{ [styles.selected]: selectedMenuItem() == 'currencies' }} onClick={() => handleMenuItemClick('currencies')}>
              {translate('currencies')}
            </div>
          </div>}>
          <ToggleButton.Group isDropdown onChange={handleMenuItemClick} selectedValue={selectedMenuItem()}>
            <ToggleButton value="revenue_types">{translate('revenue_types')}</ToggleButton>
            <ToggleButton value="expense_types">{translate('expense_types')}</ToggleButton>
            <ToggleButton value="currencies">{translate('currencies')}</ToggleButton>
          </ToggleButton.Group>
        </Show>
      </div>

      <div style={{ 'flex-grow': 1 }}>{/* necessary wrapper for flex */}
        <div class={styles.content}>
          <div class={styles.section} ref={revenueTypesSectionRef}>
            <Panel>
              <div class={styles.title}>
                {translate('revenue_types')}
                <Button onClick={handleAddRevenueTypeClick} type={ButtonType.Secondary}>
                  {translate('add')}
                </Button>
              </div>

              <DataGrid actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditRevenueTypeClick
            },
            {
                id: 'delete',
                content: translate('archive'),
                onClick: handleArchiveRevenueTypeClick
            }
        ]} isRowDataLoading={({ loading }) => loading} data={organization.revenueTypes.filter(({ archived }) => !archived)} maxHeight="300px" columnDefinitions={[
            {
                field: 'name',
                header: translate('name'),
                width: '1fr'
            }
        ]}/>
            </Panel>
          </div>

          <div class={styles.section} ref={expenseTypesSectionRef}>
            <Panel>
              <div class={styles.title}>
                {translate('expense_types')}
                <Button onClick={handleAddExpenseTypeClick} type={ButtonType.Secondary}>
                  {translate('add')}
                </Button>
              </div>

              <DataGrid actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditExpenseTypeClick
            },
            {
                id: 'delete',
                content: translate('archive'),
                onClick: handleArchiveExpenseTypeClick
            }
        ]} isRowDataLoading={({ loading }) => loading} data={organization.expenseTypes.filter(({ archived }) => !archived)} maxHeight="300px" columnDefinitions={[
            {
                field: 'name',
                header: translate('name'),
                width: '1fr'
            }
        ]}/>
            </Panel>
          </div>

          <div class={styles.section} ref={currenciesSectionRef}>
            <Panel>
              <div class={styles.title}>
                {translate('currencies')}
                <Button onClick={handleAddCurrencyClick} type={ButtonType.Secondary}>
                  {translate('add')}
                </Button>
              </div>

              <DataGrid actions={[
            {
                id: 'edit',
                content: translate('edit'),
                onClick: handleEditCurrencyClick
            },
            {
                id: 'delete',
                content: translate('archive'),
                onClick: handleArchiveCurrencyClick
            }
        ]} isRowDataLoading={({ loading }) => loading} data={organization.currencies.filter(({ archived }) => !archived)} maxHeight="300px" columnDefinitions={[
            {
                field: 'name',
                header: translate('name'),
                width: '100px'
            },
            {
                field: 'code',
                header: translate('currency_code'),
                width: '1fr'
            }
        ]}/>
            </Panel>
          </div>
        </div>
      </div>

      <RevenueTypeFormDialog isOpen={!!showRevenueTypeFormDialog()} onClose={handleCloseFormDialog} onEditRevenueTypeClick={handleEditRevenueTypeClick} revenueType={revenueTypeBeingViewed() || revenueTypeBeingEdited()} isViewMode={!!revenueTypeBeingViewed()}/>

      <ExpenseTypeFormDialog isOpen={!!showExpenseTypeFormDialog()} onClose={handleCloseFormDialog} onEditExpenseTypeClick={handleEditExpenseTypeClick} expenseType={expenseTypeBeingViewed() || expenseTypeBeingEdited()} isViewMode={!!expenseTypeBeingViewed()}/>

      <CurrencyFormDialog isOpen={showCurrencyFormDialog()} onClose={handleCloseFormDialog} onEditCurrencyClick={handleEditCurrencyClick} currency={currencyBeingEdited()} isViewMode={false}/>
    </div>);
};
export default Settings;
