import { Route, getRoute } from '@auth/routes';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { useLocalStorage } from 'solid-compose';
const OAuthFailure = () => {
    const [authenticationMode] = useLocalStorage('authenticationMode', 'signup');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const provider = searchParams['provider'];
    const knownProviders = ['google'];
    if (!knownProviders.includes(provider)) {
        throw new Error(`unknown provider ${provider}`);
    }
    switch (authenticationMode()) {
        default:
            throw new Error(`unknown mode "${authenticationMode()}"`);
        case 'login':
            navigate(`${getRoute(Route.SelectLoginAuthMethod)}?oauth-failure-with-provider=${provider}`);
            return null;
        case 'signup':
            navigate(`${getRoute(Route.SelectSignupAuthMethod)}?oauth-failure-with-provider=${provider}`);
            return null;
    }
};
export default OAuthFailure;
