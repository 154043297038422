import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { fetchCurrency } from '../resources/useOrganizationsResource';
import type { Account, Entity, Organization } from '../types';

function addEntity(entitiesType: string) {
  return (entity: { __typename: string; organizationId: string }, _: unknown, organization: Organization) => {
    if (entitiesType !== entity.__typename) {
      return;
    }
    if (!entity.organizationId) {
      throw new Error(`missing organization ID for type ${entitiesType}`);
    }
    return entity.organizationId === organization.id;
  }
}

export interface AccountsReturn {
  me: { organization: { accounts: Account[] } };
}

export interface AccountsVars {
  organizationId: string;
}

export default Document
  .query<AccountsReturn, AccountsVars, Account[]>('Accounts')
    .variableDefinitions({ organizationId: 'ID!' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .entitySet('accounts', 'Account')
          .addEntity({
            Account: () => addEntity('Account')
          })
          .scalar('organizationId')
          .entity('currency', 'Currency')
            .scalar('name')
            .scalar('code')
            .scalar('precision')
            .deriveFromReference('currencyId', fetchCurrency)
            .replaceEntity({
              Currency: (currency: Entity, _variables: unknown, account: { currencyId: string }) => {
                // console.log('--- account ---', account);
                return account.currencyId === currency.id;
              }
            })._
          .scalar('name')
          .scalar('colorTag')
          .scalar('balanceAmount')
          .scalar('archived')._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { accounts } } }) => {
    return accounts.toSorted((a1: Account, a2: Account) => a1.name.localeCompare(a2.name));
  })
  .scopeByTenants(({ organizationId }) => ({ organizationId }));
