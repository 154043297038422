import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { fetchAccount, fetchCurrency, fetchExpenseType, fetchRevenueType, fetchWallet } from '../resources/useOrganizationsResource';
import type { AccountTransactions, Entity } from '../types';

export interface AccountTransactionsReturn {
  me: { organization: { accountTransactions: AccountTransactions } };
}

export interface AccountTransactionsVars {
  organizationId: string;
  accountId: string;
  filters: unknown;
}

export default Document
  .query<AccountTransactionsReturn, AccountTransactionsVars, AccountTransactions>('AccountTransactions')
    .variableDefinitions({ organizationId: 'ID!', accountId: 'ID!', filters: 'AccountTransactionsFiltersInput' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .wrapper('accountTransactions')
          .useVariables({ accountId: 'accountId', accountTransactionsFilters: 'filters' })
          .entitySet('paginatedEntries', 'AccountTransaction')
            .scalar('organizationId')
            .scalar('date', Temporal.PlainDate.from)
            .scalar('deleted')
            .virtual('loading', false)
            .entity('revenue', 'Revenue')
              .scalar('updatedAt', Temporal.Instant.from)
              .scalar('organizationId')
              .scalar('deleted')
              .scalar('amountBeforeAllocations')
              .scalar('amountAfterAllocations')
              .scalar('description')
              .scalar('reference')
              .entity('wallet', 'Wallet')
                .scalar('name')
                .deriveFromReference('walletId', fetchWallet)
                .replaceEntity({
                  Wallet: (wallet: Entity, _variables: unknown, revenue: { walletId: string }) => {
                    // console.log('--- revenue ---', revenue);
                    return revenue.walletId === wallet.id;
                  }
                })._
              .entity('account', 'Account')
                .scalar('name')
                .deriveFromReference('accountId', fetchAccount)
                .replaceEntity({
                  Account: (account: Entity, _variables: unknown, revenue: { accountId: string }) => {
                    // console.log('--- revenue ---', revenue);
                    return revenue.accountId === account.id;
                  }
                })._
              .entity('revenueType', 'RevenueType')
                .scalar('name')
                .deriveFromReference('revenueTypeId', fetchRevenueType)
                .replaceEntity({
                  RevenueType: (revenueType: Entity, _variables: unknown, revenue: { revenueTypeId: string }) => {
                    // console.log('--- revenue ---', revenue);
                    return revenue.revenueTypeId === revenueType.id;
                  }
                })._
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('code')
                .scalar('precision')
                .deriveFromReference('currencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, revenue: { currencyId: string }) => {
                    // console.log('--- revenue ---', revenue);
                    return revenue.currencyId === currency.id;
                  }
                })._
              .entitySet('allocations', 'WalletTransfer')
                .scalar('organizationId')
                .scalar('deleted')
                .scalar('targetAmount')
                .entity('targetWallet', 'Wallet')
                  .scalar('name')
                  .scalar('colorTag')
                  .entity('currency', 'Currency')
                    .scalar('name')
                    .scalar('precision')
                    .scalar('code')._
                  .deriveFromReference('targetWalletId', fetchWallet)
                  .replaceEntity({
                    Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { targetWalletId: string }) => {
                      // console.log('--- walletTransfer ---', walletTransfer);
                      return walletTransfer.targetWalletId === wallet.id;
                    }
                  })._._._
            .entity('expense', 'Expense')
              .scalar('updatedAt', Temporal.Instant.from)
              .scalar('organizationId')
              .scalar('deleted')
              .scalar('amount')
              .scalar('description')
              .scalar('reference')
              .entity('wallet', 'Wallet')
                .scalar('name')
                .deriveFromReference('walletId', fetchWallet)
                .replaceEntity({
                  Wallet: (wallet: Entity, _variables: unknown, expense: { walletId: string }) => {
                    // console.log('--- expense ---', expense);
                    return expense.walletId === wallet.id;
                  }
                })._
              .entity('account', 'Account')
                .scalar('name')
                .deriveFromReference('accountId', fetchAccount)
                .replaceEntity({
                  Account: (account: Entity, _variables: unknown, expense: { accountId: string }) => {
                    // console.log('--- expense ---', expense);
                    return expense.accountId === account.id;
                  }
                })._
              .entity('expenseType', 'ExpenseType')
                .scalar('name')
                .deriveFromReference('expenseTypeId', fetchExpenseType)
                .replaceEntity({
                  ExpenseType: (expenseType: Entity, _variables: unknown, expense: { expenseTypeId: string }) => {
                    // console.log('--- expense ---', expense);
                    return expense.expenseTypeId === expenseType.id;
                  }
                })._
              .entity('currency', 'Currency')
                .scalar('name')
                .scalar('code')
                .scalar('precision')
                .deriveFromReference('currencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, expense: { currencyId: string }) => {
                    // console.log('--- expense ---', expense);
                    return expense.currencyId === currency.id;
                  }
                })._._
            .entity('accountTransfer', 'AccountTransfer')
              .scalar('updatedAt', Temporal.Instant.from)
              .scalar('organizationId')
              .scalar('deleted')
              .scalar('sourceAmount')
              .scalar('targetAmount')
              .entity('sourceAccount', 'Account')
                .scalar('name')
                .scalar('colorTag')
                .entity('currency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')._
                .deriveFromReference('sourceAccountId', fetchAccount)
                .replaceEntity({
                  Account: (account: Entity, _variables: unknown, accountTransfer: { sourceAccountId: string }) => {
                    // console.log('--- accountTransfer ---', accountTransfer);
                    return accountTransfer.sourceAccountId === account.id;
                  }
                })._
              .entity('targetAccount', 'Account')
                .scalar('name')
                .scalar('colorTag')
                .entity('currency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')._
                .deriveFromReference('targetAccountId', fetchAccount)
                .replaceEntity({
                  Account: (account: Entity, _variables: unknown, accountTransfer: { targetAccountId: string }) => {
                    // console.log('--- accountTransfer ---', accountTransfer);
                    return accountTransfer.targetAccountId === account.id;
                  }
                })._
              .entity('sourceCurrency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')
                .deriveFromReference('sourceCurrencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, accountTransfer: { sourceCurrencyId: string }) => {
                    // console.log('--- accountTransfer ---', accountTransfer);
                    return accountTransfer.sourceCurrencyId === currency.id;
                  }
                })._
              .entity('targetCurrency', 'Currency')
                .scalar('name')
                .scalar('precision')
                .scalar('code')
                .deriveFromReference('targetCurrencyId', fetchCurrency)
                .replaceEntity({
                  Currency: (currency: Entity, _variables: unknown, accountTransfer: { targetCurrencyId: string }) => {
                    // console.log('--- accountTransfer ---', accountTransfer);
                    return accountTransfer.targetCurrencyId === currency.id;
                  }
                })._
              .entitySet('walletTransfers', 'WalletTransfer')
                .scalar('organizationId')
                .scalar('deleted')
                .scalar('sourceAmount')
                .scalar('targetAmount')
                .entity('sourceWallet', 'Wallet')
                  .scalar('name')
                  .scalar('colorTag')
                  .deriveFromReference('sourceWalletId', fetchWallet)
                  .replaceEntity({
                    Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { sourceWalletId: string }) => {
                      // console.log('--- walletTransfer ---', walletTransfer);
                      return walletTransfer.sourceWalletId === wallet.id;
                    }
                  })._
                .entity('targetWallet', 'Wallet')
                  .scalar('name')
                  .scalar('colorTag')
                  .entity('currency', 'Currency')
                    .scalar('name')
                    .scalar('precision')
                    .scalar('code')._
                  .deriveFromReference('targetWalletId', fetchWallet)
                  .replaceEntity({
                    Wallet: (wallet: Entity, _variables: unknown, walletTransfer: { targetWalletId: string }) => {
                      // console.log('--- walletTransfer ---', walletTransfer);
                      return walletTransfer.targetWalletId === wallet.id;
                    }
                  })._
                .entity('sourceCurrency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')
                  .deriveFromReference('sourceCurrencyId', fetchCurrency)
                  .replaceEntity({
                    Currency: (currency: Entity, _variables: unknown, walletTransfer: { sourceCurrencyId: string }) => {
                      // console.log('--- walletTransfer ---', walletTransfer);
                      return walletTransfer.sourceCurrencyId === currency.id;
                    }
                  })._
                .entity('targetCurrency', 'Currency')
                  .scalar('name')
                  .scalar('precision')
                  .scalar('code')
                  .deriveFromReference('targetCurrencyId', fetchCurrency)
                  .replaceEntity({
                    Currency: (currency: Entity, _variables: unknown, walletTransfer: { targetCurrencyId: string }) => {
                      // console.log('--- walletTransfer ---', walletTransfer);
                      return walletTransfer.targetCurrencyId === currency.id;
                    }
                  })._._._
            ._._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { accountTransactions } } }) => accountTransactions)
  .scopeByTenants(({ organizationId }) => ({ organizationId }));
