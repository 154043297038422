import Group from './Group';
import styles from './Radio.module.css';
import { useRadio } from './context';
const Radio = (props) => {
    const [checkedValue, setCheckedValue] = useRadio();
    return (<div class={styles.radio} classList={{ [styles.checked]: checkedValue() === props.value }} onClick={() => setCheckedValue(props.value)}>
      <div class={styles.icon}/>

      <span class={styles.text}>{props.children}</span>
    </div>);
};
const Radio_ = Object.assign(Radio, { Group });
export default Radio_;
