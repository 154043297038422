import { awaitResource, useQueryResource } from '@subtile/project-shared';
import { orThrow } from 'or-throw';
import { unwrap } from 'solid-js/store';
import document from '../queries/organizationsQuery';
import type { Entity, Organization } from '../types';

export default function useOrganizations() {
  return useQueryResource<Organization[], {}>(document, {});
}

export function fetchWallet(walletId: string, { organizationId }: { organizationId: string }) {
  return fetchOrRetry(organizationId, (organization) => organization.wallets.find(({ id }) => walletId === id));
}

export function fetchAccount(accountId: string, { organizationId }: { organizationId: string }) {
  return fetchOrRetry(organizationId, (organization) => organization.accounts.find(({ id }) => accountId === id));
}

export function fetchCurrency(currencyId: string, { organizationId }: { organizationId: string }) {
  return fetchOrRetry(organizationId, (organization) => organization.currencies.find(({ id }) => currencyId === id));
}

export function fetchRevenueType(revenueTypeId: string, { organizationId }: { organizationId: string }) {
  return fetchOrRetry(organizationId, (organization) => organization.revenueTypes.find(({ id }) => revenueTypeId === id));
}

export function fetchExpenseType(expenseTypeId: string, { organizationId }: { organizationId: string }) {
  return fetchOrRetry(organizationId, (organization) => organization.expenseTypes.find(({ id }) => expenseTypeId === id));
}

async function fetchOrRetry(organizationId: string, fetchEntity: (organization: Organization) => Entity | undefined, retried = false) {
  if (retried) {
    throw new Error('fetching entity failed');
  }

  const [organizations, { refetch }] = await awaitResource(useOrganizations);

  const organization = orThrow(organizations()!.find(({ id }) => id === organizationId))!;

  const entity = fetchEntity(organization);

  if (!entity) {
    await refetch();
    return fetchOrRetry(organizationId, fetchEntity, true);
  }

  return unwrap(entity);
}
