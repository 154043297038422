import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import type { Errors, Expense, TypedObject } from '../types';

export interface CreateExpenseReturn {
  createExpense: Expense | { reference: string } & TypedObject | Errors;
}

interface CreateExpenseVars {
  organizationId: string;
  input: unknown;
}

export default Document
  .mutation<CreateExpenseReturn, CreateExpenseVars>('CreateExpense')
    .variableDefinitions({ organizationId: 'ID!', input: 'CreateExpenseInput!' })
    .union('createExpense')
      .useVariables({ organizationId: 'organizationId', input: 'input' })
      .onEntity('Expense')
        .scalar('organizationId')
        .scalar('date', Temporal.PlainDate.from)
        .reference('currencyId', 'Currency')
        .reference('expenseTypeId', 'ExpenseType')
        .reference('accountId', 'Account')
        .reference('walletId', 'Wallet')
        .scalar('amount')
        .scalar('description')
        .scalar('reference')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('NegativeAccountBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('ReferenceAlreadyUsed')
        .scalar('reference')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ createExpense: data }) => {
    if (data.__typename === 'Expense') {
      Document.clearQueries([
        'Expenses',
        'WalletTransactions',
        'AccountTransactions',
        'Wallets',
        'Accounts'
      ]);
    }
  });
