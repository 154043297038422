import { Outlet } from '@solidjs/router';
import { createContext, createSignal, useContext } from 'solid-js';
import Content from './Content';
import styles from './Layout.module.css';
import TopBar from './TopBar';
const LayoutContext = createContext();
const Layout = (props) => {
    const [shouldShowMenu, setShouldShowMenu] = createSignal(false);
    function handleMenuClick() {
        setShouldShowMenu(false);
    }
    function handleToggleMenuClick() {
        setShouldShowMenu((prev) => !prev);
    }
    return (<LayoutContext.Provider value={{ onMenuClick: handleMenuClick }}>
      <TopBar onMenuTriggerClick={handleToggleMenuClick} organizationsResource={props.organizationsResource} selectedOrganizationId={props.selectedOrganizationId} onSwitchOrganization={props.onSwitchOrganization} onLogoutClick={props.onLogoutClick}/>

      <div class={styles.menuAndContentContainer}>
        <div class={styles.menuContainer} classList={{ [styles.shouldShow]: shouldShowMenu() }}>
          {props.menu}
        </div>

        <Content><Outlet /></Content>
      </div>
    </LayoutContext.Provider>);
};
export default Layout;
export function useLayout() {
    return useContext(LayoutContext);
}
