import createWalletTransferMutation from '@finance/graphql/mutations/createWalletTransferMutation';
import updateWalletTransferMutation from '@finance/graphql/mutations/updateWalletTransferMutation';
import revenueQuery from '@finance/graphql/queries/revenueQuery';
import { Temporal } from '@js-temporal/polyfill';
import { MutationResponseHandler, processResponse } from '@subtile/project-shared';
import { Button, ButtonType, DateField, Dialog, FormDialog, Money, SelectEntity, TextArea, TextField, spacers } from '@subtile/universal-ui';
import { FetchStrategy } from 'fluent-graphql';
import { formatDate, formatTime, parseNumber, useI18n, useLocale } from 'solid-compose';
import { Match, Show, Switch, createEffect, createResource, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { formatMoneyInput, moneyFormatter } from '../../helpers';
import { useSelectedOrganizationOrThrow } from '../ProvideSelectedOrganization';
import RevenueFormDialog from './RevenueFormDialog';
function getInitialFormFields(walletTransfer) {
    const amount = (walletTransfer?.sourceAmount)
        ? formatMoneyInput(walletTransfer.sourceAmount)
        : '';
    return {
        date: walletTransfer?.date || Temporal.Now.plainDateISO(),
        sourceWallet: walletTransfer?.sourceWallet ? { ...walletTransfer.sourceWallet } : undefined,
        targetWallet: walletTransfer?.targetWallet ? { ...walletTransfer.targetWallet } : undefined,
        amount,
        currency: walletTransfer?.sourceCurrency ? { ...walletTransfer.sourceCurrency } : undefined,
        description: walletTransfer?.description || ''
    };
}
;
const WalletTransferFormDialog = (props) => {
    const [submitting, setSubmitting] = createSignal(false);
    const [walletsCurrencyMatch, setWalletsCurrencyMatch] = createSignal(true);
    const [organization] = useSelectedOrganizationOrThrow();
    const formatMoney = (amount, currency) => moneyFormatter(organization)(amount, currency);
    const [fields, setFields] = createStore(getInitialFormFields(props.walletTransfer));
    const [locale] = useLocale();
    const translate = useI18n();
    const shouldEditAllocationFromRevenue = () => !props.isViewMode
        && props.walletTransfer
        && props.walletTransfer.revenueIdForAllocation;
    const [isEditRevenue, setEditRevenue] = createSignal(false);
    const [revenue] = createResource(isEditRevenue, async () => {
        const fetchOptions = { fetchStrategy: FetchStrategy.FetchFromNetworkAndNoCache };
        const variables = { organizationId: organization.id, revenueId: props.walletTransfer.revenueIdForAllocation };
        const onError = (error) => { throw error; };
        const revenue = await processResponse(revenueQuery.execute(variables, fetchOptions), { onError });
        if (!revenue) {
            throw new Error();
        }
        return revenue;
    });
    createEffect(() => {
        if (props.isOpen && props.walletTransfer) {
            setFields(getInitialFormFields(props.walletTransfer));
        }
    });
    createEffect(() => {
        setWalletsCurrencyMatch(!!props.walletTransfer
            && props.walletTransfer.sourceWallet.currency.id === props.walletTransfer.targetWallet.currency.id);
    });
    const handleSubmit = async (closeDialog, onSuccess, onExpectedError, onUnexpectedError) => {
        const variables = {
            input: {
                date: fields.date,
                sourceWalletId: fields.sourceWallet.id,
                targetWalletId: fields.targetWallet.id,
                currencyId: fields.currency.id,
                amount: parseNumber(fields.amount),
                description: fields.description
            },
            organizationId: organization.id
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = (props.walletTransfer)
            ? await processResponse(updateWalletTransferMutation.execute({ ...variables, walletTransferId: props.walletTransfer.id }), { onError })
            : await processResponse(createWalletTransferMutation.execute(variables), { onError });
        if (!response) { // let `EnsureAuthenticated` component react on unauthenticated state
            return;
        }
        let data;
        if ('createWalletTransfer' in response) {
            data = response.createWalletTransfer;
        }
        else if ('updateWalletTransfer' in response) {
            data = response.updateWalletTransfer;
        }
        else {
            throw new Error();
        }
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('WalletTransfer', () => {
            props.onWalletTransferAddedOrEdited();
            closeDialog();
        })
            .onExpectedErrorType('DirectCrossCurrencyWalletTransferEditForbidden', () => {
            onExpectedError(translate('direct_cross_currency_wallet_transfer_edit_forbidden'));
        })
            .onExpectedErrorType('RevenueAllocationsExceedRevenue', ({ revenueAmount, currencyName }) => {
            onExpectedError(translate('revenue_allocations_exceed_revenue', { revenueAmount, currencyName }));
        })
            .onExpectedErrorType('NegativeWalletBalance', ({ name, balanceAmount, currencyName }) => {
            onExpectedError(translate('insufficient_funds', { name, balanceAmount, currencyName }));
        })
            .handle();
    };
    function handleEditRevenueClick() {
        setEditRevenue(true);
    }
    function handleDialogClosed() {
        setEditRevenue(false);
        setFields(getInitialFormFields());
        props.onClose();
    }
    return (<Switch fallback={<FormDialog onSubmit={handleSubmit} onSubmissionToggle={setSubmitting} body={<spacers.formFields>
            <DateField label={translate('date')} onChange={value => setFields('date', value)} value={fields.date} readOnly={props.isViewMode} required/>

            <SelectEntity label={translate('sending_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={wallet => setFields('sourceWallet', { ...wallet })} placeholder={translate('select')} selectedEntityId={fields.sourceWallet?.id} readOnly={props.isViewMode} required/>

            <SelectEntity label={translate('receiving_wallet')} entities={organization.wallets} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={wallet => setFields('targetWallet', { ...wallet })} placeholder={translate('select')} selectedEntityId={fields.targetWallet?.id} readOnly={props.isViewMode} required/>

            <Show when={!(props.isViewMode && !walletsCurrencyMatch())} fallback={<>
                <TextField label={translate('sending_amount')} value={formatMoney(props.walletTransfer.sourceAmount, props.walletTransfer.sourceCurrency)} readOnly/>

                <TextField label={translate('receiving_amount')} value={formatMoney(props.walletTransfer.targetAmount, props.walletTransfer.targetCurrency)} readOnly/>
              </>}>
              <Show when={!props.isViewMode} fallback={<TextField label={translate('amount')} value={formatMoney(props.walletTransfer.sourceAmount, props.walletTransfer.sourceCurrency)} readOnly/>}>
                <Money amount={<TextField label={translate('amount')} type="money" value={fields.amount} required onChange={value => setFields('amount', value)}/>} currency={<SelectEntity label={translate('currency')} entities={organization.currencies} filter={({ archived }) => !archived} entityLabelField="name" onEntityChange={currency => setFields('currency', { ...currency })} placeholder={translate('select')} selectedEntityId={fields.currency?.id} required/>}/>
              </Show>
            </Show>

            <TextArea label={translate('description')} onChange={value => setFields('description', value)} rows={3} value={props.isViewMode ? (fields.description || '-') : fields.description} readOnly={props.isViewMode}/>

            <Show when={props.isViewMode}>
              <TextField label={translate('last_updated')} value={formatDate(props.walletTransfer.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainDate()) + ' ' +
                    formatTime(props.walletTransfer.updatedAt.toZonedDateTimeISO(locale.timeZone).toPlainTime(), { precision: 'minute' })} readOnly/>
            </Show>
          </spacers.formFields>} buttons={onClose => <>
            <div style={{ visibility: !props.isOpen || submitting() ? 'hidden' : 'visible' }}>
              <Button type={ButtonType.Secondary} onClick={onClose}>{props.isViewMode ? translate('close') : translate('cancel')}</Button>
            </div>
            <Show when={props.isViewMode} fallback={<Button type={ButtonType.Submit}>{props.walletTransfer ? translate('update') : translate('add')}</Button>}>
              {!props.walletTransfer.deleted &&
                    <Button type={ButtonType.Secondary} onClick={() => props.onEditWalletTransferClick(props.walletTransfer)}>{translate('edit')}</Button>}
            </Show>
          </>} isOpen={props.isOpen} onClose={handleDialogClosed} renderStrategy="toggleVisibility" title={props.isViewMode ? translate('title_wallet_transfer') : (props.walletTransfer ? translate('title_edit_wallet_transfer') : translate('title_add_wallet_transfer'))}/>}>
      <Match when={revenue()}>
        <RevenueFormDialog isOpen={props.isOpen} onClose={handleDialogClosed} onRevenueAddedOrEdited={props.onRevenueEdited} onEditRevenueClick={handleEditRevenueClick} revenue={revenue()} isViewMode={false}/>
      </Match>

      <Match when={shouldEditAllocationFromRevenue()}>
        <Dialog title={translate('title_edit_or_delete_allocation')} isOpen={props.isOpen} onClose={handleDialogClosed} body={translate('edit_allocation_from_revenue')} buttons={onClose => <>
            <div style={{ visibility: revenue.loading ? 'hidden' : 'visible' }}>
              <Button type={ButtonType.Secondary} onClick={onClose}>
                {translate('cancel')}
              </Button>
            </div>

            <Button loading={revenue.loading} type={ButtonType.Submit} onClick={handleEditRevenueClick}>
              {translate('update_revenue')}
            </Button>
          </>}/>
      </Match>
    </Switch>);
};
export default WalletTransferFormDialog;
