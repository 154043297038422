export var ColorName;
(function (ColorName) {
    ColorName["Yellow"] = "YELLOW";
    ColorName["Red"] = "RED";
    ColorName["Green"] = "GREEN";
    ColorName["Orange"] = "ORANGE";
    ColorName["DarkBlue"] = "DARK_BLUE";
    ColorName["LightBlue"] = "LIGHT_BLUE";
    ColorName["Violet"] = "VIOLET";
    ColorName["Pink"] = "PINK";
    ColorName["Brown"] = "BROWN";
    ColorName["Turquoise"] = "TURQUOISE";
})(ColorName || (ColorName = {}));
let colors;
let colorMap = new Map();
export function setColors(colors_) {
    colors = colors_;
    colorMap = new Map();
    colors.forEach((color) => {
        colorMap.set(color.name, color);
    });
}
export function getColorByColorName(colorName) {
    return colorMap.get(colorName);
}
export function getColors() {
    return colors;
}
setColors([
    {
        name: ColorName.Yellow,
        darkModeColor: 'oklch(81% 0.12 100)',
        lightModeColor: 'oklch(85% 0.167 86)',
        i18nKey: 'yellow'
    },
    {
        name: ColorName.Red,
        darkModeColor: 'oklch(60% 0.165 20)',
        lightModeColor: 'oklch(56% 0.22 30)',
        i18nKey: 'red'
    },
    {
        name: ColorName.Green,
        darkModeColor: 'oklch(65% 0.125 145)',
        lightModeColor: 'oklch(59% 0.179 145)',
        i18nKey: 'green'
    },
    {
        name: ColorName.Orange,
        darkModeColor: 'oklch(72% 0.136 60)',
        lightModeColor: 'oklch(70% 0.173 55)',
        i18nKey: 'orange'
    },
    {
        name: ColorName.DarkBlue,
        darkModeColor: 'oklch(56% 0.145 260)',
        lightModeColor: 'oklch(55% 0.2 260)',
        i18nKey: 'dark_blue'
    },
    {
        name: ColorName.LightBlue,
        darkModeColor: 'oklch(73% 0.11 240)',
        lightModeColor: 'oklch(75% 0.15 232.31)',
        i18nKey: 'light_blue'
    },
    {
        name: ColorName.Violet,
        darkModeColor: 'oklch(65% 0.15 300)',
        lightModeColor: 'oklch(55% 0.23 300)',
        i18nKey: 'violet'
    },
    {
        name: ColorName.Pink,
        darkModeColor: 'oklch(76% 0.1 7)',
        lightModeColor: 'oklch(70% 0.2 0)',
        i18nKey: 'pink'
    },
    {
        name: ColorName.Brown,
        darkModeColor: 'oklch(51% 0.065 40)',
        lightModeColor: 'oklch(40% 0.12 39.6)',
        i18nKey: 'brown'
    },
    {
        name: ColorName.Turquoise,
        darkModeColor: 'oklch(75% 0.09 185)',
        lightModeColor: 'oklch(75% 0.12 185)',
        i18nKey: 'turquoise'
    }
]);
