import { createClientFetcher } from '@subtile/project-shared';
import { Document } from 'fluent-graphql';
import { Invitation, Organization } from '../types';

const client = createClientFetcher({
  httpUrl: import.meta.env.VITE_AUTH_API_URL,
  wsUrl: ''
});

function addEntity(entitiesType: string) {
  return (entity: { __typename: string; organizationId: string }, _: unknown, organization: Organization) => {
    if (entitiesType !== entity.__typename) {
      return;
    }
    if (!entity.organizationId) {
      throw new Error(`missing organization ID for type ${entitiesType}`);
    }
    return entity.organizationId === organization.id;
  }
}

export interface InvitationsReturn {
  me: { organization: { invitations: Invitation[] } };
}

interface InvitationsVars {
  organizationId: string;
}

export default Document
  .query<InvitationsReturn, InvitationsVars, Invitation[]>('Invitations')
    .variableDefinitions({ organizationId: 'ID!' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .entitySet('invitations', 'Invitation')
          .virtual('loading', false)
          .addEntity({
            Invitation: () => addEntity('Invitation')
          })
          .scalar('organizationId')
          .scalar('email')._._._._
  .makeExecutable(client)
  .transformResponse(({ me: { organization: { invitations } } }) => invitations)
  .scopeByTenants(({ organizationId }) => ({ organizationId }));
