import classNames from 'classnames';
import Decimal from 'decimal.js-light';
import { isNullOrUndefined } from 'object-array-utils';
import { parseNumber, useI18n, useLocale } from 'solid-compose';
import { Show, batch, createEffect, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import { NumberFormat } from 'user-locale';
import IconButton from '../button/IconButton';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import styles from './TextField.module.css';
const TextField = (propsWithoutDefaults) => {
    const props = mergeProps({
        type: 'text'
    }, propsWithoutDefaults);
    let inputRef;
    let rootRef;
    const [_error, setError] = createSignal('');
    const error = () => props.error || _error();
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    const [locale] = useLocale();
    const translate = useI18n();
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    function handleBlur(_e) {
        props.onBlur?.();
        // There is a known issue when dealing with input fields that have an auto-focus attribute.
        // If such a field loses focus (onBlur) while containing invalid input, an error message is displayed.
        // The spontaneous appearance of this message can lead to unexpected shifting of the webpage's layout.
        // This shift, or 'jump', can interfere with the functionality of some onClick events.
        // validate(props.value || e.currentTarget.value);
    }
    function handleChange(e) {
        formContextValue && formContextValue.clearError();
        props.onChange && props.onChange(e.target.value);
        if (validatedOnce()) {
            validate(props.value || e.currentTarget.value);
        }
    }
    function handleKeyDown(e) {
        if (formContextValue && e.code === 'Enter') {
            formContextValue.submit();
        }
    }
    createEffect(() => {
        if (props.match && validatedOnce()) {
            if (inputRef.value !== props.match) {
                setError(translate('passwords_dont_match'));
            }
            else {
                validate(props.value || inputRef.value);
            }
        }
    });
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate(props.value || inputRef.value);
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    function validate(value) {
        setValidatedOnce(true);
        if (props.required && !value.trim()) {
            setError(translate('fill_in_field'));
            return;
        }
        if (props.match && value !== props.match) {
            setError(translate('passwords_dont_match'));
            return;
        }
        if (props.minLength && value.trim().length < props.minLength) {
            setError(translate('invalid_min_length', { minLength: props.minLength }));
            return;
        }
        if (props.maxLength && value.trim().length < props.maxLength) {
            setError(translate('invalid_max_length', { maxLength: props.maxLength }));
            return;
        }
        switch (props.type) {
            case 'money':
                {
                    const amount = parseInt(value);
                    if (isNaN(amount) || amount < 0) {
                        setError(translate('invalid_monetary_amount'));
                        return;
                    }
                    const parsedNumber = parseNumber(value);
                    if (isNullOrUndefined(parsedNumber) || !new Decimal(parsedNumber).equals(parsedNumber)) {
                        switch (locale.numberFormat) {
                            case NumberFormat.CommaPeriod:
                                setError(translate('invalid_number_period_for_decimals'));
                                break;
                            case NumberFormat.PeriodComma:
                                setError(translate('invalid_number_comma_for_decimals'));
                                break;
                            case NumberFormat.SpaceComma:
                                setError(translate('invalid_number_comma_for_decimals'));
                                break;
                        }
                        return;
                    }
                }
                break;
            case 'percentage':
                {
                    const percentage = parseInt(value);
                    if (isNaN(percentage) || percentage < 0 || percentage > 100) {
                        setError(translate('invalid_percentage'));
                        return;
                    }
                    const parsedNumber = parseNumber(value);
                    if (isNullOrUndefined(parsedNumber) || !new Decimal(parsedNumber).equals(parsedNumber)) {
                        switch (locale.numberFormat) {
                            case NumberFormat.CommaPeriod:
                                setError(translate('invalid_number_period_for_decimals'));
                                break;
                            case NumberFormat.PeriodComma:
                                setError(translate('invalid_number_comma_for_decimals'));
                                break;
                            case NumberFormat.SpaceComma:
                                setError(translate('invalid_number_comma_for_decimals'));
                                break;
                        }
                        return;
                    }
                }
                break;
            case 'email':
                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!regex.test(value)) {
                    setError(translate('invalid_email'));
                    return;
                }
                break;
            case 'new_password':
                if (value.trim() !== value) {
                    setError(translate('password_no_lead_trail_spaces'));
                    return;
                }
                const defaultMinLength = 12;
                if (!props.minLength && value.length < defaultMinLength) {
                    setError(translate('invalid_min_length', { minLength: defaultMinLength }));
                    return;
                }
                const defaultMaxLength = 72;
                if (!props.maxLength && value.length > defaultMaxLength) {
                    setError(translate('invalid_max_length', { maxLength: defaultMaxLength }));
                    return;
                }
                break;
        }
        setError('');
    }
    function convertTypePropToInputTypeAttribute(type) {
        switch (type) {
            default:
                return 'text';
            case 'email':
                return 'email';
            case 'password':
                return 'password';
            case 'new_password':
                return 'password';
        }
    }
    return (<div ref={rootRef} class={styles.textField} classList={{ [styles.hasError]: props.hasError || !!error() }}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      <Show when={!props.readOnly} fallback={props.value}>
        <div class={styles.inputContainerAndCloseIcon}>
          <div class={styles.inputContainer}>
            <div class={styles.inputAndIcon}>
              <input autocomplete={props.type === 'email' ? 'email' : undefined} onBlur={handleBlur} onChange={handleChange} onInput={handleChange} onKeyDown={handleKeyDown} placeholder={props.placeholder ?? ''} class={styles.input} ref={el => { props.ref = el; inputRef = el; }} type={convertTypePropToInputTypeAttribute(props.type)} value={props.value || ''}/>

              <Show when={props.icon === 'search'}>
                <svg class={classNames(styles.icon, "icon")} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-0.78-3.887-2.197-5.303s-3.3-2.197-5.303-2.197-3.887 0.78-5.303 2.197-2.197 3.3-2.197 5.303 0.78 3.887 2.197 5.303 3.3 2.197 5.303 2.197c1.726 0 3.362-0.579 4.688-1.645l5.943 6.483c0.099 0.108 0.233 0.162 0.369 0.162 0.121 0 0.242-0.043 0.338-0.131 0.204-0.187 0.217-0.503 0.031-0.706zM1 7.5c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5-6.5-2.916-6.5-6.5z" fill="#000000"></path>
                </svg>
              </Show>
            </div>

            <div class={styles.borderBottom}/>
          </div>

          <Show when={props.onClose}>
            <IconButton onClick={props.onClose}>
              <svg class={classNames(styles.closeIcon, "icon")} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10.707 10.5l8.646-8.646c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-8.646 8.646-8.646-8.646c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l8.646 8.646-8.646 8.646c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l8.646-8.646 8.646 8.646c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-8.646-8.646z" fill="#000000"></path>
              </svg>
            </IconButton>
          </Show>
        </div>

        <FieldError error={error()}/>
      </Show>
    </div>);
};
export default TextField;
