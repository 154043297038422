import resetPasswordRequestMutation from '@auth/graphql/mutations/resetPasswordRequestMutation';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding } from '@subtile/project-shared';
import { Button, ButtonType, Panel, TextField, spacers } from '@subtile/universal-ui';
import { useI18n } from 'solid-compose';
import { Show, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
;
const ResetPasswordRequest = () => {
    const [fields, setFields] = createStore({
        email: ''
    });
    const [requested, setRequested] = createSignal(false);
    const translate = useI18n();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const input = {
            email: fields.email
        };
        const { resetPasswordRequest: data } = await resetPasswordRequestMutation.execute({ input }).catch((e) => {
            onUnexpectedError();
            throw e;
        });
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('ResetPasswordRequestSuccess', () => {
            setRequested(true);
        })
            .handle();
    };
    return (<Show when={requested()} fallback={<Onboarding.FormPage title={translate('reset_password_request_title')} description={translate('reset_password_request_description')} buttonText={translate('reset_password_request_button')} onSubmit={handleSubmit} errorWidth="firstElementChild">
        <Panel>
          <spacers.formFields>
            <TextField label={translate('email')} onChange={value => setFields('email', value)} required type="email" value={fields.email}/>
          </spacers.formFields>
        </Panel>
      </Onboarding.FormPage>}>
      <Onboarding.Page title={translate('reset_password_requested_title')} description={translate('reset_password_requested_description')}>
        <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
          {translate('back_to_homepage')}
        </Button>
      </Onboarding.Page>
    </Show>);
};
export default ResetPasswordRequest;
