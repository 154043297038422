import updateUserProfileMutation from '@auth/graphql/mutations/updateUserProfileMutation';
import { Route, getRoute } from '@auth/routes';
import { useNavigate } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse } from '@subtile/project-shared';
import { ToggleButton, spacers } from '@subtile/universal-ui';
import classNames from 'classnames';
import { useI18n, useTheme } from 'solid-compose';
import styles from './SelectTheme.module.css';
const SelectTheme = () => {
    const translate = useI18n();
    const [colorScheme, setColorScheme] = useTheme();
    const navigate = useNavigate();
    const handleSubmit = async (onSuccess, _onExpectedError, onUnexpectedError) => {
        const input = {
            colorScheme: colorScheme()
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(updateUserProfileMutation.execute({ input }), { onError });
        if (!response) {
            throw new Error();
        }
        const { updateUserProfile: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('User', () => {
            navigate(getRoute(Route.ProfileCompletionCheck));
        })
            .handle();
    };
    return (<Onboarding.FormPage title={translate('select_theme_mode_title')} buttonText={translate('continue')} onSubmit={handleSubmit} errorWidth="firstElementChild">
      <spacers.center>
        <div class={styles.toggleButtonsWrapper}>
          <ToggleButton.Group onChange={setColorScheme} selectedValue={colorScheme()}>
            <ToggleButton value="DARK">
              <svg class={classNames(styles.icon, "icon")} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10.339 20c-2.762 0-5.358-1.076-7.311-3.028s-3.028-4.549-3.028-7.311 1.075-5.358 3.028-7.311c1.016-1.016 2.205-1.795 3.534-2.316 0.198-0.077 0.423-0.022 0.561 0.139s0.161 0.391 0.056 0.575c-0.946 1.652-1.334 3.609-1.093 5.509 0.248 1.96 1.12 3.737 2.52 5.138 1.671 1.671 3.892 2.591 6.254 2.591 1.542 0 3.060-0.402 4.391-1.164 0.184-0.105 0.415-0.083 0.576 0.055s0.216 0.363 0.139 0.561c-0.521 1.329-1.3 2.518-2.316 3.534-1.953 1.953-4.549 3.028-7.311 3.028zM5.687 1.56c-0.709 0.407-1.362 0.908-1.951 1.497-1.764 1.764-2.735 4.109-2.735 6.604s0.971 4.84 2.735 6.604c1.764 1.764 4.109 2.735 6.603 2.735s4.839-0.971 6.603-2.735c0.588-0.589 1.089-1.241 1.496-1.95-1.135 0.441-2.35 0.671-3.578 0.671-2.63 0-5.103-1.024-6.962-2.884-1.559-1.559-2.529-3.537-2.805-5.719-0.206-1.631 0.004-3.3 0.594-4.822z" fill="#000000"></path>
              </svg>
              {translate('dark_mode')}
            </ToggleButton>
            <ToggleButton value="LIGHT">
              <svg class={classNames(styles.icon, "icon")} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.5 5c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5z" fill="#000000"></path>
                <path d="M3.5 11h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill="#000000"></path>
                <path d="M18.5 11h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill="#000000"></path>
                <path d="M9.5 20c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5z" fill="#000000"></path>
                <path d="M9.5 7.007c0.935 0 1.814 0.363 2.475 1.024s1.025 1.536 1.025 2.469-0.364 1.809-1.025 2.469c-0.661 0.66-1.54 1.024-2.475 1.024s-1.814-0.363-2.475-1.024c-0.661-0.66-1.025-1.536-1.025-2.469s0.364-1.809 1.025-2.469c0.661-0.66 1.54-1.024 2.475-1.024zM9.5 6.007c-2.485 0-4.5 2.011-4.5 4.493s2.015 4.493 4.5 4.493 4.5-2.011 4.5-4.493c0-2.481-2.015-4.493-4.5-4.493v0z" fill="#000000"></path>
                <path d="M5.507 6.507c-0.128 0-0.256-0.049-0.354-0.146l-2.121-2.121c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2.121 2.121c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146z" fill="#000000"></path>
                <path d="M3.386 17.614c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l2.121-2.121c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-2.121 2.121c-0.098 0.098-0.226 0.146-0.354 0.146z" fill="#000000"></path>
                <path d="M13.493 6.507c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l2.121-2.121c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-2.121 2.121c-0.098 0.098-0.226 0.146-0.354 0.146z" fill="#000000"></path>
                <path d="M15.614 17.614c-0.128 0-0.256-0.049-0.354-0.146l-2.121-2.121c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2.121 2.121c0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146z" fill="#000000"></path>
              </svg>
              {translate('light_mode')}
            </ToggleButton>
          </ToggleButton.Group>
        </div>
      </spacers.center>
    </Onboarding.FormPage>);
};
export default SelectTheme;
