import { createRoot, createSignal, onCleanup } from 'solid-js';
import { throwIfNoOwner } from '../utils';
import { createDynamicQueryResource, createQueryResource } from './createQueryResource';
const resourceMap = new Map();
function getOrAddResource(queryExecutor) {
    let mapEntry = resourceMap.get(queryExecutor);
    if (!mapEntry) {
        createRoot((dispose) => {
            resourceMap.set(queryExecutor, {
                resource: createQueryResource(queryExecutor.document, queryExecutor.variables),
                consumerCount: 1,
                rootDisposer: dispose
            });
        });
    }
    else {
        resourceMap.set(queryExecutor, {
            ...mapEntry,
            consumerCount: ++mapEntry.consumerCount
        });
    }
    return resourceMap.get(queryExecutor).resource;
}
function deleteResource(queryExecutor) {
    const mapEntry = resourceMap.get(queryExecutor);
    if (!mapEntry) {
        throw new Error();
    }
    mapEntry.rootDisposer();
    resourceMap.delete(queryExecutor);
}
export function useQueryResource(document, variables_) {
    throwIfNoOwner();
    const variables = variables_ ?? {};
    const queryExecutor = document.getQueryExecutor(variables);
    const resource = getOrAddResource(queryExecutor);
    onCleanup(() => {
        let resourceMapEntry = resourceMap.get(queryExecutor);
        if (!resourceMapEntry) {
            throw new Error();
        }
        const consumerCount = --resourceMapEntry.consumerCount;
        if (consumerCount === 0) {
            deleteResource(queryExecutor);
        }
        else {
            resourceMap.set(queryExecutor, {
                ...resourceMapEntry,
                consumerCount
            });
        }
    });
    return resource;
}
const dynamicResourceMap = new Map();
function getOrAddDynamicResource(document, initialVariables) {
    let mapEntry = dynamicResourceMap.get(document);
    if (!mapEntry) {
        createRoot((dispose) => {
            const variablesSignal = createSignal(initialVariables || null);
            dynamicResourceMap.set(document, {
                resource: createDynamicQueryResource(document, variablesSignal[0]),
                variablesSignal,
                consumerCount: 1,
                rootDisposer: dispose
            });
        });
    }
    else {
        dynamicResourceMap.set(document, {
            ...mapEntry,
            consumerCount: ++mapEntry.consumerCount
        });
    }
    const [resource, { refetch }] = dynamicResourceMap.get(document).resource;
    const variablesSignal = dynamicResourceMap.get(document).variablesSignal;
    return [resource, { refetch, useVariables: variablesSignal }];
}
function deleteDynamicResource(document) {
    let mapEntry = dynamicResourceMap.get(document);
    if (!mapEntry) {
        throw new Error();
    }
    mapEntry.rootDisposer();
    dynamicResourceMap.delete(document);
}
export function useDynamicQueryResource(document, initialVariables) {
    throwIfNoOwner();
    const resource = getOrAddDynamicResource(document, initialVariables);
    onCleanup(() => {
        let resourceMapEntry = dynamicResourceMap.get(document);
        if (!resourceMapEntry) {
            throw new Error();
        }
        const consumerCount = --resourceMapEntry.consumerCount;
        if (consumerCount === 0) {
            deleteDynamicResource(document);
        }
        else {
            dynamicResourceMap.set(document, {
                ...resourceMapEntry,
                consumerCount
            });
        }
    });
    return resource;
}
