import { Document, findGraphQLErrorByCode } from 'fluent-graphql';
import { AuthenticationStatus } from 'solid-compose';
import GraphQLErrorCode from '../graphql/GraphQLErrorCode';
import useCurrentUser from '../useCurrentUser';
import csrf from './csrf';
export default async function processResponse(promise, callbacks = {}) {
    return promise
        .then((result) => (callbacks.onSuccess) ? callbacks.onSuccess() : result)
        .catch(async (error) => {
        if (findGraphQLErrorByCode(error, GraphQLErrorCode.Unauthenticated)
            || [403, '403'].includes(error?.response?.status)) {
            const [currentUser, { refetchCurrentUser, authenticationStatus }] = useCurrentUser();
            Document.clearAllQueries();
            csrf.clearToken();
            await refetchCurrentUser();
            if (authenticationStatus() === AuthenticationStatus.Unauthenticated) {
                callbacks.onUnauthenticated?.();
                return;
            }
            if (currentUser.error) {
                callbacks.onError?.(currentUser.error);
                return;
            }
        }
        callbacks.onError?.(error);
    });
}
