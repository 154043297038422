import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document, FetchStrategy } from 'fluent-graphql';
import { fetchAccount, fetchCurrency, fetchExpenseType, fetchWallet } from '../resources/useOrganizationsResource';
import type { Entity, Expenses, PaginationInput } from '../types';

export interface ExpensesReturn {
  me: { organization: { expenses: Expenses } };
}

export interface ExpensesVars {
  organizationId: string;
  pagination: unknown;
  filters: unknown;
}

export default Document
  .query<ExpensesReturn, ExpensesVars, Expenses>('Expenses')
    .variableDefinitions({ organizationId: 'ID!', pagination: 'PaginationInput!', filters: 'ExpensesFiltersInput' })
    .viewer('me')
      .entity('organization', 'Organization')
        .useVariables({ id: 'organizationId' })
        .wrapper('expenses')
          .useVariables({ expensesPagination: 'pagination', expensesFilters: 'filters' })
          .embed('pagination')
            .scalar('cursorDirection')
            .scalar('cursorForEntriesAfter')
            .scalar('cursorForEntriesBefore')
            .scalar('maxPageSize')
            .scalar('hasMoreEntries')._
          .entitySet('paginatedEntries', 'Expense')
            .scalar('organizationId')
            .scalar('date', Temporal.PlainDate.from)
            .scalar('updatedAt', Temporal.Instant.from)
            .scalar('deleted')
            .virtual('loading', false)
            .scalar('amount')
            .scalar('description')
            .scalar('reference')
            .entity('wallet', 'Wallet')
              .scalar('name')
              .scalar('colorTag')
              .deriveFromReference('walletId', fetchWallet)
              .replaceEntity({
                Wallet: (wallet: Entity, _variables: unknown, expense: { walletId: string }) => {
                  // console.log('--- expense ---', expense);
                  return expense.walletId === wallet.id;
                }
              })._
            .entity('account', 'Account')
              .scalar('name')
              .scalar('colorTag')
              .deriveFromReference('accountId', fetchAccount)
              .replaceEntity({
                Account: (account: Entity, _variables: unknown, expense: { accountId: string }) => {
                  // console.log('--- expense ---', expense);
                  return expense.accountId === account.id;
                }
              })._
            .entity('expenseType', 'ExpenseType')
              .scalar('name')
              .deriveFromReference('expenseTypeId', fetchExpenseType)
              .replaceEntity({
                ExpenseType: (expenseType: Entity, _variables: unknown, expense: { expenseTypeId: string }) => {
                  // console.log('--- expense ---', expense);
                  return expense.expenseTypeId === expenseType.id;
                }
              })._
            .entity('currency', 'Currency')
              .scalar('name')
              .scalar('precision')
              .scalar('code')
              .deriveFromReference('currencyId', fetchCurrency)
              .replaceEntity({
                Currency: (currency: Entity, _variables: unknown, expense: { currencyId: string }) => {
                  // console.log('--- expense ---', expense);
                  return expense.currencyId === currency.id;
                }
              })._._._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organization: { expenses } } }) => expenses)
  .scopeByTenants(({ organizationId }) => ({ organizationId }))
  .setRefetchStrategy(FetchStrategy.FetchFromNetworkAndRecreateCache)
  .clearAfter((variables: { pagination: PaginationInput }) => {
    if (variables.pagination?.cursor) {
      return Temporal.Duration.from({ minutes: 15 });
    }
  });
