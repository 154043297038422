import styles from './Toolbar.module.css';
const Toolbar = (props) => {
    return (<div class={styles.controls}>
      <div class={styles.start}>
        {props.start}
      </div>

      <div class={styles.end}>
        {props.end}
      </div>
    </div>);
};
export default Toolbar;
