import classNames from 'classnames';
import { Match, Switch } from 'solid-js';
import styles from './Button.module.css';
import EmailIcon from './email/Icon';
import GoogleIcon from './google/Icon';
const GoogleButton = (props) => {
    function handleClick() {
        props.onClick();
    }
    function handleInputKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onClick();
        }
    }
    return (<div class={classNames(styles.button, styles[props.provider], "no-autofocus")} onClick={handleClick} onKeyDown={handleInputKeyDown} tabindex="0">
      <div class={styles.iconWrapper}>
        <Switch>
          <Match when={props.provider === 'google'}>
            <GoogleIcon />
          </Match>
          <Match when={props.provider === 'email'}>
            <EmailIcon />
          </Match>
        </Switch>
      </div>

      <div class={styles.text}>{props.children}</div>
    </div>);
};
export default GoogleButton;
