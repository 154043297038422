import { useI18n, useLocale } from 'solid-compose';
import { Show } from 'solid-js';
import styles from './MonthNavigation.module.css';
const MonthNavigation = (props) => {
    const [locale] = useLocale();
    const translate = useI18n();
    function handlePreviousMonthClick() {
        // https://tc39.es/proposal-temporal/docs/#Temporal-PlainYearMonth
        props.setYearMonth((yearMonth) => yearMonth.subtract({ months: 1 }));
    }
    function handleNextMonthClick() {
        // https://tc39.es/proposal-temporal/docs/#Temporal-PlainYearMonth
        props.setYearMonth((yearMonth) => yearMonth.add({ months: 1 }));
    }
    // https://tc39.es/proposal-temporal/docs/plainyearmonth.html
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    const yearMonthNameString = () => props.yearMonth.toLocaleString(locale.languageTag, { month: 'long', year: 'numeric' });
    return (<div class={styles.monthNavigation}>
      <span class={styles.yearMonthName}>{yearMonthNameString()}</span>

      <Show when={props.isFirst}>
        <div class={styles.navigationButtons}>
          <div class={styles.previousMonth} onClick={handlePreviousMonthClick}><span title={translate('previous_month')}></span></div>
          <div class={styles.nextMonth} onClick={handleNextMonthClick}><span title={translate('next_month')}></span></div>
        </div>
      </Show>
    </div>);
};
export default MonthNavigation;
