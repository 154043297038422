import { Client } from 'fluent-graphql';
import csrf from './csrf';
export default function createClientFetcher({ httpUrl, wsUrl }) {
    return async () => {
        const csrfToken = await csrf.fetchToken();
        wsUrl = `${wsUrl}${csrfToken}`;
        return new Client({
            http: {
                url: httpUrl,
                credentials: 'include',
                headers: { 'x-csrf-token': csrfToken }
            },
            ws: {
                url: wsUrl
            }
        });
    };
}
