import classNames from 'classnames';
import { isArray } from 'object-array-utils';
import { useI18n, useViewport } from 'solid-compose';
import { For, Match, Show, Switch, batch, createEffect, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import { clickOutside, pressEscape } from '../../helpers/directives';
import Loader from '../loader/Loader';
import NextPrevious from '../navigation/NextPrevious';
import styles from './DataGrid.module.css';
import getPositionActionMenuFun from './getPositionActionMenuFun';
import setDataGridHeight from './setDataGridHeight';
false && setDataGridHeight;
false && clickOutside;
false && pressEscape;
var CursorDirection;
(function (CursorDirection) {
    CursorDirection["After"] = "AFTER";
    CursorDirection["Before"] = "BEFORE";
})(CursorDirection || (CursorDirection = {}));
// virtualization example:
// https://github.com/nchudleigh/solid-virtual-list/blob/main/VirtualList.tsx
function DataGrid(propsWithoutDefaults) {
    let bodyElement;
    const props = mergeProps({
        entryId: (entry) => entry.id,
        maxHeight: 'none',
        nextEntriesNavigationDisabled: false,
        previousEntriesNavigationDisabled: false,
        loading: false
    }, propsWithoutDefaults);
    const translate = useI18n();
    const [shouldShowActionMenu, setShouldShowActionMenu] = createSignal(false);
    const [rowDataForAction, setRowDataForAction] = createSignal();
    const [previousEntriesNavigationDisabled, setPreviousEntriesNavigationDisabled] = createSignal(false);
    const [nextEntriesNavigationDisabled, setNextEntriesNavigationDisabled] = createSignal(false);
    const viewport = useViewport();
    let actionMenuTriggerElement;
    let positionActionMenu;
    let resetPositionActionMenu;
    onMount(() => {
        [positionActionMenu, resetPositionActionMenu] = getPositionActionMenuFun({ bodyElement, styles });
        const handleResize = () => {
            if (actionMenuTriggerElement) {
                positionActionMenu({ actionMenuTriggerElement });
            }
        };
        window.addEventListener('resize', handleResize);
        onCleanup(() => document.body.removeEventListener('resize', handleResize));
    });
    createEffect(() => {
        if (props.loading) {
            batch(() => {
                setNextEntriesNavigationDisabled(true);
                setPreviousEntriesNavigationDisabled(true);
            });
        }
    });
    createEffect(() => {
        if (!props.paginationResponseMeta || !props.setPaginationRequestMeta) {
            return;
        }
        if (props.data
            && props.paginationResponseMeta.cursorDirection === CursorDirection.Before
            && !props.paginationResponseMeta.hasMoreEntries
        // when refetching a page after adding an entry, it's better not to have the 'before' cursor,
        // because this allows a new entry to be shown on the page if the first page is displayed
        // && revenues_.paginatedEntries.length < revenues_.pagination.maxPageSize
        ) {
            props.setPaginationRequestMeta({ cursor: undefined, direction: undefined });
        }
    });
    createEffect(() => {
        if (!props.paginationResponseMeta || props.loading) {
            return;
        }
        batch(() => {
            switch (props.paginationResponseMeta.cursorDirection) {
                case CursorDirection.After:
                    setNextEntriesNavigationDisabled(!props.paginationResponseMeta.hasMoreEntries);
                    setPreviousEntriesNavigationDisabled(!props.paginationRequestMeta.cursor);
                    break;
                case CursorDirection.Before:
                    setPreviousEntriesNavigationDisabled(!props.paginationResponseMeta.hasMoreEntries);
                    setNextEntriesNavigationDisabled(false);
                    break;
                default:
                    throw new Error();
            }
        });
    });
    function handlePreviousEntriesClick() {
        if (!props.paginationResponseMeta || !props.setPaginationRequestMeta) {
            throw new Error();
        }
        const cursorForEntriesBefore = props.paginationResponseMeta.cursorForEntriesBefore;
        if (cursorForEntriesBefore) {
            props.setPaginationRequestMeta({
                cursor: cursorForEntriesBefore,
                direction: CursorDirection.Before
            });
        }
    }
    function handleNextEntriesClick() {
        if (!props.paginationResponseMeta || !props.setPaginationRequestMeta) {
            throw new Error();
        }
        const cursorForEntriesAfter = props.paginationResponseMeta.cursorForEntriesAfter;
        if (cursorForEntriesAfter) {
            props.setPaginationRequestMeta({
                cursor: cursorForEntriesAfter,
                direction: CursorDirection.After
            });
        }
    }
    function handleActionMenuTriggerClick(e, entry) {
        setShouldShowActionMenu(true);
        setRowDataForAction(() => entry);
        actionMenuTriggerElement = e.currentTarget;
        positionActionMenu({ actionMenuTriggerElement });
    }
    function handleRowOver(id) {
        const cellElements = bodyElement.querySelectorAll(`[data-id="${id}"]`);
        cellElements.forEach((element) => element.classList.add(styles.cellHover));
    }
    function handleRowOut(id) {
        const cellElements = bodyElement.querySelectorAll(`[data-id="${id}"]`);
        cellElements.forEach((element) => element.classList.remove(styles.cellHover));
    }
    function handleActionClick(callback) {
        setShouldShowActionMenu(false);
        resetPositionActionMenu();
        callback(rowDataForAction());
    }
    const currentResponsiveLayout = () => viewport.width && props.responsiveLayouts?.[viewport.width];
    const gridTemplateColumns = () => {
        const currentResponsiveLayout_ = currentResponsiveLayout();
        const columnDefinitions = (currentResponsiveLayout_)
            ? currentResponsiveLayout_.columnDefinitions
            : props.columnDefinitions;
        return columnDefinitions.map(({ width }) => width).join(' ') + ((props.actions) ? ' 50px' : '');
    };
    function renderHeaderRow() {
        const currentResponsiveLayout_ = currentResponsiveLayout();
        if (currentResponsiveLayout_) {
            switch (currentResponsiveLayout_.strategy) {
                case 'groupedColumns':
                    return currentResponsiveLayout_.columnDefinitions
                        .map((columnDefinition) => <div class={classNames(styles.headerCell, styles.textStackedVertically)} classList={{ [styles.alignEnd]: columnDefinition.align === 'end' }}>
                {columnDefinition.headers.map((header) => <div>{header}</div>)}
              </div>);
                case 'hideColumns':
                    return currentResponsiveLayout_.columnDefinitions
                        .map((columnDefinition) => <div class={styles.headerCell} classList={{ [styles.alignEnd]: columnDefinition.align === 'end' }}>
                {columnDefinition.header}
              </div>);
            }
        }
        return props.columnDefinitions
            .map((columnDefinition) => <div class={styles.headerCell} classList={{ [styles.alignEnd]: columnDefinition.align === 'end' }}>
          {columnDefinition.header}
        </div>);
    }
    function renderActionMenu(entry) {
        const currentResponsiveLayout_ = currentResponsiveLayout();
        const groupedColumns = currentResponsiveLayout_ && currentResponsiveLayout_.strategy === 'groupedColumns';
        return (<div class={styles.cell} classList={{
                [styles.clickable]: !!props.onRowClick,
                [styles.textStackedVertically]: !!groupedColumns
            }} data-id={props.entryId(entry)}>
        {!props.isRowDataDeleted?.(entry) && !props.isRowDataLoading?.(entry)
                ? <div class={styles.actionMenuTrigger} onClick={e => handleActionMenuTriggerClick(e, entry)}/>
                : null}
      </div>);
    }
    function renderDataRow(entry) {
        const currentResponsiveLayout_ = currentResponsiveLayout();
        if (currentResponsiveLayout_) {
            switch (currentResponsiveLayout_.strategy) {
                case 'groupedColumns':
                    return (<For each={currentResponsiveLayout_.columnDefinitions}>{(columnDefinition, i) => <div class={classNames(styles.cell, styles.textStackedVertically)} classList={{
                                [styles.clickable]: !!props.onRowClick,
                                [styles.deleted]: props.isRowDataDeleted?.(entry),
                                [styles.allowOverflow]: columnDefinition.allowOverflow,
                            }} data-id={props.entryId(entry)} onClick={() => props.onRowClick?.(entry)} onMouseOver={() => props.onRowClick && handleRowOver(props.entryId(entry))} onMouseOut={() => props.onRowClick && handleRowOut(props.entryId(entry))}>
                {(columnDefinition.fields)
                                ? columnDefinition.fields.map((field, j) => <div class={styles.cellInner} classList={{ [styles.alignEnd]: columnDefinition.align === 'end', [styles.allowOverflow]: columnDefinition.allowOverflow }}>
                        {props.isRowDataLoading?.(entry)
                                        ? (i() === 0 && j === 0 ? <Loader /> : '')
                                        : (isArray(field)
                                            ? props.transformers?.[columnDefinition.keys[j]](field.map(v => entry[v]), entry)
                                            : ((columnDefinition.keys)
                                                ? props.transformers?.[columnDefinition.keys[j]](entry[field], entry)
                                                : ((props.transformers?.[field])
                                                    ? props.transformers[field](entry[field], entry)
                                                    : entry[field])))}
                      </div>)
                                : columnDefinition.keys.map((key, j) => <div class={styles.cellInner} classList={{ [styles.alignEnd]: columnDefinition.align === 'end', [styles.allowOverflow]: columnDefinition.allowOverflow }}>
                        {props.isRowDataLoading?.(entry)
                                        ? (i() === 0 && j === 0 ? <Loader /> : '')
                                        : props.transformers?.[key](columnDefinition.fields?.[j], entry)}
                      </div>)}
              </div>}</For>);
            }
        }
        const columnDefinitions = (currentResponsiveLayout_ && currentResponsiveLayout_.strategy === 'hideColumns')
            ? currentResponsiveLayout_.columnDefinitions
            : props.columnDefinitions;
        return (<For each={columnDefinitions}>{(columnDefinition, i) => <div class={styles.cell} classList={{
                    [styles.clickable]: !!props.onRowClick,
                    [styles.deleted]: props.isRowDataDeleted?.(entry),
                    [styles.allowOverflow]: columnDefinition.allowOverflow
                }} data-id={props.entryId(entry)} onClick={() => props.onRowClick?.(entry)} onMouseOver={() => props.onRowClick && handleRowOver(props.entryId(entry))} onMouseOut={() => props.onRowClick && handleRowOut(props.entryId(entry))}>
          <span class={styles.cellInner} classList={{ [styles.alignEnd]: columnDefinition.align === 'end', [styles.allowOverflow]: columnDefinition.allowOverflow }}>
            {props.isRowDataLoading?.(entry)
                    ? (i() === 0 ? <Loader /> : '')
                    : ((columnDefinition.key)
                        ? ((columnDefinition.fields)
                            ? props.transformers[columnDefinition.key](columnDefinition.fields.map(v => entry[v]), entry)
                            : props.transformers[columnDefinition.key](entry[columnDefinition.field], entry))
                        : ((props.transformers?.[columnDefinition.field])
                            ? props.transformers[columnDefinition.field](entry[columnDefinition.field], entry)
                            : entry[columnDefinition.field]))}
          </span>
        </div>}</For>);
    }
    return (<div class={styles.dataGrid} use:setDataGridHeight={[styles, props.maxHeight, () => props.loading, () => props.data]}>
      <div class={styles.header} style={{
            'grid-template-columns': gridTemplateColumns()
        }}>
        {renderHeaderRow()}

        <Show when={props.actions}>
          <div />
        </Show>
      </div>

      <div class={styles.body} ref={bodyElement} style={{
            'max-height': props.maxHeight
        }}>
        <Show when={!props.loading} fallback={<div style={{
                'padding-top': '20px'
            }}>
            <Loader />
          </div>}>
          <div style={{
            display: 'grid',
            'grid-template-columns': gridTemplateColumns()
        }}>
            <For each={props.data}>{(entry) => <>
                {renderDataRow(entry)}

                <Show when={props.actions}>
                  {renderActionMenu(entry)}
                </Show>
              </>}</For>
          </div>

          <Show when={props.actions}>
            <div class={styles.actionMenu} classList={{
            [styles.shouldShow]: shouldShowActionMenu()
        }} use:clickOutside={() => setShouldShowActionMenu(false)} use:pressEscape={() => setShouldShowActionMenu(false)}>
            <For each={props.actions}>{({ content, onClick }) => <div class={styles.action} onClick={() => handleActionClick(onClick)}>{content}</div>}</For>
            </div>
          </Show>
        </Show>
      </div>

      <div class={styles.footer}>
        <Switch>
          <Match when={props.onNextEntriesClick && props.onPreviousEntriesClick}>
            <NextPrevious onPreviousClick={props.onPreviousEntriesClick} onNextClick={props.onNextEntriesClick} nextNavigationDisabled={props.nextEntriesNavigationDisabled} previousNavigationDisabled={props.previousEntriesNavigationDisabled} tooltipTextPrevious={translate('previous_entries')} tooltipTextNext={translate('next_entries')}/>
          </Match>

          <Match when={props.setPaginationRequestMeta}>
            <NextPrevious onPreviousClick={handlePreviousEntriesClick} onNextClick={handleNextEntriesClick} nextNavigationDisabled={nextEntriesNavigationDisabled()} previousNavigationDisabled={previousEntriesNavigationDisabled()} tooltipTextPrevious={translate('previous_entries')} tooltipTextNext={translate('next_entries')}/>
          </Match>
        </Switch>
      </div>
    </div>);
}
;
export default DataGrid;
