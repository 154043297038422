import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import type { Errors, ExpenseType, TypedObject } from '../types';

export interface UpdateExpenseTypeReturn {
  updateExpenseType: ExpenseType | { name: string } & TypedObject | Errors;
}

interface UpdateExpenseTypeVars {
  organizationId: string;
  expenseTypeId: string;
  input: unknown;
}

export default Document
  .mutation<UpdateExpenseTypeReturn, UpdateExpenseTypeVars>('UpdateExpenseType')
    .variableDefinitions({ organizationId: 'ID!', expenseTypeId: 'ID!', input: 'ExpenseTypeInput!' })
    .union('updateExpenseType')
      .useVariables({ organizationId: 'organizationId',  expenseTypeId: 'expenseTypeId', input: 'input' })
      .onEntity('ExpenseType')
        .scalar('name')
        .scalar('archived')
        .scalar('organizationId')._
      .onTypedObject('NameAlreadyUsed')
        .scalar('name')._
      .onTypedObject('InputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
