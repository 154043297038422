import { useI18n } from 'solid-compose';
import { Show, batch, createSignal, onCleanup, onMount } from 'solid-js';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import styles from '../text-field/TextField.module.css';
const TextArea = (props) => {
    const [error, setError] = createSignal('');
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    let rootRef;
    const translate = useI18n();
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    function handleChange(e) {
        formContextValue && formContextValue.clearError();
        props.onChange(e.target.value);
        if (validatedOnce()) {
            validate();
        }
    }
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate();
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    function validate() {
        setValidatedOnce(true);
        if (props.required && !props.value) {
            setError(translate('fill_in_field'));
            return;
        }
        setError('');
    }
    return (<div ref={rootRef} class={styles.textField} classList={{ [styles.hasError]: !!error() }}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      <Show when={!props.readOnly} fallback={props.value}>
        <div class={styles.inputContainer}>
          <textarea onChange={handleChange} onInput={handleChange} rows={props.rows} class={styles.input} classList={{ [styles.hasError]: !!error() }} value={props.value || ''}/>
          <div class={styles.borderBottom}/>
        </div>

        <FieldError error={error()}/>
      </Show>
    </div>);
};
export default TextArea;
