import { DateEndianness, formatDate, useI18n, useLocale } from 'solid-compose';
import { Show, batch, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import TextField from '../text-field/TextField';
import styles from './DateField.module.css';
import validateDate from './validateDate';
let dayInputElement;
let monthInputElement;
let yearInputElement;
const DateField = (props) => {
    let rootRef;
    const [locale] = useLocale();
    const translate = useI18n();
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    const [day, setDay] = createSignal(String(props.value?.day || ''));
    const [month, setMonth] = createSignal(String(props.value?.month || ''));
    const [year, setYear] = createSignal(String(props.value?.year || ''));
    const [invalidDay, setInvalidDay] = createSignal(false);
    const [invalidMonth, setInvalidMonth] = createSignal(false);
    const [invalidYear, setInvalidYear] = createSignal(false);
    const [error, setError] = createSignal('');
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    createEffect(() => {
        batch(() => {
            if (props.value) {
                setDay(String(props.value.day));
                setMonth(String(props.value.month));
                setYear(String(props.value.year));
                setError('');
            }
        });
    });
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate();
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    createEffect(() => {
        const [year_, month_, day_] = [year(), month(), day()];
        if (year_ && month_ && day_) {
            const { date, invalidDateComponents } = validateDate(year_, month_, day_);
            if (date && invalidDateComponents.length === 0) {
                props.onChange(date);
            }
        }
    });
    function updateErrors() {
        if (!validatedOnce()) {
            return;
        }
        const [invalidYear_, invalidMonth_, invalidDay_] = [invalidYear(), invalidMonth(), invalidDay()];
        if (invalidYear_ || invalidMonth_ || invalidDay_) {
            const dayNumber = Number(day());
            const monthNumber = Number(month());
            const yearNumber = Number(year());
            if (!dayNumber || !monthNumber || !yearNumber) {
                setError(translate('invalid_date'));
                return;
            }
            if (dayNumber > 31 || monthNumber > 12 || yearNumber < 1900) {
                switch (locale.dateFormat.endianness) {
                    case DateEndianness.LittleEndian:
                        setError(translate('invalid_date_little_endian'));
                        break;
                    case DateEndianness.MiddleEndian:
                        setError(translate('invalid_date_middle_endian'));
                        break;
                    case DateEndianness.BigEndian:
                        setError(translate('invalid_date_big_endian'));
                        break;
                }
                return;
            }
            setError(translate('invalid_date'));
            return;
        }
        setError('');
    }
    function allEmpty() {
        return day() === '' && month() === '' && year() === '';
    }
    function handleDayChange(value) {
        setDay(value);
        formContextValue && formContextValue.clearError();
        if (validatedOnce()) {
            validate();
        }
    }
    function handleMonthChange(value) {
        setMonth(value);
        formContextValue && formContextValue.clearError();
        if (validatedOnce()) {
            validate();
        }
    }
    function handleYearChange(value) {
        setYear(value);
        formContextValue && formContextValue.clearError();
        if (validatedOnce()) {
            validate();
        }
    }
    function validate() {
        setValidatedOnce(true);
        const [year_, month_, day_] = [year(), month(), day()];
        if (allEmpty() && !props.required) {
            batch(() => {
                setInvalidDay(false);
                setInvalidMonth(false);
                setInvalidYear(false);
                updateErrors();
            });
            return;
        }
        batch(() => {
            const { invalidDateComponents } = validateDate(year_, month_, day_);
            if (document.activeElement !== dayInputElement && day() !== '') {
                setInvalidDay(invalidDateComponents.includes('day'));
            }
            if (document.activeElement !== monthInputElement && month() !== '') {
                setInvalidMonth(invalidDateComponents.includes('month'));
            }
            if (document.activeElement !== yearInputElement && year() !== '') {
                setInvalidYear(invalidDateComponents.includes('year'));
            }
            if (document.activeElement !== dayInputElement
                && document.activeElement !== monthInputElement
                && document.activeElement !== yearInputElement) {
                setInvalidDay(invalidDateComponents.includes('day'));
                setInvalidMonth(invalidDateComponents.includes('month'));
                setInvalidYear(invalidDateComponents.includes('year'));
            }
        });
        updateErrors();
    }
    function renderDayField() {
        return (<div class={styles.dayFieldWrapper}>
        <TextField hasError={!!error() && invalidDay()} onChange={handleDayChange} placeholder={translate('dd')} ref={dayInputElement} value={day()}/>
      </div>);
    }
    function renderMonthField() {
        return (<div class={styles.monthFieldWrapper}>
        <TextField hasError={!!error() && invalidMonth()} onChange={handleMonthChange} placeholder={translate('mm')} ref={monthInputElement} value={month()}/>
      </div>);
    }
    function renderYearField() {
        return (<div class={styles.yearFieldWrapper}>
        <TextField hasError={!!error() && invalidYear()} onChange={handleYearChange} placeholder={translate('yyyy')} ref={yearInputElement} value={year()}/>
      </div>);
    }
    function renderFields() {
        switch (locale.dateFormat.endianness) {
            case DateEndianness.LittleEndian:
                return <>
          {renderDayField()}
          {renderMonthField()}
          {renderYearField()}
        </>;
            case DateEndianness.MiddleEndian:
                return <>
          {renderMonthField()}
          {renderDayField()}
          {renderYearField()}
        </>;
            case DateEndianness.BigEndian:
                return <>
          {renderYearField()}
          {renderMonthField()}
          {renderDayField()}
        </>;
        }
    }
    return (<div ref={rootRef} class={styles.dateField} classList={{ [styles.hasError]: !!error() }}>
      <Show when={props.label}>
        <div class={styles.label}>
          {props.label}
        </div>
      </Show>

      <Show when={!props.readOnly} fallback={(props.value) ? formatDate(props.value) : ''}>
        <div class={styles.textFieldsContainer}>
          {renderFields()}
        </div>

        <FieldError error={error()}/>
      </Show>
    </div>);
};
export default DateField;
