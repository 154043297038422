import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { Revenue } from '../types';

export interface DeleteRevenueReturn {
  deleteRevenue: Pick<Revenue, 'id' | '__typename' | 'deleted'>;
}

interface DeleteRevenueVars {
  organizationId: string;
  revenueId: string;
}

export default Document
  .mutation<DeleteRevenueReturn, DeleteRevenueVars>('DeleteRevenue')
    .variableDefinitions({ organizationId: 'ID!', revenueId: 'ID!' })
    .union('deleteRevenue')
      .useVariables({ organizationId: 'organizationId', revenueId: 'revenueId' })
      .onEntity('Revenue')
        .scalar('organizationId')
        .scalar('deleted')._
      .onTypedObject('NegativeAccountBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._
      .onTypedObject('NegativeWalletBalance')
        .scalar('name')
        .scalar('currencyName')
        .scalar('balanceAmount')._._._
  .makeExecutable(clientFetcher)
  .afterExecution(({ deleteRevenue: data }) => {
    if (data.__typename === 'Revenue') {
      Document.clearQueries([
        'Revenues',
        'WalletTransfers',
        'WalletTransactions',
        'AccountTransactions',
        'Wallets',
        'Accounts'
      ]);
    }
  });
