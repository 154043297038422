const modifiers = {};
export default modifiers;
export function createModifier(name, style) {
    style = {
        display: 'contents',
        ...style
    };
    const Modifier = (props) => {
        return (<div style={style}>
        {props.children}
      </div>);
    };
    Modifier.displayName = name;
    modifiers[name] = Modifier;
    return Modifier;
}
