import { Onboarding as Onboarding_ } from '@subtile/project-shared';
import AddAccounts from '../onboarding/AddAccounts';
import AddExpenseTypes from '../onboarding/AddExpenseTypes';
import AddOrganization from '../onboarding/AddOrganization';
import AddRevenueTypes from '../onboarding/AddRevenueTypes';
import AddWallets from '../onboarding/AddWallets';
import InviteMembers from '../onboarding/InviteMembers';
import Completed from '../onboarding/OnboardingCompleted';
import SelectOrganization from '../onboarding/SelectOrganization';
import SetupOrganization from '../onboarding/SetupOrganization';
import Welcome from '../onboarding/Welcome';
export default Object.assign({}, {
    AddOrganization,
    SetupOrganization,
    Welcome,
    Completed,
    InviteMembers,
    AddWallets,
    AddRevenueTypes,
    AddExpenseTypes,
    AddAccounts,
    SelectOrganization,
    Layout: Onboarding_.Layout
});
