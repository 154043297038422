export default function getScrollableAncestor(element) {
    let currentElement = element.parentElement;
    while (currentElement) {
        const overflowY = getComputedStyle(currentElement).overflowY;
        if (overflowY === 'auto' || overflowY === 'scroll') {
            return currentElement;
        }
        currentElement = currentElement.parentElement;
    }
    return null;
}
