import { Onboarding } from '@subtile/project-shared';
import { useI18n } from 'solid-compose';
import AddNamedEntities from './AddNamedEntities';
const AddWallets = () => {
    const translate = useI18n();
    return (<AddNamedEntities entitiesType="WALLETS" title={translate('add_wallets_title')} submitButtonLabel={translate('add_wallets')} inputLabel={translate('wallet_names')} addEntityButtonLabel={translate('add_another_wallet')} summary={<Onboarding.Description>
          <Onboarding.Note>
            <div>
              {translate('add_wallets_description')}
            </div>
          </Onboarding.Note>

          <Onboarding.Note>
            <div>
              {translate('add_wallets_description_examples')}
            </div>
          </Onboarding.Note>
        </Onboarding.Description>}/>);
};
export default AddWallets;
