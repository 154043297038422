import clientFetcher from '@finance/clientFetcher';
import { Document } from 'fluent-graphql';
import { rejectProperties } from 'object-array-utils';
import { orThrow } from 'or-throw';
import type { Account, Currency, Entity, ExpenseType, Organization, RevenueType, Wallet } from '../types';

interface ExecutionContext {
  organizations: Organization[];
}

function addEntity(entitiesType: string) {
  return (entity: { __typename: string; organizationId: string }, _: unknown, organization: Organization) => {
    if (entitiesType !== entity.__typename) {
      return;
    }
    if (!entity.organizationId) {
      throw new Error(`missing organization ID for type ${entitiesType} in entity ${JSON.stringify(rejectProperties(entity, ['__meta']), null, 2)} and operation ${(entity as any).__meta.operationName}`);
    }
    return entity.organizationId === organization.id;
  }
}

const fetchCurrency = (currencyId: string, _variables: unknown, context: ExecutionContext) => {
  const currencies = context.organizations.flatMap(({ currencies }) => currencies);
  return orThrow(currencies.find(({ id }) => currencyId === id));
};
const fetchWallet = (walletId: string, _variables: unknown, context: ExecutionContext) => {
  const wallets = context.organizations.flatMap(({ wallets }) => wallets);
  return orThrow(wallets.find(({ id }) => walletId === id));
};

export interface OrganizationsQueryReturn {
  me: { organizations: Organization[] };
}

export default Document
  .query<OrganizationsQueryReturn, {}, Organization[]>('Organizations')
    .viewer('me')
      .entitySet('organizations', 'Organization')
        .addEntity({
          Organization: () => true
        })
        .scalar('name')
        .scalar('archived')
        .scalar('completedSetups')
        .entitySet('members', 'Member')
          .virtual('loading', false)
          .addEntity({
            Member: addEntity('Member')
          })
          .scalar('organizationId')
          .entity('user', 'User')
            .scalar('email')
            .scalar('preferredName')._._
        .entitySet('currencies', 'Currency')
          .virtual('loading', false)
          .addEntity({
            Currency: addEntity('Currency')
          })
          .scalar('name')
          .scalar('precision')
          .scalar('code')
          .scalar('archived')
          .scalar('isDefault')
          .scalar('organizationId')._
        .entitySet('revenueTypes', 'RevenueType')
          .virtual('loading', false)
          .entitySet('allocationRules', 'RevenueAllocationRule')
            .scalar('allocationPercentage')
            .entity('targetWallet', 'Wallet')
              .scalar('name')
              .deriveFromReference('targetWalletId', fetchWallet)
              .replaceEntity({
                Wallet: (wallet: Entity, _variables: unknown, allocationRule: { targetWalletId: string }) => {
                  // console.log('--- allocationRule ---', allocationRule);
                  return allocationRule.targetWalletId === wallet.id;
                }
              })._._
          .addEntity({
            RevenueType: addEntity('RevenueType')
          })
          .scalar('name')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('expenseTypes', 'ExpenseType')
          .virtual('loading', false)
          .addEntity({
            ExpenseType: addEntity('ExpenseType')
          })
          .scalar('name')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('accounts', 'Account')
          .virtual('loading', false)
          .addEntity({
            Account: addEntity('Account')
          })
          .entity('currency', 'Currency')
            .scalar('name')
            .scalar('precision')
            .scalar('code')
            .deriveFromReference('currencyId', fetchCurrency)
            .replaceEntity({
              Currency: (currency: Entity, _variables: unknown, account: { currencyId: string }) => {
                // console.log('--- account ---', account);
                return account.currencyId === currency.id;
              }
            })._
          .scalar('name')
          .scalar('colorTag')
          .scalar('balanceAmount')
          .scalar('archived')
          .scalar('organizationId')._
        .entitySet('wallets', 'Wallet')
          .virtual('loading', false)
          .addEntity({
            Wallet: addEntity('Wallet')
          })
          .entity('currency', 'Currency')
            .scalar('name')
            .scalar('precision')
            .scalar('code')
            .deriveFromReference('currencyId', fetchCurrency)
            .replaceEntity({
              Currency: (currency: Entity, _variables: unknown, wallet: { currencyId: string }) => {
                // console.log('--- wallet ---', wallet);
                return wallet.currencyId === currency.id;
              }
            })._
          .scalar('name')
          .scalar('colorTag')
          .scalar('balanceAmount')
          .scalar('archived')
          .scalar('organizationId')._._._._
  .makeExecutable(clientFetcher)
  .transformResponse(({ me: { organizations } }) => {
    return organizations.map((organization: Organization) => {
      return {
        ...organization,
        accounts: organization.accounts.toSorted((a1: Account, a2: Account) => a1.name.localeCompare(a2.name)),
        wallets: organization.wallets.toSorted((w1: Wallet, w2: Wallet) => w1.name.localeCompare(w2.name)),
        revenueTypes: organization.revenueTypes.toSorted((r1: RevenueType, r2: RevenueType) => r1.name.localeCompare(r2.name)),
        expenseTypes: organization.expenseTypes.toSorted((e1: ExpenseType, e2: ExpenseType) => e1.name.localeCompare(e2.name)),
        currencies: organization.currencies.toSorted((c1: Currency, c2: Currency) => {
          if (c1.isDefault) return -1;
          if (c2.isDefault) return 1;
          return c1.name.localeCompare(c2.name);
        })
      }
    });
  })
  .createExecutionContext((_variables, { me: { organizations } }) => ({ organizations }));
