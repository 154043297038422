import { isNullOrUndefined } from 'object-array-utils';
import { ColorScheme, useLocale } from 'solid-compose';
import { Show, mergeProps } from 'solid-js';
import styles from './ColorTag.module.css';
import { getColorByColorName } from './colors';
const ColorTag = (propsWithoutDefaults) => {
    const props = mergeProps({ style: {} }, propsWithoutDefaults);
    const [locale] = useLocale();
    const colorCode = () => {
        if (props.colorCode) {
            return props.colorCode;
        }
        if (props.colorName) {
            const color = getColorByColorName(props.colorName);
            if (color) {
                return (locale.colorScheme === ColorScheme.Dark)
                    ? color.darkModeColor
                    : color.lightModeColor || color.darkModeColor;
            }
        }
        return null;
    };
    return (<Show when={colorCode()} fallback={props.children}>{colorCode => <div class={styles.colorTag} style={{ '--size': props.size }}>
        <div class={styles.color} style={{ 'background-color': colorCode() }}/>

        <Show when={!isNullOrUndefined(props.children)}>
          <span style={props.style}>{props.children}</span>
        </Show>
      </div>}</Show>);
};
export default ColorTag;
