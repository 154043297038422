import logOutUserMutation from '@auth/graphql/mutations/logOutUserMutation';
import { Route, getRoute } from '@auth/routes';
import { useNavigate } from '@solidjs/router';
import { csrf, processResponse, useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { Document } from 'fluent-graphql';
import { AuthenticationStatus, useI18n } from 'solid-compose';
const LogOut = () => {
    const navigate = useNavigate();
    const translate = useI18n();
    const [_currentUser, { authenticationStatus, refetchCurrentUser }] = useCurrentUser();
    async function logOut() {
        await processResponse(logOutUserMutation.execute({}), {
            onError: (e) => {
                alert(translate('operation_failed'));
                setTimeout(() => { throw e; }, 0);
                navigate(getRoute(Route.ProfileCompletionCheck));
            },
            onUnauthenticated: () => {
                navigate(getRoute(Route.ProfileCompletionCheck));
            },
            onSuccess: async () => {
                Document.clearAllQueries();
                csrf.clearToken();
                await refetchCurrentUser();
                if (authenticationStatus() === AuthenticationStatus.Unauthenticated) {
                    navigate(getRoute(Route.ProfileCompletionCheck));
                    return;
                }
                throw new Error('cannot logout user');
            }
        });
    }
    logOut();
    return (<spacers.viewportFill>
      <Loader />
    </spacers.viewportFill>);
};
export default LogOut;
