import clientFetcher from '@auth/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Errors, TypedObject } from '../types';

export interface ResetPasswordReturn {
  resetPassword:
    { currentServerTime: Temporal.Instant } & TypedObject
    | { email: string } & TypedObject
    | Errors;
}

interface ResetPasswordVars {
  token: string;
  input: unknown;
}

export default Document
  .mutation<ResetPasswordReturn, ResetPasswordVars>('ResetPassword')
    .variableDefinitions({ input: 'ResetPasswordInput!', token: 'String!' })
    .union('resetPassword')
      .useVariables({ input: 'input', token: 'token' })
      .onTypedObject('ResetPasswordSuccess')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('TokenInvalidOrExpired')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('PublicInputErrors')
        .scalar('errors')._._._
  .makeExecutable(clientFetcher);
