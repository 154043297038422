import { TextDirection, useLocale } from 'solid-compose';
export default function getPositionActionMenuFun({ bodyElement, styles }) {
    return [
        ({ actionMenuTriggerElement }) => {
            const [locale] = useLocale();
            const actionMenuElement = bodyElement.querySelector(`.${styles.actionMenu}`);
            const actionMenuTriggerRect = actionMenuTriggerElement.getBoundingClientRect();
            const bodyRect = bodyElement.getBoundingClientRect();
            actionMenuElement.style.bottom = 'auto';
            actionMenuElement.style.top = `${actionMenuTriggerRect.bottom - bodyRect.top - 7 + bodyElement.scrollTop}px`;
            if (locale.textDirection === TextDirection.LeftToRight) {
                actionMenuElement.style.left = `${actionMenuTriggerRect.right - bodyRect.left - actionMenuElement.offsetWidth}px`;
                actionMenuElement.style.right = 'auto';
            }
            else {
                actionMenuElement.style.right = `${bodyRect.right - actionMenuElement.offsetWidth - actionMenuTriggerRect.left}px`;
                actionMenuElement.style.left = 'auto';
            }
            let actionMenuRect = actionMenuElement.getBoundingClientRect();
            if (actionMenuRect.bottom > bodyRect.bottom) {
                actionMenuElement.style.bottom = `${-bodyElement.scrollTop + 5}px`;
                actionMenuElement.style.top = 'auto';
                actionMenuRect = actionMenuElement.getBoundingClientRect();
            }
        },
        () => {
            const actionMenuElement = bodyElement.querySelector(`.${styles.actionMenu}`);
            actionMenuElement.style.top = '0';
            actionMenuElement.style.bottom = 'auto';
        }
    ];
}
