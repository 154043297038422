import { Route, getRoute } from '@auth/routes';
import { A, useNavigate, useSearchParams } from '@solidjs/router';
import { Logo } from '@subtile/project-shared';
import { FieldError, Loader, OAuth, spacers } from '@subtile/universal-ui';
import { useI18n, useLocalStorage, useLocale } from 'solid-compose';
import { Match, Show, Switch, createSignal } from 'solid-js';
import styles from './AuthenticationMethodSelector.module.css';
const AuthenticationMethodSelector = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const translate = useI18n();
    const [locale] = useLocale();
    const [clickedOAuthButton, setClickedOAuthButton] = createSignal(false);
    const [_mode, { set: storeMode }] = useLocalStorage('authenticationMode', 'signup');
    storeMode(props.mode);
    const oauthFailureWithProvider = searchParams['oauth-failure-with-provider'];
    function handleGoogleButtonClick() {
        const handlePageShow = (event) => {
            if (event.persisted) {
                window.removeEventListener('pageshow', handlePageShow);
                window.location.reload();
            }
        };
        window.addEventListener('pageshow', handlePageShow);
        setClickedOAuthButton(true);
        document.location.assign(`${import.meta.env.VITE_GOOGLE_OAUTH_URL}?language_tag=${locale.languageTag}`);
    }
    return (<Show when={!clickedOAuthButton()} fallback={<spacers.viewportFill>
        <Loader />
      </spacers.viewportFill>}>
      <div class={styles.authMethodSelector}>
        <div class={styles.logoWrapper}><Logo /></div>

        <div class={styles.title}>
          <Switch>
            <Match when={props.mode === 'login'}>
              {translate('select_login_auth_method_title')}
            </Match>
            <Match when={props.mode === 'signup'}>
              {translate('select_signup_auth_method_title')}
            </Match>
          </Switch>
        </div>

        <Show when={oauthFailureWithProvider}>
          <div class={styles.error}>
            <Switch fallback={<FieldError error={translate('oauth_failed')}/>}>
              <Match when={oauthFailureWithProvider === 'google'}>
                <FieldError error={translate('google_oauth_failed')}/>
              </Match>
            </Switch>
          </div>
        </Show>

        <div style={{ display: 'flex', 'flex-direction': 'column', 'align-items': 'center' }}>
          <OAuth.Container>
            <OAuth.Button onClick={handleGoogleButtonClick} provider="google">
              <span innerHTML={translate('continue_with_google')}/>
            </OAuth.Button>

            <OAuth.Button onClick={() => {
            (props.mode === 'signup')
                ? navigate(getRoute(Route.CreateAccountWithEmail))
                : navigate(getRoute(Route.LogInWithEmail));
        }} provider="email">
              <span innerHTML={translate('continue_with_email')}/>
            </OAuth.Button>
          </OAuth.Container>

          <Switch>
            <Match when={props.mode === 'signup'}>
              <div class={styles.alreadyAnAccount}>
                {translate("already_an_account")}
                {' '}
                <A href={getRoute(Route.SelectLoginAuthMethod)}>{translate("already_an_account_log_in")}</A>
              </div>
            </Match>
            <Match when={props.mode === 'login'}>
              <div class={styles.noAccountYet}>
                {translate("no_account_yet")}
                {' '}
                <A href={getRoute(Route.SelectSignupAuthMethod)}>{translate("create_account")}</A>
              </div>
            </Match>
          </Switch>
        </div>

        <div class={styles.agreeToLegalPolicies} innerHTML={translate('agree_to_legal_policies')}/>
      </div>
    </Show>);
};
export default AuthenticationMethodSelector;
