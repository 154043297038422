import { isNullOrUndefined } from 'object-array-utils';
import RootObject from '../document/RootObject';

export function maybe(transformer) {
  if (typeof transformer !== 'function') {
    throw new Error('maybe(transformer) should receive a function as argument');
  }

  return (value) => isNullOrUndefined(value) ? value : transformer(value);
}

export function throwIfNotInstanceOfDocument(instance) {
  if (!instance.operationType) {
    if (instance instanceof RootObject) {
      throw new Error('Access _ once more to return the document object');
    }
    throw new Error();
  }
}
