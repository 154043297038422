const spacers = {};
export default spacers;
export function createSpacer(name, style) {
    const Spacer = (props) => {
        return (<div style={style}>
        {props.children}
      </div>);
    };
    Spacer.displayName = name;
    spacers[name] = Spacer;
    return Spacer;
}
