import clientFetcher from '@finance/clientFetcher';
import { Temporal } from '@js-temporal/polyfill';
import { Document } from 'fluent-graphql';
import { Wallet } from '../types';

export interface ArchiveWalletReturn {
  archiveWallet: Pick<Wallet, 'id' | '__typename' | 'archived'>;
}

interface ArchiveWalletVars {
  organizationId: string;
  walletId: string;
}

export default Document
  .mutation<ArchiveWalletReturn, ArchiveWalletVars>('ArchiveWallet')
    .variableDefinitions({ organizationId: 'ID!', walletId: 'ID!' })
    .union('archiveWallet')
      .useVariables({ organizationId: 'organizationId', walletId: 'walletId' })
      .onEntity('Wallet')
        .scalar('organizationId')
        .scalar('archived')._
      .onTypedObject('AtLeastOneActiveWalletRequired')
        .scalar('currentServerTime', Temporal.Instant.from)._
      .onTypedObject('CannotArchiveTargetWalletForAllocation')
        .scalar('name')._
      .onTypedObject('NonZeroArchivedWalletBalance')
        .scalar('name')._._._
  .makeExecutable(clientFetcher);
