import acceptInvitationMutation from '@auth/graphql/mutations/acceptInvitationMutation';
import { Route, getRoute } from '@auth/routes';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { MutationResponseHandler, Onboarding, processResponse, useCurrentUser } from '@subtile/project-shared';
import { Button, ButtonType, FormError, Loader, Panel, TextField, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus, useI18n, useLocalStorage } from 'solid-compose';
import { Match, Show, Switch, batch, createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
const AcceptInvitation = () => {
    const navigate = useNavigate();
    const [fields, setFields] = createStore({
        securityCode: ''
    });
    const [requestSuccess, setRequestSuccess] = createSignal(false);
    const [tokenInvalidOrExpired, setTokenInvalidOrExpired] = createSignal(false);
    const [invalidSecurityCode, setInvalidSecurityCode] = createSignal(false);
    const [reachedMaxSecurityCodeAttempts, setReachedMaxSecurityCodeAttempts] = createSignal(false);
    const [emailDoesNotMatchInvitation, setEmailDoesNotMatchInvitation] = createSignal(false);
    const [invitationToken, { set: setInvitationToken, remove: removeInvitationToken }] = useLocalStorage('invitationToken');
    const [registeredEmail] = useLocalStorage('registeredEmail');
    const [searchParams] = useSearchParams();
    const token = searchParams['token'] || invitationToken();
    if (!token) {
        navigate('/');
        return;
    }
    setInvitationToken(token);
    const [currentUser, { authenticationStatus, authenticationError }] = useCurrentUser();
    const translate = useI18n();
    createEffect(() => {
        if (authenticationStatus() === AuthenticationStatus.Unauthenticated) {
            (registeredEmail())
                ? navigate(getRoute(Route.LogInWithEmail))
                : navigate(getRoute(Route.SelectSignupAuthMethod));
            return;
        }
        if (authenticationStatus() === AuthenticationStatus.Errored) {
            throw authenticationError();
        }
    });
    const handleSubmit = async (onSuccess, onExpectedError, onUnexpectedError) => {
        const input = {
            securityCode: fields.securityCode
        };
        const onError = (error) => {
            onUnexpectedError();
            throw error;
        };
        const response = await processResponse(acceptInvitationMutation.execute({ token, input }), { onError });
        if (!response) {
            navigate(getRoute(Route.SelectLoginAuthMethod));
            return;
        }
        const { acceptInvitation: data } = response;
        new MutationResponseHandler(data, onSuccess, onUnexpectedError)
            .onSuccessType('AcceptInvitationSuccess', () => {
            removeInvitationToken();
            setRequestSuccess(true);
        })
            .onExpectedErrorType('TokenInvalidOrExpired', () => {
            batch(() => {
                removeInvitationToken();
                setTokenInvalidOrExpired(true);
                onExpectedError(translate('invitation_token_invalid_or_expired'));
            });
        })
            .onExpectedErrorType('InvalidSecurityCode', () => {
            batch(() => {
                setInvalidSecurityCode(true);
                onExpectedError(translate('invitation_security_code_invalid'));
            });
        })
            .onExpectedErrorType('ReachedMaxSecurityCodeAttempts', () => {
            batch(() => {
                removeInvitationToken();
                setReachedMaxSecurityCodeAttempts(true);
                onExpectedError(translate('reached_max_security_code_attempts'));
            });
        })
            .onExpectedErrorType('EmailDoesNotMatchInvitation', () => {
            batch(() => {
                setEmailDoesNotMatchInvitation(true);
                onExpectedError(translate('email_does_not_match_invitation', { email: currentUser()?.email ?? '…' }));
            });
        })
            .handle();
    };
    return (<Show when={!currentUser.loading} fallback={<spacers.viewportFill>
        <Loader />
      </spacers.viewportFill>}>
      <Switch fallback={<Onboarding.FormPage title={translate('accept_invitation_title')} description={translate('accept_invitation_description')} buttonText={translate('accept_invitation')} onSubmit={handleSubmit} errorWidth="firstElementChild">
          <Panel>
            <spacers.formFields>
              <TextField label={translate('security_code')} onChange={value => setFields('securityCode', value)} required value={fields.securityCode}/>
            </spacers.formFields>
          </Panel>
        </Onboarding.FormPage>}>
        <Match when={tokenInvalidOrExpired()}>
          <Onboarding.Page title={translate('accept_invitation_title')}>
            <FormError>{translate('invitation_token_invalid_or_expired')}</FormError>

            <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
              {translate('back_to_homepage')}
            </Button>
          </Onboarding.Page>
        </Match>

        <Match when={invalidSecurityCode()}>
          <Onboarding.Page title={translate('accept_invitation_title')}>
            <FormError>{translate('invitation_security_code_invalid')}</FormError>

            <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
              {translate('back_to_homepage')}
            </Button>
          </Onboarding.Page>
        </Match>

        <Match when={reachedMaxSecurityCodeAttempts()}>
          <Onboarding.Page title={translate('accept_invitation_title')}>
            <FormError>{translate('reached_max_security_code_attempts')}</FormError>

            <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
              {translate('back_to_homepage')}
            </Button>
          </Onboarding.Page>
        </Match>

        <Match when={emailDoesNotMatchInvitation()}>
          <Onboarding.Page title={translate('accept_invitation_title')}>
            <FormError>{translate('email_does_not_match_invitation', { email: currentUser()?.email ?? '…' })}</FormError>

            <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
              {translate('back_to_homepage')}
            </Button>
          </Onboarding.Page>
        </Match>

        <Match when={requestSuccess()}>
          <Onboarding.Page title={translate('accept_invitation_success_title')} description={translate('accept_invitation_success_description')}>
            <Button onClick={() => navigate('/')} type={ButtonType.Primary}>
              {translate('back_to_homepage')}
            </Button>
          </Onboarding.Page>
        </Match>
      </Switch>
    </Show>);
};
export default AcceptInvitation;
