import { Route, getRoute } from '@auth/routes';
import { Navigate } from '@solidjs/router';
import { useCurrentUser } from '@subtile/project-shared';
import { Loader, spacers } from '@subtile/universal-ui';
import { AuthenticationStatus } from 'solid-compose';
import { Match, Switch } from 'solid-js';
const EnsureUnauthenticated = (props) => {
    const [currentUser, { authenticationStatus, authenticationError }] = useCurrentUser();
    function throwError() {
        throw authenticationError();
    }
    return (<Switch>
      <Match when={currentUser.loading}>
        <spacers.viewportFill>
          <Loader />
        </spacers.viewportFill>
      </Match>
      <Match when={authenticationStatus() === AuthenticationStatus.Errored}>
        {throwError()}
      </Match>
      <Match when={authenticationStatus() === AuthenticationStatus.Unauthenticated}>
        {props.children}
      </Match>
      <Match when={authenticationStatus() === AuthenticationStatus.Authenticated}>
        <Navigate href={getRoute(Route.ProfileCompletionCheck)}/>
      </Match>
    </Switch>);
};
export default EnsureUnauthenticated;
