import { useI18n } from 'solid-compose';
import { Show, batch, createSignal, onCleanup, onMount } from 'solid-js';
import FieldError from '../form/FieldError';
import { useForm } from '../form/Form';
import { useFormResetter } from '../form/FormResetter';
import styles from './Group.module.css';
import { RadioProvider } from './context';
const Group = (props) => {
    let rootRef;
    const [error, setError] = createSignal('');
    const translate = useI18n();
    const formContextValue = useForm();
    const formResetterContextValue = useFormResetter();
    const [validatedOnce, setValidatedOnce] = createSignal(false);
    if (formContextValue) {
        onMount(() => {
            const removeElement = formContextValue.addElement({
                ref: rootRef,
                validate: () => {
                    validate();
                    return error() === '';
                }
            });
            onCleanup(removeElement);
        });
    }
    if (formResetterContextValue) {
        onMount(() => {
            const removeElement = formResetterContextValue.addElement({
                reset: () => {
                    batch(() => {
                        setValidatedOnce(false);
                        setError('');
                    });
                }
            });
            onCleanup(removeElement);
        });
    }
    function handleChange(value) {
        formContextValue && formContextValue.clearError();
        props.onChange(value);
        if (validatedOnce()) {
            validate();
        }
    }
    function validate() {
        setValidatedOnce(true);
        if (props.required && !props.checkedValue) {
            setError(translate('select_radio'));
            return;
        }
        setError('');
    }
    return (<RadioProvider checkedValue={props.checkedValue} setCheckedValue={handleChange}>
      <div ref={rootRef} class={styles.radioGroup} classList={{ [styles.hasError]: !!error() }}>
        <Show when={props.label}>
          <div class={styles.label}>
            {props.label}
          </div>
        </Show>

        {props.children}

        <FieldError error={error()}/>
      </div>
    </RadioProvider>);
};
export default Group;
